import React, { Component } from 'react';
import {Tabs, Tab } from 'react-bootstrap';


class TabContainer extends React.Component {
  // Props:
  //  tabs: list of 'pages' to be contained in tabs (in order)
  //  tabTitles: display names for the tabs (in order)
  //  key: default selected tab index
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);
	// console.log("TabContainer constructor - this.props: ", this.props);
    this.state = {
      key: this.props.defaultKey != undefined ? this.props.defaultKey : 0
    };
  }

  handleSelect(key) {
    this.setState({ key });
  }

  render() {
    return (

      <Tabs
        activeKey={this.state.key}
        onSelect={this.handleSelect}
        id={this.props.id}
      >
        {this.props.tabs.map((tab, i) => {     
           // console.log("Entered");                 
           // Return the element. Also pass key     
           return (<Tab eventKey={i} title={this.props.tabTitles[i]}> {tab} </Tab>);
        })}
      </Tabs>
    );
  }
}

export default TabContainer;
