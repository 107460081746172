export default {
	"buttons": {
		"newSalesOrder": "Nuevo pedido de venta",
		"newFormula": "Nueva fórmula",
		"newLabRequest": "Nueva solicitud de laboratorio",
		"newComponent": "Nueva componente",
		"newPurchaseOrder": "Nueva orden de compra",
		"add": "Añadir",
		"remove": "Retirar",
		"delete": "Borrar",
		"deleteOrder": "Eliminar Orden",
		"close": "Cerrar",
		"cancel": "Cancelar",
		"saveChanges": "Guardar Cambios",
		"addNewFormula": "Añadir nueva Fórmula",
		"deleteFormula": "Eliminar Fórmula",
		"deleteComponent": "Eliminar Componente",
		"deleteUser": "Borrar usuario",
		"deleteVendor": "Eliminar proveedor",
		"deleteShipper": "Eliminar remitente",
		"deleteSubstrate": "Eliminar sustrato",
		"specOutput": "Spec Output",
		"pdf": "PDF",
		"xls": "XLS",
		"submit": "Enviar",
		"approveNotes": "Aprobar Notas",
		"sendEmail": "Enviar correo electrónico",
		"noEmail": "Sin correo electrónico",
		"changePassword": "Cambia la contraseña",
		"sendResetCode": "Enviar código de reinicio",
		"back": "Espalda",
		"validate": "Validar",
		"combineBatches": "Combinar lotes",
		"uncombineBatches": "Uncombine lotes",
		"showAll": "Mostrar todo",
		"hideAll": "Ocultar todo",
		"billOfLadingInstructions": "Instrucciones de conocimiento de embarque",
		"populate": "Poblar",
		"update": "Actualizar",
		"refresh": "Refrescar",
		"updateActual": "Actualizar Actual",
		"export": "Exportar",
		"yes": "sí",
		"no": "no",
		"newTask": "Nueva tarea",
		"uploadCRMReport": "Subir informe de CRM",
		"deleteTask": "Eliminar tarea",
		"newCallReport": "Informe de nueva llamada",
		"deleteCallReport": "Eliminar informe de llamadas",
		"uploadCallReport": "Subir informe de llamadas",
		"upload": "Subir",
		"exportPDF": "Exportar a PDF",
		"exportPDFEnglish": "Exportar a PDF en Engligh",
		"exportPDFSpanish": "Exportar a PDF en Español",
		"inactivate": "Inactivar",
		"qboSync": "Sincronizar con QBO",
	},
}
