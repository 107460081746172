import React, { Component } from 'react';
import {Modal, Button, Form, Row, Col } from 'react-bootstrap';
import InputText from '../common_components/InputText.js';
import {getAPI, postAPI, getLoggedInUser} from '../common_components/common.js';
import Config from '../Config';
const { Translate } = require('react-i18nify');

class ContactUsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailSubject: '',
			emailBody: '',
			bSending: false,
		};
		
		this.sendEmail = this.sendEmail.bind(this);
	}
	componentDidMount() {
	}
	sendEmail() {
		
		const loggedInUser = getLoggedInUser();
		// console.log("sendEmail() called - loggedInUser: ", loggedInUser);
		let body = this.state.emailBody;
		body = body.replace("\n", "<br />");
		const US_Mex = Config.locale === "EN_US" ? "US" : "Mexico"
		body += "<br /> " + loggedInUser.displayName + ",";
		body += "<br /> BCM Inks MyInkIQ " + US_Mex;
		
		this.setState({bSending: true});
		let emailsDto = {
			subject: this.state.emailSubject, 
			body: body, 
			toAddresses: "bcmsoftwaresupport@lamproslabs.com",
			// ccAddresses: "sage@lamproslabs.com",
			fromName: loggedInUser.displayName,
			fromEmail: "cs@bcminks.com",
			headers: ["Reply-To: " + loggedInUser.emailAddress]
		};
		postAPI("emails/0/send", emailsDto).then(res => {
			if(res.error)
				alert(res.error.message);
			this.setState({bSending: false});
			this.props.onClose();
		});
	}
	render() {
		const content = <Form className='form-page' horizontal>
			<Row>
				<Col md={12}>
					<InputText label={<Translate value="components.settings.subject" />} 
						id={"emailSubject"} name={"emailSubject"} action={(e) => {
							// console.log("subject changed: ", e.target.value); 
							this.setState({emailSubject: e.target.value})
						}}
						value={this.state.emailSubject} 
					/></Col>
				<Col md={12}>
					<InputText 
					label={<Translate value="components.settings.message" />} id={"emailBody"} 
					name={"emailBody"} action={(e) => {
							// console.log("body changed: ", e.target.value); 
							this.setState({emailBody: e.target.value})
						}} type={"TextArea"} 
					value={this.state.emailBody}  
				/>
				</Col>
			</Row>
		</Form>
		return(<Modal show={this.props.visible} bsSize="medium">
			<Modal.Header>
				<Modal.Title><Translate value='components.settings.contactUs' /></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={this.props.onClose}>{<Translate value='components.settings.close' />}</Button>
				<Button onClick={this.sendEmail} bsStyle='primary' disabled={this.state.bSending}>
					{<Translate value='components.settings.send' />}
				</Button>
			</Modal.Footer>
		</Modal>)
	}
}


export default ContactUsForm;