import React, { Component } from 'react';
import Sidebar from './Sidebar';
import {Grid, Row, Col } from 'react-bootstrap';
import PurchaseOrder from '../purchase-orders/PurchaseOrder.js';
import Formula from '../formulas/Formula.js';
import InkComponent from '../ink-components/InkComponent.js';
import Setting from '../settings/Setting.js';
import Order from '../orders/Order.js';
import Customer from '../customers/Customer.js';
import Inventory from '../inventory/Inventory.js';
import Report from '../reports/Report.js';
import Home from '../home/Home.js';
import Training from '../training/Training.js';
import HomePage from '../home/HomePage.js';
import UserForm from '../settings/UserForm.js';
import ContactUsForm from '../components/ContactUsForm.js';
import Header from './Header';
import './Dashboard.css'
import Config from '../Config';
import { getAPI, getLoggedInUser} from '../common_components/common.js';
const { Translate } = require('react-i18nify');

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeKey: 1,
			search: '',
			interval: null,
			showUserForm: false,
		};
		
		this.refresh = this.refresh.bind(this);
		this.search = this.search.bind(this);
	}

	//this fella will handle all navigtation to the main content div
	navigator = (e) => {
		this.setState({
			activeKey: e,
		});
	}

	refresh() {
		const activeKey = this.state.activeKey;
		this.setState({activeKey: -1}, () => this.setState({activeKey}));
	}

	handleUserMenuOptionClick(i) {
		switch(i) {
			case 1:
				this.setState({
					// activeKey: 8
					showUserForm: true,
				});
				break;
			case 2:
				clearInterval(this.state.interval);
				this.props.onLogout();
				break;
		}
	}
	
	search(txtSearch) {
		// console.log("Dashboard::search() - txtSearch: ", txtSearch);
		this.setState({
			search: txtSearch,
		});
	}
	
	async componentDidMount() {
		try {
			
			const interval = setInterval(async () => {
			  // const res = await fetch('https://api.apijson.com/...');
			  // const blocks = await res.json();
			  // console.log("async Dashboard::componentDidMount() called");
				getAPI("/users/keep").then((result) => {
				// console.log("result from keep connection: ", result);
					if(result['status'] == 401) {
						clearInterval(this.state.interval);
						this.props.onLogout();
						window.location.reload(); // Refresh page so that the login screen is displayed
					}
				},
				(error) => {console.log("error from keep connection: ", error);}
				);

			}, Config.keepConnInterval);
			this.setState({interval: interval});
		} catch(e) {
			console.log(e);
		}
	}
    
	render() {
		const navigationMap = {
			1: <HomePage search={this.state.search} />,
			2: <Order search={this.state.search}  />,
			3: <Formula search={this.state.search}  />,
			4: <InkComponent search={this.state.search} />,
			5: <PurchaseOrder search={this.state.search}  />,
			6: <Customer search={this.state.search}  />,
			7: <Inventory search={this.state.search}  />,
			8: <Setting search={this.state.search}  />,
			9: <Report search={this.state.search}  />,
			10: <Training search={this.state.search}  />
		};
		
		const currentPage = navigationMap[this.state.activeKey];
		// console.log("Dashboard::render() - this.state.activeKey: ", this.state.activeKey, ", currentPage: ", currentPage);
		
		const loggedInUser = getLoggedInUser();
		
		const editUserForm = this.state.showUserForm ? <UserForm
			visible={this.state.showUserForm}
			onClose={() => this.setState({showUserForm: false})}
			onSave={() => {
				this.setState({showUserForm: false});
			}}
			activeCustomers={[]}
			editUserId={loggedInUser.id}
			bEditProfile={true}
		/> : "";
		

		return (
			<div id="Dashboard-container">
				<Header
					userMenuOptions={[
						{label: <Translate value="components.labels.settings" />, onClick: () => this.handleUserMenuOptionClick(1)},
						{label: <Translate value="components.labels.logout" />, onClick: () => this.handleUserMenuOptionClick(2)},
					]}
					search={this.search}
				/>
				<Grid fluid>
					<Row>
						<Col md={12} mdHidden lgHidden>
							<Sidebar navigateTo={this.navigator} refresh={this.refresh} selectedKey={this.state.activeKey} top />
						</Col>
					</Row>
					<Row>
						<Col md={1} xsHidden smHidden>
							<Sidebar navigateTo={this.navigator} selectedKey={this.state.activeKey} refresh={this.refresh}/>
						</Col> 
						<Col md={11}>
							{currentPage}
						</Col>
					</Row>
				</Grid>
				<div id='footer'>
					<Row>
						<Col md={12} lg={12} sm={12}>
		<div>©2018 | LAMPROS LABS <br /> <Translate value={"components.labels.allRightsReserved"} /></div>
						</Col>
					</Row>
				</div>
				{editUserForm}
			</div>
		);
	}
}

export default Dashboard;
