export default {
	"nav":{
		"dashboard": "Dashboard",
		"orders": "Orders",
		"components": "Components",
		"formulas": "Formulas",
		"po": "Purchase Orders",
		"customers": "Customers",
		"inventory": "Inventory",
		"settings": "Settings",
		"reports": "Reports",
		"tasks": "Tasks",
		"CRMReports": "CRM Reports",
	}
}
