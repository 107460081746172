import React, { Component } from 'react';
import DataDropdown from '../common_components/DataDropdown.js';
import {Row, Col, Button} from 'react-bootstrap';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import {mapArrayToObject, getAPI, postAPI, barChartOptions, pieChartOptions, lineChartOptions, getLoggedInUser, constants, toCurrency, objMonths} from '../common_components/common.js';
// var config = require('../config.js');
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');


//	PROPS params
//	New approach: Filter the data within the component before passing it on to the grid.
class MonthlyExpenditures extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			overallData: [],
			salesRepId: null,
			userId: null,
			salesRepData: [],
			monthlyData: [],
			bSalesManagerOrVIP: false,
			salesRepList: [],
			categoriesData: [],
			year: (new Date()).getFullYear(),
			month: (new Date()).getMonth() + 1,
			yearsList: [],
			salesGoalsAndBudgets: [],
		};
		this.salesRepChanged = this.salesRepChanged.bind(this);
		this.yearChanged = this.yearChanged.bind(this);
		this.monthChanged = this.monthChanged.bind(this);
		this.getSalesRepData = this.getSalesRepData.bind(this);
		this.refreshData = this.refreshData.bind(this);
		
	}
	
	
	componentDidMount() {
		this.refreshData(this.state.year);
	}
	
	refreshData(pYear) {
		const loggedInUser = getLoggedInUser();
		const userId = loggedInUser['id'];
		const bSalesManagerOrVIP = 
		loggedInUser.userTypeId == constants.sales_manager_type_id || 
		loggedInUser.userTypeId == constants.vip_type_id;
		
		//////////////////// Samee - Get Sales Total and Goals ///////////////////////////
		
		getAPI("/common/salesgoalsandbudgets?year=" + pYear).then(resSalesGoalsAndBudgets => {
			const salesGoalsAndBudgets = resSalesGoalsAndBudgets['data'];
		
			var salesRepList = salesGoalsAndBudgets.map(row => ({
				id: row.userId,
				name: row.userDisplayName,
			}));
			
			getAPI("/common/monthlyexpendituresandbudgets?year=" + pYear).then(monthlyExpendituresAndBudgets => {
				// console.log("monthlyExpendituresAndBudgets: ", monthlyExpendituresAndBudgets);
				var overallData = [];
				var categoriesData = [];
				var monthlyData = [];
				// var salesRepList = [];
				
				monthlyExpendituresAndBudgets['data'].reduce(function(res, value) {
					
					var arrSalesRepName = value.salesRepName.split(" ");
					const salesGoalAndBudgetForSalesRep = salesGoalsAndBudgets.filter(row => row.userId == value.salesRepId)[0];
					const yearlyExpenseBudgetForSalesRep = salesGoalAndBudgetForSalesRep != undefined ? salesGoalAndBudgetForSalesRep['totalExpenseBudget'] : 0;
					
					// OverallData
					if(res[value.salesRepId] == undefined) {
						res[value.salesRepId] = { 
							userId: value.salesRepId, 
							salesRep: value.salesRepName, 
							expenseBudget: yearlyExpenseBudgetForSalesRep, // value.totalExpenseBudget, 
							expenditureAmount: 0.0,
							yearlyexpenseBudget: yearlyExpenseBudgetForSalesRep, // value.totalExpenseBudget, 
						};
						overallData.push(res[value.salesRepId]);
						/*salesRepList.push({
							id: value.salesRepId, 
							name: value.salesRepName, 
							firstName: arrSalesRepName[0], 
							lastName: arrSalesRepName[1]
						});*/
					}
					// res[value.salesRepId].expenseBudget = value.totalExpenseBudget;
					res[value.salesRepId].expenditureAmount += value.expenditureAmount;
					
					
					// categoriesData
					if(res[value.salesRepId][value.expenditureCategoryLookupId] == undefined) {
						res[value.salesRepId][value.expenditureCategoryLookupId] = { 
							userId: value.salesRepId, 
							salesRep: value.salesRepName, 
							expenseBudget: value.totalExpenseBudget, 
							expenditureAmount: 0.0,
							customerId: value.expenditureCategoryLookupId,
							customerName: value.expenditureName,
							yearlyexpenseBudget: value.totalExpenseBudget,
						};
						categoriesData.push(res[value.salesRepId][value.expenditureCategoryLookupId]);
					}
					// res[value.salesRepId][value.expenditureCategoryLookupId].expenseBudget = value.totalExpenseBudget;
					res[value.salesRepId][value.expenditureCategoryLookupId].expenditureAmount += value.expenditureAmount;
					
					// MonthlyData
					if(res[value.salesRepId][value.expenditureCategoryLookupId][value.month] == undefined) {
						res[value.salesRepId][value.expenditureCategoryLookupId][value.month] = { 
							userId: value.salesRepId, 
							customerName: value.expenditureName,
							expenditureAmount: 0.0,
							month: value.month,
						};
						monthlyData.push(res[value.salesRepId][value.expenditureCategoryLookupId][value.month]);
					}

					res[value.salesRepId][value.expenditureCategoryLookupId][value.month].expenditureAmount += value.expenditureAmount;
					
					
					return res;
				}, {});

				// console.log("overallData: ", overallData, ", categoriesData: ", categoriesData, ", monthlyData: ", monthlyData);
				
				// let salesRepId = !bSalesManagerOrVIP ? userId : (salesRepList[0] != undefined ? salesRepList[0]['id'] : 0);
				
				var currentYear = (new Date()).getFullYear();
				var yearsList = [];
				for(var startYear = currentYear; startYear >= 2016 ; startYear--) 
					yearsList.push({id: startYear, name: startYear});
				
				this.setState({
					overallData: overallData,
					userId: userId,
					monthlyData: monthlyData,
					// salesRepId: salesRepId,
					bSalesManagerOrVIP: bSalesManagerOrVIP,
					salesRepList: salesRepList,
					categoriesData: categoriesData,
					yearsList: yearsList,
					year: pYear,
					salesGoalsAndBudgets: salesGoalsAndBudgets,
				});
			});
		});

	}
	
	yearChanged(event) {
		const pYear = event.target.value;
		this.refreshData(pYear);
	}
	
	monthChanged(event) {
		const pMonth = event.target.value;
		this.setState({
			month: pMonth,
		});
	}
	
	salesRepChanged(event) {
		const salesRepId = event.target.value;
		this.setState({
			salesRepId: salesRepId,
		});
	}
	
	getSalesRepData(salesRepId) {
		getAPI("/common/salesgoals/customers?userId=" + salesRepId).then(salesRepData => {
			// console.log("salesRepData: ", salesRepData);
			this.setState({
				salesRepData: salesRepData['data'],
				salesRepId: salesRepId,
			});
		});
	}
	
	
	
	
	
	render() {
		HC_exporting(Highcharts);
		let salesRepsMapped = mapArrayToObject(this.state.salesRepList, 'id', 'name');
		let yearsMapped = mapArrayToObject(this.state.yearsList, 'id', 'name');
		// console.log("Order::render() called - this.props: ", this.props, ", this.state.data: ", this.state.data);
		const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const objCurrentDate = new Date();
		const currentYear = this.state.year; // objCurrentDate.getFullYear();
		const currentMonth = this.state.month; // objCurrentDate.getMonth() + 1; // Month returned is 0 based.
		let salesRepId = this.state.salesRepId;
		if(!this.state.bSalesManagerOrVIP && salesRepId == null)
			salesRepId = this.state.userId;
		
		var salesRepData = this.state.categoriesData.filter(obj => obj.userId == salesRepId); // this.state.salesRepData;
		var overallData = this.state.overallData;
		var monthlyData = this.state.monthlyData;
		
		
		let salesRepName = "";
		let salesRepYearlyexpenseBudget = 0;
		const salesRepRow = this.state.salesGoalsAndBudgets.filter(obj => obj.userId == salesRepId)[0]; // overallData.filter(obj => obj.userId == salesRepId)[0];
		if(salesRepRow != undefined) {
			// Samee use yearlyexpenseBudget instead
			salesRepYearlyexpenseBudget = salesRepRow.totalExpenseBudget; // yearlyexpenseBudget; // salesRepRow.expenseBudget;
			salesRepName = salesRepRow.userDisplayName; // salesRep;
		}
		
		
		let overallYearlyexpenseBudget = 0;
		// overallData.forEach(obj => {
			// Samee use yearlyexpenseBudget instead
		//	overallYearlyexpenseBudget += obj.yearlyexpenseBudget; // obj.expenseBudget;
		// });
		
		this.state.salesGoalsAndBudgets.forEach(obj => {
			// Samee use totalExpenseBudget instead
			overallYearlyexpenseBudget += obj.totalExpenseBudget;
		});
		
		// console.log("salesRepId: ", salesRepId, ", salesRepName: ", salesRepName, ", currentYear: ", currentYear, ", currentMonth: ", currentMonth, ", salesRepYearlyexpenseBudget: ", salesRepYearlyexpenseBudget, ", overallYearlyexpenseBudget: ", overallYearlyexpenseBudget);
		
		/////////////////////// SALES REP CHARTS /////////////////////
		var salesRepPieChartOptions = JSON.parse(JSON.stringify(pieChartOptions));
		var salesRepBarChartOptions = JSON.parse(JSON.stringify(barChartOptions));
		var salesRepLineChartOptions = JSON.parse(JSON.stringify(lineChartOptions));
		
		/////////////////////////// Pie Chart //////////////////////////////
		salesRepPieChartOptions.title.text = "Total % of " + currentYear + " Expenditures - " + salesRepName;
		salesRepPieChartOptions.series[0].data = salesRepData.map(obj => ({name: obj.customerName, x: obj.expenseBudget, y:obj.expenditureAmount}));
		salesRepPieChartOptions.tooltip.pointFormat = '<b>{point.name}</b>: <b>Total Expenditure:</b>{point.y:,.2f} - <b>{point.percentage:.2f}%</b>';
		
		/*
		salesRepPieChartOptions.series[0].data = monthlyData.filter(row => (row.userId == salesRepId && row.month == currentMonth)).map(obj => {
			const expenseBudgetRow = salesRepData.filter(salesRepRow => salesRepRow.customerName == obj.customerName)[0];
			const expenseBudget = expenseBudgetRow != undefined ? expenseBudgetRow.expenseBudget / 12 : 0;
			return {name: obj.customerName, x: expenseBudget, y:obj.expenditureAmount}
		});
		*/
		
		///////////////////////// Bar Chart //////////////////////////
		let totalSalesAmount = 0;
		let totalMonthlyExpenseBudget = salesRepYearlyexpenseBudget / 12; // 0;
		monthlyData.filter(row => (row.userId == salesRepId && row.month == currentMonth)).map(obj => {
			salesRepBarChartOptions.xAxis.categories.push(obj.customerName);
			const expenseBudgetRow = salesRepData.filter(salesRepRow => salesRepRow.customerName == obj.customerName)[0];
			const expenseBudget = expenseBudgetRow != undefined ? Math.round((expenseBudgetRow.expenseBudget / 12) * 100) / 100 : 0;
			// salesRepBarChartOptions.series[0].data.push(expenseBudget);
			// totalMonthlyExpenseBudget += expenseBudget;
			salesRepBarChartOptions.series[1].data.push(obj.expenditureAmount);
			totalSalesAmount += obj.expenditureAmount;
		});
		let percentExpenditure = totalMonthlyExpenseBudget > 0 ? ((totalSalesAmount * 100) / totalMonthlyExpenseBudget) : 0;
		// if(percentExpenditure > 100)
		//	percentExpenditure = 100.0;
		percentExpenditure = percentExpenditure.toFixed(2);
		salesRepBarChartOptions.title.text = salesRepName + " (Monthly Budget: " + toCurrency(totalMonthlyExpenseBudget) + ", Total Expenditure: " + toCurrency(totalSalesAmount) + " - " + percentExpenditure + "%)";
		salesRepBarChartOptions.series[0].name = 'Budget';
		salesRepBarChartOptions.series[1].name = 'Expenditure';
		salesRepBarChartOptions.series[0].showInLegend = false;
		
		////////////////////////// Line Chart ////////////////////////////
		salesRepLineChartOptions.title.text = currentYear + " Expenditures - " + salesRepName;
		salesRepLineChartOptions.series[0].name = 'Budget';
		salesRepLineChartOptions.series[1].name = 'Expenditure';
		
		
		let accumulatedexpenseBudget = 0;
		let accumulatedexpenditureAmount = 0;
			
		monthsList.map((month, i) => {
			const expenseBudget = salesRepYearlyexpenseBudget / 12;
			let expenditureAmount = 0;
			monthlyData.filter(obj => obj.userId == salesRepId 
				&& obj.month == (i + 1)).forEach(row => {
				expenditureAmount += row.expenditureAmount;	
			});
			/*
			salesRepBarChartOptions.xAxis.categories.push(i + 1);
			salesRepBarChartOptions.series[0].data.push(expenseBudget);
			salesRepBarChartOptions.series[1].data.push(expenditureAmount);
			*/
			accumulatedexpenseBudget += expenseBudget;
			accumulatedexpenditureAmount += expenditureAmount;
			
			salesRepLineChartOptions.xAxis.categories.push(month);
			salesRepLineChartOptions.series[0].data.push(Math.round(accumulatedexpenseBudget * 100) / 100);
			salesRepLineChartOptions.series[1].data.push(Math.round(accumulatedexpenditureAmount * 100) / 100 );
		});
		

		
		
		/////////////////////// OVERALL CHARTS /////////////////////
		var overallPieChartOptions = JSON.parse(JSON.stringify(pieChartOptions));
		var overallBarChartOptions = JSON.parse(JSON.stringify(barChartOptions));
		var overallLineChartOptions = JSON.parse(JSON.stringify(lineChartOptions));
		
		
		// Pie Chart
		overallPieChartOptions.title.text = "Percent of Overall Expenditures";
		
		// Samee - User yearlyexpenseBudget instead
		// overallPieChartOptions.series[0].data = overallData.map(obj => ({name: obj.salesRep, x: obj.expenseBudget, y:obj.expenditureAmount}));
		overallPieChartOptions.series[0].data = overallData.map(obj => ({name: obj.salesRep, x: obj.yearlyexpenseBudget, y:obj.expenditureAmount}));
		overallPieChartOptions.tooltip.pointFormat = '<b>{point.name}</b>: <b>Total Expenditure:</b>${point.y:,.2f} <b>Total Budget:</b>${point.x:,.2f} - <b>{point.percentage:.2f}%</b>';
		
		
		// Bar Chart
		overallBarChartOptions.title.text = "Individual Budget/Expenditures";
		overallBarChartOptions.xAxis.categories = overallData.map(obj => obj.salesRep);
		// Samee - User yearlyexpenseBudget instead
		// overallBarChartOptions.series[0].data = overallData.map(obj => obj.expenseBudget);
		overallBarChartOptions.series[0].data = overallData.map(obj => obj.yearlyexpenseBudget);
		overallBarChartOptions.series[1].data = overallData.map(obj => obj.expenditureAmount);
		overallBarChartOptions.series[0].name = 'Budget';
		overallBarChartOptions.series[1].name = 'Expenditure';
		
		
		// Line Chart
		overallLineChartOptions.title.text = "Overall Budget/Expenditures";
		overallLineChartOptions.series[0].name = 'Budget';
		overallLineChartOptions.series[1].name = 'Expenditure';
		
		
		let overallAccumulatedexpenseBudget = 0;
		let overallAccumulatedexpenditureAmount = 0;
		
		monthsList.map((month, i) => {
			const expenseBudget = overallYearlyexpenseBudget / 12;
			let expenditureAmount = 0;
			const dataRows = monthlyData.filter(obj => obj.month == (i + 1));
			if(dataRows != undefined) {
				dataRows.forEach(row => {
					expenditureAmount += row.expenditureAmount;
				});
			}
			
			overallAccumulatedexpenseBudget += expenseBudget;
			overallAccumulatedexpenditureAmount += expenditureAmount;
			
			overallLineChartOptions.xAxis.categories.push(month);
			overallLineChartOptions.series[0].data.push(Math.round(overallAccumulatedexpenseBudget * 100) / 100 );
			overallLineChartOptions.series[1].data.push(Math.round(overallAccumulatedexpenditureAmount * 100) / 100  );
		});
		
		
		return (
			<div>
				<DataDropdown 
					label={<Translate value="components.labels.year" />} 
					id={'year'} name={'year'} data={yearsMapped} 
					action={this.yearChanged} value={this.state.year} 
				/>
				{this.state.bSalesManagerOrVIP && <DataDropdown 
					label={<Translate value="components.labels.salesRep" />} 
					id={'salesRep'} name={'salesRep'} data={salesRepsMapped} 
					action={this.salesRepChanged} value={this.state.salesRep} 
				/>}
				<br />
				<HighchartsReact
					highcharts={Highcharts}
					options={salesRepPieChartOptions}
				  />
				  <br />
				  <DataDropdown 
					label={<Translate value="components.labels.month" />} 
					id={'month'} name={'month'} data={objMonths} 
					action={this.monthChanged} value={this.state.month} 
				/>
				<HighchartsReact
					highcharts={Highcharts}
					options={salesRepBarChartOptions}
				  />
				  <HighchartsReact
					highcharts={Highcharts}
					options={salesRepLineChartOptions}
				  />
				  
				<br />
				{this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallPieChartOptions}
				  />}
				 {this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallBarChartOptions}
				  />}
				  <HighchartsReact
					highcharts={Highcharts}
					options={overallLineChartOptions}
				  />
			</div>
		);
	}
}

export default MonthlyExpenditures;