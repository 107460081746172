import React, {Component} from 'react';
import PopupMenu from '../common_components/PopupMenu.js';
import {Grid, Row, Col, Navbar, Form, FormControl, Button, Nav, NavItem} from 'react-bootstrap';
import I18n from '../translations/myinkiq-i18n.js';
import config from '../Config.js';
import ContactUsForm from '../components/ContactUsForm.js';
import Octicon, { Globe } from '@githubprimer/octicons-react';
const { Translate, Localize } = require('react-i18nify');

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			txtSearch: '',
			bShowContactUsForm: false,
		};
		this.onKeyUp = this.onKeyUp.bind(this);
		this.btnSearchClicked = this.btnSearchClicked.bind(this);
		this.txtSearchChanged = this.txtSearchChanged.bind(this);
	}

	onKeyUp(event) {
		const keyCode = event.keyCode;
		const txtSearch = this.state.txtSearch;
		// console.log("Header::onKeyUp() - txtSearch: ", txtSearch, " keyCode: ", keyCode);
		if(keyCode == 13)
		{
			// this.props.search(txtSearch);
		}
	}
	
	txtSearchChanged(event) {
		const txtSearch = event.target.value;
		// console.log("Header::txtSearchChanged() - txtSearch: ", txtSearch);
		this.props.search(txtSearch);
		this.setState({txtSearch: txtSearch});
	}

	btnSearchClicked(event) {
		event.preventDefault();
		/*
		var txtSearch = this.state.txtSearch;
		console.log("when button clicked, txtSearch: ", txtSearch);
		var currentPage = this.state.currentPage;
		var newPage = React.cloneElement(
		  currentPage,
		  {txtSearch: txtSearch}
		);
		this.setState({currentPage: newPage});
		*/

		// if(this.state.txtSearch != '')
		this.props.search(this.state.txtSearch);
	}

	render() {
		const contactUsForm = this.state.bShowContactUsForm ? <ContactUsForm
			visible={this.state.bShowContactUsForm}
			onClose={() => this.setState({bShowContactUsForm: false})}
		/> : "";
		
		return <div id='header'>
			<Row>
				<Col sm={4}>
					<div class="logo" style={{marginTop: '-.67em'}}>
						<img
							src='appLogo.png'
							alt='MyInkIQ logo'
						/>
					</div>
				</Col>
				<Col sm={4}>
					<div class="logo" style={{marginTop: '-.67em'}}>
						<img
							src={config.logoLocation}
							alt='Company logo'
						/>
					</div>
				</Col>
				<Col sm={4}>
					<Form inline id="header-form">
						<FormControl
							id="header-search"
							placeholder='Search...'
							bsSize='sm'
							onKeyUp={this.onKeyUp}
							onChange={this.txtSearchChanged}
							value={this.state.txtSearch}
						/>
						<Button type='submit' style={{display: 'none'}} />
						<PopupMenu
							link={<Translate value="components.labels.user" />}
							options={this.props.userMenuOptions}
						/>
						&emsp;
						<PopupMenu
							link={<Octicon icon={Globe} />}
							options={[
								{
									label: 'English',
									onClick: () => I18n.setLocale('en'),
								},
								{
									label: 'Español',
									onClick: () => I18n.setLocale('es'),
								},
							]}
						/>&nbsp;
						<a href='javascript:;' style={{color:'white', fontWeight:'bold'}} onClick={() => {
							this.setState({bShowContactUsForm: true});
						}}>Support Email</a>
						{contactUsForm}
					</Form>
				</Col>
			</Row>
		</div>
	}

	/*render() {
		return <div id='header'>
			<Navbar inverse>
				<Navbar.Brand>
					<div class="logo" style={{marginTop: '-.67em'}}>
						<img
							src='appLogo.png'
							alt='MyInkIQ logo'
							style={{
								width: '67%',
								height: 'auto',
							}}
						/>
					</div>
					</Navbar.Brand>
					<Navbar.Brand>
					<div class="logo" style={{marginTop: '-1em'}}>
						<img
							src={config.logoLocation}
							alt='Company logo'
							style={{
								width: 'auto',
								height: '57.7px',
							}}
						/>
					</div>
					</Navbar.Brand>
				
				<Nav pullRight>
					<Navbar.Text style={{margin: '.75em'}}>
						<Form inline>
							<FormControl
								placeholder='Search...'
								bsSize='sm'
								onKeyUp={this.onKeyUp}
								onChange={this.txtSearchChanged}
								value={this.state.txtSearch}
							/>
							<Button type='submit' style={{display: 'none'}} />
						</Form>
					</Navbar.Text>
					<Navbar.Text style={{margin: '.75em'}}>
						<PopupMenu
							link={<Translate value="components.labels.user" />}
							options={this.props.userMenuOptions}
						/>
					</Navbar.Text>
				</Nav>
			</Navbar>
		</div>;
	}*/
}

export default Header;
