let config = {
    baseURL: process.env.REACT_APP_BASE_API_URL,
    keepConnInterval: 300000, // 5 minutes - Time in milli seconds after which keep connection gets called
    currencyURL: 'https://www.mataf.net/en/currency/converter-USD-ESP?m1=1',
    reportURL: process.env.REACT_APP_REPORT_URL,
    env: '', 
    reportBtnStyle: 'info', // The way the Report buttons are styled on the forms
    locale: '', 
    weightSymbol: '', 
    currencySymbol: '', 
    weightConversionUnit: 2.2, 
    currencyConversionUnit: 20.44,
    logoLocation: '', 
    emptyContainerWeight: 40,
    dummyStateId: 0, // dummyStateId must be 0 for US, -1 for Mex 
    bcmInks: '',
	bcmInksId: 0,
};
/*
console.log("process.env.REACT_APP_ENV: ", process.env.REACT_APP_ENV, ", config: ", config);
if (process.env.REACT_APP_ENV === 'staging') {
	config.baseURL = "https://staging.myinkiq.com/myinkiq-0.1.0/";
	config.reportURL = "http://staging.myinkiq.com/birt/frameset";
}
*/
if (process.env.REACT_APP_ENV === 'mexico') {
    config.env = 'mex';
    config.locale = 'ES_MX';
    config.weightSymbol = 'KG';
    config.currencySymbol = 'Mex$';
    config.logoLocation = 'bcmMEX.png';
    config.dummyStateId = -1;
    config.bcmInks = 'BCM Inks de Mexico S. De R.L. De C.V.';
    config.bcmInksId = 1095;
	config.weightConversionUnit = 1;
	// config.reportURL = 'https://bcminks.mx.myinkiq.com/birt/frameset';
}
else {
    config.env = process.env.REACT_APP_ENV === 'staging' ? 'dev' : 'prod';
    config.locale = 'EN_US';
    config.weightSymbol = 'LB';
    config.currencySymbol = '$';
    config.logoLocation = 'bcmUSA.png';
    config.bcmInks = 'BCM INKS USA, INC.';
	config.bcmInksId = 499;
	// config.reportURL = 'https://reports.myinkiq.com/frameset';
	
	if(process.env.REACT_APP_ENV === 'staging') {
		// config.reportURL = 'https://reports.myinkiq.com/frameset'; 
		// config.reportURL = 'https://staging.myinkiq.com/birt/frameset';
	}
}

export default config;
