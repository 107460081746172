import React, { Component } from 'react';
import {Col, FormGroup, ControlLabel, FormControl, Checkbox, Radio, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import ReactAutocomplete from 'react-autocomplete';
import Select, { createFilter } from 'react-select';
import Datetime from 'react-datetime';
import Config from '../Config';
import './react-datetime.css';

class InputText extends Component {
	constructor(props) {
		super(props);
		this.state = {
			input: '',
		};
		this.change = this.change.bind(this);
		this.inputChanged = this.inputChanged.bind(this);
	}
	
	change(event) {
		//console.log("InputText::change() - event: ", event, ", this.props.name: ", this.props.name, "TYPE: ", this.props.type, "Value: ", this.props.value);
		event = event || {value: 0, label: ""};
		this.props.action(event, this.props.name, this.props.type, this.props.value); //, event.target.value, event.target);
	}
	inputChanged(event) {
		// console.log("InputText::inputChanged() - event: ", event, ", this.props.name: ", this.props.name, "TYPE: ", this.props.type, "Value: ", this.props.value, ", event.target.value: ", event.target.value);
		event = event || {value: 0, label: ""};
		this.props.inputChanged(event, this.props.name, this.props.type, this.props.value); //, event.target.value, event.target);
	}
	
	
	render() {
		var html = "";

		var label = this.props.label ? this.props.label : "";
		var asterisk = this.props.required ? '*' : '';
		const errMsg = this.props.errMsg ? <span style={{color:'red'}}>{this.props.errMsg}</span> : "";

		const dateFormat = Config.locale.toLowerCase() == "es_mx" ? "DD/MM/YYYY" : "MM/DD/YYYY";
		var type = '';
		
		switch(this.props.type) 
		{
			case 'TextArea':
				type = <FormControl disabled={this.props.disabled} componentClass='textarea' onChange={this.change} value={this.props.value} />;
				break;
			
			case 'CheckBox':
				type = <Checkbox
					disabled={this.props.disabled}
					onClick={this.change}
					checked={this.props.value}
					value={this.props.value}
					style={{
						top: '-4px',
						width: '16px',
					}}
				/>;
				break;

			case 'Radio':
				type = <Radio disabled={this.props.disabled} onClick={this.change} defaultChecked={this.props.defaultChecked} name={this.props.name} value={this.props.value}></Radio>
				break;
				
			 case 'AutoComplete':
			 	// console.log("this.props.data when rendering AutoComplete: ", this.props.data);
			 	type = <ReactAutocomplete
				 		disabled={this.props.disabled}
			 			items={this.props.data}
			 			shouldItemRender={(item, value) => item.label != null && value != null && item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
			 			getItemValue={item => item.label}
			 			// getItemValue={item => item.id}
			 			menuStyle={{
			 				border: '1px solid black',
			 				background: 'rgba(255, 255, 255, 0.9)',
			 				padding: '2px 0',
			 				fontSize: '90%',
			 				position: 'absolute',
			 				top: 'auto',
			 				left: 'auto',
			 				width: '25em',
							height: '20em',
			 				zIndex: 1,
			 				overflow: 'auto',
			 				// maxHeight: '100%', // TODO: don't cheat, let it flow to the bottom
			 			}}
			 			renderItem={(item, highlighted) =>
			 			  <div
			 				key={item.id}
			 				style={{
			 					background: highlighted ? '#337ab7' : 'white',
			 					color: highlighted ? 'white' : 'black',
			 					padding: '.17em',
			 				}}
			 			  >
			 				{item.label}
			 			  </div>
			 			}
			 			value={this.props.value ? this.props.value : this.state.value}
			 			onChange={e => {
							this.setState({ value: e.target.value });
							this.inputChanged(e);
						}}
			 			// onSelect={(value, item) => this.setState({ value: item.label })}
			 			// onSelect={(value, item) => console.log('value: ', value, ", item: ", item)}
			 			onSelect={(value, item) => {
			 				this.setState({ value: item.label });
			 				this.change(item);
			 			}}
			 		  />;
			 	break;
			
			case 'DateTime':
				type = <Datetime inputProps={{disabled: this.props.disabled }} 
					value={this.props.value} dateFormat={dateFormat} timeFormat={false} 
					onChange={this.change} closeOnSelect={true} 
				/>;
				break;
			
			case 'DateTimestamp':
				type = <Datetime  inputProps={{disabled: this.props.disabled }}  
					value={this.props.value} dateFormat={dateFormat} timeFormat={"h:mm A"} 
					onChange={this.change} closeOnSelect={true} 
				/>;
				break;
				
			case 'Label':
				type = <span class='label-value'>{this.props.value}</span>;
				break;
			
			case 'MultiSelect':
				type = <Select
					options={this.props.data}
					isDisabled={this.props.disabled}
					isMulti={true}
					onChange={this.change}
					value={this.props.value}
					isClearable={true}
					styles={{
						valueContainer: (provided, state) => ({
							...provided,
							maxHeight: this.props.maxHeight,
							overflowY: 'auto',
						}),
						control: (provided, state) => ({
							...provided,
							display: 'block',
						})
					}}
				/>;
				break;
			
			case 'SingleSelect':
				let filterOption = createFilter({
					matchFrom: 'any',
					stringify: option => `${option.label}`,
				});
				
				if(this.props.customFilterSort) {
					filterOption = (candidate, input) => {
					  // console.log("candidate: ", candidate, ", input: ", input);
					  const label = candidate.label;
					  const indexSpace = label.indexOf(" ");
					  let componentNumber = label.substring(0, indexSpace);
					  let componentName = label.substring(indexSpace + 2, label.length-1);
					  
					  // console.log("componentNumber: ", componentNumber, ", componentName: ", componentName);
					  if(!isNaN(input)) {
						  return componentNumber.includes(input) || componentName.includes(input);
					  } else {
						  return componentName.toLowerCase().includes(input.toLowerCase());
					  }
					  // return true; // candidate.data.__isNew__ || candidate.label.includes(input);
					};
				}
			
				type = <Select options={this.props.data} isDisabled={this.props.disabled} 
				filterOption={filterOption}
				isMulti={false} onChange={this.change} value={this.props.value} isClearable={true} />;
				break;

			case 'Password':
				type = <FormControl disabled={this.props.disabled} type='password' onChange={this.change} value={this.props.value} />;
				break;
			
			case 'File':
				type = <input type="file" disabled={this.props.disabled} onChange={this.change} value={this.props.value} />;
				break;
				
			default: 
				type = <FormControl disabled={this.props.disabled} type='text' onChange={this.change} value={this.props.value} />;
				break;
		}
		
		const control = this.props.tooltip ? <OverlayTrigger
			placement='top'
			delayShow={500}
			overlay={<Tooltip>{this.props.tooltip}</Tooltip>}
		>
			{type}
		</OverlayTrigger> : type;

		const labelText = <ControlLabel>{label}: <span className='required'>{asterisk}</span></ControlLabel>;
		const labelElement = this.props.tooltip ? <OverlayTrigger
			placement='top'
			delayShow={500}
			overlay={<Tooltip>{this.props.tooltip}</Tooltip>}
		>
			{labelText}
		</OverlayTrigger> : labelText;
		var objIndent = this.props.bIndent ? {marginLeft: '20px'} : {};
		return <FormGroup bsSize='sm' style={objIndent}>
			<Col sm={4}>
				{labelElement}
			</Col>
			<Col sm={8}>
				{control}{errMsg}
			</Col>
		</FormGroup>
	}
}

export default InputText;
