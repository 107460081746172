import React, { Component } from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown.js';
import {getAPI, convertTimestampToDate} from '../common_components/common.js';
import UserForm from './UserForm';
import Config from '../Config';
const { Translate } = require('react-i18nify');

export default class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiUrl: Config.baseURL + 'users/list',
			columns: [
				<Translate value='components.settings.firstName' />,
				<Translate value='components.settings.lastName' />,
				<Translate value='components.settings.userName' />,
				<Translate value='components.settings.email' />,
				<Translate value='components.settings.type' />,
				<Translate value='components.settings.lastUpdated' />,
			],
			fields: ['firstName', 'lastName', 'userName', 'emailAddress', 'userType', 'lastUpdatedDate'],
			formatColumns: {5: {'date': true}},
			data: [],
			offset: 0,
			limit: 2500,
			showUserForm: false,
			idField: 'id',
			editUserId: null,
			loading: true,
			autoRefresh: true,
			interval: null,
			activeCustomers: [],
			userRetired: '0',
		}

		this.showEditUserForm = this.showEditUserForm.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.userStateChanged = this.userStateChanged.bind(this);
	}

	//callback for clicking datagrid rows
	showEditUserForm(dataIn) {
		// console.log("SETTINGS 2xclick HANDLER", dataIn, dataIn[this.state.idField]);
		this.setState({
			editUserId: dataIn[this.state.idField],
			showUserForm: true,
		});
	};

	userStateChanged(event) {
		var val = event.target.value;
		// console.log("userStateChanged: ", val);
		this.setState({
			userRetired: val,
		});
	}
	
	refreshData() {
		this.setState({loading: true});
		getAPI("users/list").then(resUsers => {
			getAPI("customers/list").then(resCustomers => {
				// It seems all customers are to be fetched.
				const activeCustomers = resCustomers['data']; // .filter(obj => obj.status == 'Active');
				const activeUsers = resUsers['data']; //.filter(users => users.retired == false);
				this.setState({
					data: activeUsers,
					activeCustomers: activeCustomers,
					loading: false,
				});
			});	
		});
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showUserForm) {
				// console.log("Users::componentDidMount() - refreshData being called....");
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	render() {
		let data = this.state.data;
		
		if(this.state.userRetired != '')
		{
			// console.log("this.state.userRetired: ", this.state.userRetired, ", data: ", data);
			data = data.filter(obj => obj.retired == this.state.userRetired);
		}

		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return this.state.formatColumns[i] && this.state.formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		const editUserForm = this.state.showUserForm ? <UserForm
					visible={this.state.showUserForm}
					onClose={() => this.setState({showUserForm: false})}
					onSave={() => {
						this.setState({showUserForm: false});
						this.refreshData();
					}}
					activeCustomers={this.state.activeCustomers}
					editUserId={this.state.editUserId}
				/> : "";
		return (
			<div>
				<DataGrid
					columns={this.state.columns}
					fields={this.state.fields}
					data={data}
					formatColumns={this.state.formatColumns}
					onDoubleClick={this.showEditUserForm}
					loading={this.state.loading}
					heightOffset={190}
				/>
				
				<Row>
					<Col md="2"></Col>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.type" />} 
								action={this.userStateChanged} data={{'0': 'Active', '1': 'Inactive'}} 
								value={this.state.userRetired}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Button bsSize='sm' onClick={() => this.setState({showUserForm: true, editUserId: null})}>
					<Translate value='components.settings.newUser' />
				</Button>
					</Col>
				</Row>
				
				
				{editUserForm}
			</div>
		);
	}
}
