import React, { Component } from 'react';
import {Button, Form, Modal, Row, Col} from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataGrid from '../common_components/DataGrid.js';
import {mapArrayToObject, getAPI, postAPI, validateForm, showFormValidationAlert} from '../common_components/common.js';
import Config from '../Config';
const { Translate } = require('react-i18nify');

export default class ContactTypes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// apiUrl: Config.baseURL + 'administration/contactTypes/list',
			apiUrl: Config.baseURL + 'common/contacttypes',
			columns: [
				<Translate value='components.settings.Position' />,
			],
			fields: ['name'],
			formatColumns: {},
			data: [],
			offset: 0,
			limit: 2500,
			showNewContactTypeForm: false,
			editContactTypeId: null,
			loading: true,
			autoRefresh: true,
			interval: null,
		};
		this.showEditContactTypeForm = this.showEditContactTypeForm.bind(this);
		
	}
	
	refreshData() {
		this.setState({loading: true});
		getAPI("common/contacttypes").then(resContactTypes => {
			this.setState({
				data: resContactTypes['data'],
				loading: false,
			});
		});	
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showNewContactTypeForm) {
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	showEditContactTypeForm(dataIn) {
		this.setState({
			editContactTypeId: dataIn['id'],
			showNewContactTypeForm: true,
		});
	}


	
	render() {
		let data = this.state.data;
		// console.log(data);
		if(this.props.search != undefined)
		{
			data = data.filter(obj => 
				obj.name.toLowerCase()
					.indexOf(this.props.search.toLowerCase()) > -1 
			);
		}
		const editContactTypeForm = this.state.showNewContactTypeForm ? <NewContactTypeForm
					visible={this.state.showNewContactTypeForm}
					onClose={() => this.setState({showNewContactTypeForm: false})}
					onSave={() => {
						this.setState({showNewContactTypeForm: false});
						this.refreshData();
					}}
					editContactTypeId={this.state.editContactTypeId}
				/> : "";
	
		return(<div>
			<DataGrid
				columns={this.state.columns}
				fields={this.state.fields}
				data={data}
				formatColumns={this.state.formatColumns}
				onDoubleClick={this.showEditContactTypeForm}
				loading={this.state.loading}
				heightOffset={190}
			/>
			<Button bsSize='sm' onClick={() => this.setState({showNewContactTypeForm: true, editContactTypeId: null})}>
				<Translate value='components.settings.newContactType' />
			</Button>
			{editContactTypeForm}
		</div>);
	}
}

class DeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deleteContactTypeConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}
const validationFields = {
	name: "required",
};

class NewContactTypeForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			formData: {
				id: 0,
				value: "",
				name: "",
				code: "CONTACT_TYPE",
				category: "",
				retired: false,
			},
			showDeleteConfirmation: false,
		};
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}
	
	componentDidMount() {
		if(this.props.editContactTypeId) {
			let formData = this.state.formData;
			const contactTypeId = this.props.editContactTypeId;
			getAPI("/common/contactTypes/" + contactTypeId).then(resContactType => {
				// console.log(resContactType);
				formData = resContactType['data'][0];
				this.setState({
					formData: formData,
				});
			});
		}
	}

	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		formData[name] = value;
		
		switch(name) {
			case 'name':
				formData['value'] = value;
				break;
		}
		
		this.setState({
		  formData: formData,
		});
	}
	
	onSave() {
		let validation = validateForm(this.state.formData, validationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			postAPI("common/contactTypes", this.state.formData).then(resContactType => {
			if(resContactType['success']) {
				alert("ContactType info Saved successfully.");
				this.props.onSave();
			}
			else
			{
				alert(resContactType['error']);
			}
		});
		}
	}
	
	onDelete() {
		postAPI("common/contactTypes/" + this.state.formData.id, this.state.formData).then(resContactType => {
			// alert(<Translate value="components.labels.poDeleted" />);
			alert("ContactType has been deleted successfully");
			this.props.onSave();
		});
	}

	render() {
		const content = 
		<Form className='form-page' horizontal>
			<InputText required label={<Translate value='components.settings.name' />} 
				name={'name'} value={this.state.formData.name} 
				action={this.handleInputChanged} 
				errMsg={this.state.errors.name ? this.state.errors.name : ""}
			/>
			<InputText label={<Translate value='components.settings.category' />} name={'category'} value={this.state.formData.category} action={this.handleInputChanged} />
			<InputText type='CheckBox' label={<Translate value='components.settings.inactive' />} name={'retired'} value={this.state.formData.retired} action={this.handleInputChanged}/>
		</Form>
				
		return(<Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title><Translate value='components.settings.newContactType' /></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={this.props.onClose}>{<Translate value='components.settings.close' />}</Button>
				<Button onClick={this.onSave} bsStyle='primary'>{<Translate value='components.settings.saveChanges' />}</Button>
			</Modal.Footer>
		</Modal>);
	}
}
