import {spanishCallReportLabels, trimChars} from '../common_components/common.js';

export default {
	"labels":{
		"search": "Buscar",
		"user": "Usuario", // "Usuario ▾",
		"settings": "Configuraciones",
		"logout": "Cerrar sesión",
		"allRightsReserved": "TODOS LOS DERECHOS RESERVADOS.",
		"records": "archivos",
		"totalLowerCase": "total",
		// Labels on Listing Pages
		"show": "Espectáculo",
		"type": "Tipo",
		//////////////////// GRID COLUMNS //////////////////////
		//	Orders
		"orderId": "Orden #",
		"orderDate": "Fecha de orden",
		"orderType": "Tipo de orden",
		"customer": "Cliente",
		"note": "Nota",
		"status": "Estado",
		"progress": "Progreso",
		"shipper": "Expedidor",
		"shipDate": "Fecha de envío",
		"numberOfSkids": "# patines",
		"instructions": "Instrucciones",
		"calculatedSummary": "Resumen calculado",
		"editableReportSummary": "Resumen de informe editable",
		"description": "Descripción",
		"addAddress": "Añadir Dirección",
		// Batches
		"formulaNumber": "Fórmula #",
		"name": "Nombre",
		"container": "Envase",
		"quantity": "Cantidad",
		"lbsOrdered": "LBs Ordenado",
		"LBsOrdered": "LBs Ordenado",
		"KGsOrdered": "KGs Ordenado",
		"combine": "Combinar",
		// Lab Requests
		"labRequestId": "Solicitud de laboratorio #",
		"dueDate": "Fecha de vencimiento",
		"deliveryDate": "Fecha de entrega",
		// Matches
		"colorName": "Nombre del color",
		"matchedBy": "Emparejado por",
		"completedBy": "Completado por",
		"anilox": "Anilox",
		// Actionable Items - Approve Process Notes
		"approved": "Aprobado",
		// Actionable Items - To be Priced
		"numOfComp": "# de componentes",
		"numOfOrders": "# de pedidos",
		"rmc": "RMC",
		// Emails to be Sent
		"formulaName": "Nombre de la fórmula",
		"price": "Precio",
		// Sales Goals
		"salesGoals": "Objetivos de ventas",
		// New vendor
		"recentPurchaseOrders": "Órdenes de compra recientes",
		// New shipper
		"recentSalesOrders": "Órdenes de venta recientes",
		"orderNumber": "Número de orden",
		"scheduledDeliveryDate": "Fecha programada de entrega",
		
		// Orders
		"amount": "Cantidad",
		// Formulas
		"substrate": "Sustrato",
		"duration": "Duración",
		// Components
		"component": "Componente",
		"components": "Componentes",
		"componentId": "Componente #",
		"componentNumber": "Número de componente",
		"vendor": "Vendedor",
		"vendors": "vendedores",
		"numOfFormulas": "# de fórmulas",
		"dateEntered": "Fecha ingresada",
		"lastUsed": "Utilizado por última vez",
		
		// P.O.
		"poId": "PO #",
		"notes": "Notas",
		
		// OrderForm
		"poNumber": "Número PO",
		"salesRep": "Representante de ventas",
		"contact": "Contacto",
		"consignment": "Envío",
		"consignmentBilling": "Facturación de consignación",
		"address": "Dirección",
		"shippingAddress": "Dirección de Envío",
		"billingAddress": "Dirección de Envio",
		"email": "Email",
		"orderPreferences": "Preferencias de pedido",
		"invoiceNotes": "Notas de la factura",
		"customerNotes": "Notas de los clientes",
		"billOfLading": "Guía de carga",
		"invoiceDate": "Fecha de la factura",
		"shipDate": "Fecha de envío",
		"trackingNumber": "# De seguimiento",
		"mustDeliverByDate": "Debe entregar por fecha",
		"discount": "Descuento",
		"skidCharge": "Impuesto de venta", // "Carga de deslizamiento",
		"freightCharge": "Carga de carga",
		"fuelCharge": "Carga de combustible",
		"total": "Total",
		"kgsOrdered": "KG pedido",
		"unitPrice": "Precio unitario",
		"extPrice": "Ext. Precio",
		"matchTo": "Emparejar a",
		"substrates": "Sustratos",
		"inkType": "Tipo de tinta",
		"opVarnish": "Barniz O / P",
		"colorTraps": "Trampas de color",
		"needPrints": "Necesita impresiones",
		"sendPrints": "Enviar impresiones",
		"needLSDPrints": "Necesita impresiones de LSD",
		"sendLSDPrints": "Enviar impresiones de LSD",
		"deliveryType": "Tipo de entrega",
		"shipperTrackingNumber": "Seguimiento del remitente #",
		"lbsShipped": "LBs enviado",
		"LBsShipped": "LBs enviado",
		"KGsShipped": "KGs enviado",
		"LBsMade": "LBs Hecho",
		"KGsMade": "KGs Hecho",
		"colorMatchName": "Nombre de coincidencia de color",
		"matchToPMS": "Coincidir con PMS",
		"matchToGCMI": "Coincidir con GCMI",
		"matchToLiquidSample": "Coincidir con la muestra líquida",
		"matchToPrintSample": "Emparejar para imprimir muestra",
		"matchToOther": "Coincidir con otros",
		"formula": "Fórmula",
		"formulas": "Fórmulas",
		'taxAmount': 'IVA',
		'iva': "IVA",
		'grandTotal': 'Gran total',
		'convertedAmount': 'Cantidad convertida',
		'amountPaid': 'Cantidad pagada',
		"isLate": "Es tarde",
		"amountReceived": "Cantidad recibida",
		"amountClaimed": "Cantidad reclamada",
		"latecomments": "Comentarios",
		"newDeliveryDate": "Nueva fecha de entrega",
		"reason": "Razón",
		"numDrawDowns": "# de retiros",
		'bcmRep': 'representante de BCM',
		
		// Formulas Form
		"sequenceNumber": "Secuencia #",
		"percentUsed": "Porcentaje utilizado",
		"costPerLb": "Costo por LB",
		"costPerLB": "Costo por LB",
		"costPerKG": "Costo por KG",
		"cost": "Costo",
		"pigment": "Pigmento",
		"resin": "Resina",
		"number": "Número",
		
		"prefix": "Prefijo",
		"suffix": "Sufijo",
		"min": "Mínimo",
		"max": "Máximo",
		"minPH": "PH Mínimo",
		"maxPH": "PH Máximo",
		"viscosity": "Viscosidad",
		"minViscosity": "Viscosidad mínima",
		"maxViscosity": "Viscosidad maxima",
		"viscosityCup": "Copa de Viscosidad",
		"health": "Salud",
		"flammability": "Inflamabilidad",
		"reactivity": "Reactividad",
		"personalProtection": "Protección personal",
		"hazardPictograms": "Pictogramas de peligro",
		"customers" : "Clientes",
		"lastOrdered": "Último pedido",
		"rawMaterialCost": "Costo de la materia prima",
		"customerColorName": "Nombre del color del cliente",
		"customerFormulaNotes": "Notas de la fórmula del cliente",
		"visibleToCustomer": "Visible para la cliente",
		"priceBreakdown": "Caída de precios",
		"shrinkageMarkup": "Margen de contracción",
		"basePrice": "Precio base",
		"markup": "Margen",
		"markups": "Marcas",
		"allMarkups": "Todas las marcas",
		"shipping": "Envío",
		"priceChart": "Tabla de precios",
		"effectiveDate": "Fecha efectiva",
		"expirationDate": "Fecha de caducidad",
		"sequence": "Secuencia",
		// Ink Components
		"leadTime": "Tiempo de espera",
		"backupVendor": "Vendedor de respaldo",
		"inventoryCount": "Cuenta de inventario",
		"inventory": "Inventario",
		"minInventory": "Inventario mínimo",
		
		// P.O
		"weight": "Peso",
		"totalPrice": "Precio total",
		"received": "Recibido",
		
		// Login
		"blankUsernamePassword": "El nombre de usuario o la contraseña no se pueden dejar en blanco.",
		"invalidUsernamePassword": "usuario o contraseña invalido",
		"loginTitle": "Iniciar sesión en MyInkIQ",
		"username": "Nombre de usuario",
		"password": "Contraseña",
		
		// Alerts
		"salesOrderSaved": "El pedido de venta se ha guardado correctamente",
		"salesOrderDelete": "El pedido de venta se ha eliminado correctamente",
		"formulaSaved": "Fórmula se ha guardado con éxito",
		"formulaCustomerSaved": "Fórmula Información del cliente se ha guardado con éxito",
		"formulaDeleted": "Fórmula ha sido eliminado con éxito",
		"componentSaved": "Componente se ha guardado con éxito",
		"componentDeleted": "Componente ha sido eliminado con éxito",
		"poSaved": "La orden de compra se ha guardado con éxito",
		"poDeleted": "La orden de compra ha sido eliminada exitosamente",
		
		// delete confirmation prompts
		"deleteFormulaConfirmationPrompt": "¿Estás seguro de que quieres eliminar esta fórmula?",
		"deleteLabRequestConfirmationPrompt": "¿Estás seguro de que deseas eliminar esta solicitud de laboratorio?",
		"deleteInkComponentConfirmationPrompt": "¿Está seguro de que desea eliminar este componente de tinta?",
		"deleteOrderConfirmationPrompt": "¿Seguro que quieres borrar este pedido?",
		"deletePurchaseOrderConfirmationPrompt": "¿Estás seguro de que quieres eliminar esta orden de compra?",
		"deleteSubstrateConfirmationPrompt": "¿Estás seguro de que quieres eliminar este sustrato?",
		"deleteShipperConfirmationPrompt": "¿Estás seguro de que quieres eliminar este remitente?",
		"deleteVendorConfirmationPrompt": "¿Estás seguro de que quieres eliminar este proveedor?",
		"deleteUserConfirmationPrompt": "¿Estás seguro de que quieres eliminar a este usuario?",
		"deleteTaskConfirmationPrompt": "¿Seguro que quieres eliminar esta tarea?",
		"deleteCallReportConfirmationPrompt": "¿Estás seguro de que deseas eliminar este informe de llamadas?",

		"closeConfirmationPrompt": "Tienes cambios sin guardar, ¿estás seguro de que quieres cerrar?",
		"inactivateUserConfirmationPrompt": "¿Estás segura de que quieres inactivar a esta usuaria",
		
		"validationError": "Los datos no se guardan, corrigen los errores en el formulario.",
		"updateInventoryConfirmationPrompt": "¿Quieres actualizar el inventario ahora o más tarde?",
		"bUpdateLater": "Actualizar más tarde",
		"pickDateTime": "Elija una fecha / hora",
		"maxContainerWtError": "El peso pedido supera el peso máximo del contenedor. Ajuste la cantidad.",

		// data filters
		'filterAll': 'Todas',
		'filterCompleted': 'Terminado',
		'filterNew': 'Nuevo',
		'filterSaved': 'Salvado',
		'filterSubmitted': 'Presentado',
		'filterActive': 'Activo',
		'filterExperimental': 'Experimental',
		'filterInactive': 'Inactivo',
		'filterProspect': 'Perspectiva',
		'filterMostPopular': 'Más popular basado en pedidos utilizados',
		'filterCreditMemo': 'Nota de crédito',
		'filterLabRequest': 'Solicitud de laboratorio',
		'filterSalesOrder': 'Órdenes de venta',
		'filterBcmInkOrders': 'Órdenes de tinta BCM',
		'filterColorMatch': 'Coincidencia de color',
		'filterColorMatchRepull': 'Coincidencia de color y retracción',
		'filterRnd': 'R & D',
		'filterNonBcmInkOrders': 'Órdenes de tinta no BCM',
		'filterAdditive': 'Aditivo',
		'filterBase': 'Base',
		'filterBlendingInk': 'Tinta de mezcla',
		'filterDispersion': 'Dispersión',
		'filterFinishedInk': 'Tinta acabada',
		'filterOpv': 'OPV',
		'filterOther': 'Otro',
		'filterVarnish': 'Barniz',

		// Login
		'changePassword': 'Cambia la contraseña',
		'changePasswordPrompt': 'Por razones de seguridad, debes actualizar tu contraseña.',
		'confirmPassword': 'Confirmar contraseña',
		'resetPassword': 'Restablecer la contraseña',
		'forgotPassword': '¿Se te olvidó tu contraseña?',
		'resetCode': 'Restablecer Código',
		'completed': 'Terminado',
		'started': 'Empezado',
		'changeReason': 'Cambiar la razón',

		// Order types
		'creditMemo': 'Nota de crédito',
		'labRequest': 'Solicitud de laboratorio',
		'salesOrder': 'Órdenes de venta',

		// Order statuses
		'entered': 'Ingresó',
		'confirmed': 'Confirmado',
		'completed': 'Terminado',
		'quoted': 'Citado',
		'invoiced': 'Facturado',
		'shippedInvoiced': 'Enviado / Facturado',
		'shipped': 'Enviado',
		'received': 'Recibido',
		'active': 'Activo',
		'inactive': 'Inactivo',
		'experimental': 'Experimental',

		// Ink types
		'additive': 'Aditivo',
		'base': 'Base',
		'blendingInk': 'Tinta de mezcla',
		'dispersion': 'Dispersión',
		'finishedInk': 'Tinta acabada',
		'opv': 'OPV',
		'other': 'Otro',
		'varnish': 'Barniz',
		
		'fuelChargeToBeAdded': 'Cargo por combustible que se agregará',
		'freightChargeToBeAdded': 'Cargo de flete a agregar',
		'plasticDrumsToBeAdded': 'Tambores de plástico para agregar',
		'useSpectro1': 'Utilice Spectro1',
		
		'transparent': 'Transparente',
		'opaque': 'Opaco',
		
		// Tasks
		'taskId': 'Tarea #',
		'task': 'Tarea',
		'assignedTo': 'Asignado a',
		'toDo': 'Que hacer',
		'incomplete': 'Incompleta',
		'taskTitle': 'Título de tarea',
		'dueDateTime': 'Fecha de vencimiento',
		'createTask': 'Crea otra tarea',
		
		// Call Reports
		'callReports': 'Informes de llamadas',
		'general': 'General',
		'pressRun': 'Presione Ejecutar',
		'blending': 'Sistema de mezcla',
		'jobDesc': 'Escritorio de trabajo',
		'date': 'Fecha',
		'tasksAssigned': 'Tareas asignadas',
		'tasksCompleted': 'Tareas completadas',
		'contacts': 'Contactos',
		'salesReps': 'Representantes de ventas',
		'deptEmails': 'Departamentos a correo electrónico',
		'callDate': 'Fecha de la llamada',
		'objectives': 'Objetivos',
		'summary': 'Resumen',
		'pressSpeed': 'Velocidad de prensa',
		'initialNotes': trimChars(spanishCallReportLabels['initialNotes'][1]),
		'initialToleranceMethod': 'Método de tolerancia inicial',
		'finalNotes': trimChars(spanishCallReportLabels['finalNotes'][1]),
		'finalToleranceMethod': 'Método de tolerancia final',
		'batch': 'Lote',
		'dryerPct': 'Secadora %',
		'uv': 'UV',
		'pHInitial': 'PH inicial',
		'viscosityInitial': 'Viscosidad Inicial',
		'toleranceInitial': 'Tolerancia inicial',
		'pHFinal': 'PH Final',
		'viscosityFinal': 'Viscosidad final',
		'toleranceFinal': 'Tolerancia final',
		'sampleCollected': 'Muestra recogida',
		'nextStep': 'Próximo paso',
		'uploadCallReport': 'Subir informe de llamadas',
		'dEInitial': 'DE Inicial',
		'dEFinal': 'DE final',
		'densityInitial': 'Densidad inicial',
		'densityFinal': 'Densidad final',
		'takeaway': 'Para llevar',
		'account': trimChars(spanishCallReportLabels['account'][1]),
		'jobDesc': trimChars(spanishCallReportLabels['jobDesc'][1]),
		'salesPerson': spanishCallReportLabels['salesPerson'][1],
		'callDate': spanishCallReportLabels['callDate'][1],
		'meetingObjectives': spanishCallReportLabels['meetingObjectives'][1],
		'meetingSummary': spanishCallReportLabels['meetingSummary'][1],
		'metWith': spanishCallReportLabels['metWith'][1],
		'supervisedRun': spanishCallReportLabels['supervisedRun'][1],
		'assisted': spanishCallReportLabels['assisted'][1],
		'bcmReps': spanishCallReportLabels['bcmReps'][1],
		'mainTakeaways': spanishCallReportLabels['mainTakeaways'][1],
		'followUps': spanishCallReportLabels['followUps'][1],
		'press': spanishCallReportLabels['press'][1],
		'substrate': spanishCallReportLabels['substrate'][1],
		'pressSpeed': spanishCallReportLabels['pressSpeed'][1],
		'pressRunObjectives': spanishCallReportLabels['pressRunObjectives'][1],
		'toleranceMethod': spanishCallReportLabels['toleranceMethod'][1],
		'station': spanishCallReportLabels['station'][1],
		'dryerPct': spanishCallReportLabels['dryerPct'][1],
		'uv': spanishCallReportLabels['uv'][1],
		'pH': spanishCallReportLabels['pH'][1],
		'pressRunSummary': spanishCallReportLabels['pressRunSummary'][1],
		'notesAdjustments': spanishCallReportLabels['notesAdjustments'][1],
		'actionItems': spanishCallReportLabels['actionItems'][1],
		'nextStep': spanishCallReportLabels['nextStep'][1],
		'dueDate': spanishCallReportLabels['dueDate'][1],
		'initialInkConditions': spanishCallReportLabels['initialInkConditions'][1],
		'finalInkConditions': spanishCallReportLabels['finalInkConditions'][1],
		'dE': spanishCallReportLabels['dE'][1],
		'formulaCol': spanishCallReportLabels['formulaCol'][1],
		'lrType': 'Tipo LR',
		'month': 'Mes',
		'year': 'Año',
		'prospectCallsPerMonth': 'Llamadas de clientes potenciales / mes',
		'numCalls': 'Numero de llamadas',
		'salesGoal': 'Objetivo de ventas',
		'expenseBudget': 'Presupuesto de gastos',
		'vocWeight': 'Peso VOC %',
		'density': 'Densidad (Lbs/Gal)',
		'ammoniaWeight': 'Amoníaco %',
	},
}