import React, { Component } from 'react';
import Spinner from './Spinner';
import { Row, Col, FormControl, DropdownButton, MenuItem, Checkbox, Button } from 'react-bootstrap';
import './DataGrid.css'
import { convertTimestampToDate, currencySymbols, toCurrency } from './common.js';
import Config from '../Config';
import Octicon, { Settings, ArrowSmallUp, ArrowSmallDown } from '@githubprimer/octicons-react';
const { Translate } = require('react-i18nify');

// Samee: I'm changing this from 25 to 100 since order formulas exceeding 25 in number are not shown then
const limit = 100; // 25
// Params:
//	data: 2D array containing Grid Data to be displayed
//	data: Data fetched from the API call
//	columns: linear array containing the columns
//	fields:	linear array containing fields to display in the grid
//	filter: Assoc array specifying the filter criteria:
//	E.g {
//		'orderStatus': {
//			'op': '=',
//			'val': 'Active'
//		}
//	}
//	The above gets all orders having an Active status. 
//	E.g {
//		'*': {
//			'op': 'contains',
//			'val': '234'
//		}
//	}
//	The above gets all rows where any of the fields contain a '234'
//	The param 'op' can have other values such as 'contains', 'begins_with', 'ends_with'

//	formatColumns: array containing indexes of the grid columns whose date is to be formatted
//	offset: row offset to begin with
//	limit:	number of rows shown per grid
// 	onDoubleClick: signal to return row data on double click
//  onSelect: signal to return row data on select
//	editRowIdField: The field name from where to pick the row ID once double clicked.
class DataGrid extends Component {

	
	constructor(props) {
		super(props);
		var filter = this.props.filter != undefined ? this.props.filter : {};
		const isColumnHidden = [];
		if (this.props.importantColumns && window.innerWidth < 800) {
			for (let i = 0; i < this.props.columns.length; i++) {
				isColumnHidden[i] = true;
			}
			for (const column of this.props.importantColumns) {
				if (column == 'components.customers.notes'){
					isColumnHidden = true ;
				}
				isColumnHidden[column] = false;
			}
		}
		if (this.props.importantColumns){
			for (let i = 0; i < this.props.columns.length; i++) {
				if (this.props.columns[i].props.value == 'components.customers.notes' || this.props.columns[i].props.value == 'components.labels.duration' ){
					isColumnHidden[i] = true ;
				}
				else{
					isColumnHidden[i] = false;
				}
				
				
			}
		}
			

		
		this.state = {
			data: [],
			isColumnHidden: isColumnHidden,
			filter: filter,
			selectedRowIndex: -1,
			end: limit,
			sortColumn: null,
			sortAsc: true,
		}
		this.textFilterChanged = this.textFilterChanged.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.sortByCell = this.sortByCell.bind(this);
		this.sortHelper = this.sortHelper.bind(this);
		this.filterData = this.filterData.bind(this);
	}
	
	componentDidMount() {
		// console.log('DataGrid mounted...this.props: ', this.props.data);
		
		
			
		this.setState({
			data: this.props.data,
		});
	}
	
	componentWillReceiveProps(nextProps) {
		
		if(nextProps.data && nextProps.data != this.props.data)
		{
			// console.log("Setting dataDataGrid::componentWillReceiveProps() - nextProps.data: ", nextProps.data, ", this.props.data: ", this.props.data);
			
			// pass back the number of rows if specified by onLoad
			if(this.props.onGridLoaded != undefined) {
				// this.props.onGridLoaded(nextProps.data.length);
			}
			// console.log("this.props.selectedRowIndex: ", this.props.selectedRowIndex, ", nextProps.selectedRowIndex: ", nextProps.selectedRowIndex);
			// this.setState({selectedRowIndex: i})
			let selectedRowIndex = this.state.selectedRowIndex;
			if(nextProps.selectedRowIndex != null && nextProps.selectedRowIndex >= 0)
				selectedRowIndex = nextProps.selectedRowIndex;
			
			this.setState({
				data: nextProps.data,
				selectedRowIndex: selectedRowIndex,
			});
		}
	}

	textFilterChanged(event) {
		var val = event.target.value;
		var keyCode = event.keyCode;
		var fieldName = event.target.name;
		// console.log('event in textFilterChanged: ', event, ', keyCode: ', keyCode);
		// console.log('name: ', event.target.name, 'id: ', event.target.id);
		
		// Samee - Remove the need to hit enter for searching
		// if(keyCode == 13)
		{
			var newFilter = this.state.filter;
			// console.log("textFilterChanged - fieldName: ", fieldName, ", val: ", val);
			this.props.onTextFieldChanged(fieldName, val);
			if(val === '')
			{
				delete newFilter[fieldName];
			}
			else
			{
				newFilter[fieldName] = val;
			}
			this.setState({filter: newFilter});
		}
		this.filterData()
	}

	filterData()
	{
		const data = this.state.data;
		const filter = this.state.filter;
		// console.log(filter)
		var filtered = [];
		if (Object.keys(filter).length === 0) {return data}
		for (const dataObject of data) {
			var outerFlag = []
			for (const fieldName of Object.keys(filter)) {
				// console.log("Field Name: ", fieldName)
				var innerFlag = false;
				var dataValue = dataObject[fieldName];
				var filterValue = filter[fieldName];
				// Check the type of data
				// console.log("Filter Value: ", filterValue, "Data: ", dataValue)
				if (dataValue === null) {break}
				switch (typeof dataValue) {
					case "number":
						if (dataValue.toString().includes(filterValue)) {
							innerFlag = true;
						}
						break;
					default: // string
						if (fieldName == 'status' || 
							fieldName == 'statusName' ||
							fieldName == 'formulaStatusName' ||
							fieldName == 'formulaType' ||
							fieldName == 'salesOrderType'
							// fieldName == '' ||
							// fieldName == '' ||
							// fieldName == '' ||
							// fieldName == '' ||
							// fieldName == ''
						) // used for fields that require exact matches.
						{
							if (dataValue.toLowerCase() === filterValue.toLowerCase()) {
								innerFlag = true;
								break;
							} else {
								break;
							}
						} else if (dataValue.toLowerCase().includes(filterValue.toLowerCase())) {
							innerFlag = true;
							break;
						}
						break;
						
				}
				if (innerFlag) {outerFlag.push(true)} else {outerFlag.push(false);}
			}
			if (!outerFlag.includes(false)) {filtered.push(dataObject)}
		}
		return filtered;
	}

	handleScroll(event) {
		// https://stackoverflow.com/a/49573628
		const offset = 5; // Samee - increase this to 10 in case scrolling issues continue to occur
		const diff = event.target.scrollHeight - event.target.scrollTop;
		// console.log("handleScroll() - scrollHeight: ", event.target.scrollHeight, ", scrollTop: ", event.target.scrollTop, ", diff: ", (diff), ", event.target.clientHeight: ", event.target.clientHeight);
		// var scrolledToBottom = (diff === event.target.clientHeight);
		var scrolledToBottom = diff <= (Math.floor(event.target.clientHeight) + offset);
		if (scrolledToBottom) {
			// console.log("scrolled to bottom");
			// this.props.handleScroll();
			var end = this.state.end;
			end += limit;
		
			this.setState({
				end: end,
			});
		}
			
	}

	sortHelper(a, b, sortColumn, sortAsc) {
		const asc = sortAsc ? 1 : -1;
		if (a[sortColumn] === b[sortColumn]) {
			return 0;
		} else if (a[sortColumn] === null || a[sortColumn] === undefined){
			return 1;
		} else if (b[sortColumn] === null || b[sortColumn] === undefined){
			return -1;
		} else if (a[sortColumn] < b[sortColumn]) {
			return 1 * asc;
		} else {
			return -1 * asc;
		}
	}
	
	sortByCell(sortColumn) {

		var sortAsc = this.state.sortAsc;
		var data = this.state.data;
		
		if(sortColumn == this.state.sortColumn)
			sortAsc = !sortAsc;
		else
			sortAsc = true;
		// console.log("DataGrid::sortByCell() - this.state.sortColumn: ", this.state.sortColumn, ", sortColumn: ", sortColumn, ", sortAsc: ", sortAsc);
		
		if(sortColumn)
		{
			data = data.sort((a, b) => this.sortHelper(a, b, sortColumn, sortAsc));
		}
		
		this.setState({
			sortColumn: sortColumn,
			sortAsc: sortAsc,
			data: data,
		});
		
		this.props.onSort(data);
	}
	
	
	
	render() {
		if (this.props.loading)
			return(<Spinner />);

		var rows = [];
		// var offset = this.props.offset != undefined ? this.props.offset : 0;
		// var limit = this.props.limit != undefined ? this.props.limit : 25;
		var formatColumns = this.props.formatColumns;
		// var filter = this.props.filter != undefined ? this.props.filter : this.state.filter;
		// var txtSearch = this.props.txtSearch;
		
		var arrFieldsFlipped = {};
		for(var i in this.props.fields)
			arrFieldsFlipped[this.props.fields[i]] = i;
			
		// console.log('DataGrid render, txtSearch: ', txtSearch);
		// if(txtSearch != undefined && txtSearch != '')
		// {
		// 	filter['*'] = {'op': 'contains', 'val': txtSearch};
		// }
		// console.log('filter in DataGrid render: ', filter);
		// console.log('DataGrid render(): start iterating data');
		var numRows = 0;
		
		var data = this.filterData();
		// console.log("DataGrid::render() - this.state.data: ", this.state.data);

		data = data.slice(0, this.state.end + 1);
		let bShowTotalsFooter = false;
		let totalsFooterData = this.props.fields.map(val => {return "";});
		
		let total = 0;
		rows = data.map((row, i) => {
			if (this.props.totalField) total += row[this.props.totalField];
			
			// Adding totals in footer whereever specified
			if (formatColumns != null) {
				Object.keys(formatColumns).forEach(colIndex => {
					const colField = this.props.fields[colIndex];
					if(formatColumns[colIndex].showTotal) {
						if(row[colField] != null && row[colField] != "" && !isNaN(row[colField])) {
							if(totalsFooterData[colIndex] == "") totalsFooterData[colIndex] = 0;
							totalsFooterData[colIndex] += parseFloat(row[colField]);
						}
						if(!bShowTotalsFooter) bShowTotalsFooter = true;
					}
				});
			}
			
			
			
			return <DataGridRow 
				onDoubleClick={this.props.onDoubleClick}
				onSelect={(rowIndex) => {
					this.setState({selectedRowIndex: i})
					if (this.props.onSelect)
						this.props.onSelect(rowIndex)
				}}
				data={row}
				isColumnHidden={this.state.isColumnHidden}
				rowIndex={i}
				selected={this.state.selectedRowIndex === i}
				fields={this.props.fields} 
				formatColumns={formatColumns}
			/>
		});
		if(this.props.totalValue != undefined)
			total = parseFloat(this.props.totalValue);
		// console.log("totalsFooterData: ", totalsFooterData);
		
		// console.log('DataGrid render(): end iterating data');
		// console.log('DataGrid::render() - rows: ', rows);
		const heightOffset = this.props.heightOffset || 150;
		return <div
			class='table-responsive data-grid-container'
			onScroll={this.handleScroll}
			style={{
				height: 'calc(100vh - ' + heightOffset + 'px)',
			}}
		>
			<div
				class='data-grid-top-bar'
			>
			{this.props.moveRowUp != null &&
				<Button size="sm"
					onClick={() => {
						this.props.moveRowUp();
					}}
				><Octicon icon={ArrowSmallUp} size='medium' />
				</Button>
			}
			&nbsp;
			{this.props.moveRowDown != null &&
				<Button size="sm"
					onClick={this.props.moveRowDown}
				><Octicon icon={ArrowSmallDown} size='medium' />
				</Button>
			}
				{this.state.data.length} <Translate value='components.labels.records' />
				{this.props.totalField && <span>&nbsp; | &nbsp;{toCurrency(total)} <Translate value='components.labels.totalLowerCase' /></span>}
				&nbsp;&nbsp;
				<DropdownButton
					pullRight
					bsSize='xs'
					title={<Octicon icon={Settings} />}
				>
					{this.props.columns.map((column, i) =>
						<MenuItem
							key={i}
							onClick={() => {

								const isColumnHidden = this.state.isColumnHidden.slice(0, this.props.columns.length);
								isColumnHidden[i] = !isColumnHidden[i];
								this.setState({isColumnHidden});
							}}
						>
							<Checkbox checked={!this.state.isColumnHidden[i]}>{column}</Checkbox>
						</MenuItem>
					)}
					<MenuItem divider />
					<MenuItem
						onClick={() => {this.setState({isColumnHidden: []})}}
					>
						<Translate value='components.buttons.showAll' />
					</MenuItem>
					<MenuItem
						onClick={() => {this.setState({isColumnHidden: this.props.columns.map(() => true)})}}
					>
						<Translate value='components.buttons.hideAll' />
					</MenuItem>
				</DropdownButton>
			</div>
			<table
				class="table table-striped table-hover data-grid"
				id={this.props.id}
			>
				<thead>
					<DataGridRow
						data={this.props.columns}
						isColumnHidden={this.state.isColumnHidden}
						isHeader={true}
						formatColumns={formatColumns}
						parentAction={this.textFilterChanged}
						headerCellClicked={this.sortByCell}
						filter={this.state.filter}
						fields={this.props.fields}
						sortColumn={this.state.sortColumn}
						sortAsc={this.state.sortAsc}
					/>
				</thead>
				<tbody>
					{rows}
					{bShowTotalsFooter && <DataGridRow
						data={totalsFooterData}
						isColumnHidden={false}
						isFooter={true}
						formatColumns={formatColumns}
						fields={this.props.fields}
					/>}
				</tbody>
			</table>
		</div>;
	}
}


export default DataGrid;

// Params:
// Please Note: the first column of the row should be the unique id for the datatype.
// data: array of data to be displayed
//	isHeader (Whether the cell is a row header cell)
//	formatColumns: array containing indexes of the grid columns whose date is to be formatted
//	clickable: whether the row should throw click events
//	rowIndex: zero-based row index used to keep track of selected row.

class DataGridRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
		this.timeout = null;
		this.onSelect = this.onSelect.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this)
		this.handleDoubleTouch = this.handleDoubleTouch.bind(this)
	}
	
	handleDoubleClick(event) {

		// console.log("THis is a test")
		if (typeof this.props.onDoubleClick === 'function') {
			// console.log("DataGridRow::handleDoubleClick() - this.props.data: ", this.props.data);
			this.props.onDoubleClick(this.props.data)	
		}
	}

	handleDoubleTouch(event) {
		if (typeof this.props.onDoubleClick === 'function') {
			// console.log("DataGridRow::handleDoubleClick() - this.props.data: ", this.props.data);
			if (this.timeout === null){
				this.timeout = window.setTimeout(() => {this.timeout = null; this.onSelect(event);}, 500);
			} else {
				this.timeout = null;
				window.clearTimeout()
				this.props.onDoubleClick(this.props.data)
			}
		} else if (typeof this.props.onSelect === 'function') {
			this.onSelect(event);
		}

	}
	
	onSelect(event) {
		// console.log("DataGridRow::onSelect() - this.props.rowIndex: ", this.props, ", this.props.rowId: ", this.props.rowId);
		if (typeof this.props.onSelect === 'function')
			this.props.onSelect(this.props.rowIndex);
	}	
	
	render() {
		var cells = [];
		// this.props.data.forEach(value)
		/*
		for(var i in this.props.data)
		{
			var value = this.props.data[i];
			var field = this.props.fields ? this.props.fields[i] : null;
			var formatColumn = this.props.formatColumns != undefined && this.props.formatColumns[i] != undefined ? this.props.formatColumns[i] : null;
			// Setting variable
			var linkInfo =  null;
			if(formatColumn['linkInfo'] != undefined) {
				const linkIndex = formatColumn['linkInfo']['columnIndex'];
				const linkId = this.props.data[linkIndex];
				const linkVar = formatColumn['linkInfo']['var'];
				linkInfo = {'id': linkId, 'var': linkVar}
			}
			
			if (this.props.isHeader && this.props.sortColumn === field)
				value += this.props.sortAsc ? ' ▼' : ' ▲';
			cells.push(
				<DataGridCell
					isHeader={this.props.isHeader}
					value={value}
					formatColumn={formatColumn}
					action={this.props.parentAction}
					click={this.cellClicked}
					name={field}
					linkInfo={linkInfo}
				/>
			);
		}
		*/
		
		cells = this.props.fields.map((field, i) => {
			if (this.props.isColumnHidden[i]) return '';

			var value = this.props.isFooter ? this.props.data[i] : (field != null ? this.props.data[field] : this.props.rowIndex);
			// if(this.props.isFooter) value = this.props.data[i];
			var linkInfo =  null;
			var formatColumn = this.props.formatColumns ? this.props.formatColumns[i]: null;
			
			let bgColor = null;
			if (this.props.isHeader) {
				value = <span onClick={() => this.props.headerCellClicked(field)}>{this.props.data[i]}</span>;
				
				var sortArrow = '';
				var inputTextFilter = '';
				
				if(this.props.sortColumn === field)
					sortArrow = this.props.sortAsc ? ' ▼' : ' ▲';

				if(formatColumn) {
					///////////////// Column text filtering - Riley's code uses 'textFilter' attribute /////////////
					if(formatColumn['textFilter'] != undefined) {
						const textFilter = formatColumn['textFilter'];
						// inputTextFilter = <Row><FormControl className='header-input' type='text' onChange={this.change} name={textFilter} onKeyUp={this.props.parentAction} style={{ width: "75px" }} /></Row>;
						inputTextFilter = <Row><FormControl className='header-input' type='text' onChange={this.props.parentAction} name={textFilter} style={{ width: "75px" }} /></Row>;
					}

					

					///////////////// Column text filtering - Samee's code uses 'columnFilter' attribute /////////////
					if(formatColumn['columnFilter'] != undefined) {
					
						const columnFilter = formatColumn['columnFilter'];
						const callback = this.props.formatColumns['callbackColumnFilter'];
						
						inputTextFilter = <Row><input className='header-input' id={field} name={field} onChange={callback} value={columnFilter} style={{ width: "75px" }}/></Row>;

					
					}
					
					if(formatColumn['checkbox'] != undefined && formatColumn['headerCheckbox']) {
						const callback = formatColumn['callbackCheckbox'];
						value = <span><input type={'checkbox'} id={field} name={field} onChange={callback} />&nbsp;Select All</span>;
						// value = <Checkbox 
						// 	id={field} name={field}
						// 	onChange={callback}>
						// 	&nbsp;Select All
						// </Checkbox>
					}		
				}

				value = 
					<div id={field}>
						<Col md={12}>
							<Row>{value}{sortArrow}&nbsp;</Row>
							{inputTextFilter}
						</Col>
					</div>;
			} else {
				if(formatColumn) 
				{
					if(formatColumn['link'] != undefined) {
						const linkColumnName = formatColumn['link']['column'];
						const linkId = this.props.data[linkColumnName];
						const linkVar = formatColumn['link']['var'];
						const functionName = formatColumn['link']['func'];
						
						value = <a href={'javascript:;'} id={linkId} name={linkVar} onTouchStart={functionName} onClick={functionName}>{value}</a>;
					}
				
					if(formatColumn['date'] != undefined && value)
					{
						var objDate = new Date(value);
						value = convertTimestampToDate(value);
					}

					// if ((formatColumn['currency'] != undefined) && (!this.props.isHeader) && ((value != null) || (value != undefined))) {
					// Few minor adjusmments to the if condition - by Samee.
					
					if (formatColumn['currency'] != undefined && value != "" && value != null && value != undefined && !isNaN(value)) {
						// check localization for currency here	
						let locale = null;
						if(formatColumn['colLocale'] != undefined) {
							locale = this.props.data[formatColumn['colLocale']];
						} else {
							locale = "" + formatColumn['currency'] === 'true' ?
							 Config.locale : formatColumn['currency'];
						}
						
						locale = (locale == null || locale == undefined) ? 
							Config.locale.toLowerCase() : locale.toLowerCase();
						
						// console.log("formatColumn['colLocale']: ", formatColumn['colLocale'], ", value for currency conversion: ", value, ", locale: ", locale);
							
						const currencySymbol = currencySymbols[locale];
						const currencyDecimals = formatColumn['currencyDecimals'] != undefined ? formatColumn['currencyDecimals'] : 2;
						value = currencySymbol + 
								parseFloat(value).toFixed(currencyDecimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
							
					}
					
					if ((formatColumn['percentage'] != undefined) && ((value != null) || (value != undefined))) {
						value = (value*100).toFixed(2)+"%";
					}
					
					
					if ((formatColumn['percent'] != undefined) && !isNaN(value) && ((value != null) || (value != undefined))) {
						value = parseFloat(value).toFixed(2)+"%";
					}
					
					
					if ((formatColumn['decimals'] != undefined) && ((value != null) && (value != undefined) && (value != ""))) {
						const numDecimals = formatColumn['decimals'] ? formatColumn['decimals'] : 0
						value = value.toFixed(numDecimals);
					}
		
					if(formatColumn['checkbox'] != undefined && !this.props.isFooter) {
						const chkValue = value; // Datagrid column value
						const callback = formatColumn['callbackCheckbox'] ? 
							formatColumn['callbackCheckbox'] : null;
						let checked = formatColumn['checkbox'] && 
							formatColumn['checkbox'][chkValue] ? "checked" : "";
						
						// Check the checkbox if the specified column has a non-empty value
						if(formatColumn['colCheckBound']) {
							checked = this.props.data[formatColumn['colCheckBound']] 
								!= null && this.props.data[formatColumn['colCheckBound']] 
									> 0 ? "checked" : "";
						}
						
						// Disable checkbox if the specified column has a non-empty value
						let disabled = false;
						if(formatColumn['colDisabledBound']) {
							disabled = this.props.data[formatColumn['colDisabledBound']];
						}
						
						// Hide checkbox if the specified column has a non-empty value
						let visible = true;
						if(formatColumn['colVisibleBound']) {
							visible = !this.props.data[formatColumn['colVisibleBound']];
						}
						
						// Disable checkbox (regardless)
						if(formatColumn['disabled']) {
							disabled = true;
						}
						
						// Make checkbox readonly (regardless)
						let readonly = false;
						if(formatColumn['readonly']) {
							readonly = true;
						}

						// value = visible ? <input type={'checkbox'} value={chkValue} 
						//	checked={checked} onChange={callback} disabled={disabled} 
						//	readonly={readonly} /> : "";
						// console.log("formatColumn['checkbox']: ", formatColumn['checkbox'], ", chkValue: ", chkValue, ", checked: ", checked);
						value = visible ? 
						<input type={'checkbox'}
							value={chkValue} checked={checked} disabled={disabled} 
							readonly={readonly} onChange={callback}>
						</input>
						: "";
					}

					if(formatColumn['idLookup'] != undefined) {
						const referenceData = formatColumn['idLookup'];
						value = referenceData[value];
					}

					if(formatColumn['textField'] != undefined && !this.props.isFooter) {
						const callback = formatColumn['callbackText'] ? 
							formatColumn['callbackText'] : null;
							
						const columnName = formatColumn['textField'];
						const columnId = this.props.data[columnName];
						value = <input type='text' id={columnId}
							 onBlur={callback} />
					}
					
					
					if(formatColumn['bgColor'] != undefined)
						bgColor = this.props.data[formatColumn['bgColor']];
				}	
			}
			
			return <DataGridCell
				isHeader={this.props.isHeader}
				isFooter={this.props.isFooter}
				value={value}
				formatColumn={this.props.formatColumns}
				action={this.props.parentAction}
				name={field}
				bgColor={bgColor}
			/>
		});
		
		// console.log("DataGridRow::render() - cells: ", cells);
		return <tr
			class={this.props.selected ? 'data-grid-selected-row' : 'data-grid-row'}
			onDoubleClick={this.handleDoubleClick}
			onClick={this.onSelect}
			// onFocus={() => {console.log("TEST")}}
			onTouchEnd={this.handleDoubleTouch}
		> 
			{cells}
		</tr>;
	}
}


// Params: 
//	value (displaying the HTML content) 
//	isHeader (Whether the cell is a row header cell)
//	textFilter: Whether the header cell has any text fields for filtering (e.g. in case of Formulas)
//	formatColumn: Column Format if any
class DataGridCell extends Component {

	constructor(props) {
		super(props);
		
		var textFilter = this.props.formatColumn != undefined && this.props.formatColumn['textFilter'] != undefined ? this.props.formatColumn['textFilter'] : '';
		this.state = {
			textFilter: textFilter,
			textFilterVal: ''
		};
		
	}
//

	render() {
		
		var value = this.props.value;
		/*
		var formatColumn = this.props.formatColumn;
		var textFilter = '';
		// tag = this.props.isHeader ? '<th>' : '<td>';

		if(formatColumn != undefined) {
			if(formatColumn['date'] != undefined && !this.props.isHeader && value)
			{
				var objDate = new Date(value);
				value = convertTimestampToDate(value);
			}
			
			if(formatColumn['textFilter'] != undefined) {
				textFilter = formatColumn['textFilter'];
			}
			
			if(formatColumn['checkbox'] != undefined && !this.props.isHeader && !value) {
				value = <input type={'checkbox'} />;
			}
			
			if(this.props.linkInfo && !this.props.isHeader) {
				const id = this.props.linkInfo['id'];
				const varName = this.props.linkInfo['var'];
				value = <a href='javascript:;' id={id} name={varName}>{value}</a>
			}
		}
		*/
		if(this.props.isHeader)
		{
			/*
			if(textFilter != '')
			{
				return <td className='data-grid-header' onClick={this.click}>
					{value}&nbsp;<input id={textFilter} name={textFilter} onKeyUp={this.props.action} />
				</td>
			}
			else
			{
				return <td className='data-grid-header' onClick={this.click}>
					{value}
				</td>
			}
			*/
			return <th className='data-grid-header'>{value}</th>
		} else if(this.props.isFooter)
		{
			return <td className='data-grid-header'>{value}</td>
		}
		else
		{
			return <td style={{backgroundColor: this.props.bgColor}}>{value}</td>
		}
	}
	
}
