import SidebarEsTrans from './SidebarEsTranslation.js';
import SidebarEnTrans from './SidebarEn.js';
import ReportsEnTrans from './ReportsEn.js';
import ReportsEsTrans from './ReportsEs.js';
import CustomersEnTrans from './CustomersEn.js';
import CustomersEsTrans from './CustomersEs.js';
import LabelsEnTrans from './LabelsEn.js';
import LabelsEsTrans from './LabelsEs.js';
import ButtonsEnTrans from './ButtonsEn.js';
import ButtonsEsTrans from './ButtonsEs.js';
import TabsEnTrans from './TabsEn.js';
import TabsEsTrans from './TabsEs.js';
import SettingsEnTrans from './SettingsEn';
import SettingsEsTrans from './SettingsEs';
import InventoryEnTrans from './InventoryEn.js';
import InventoryEsTrans from './InventoryES.js';
import TooltipsEnTrans from './TooltipsEn';
import TooltipsEsTrans from './TooltipsEs';
const { I18n } = require('react-i18nify');
I18n.setTranslations({
  en: {
    components: {
		...SidebarEnTrans,
		...LabelsEnTrans,
		...ButtonsEnTrans,
		...TabsEnTrans,
    ...ReportsEnTrans,
    ...CustomersEnTrans,
    ...SettingsEnTrans,
    ...InventoryEnTrans,
    ...TooltipsEnTrans,
    },
    date: {
      long: 'MMMM Do, YYYY'
    }
  },
  es: {
    components: {
		...SidebarEsTrans,
		...LabelsEsTrans,
		...ButtonsEsTrans,
		...TabsEsTrans,
    ...ReportsEsTrans,
    ...CustomersEsTrans,
    ...SettingsEsTrans,
    ...InventoryEsTrans,
    ...TooltipsEsTrans,
    },
    date: {
      long: 'D MMMM YYYY'
    }  }
});

export default I18n;
