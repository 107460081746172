import React, { Component } from 'react';
import {Grid, Row, Col } from 'react-bootstrap';
import TabContainer from '../common_components/TabContainer.js';

import Customer from '../customers/Customer.js';
import Inventory from '../inventory/Inventory.js';
import TabItem from './TabItem.js';
import Formula from '../formulas/Formula.js';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');
class ActionableItems extends Component {


constructor(props){
    super(props);
    
    this.state = {
    	processNotesCount: 0,
        toBePricedCount: 0,	
    	emailsToBeSentCount: 0,
    };

  }
	render(){
		const tabTitles = [
			<span><Translate value="components.tabs.approvedProcessNotes" />
			{" (" + this.state.processNotesCount + ")"}</span>,
			<span><Translate value="components.tabs.toBePriced" />
			{" (" + this.state.toBePricedCount + ")"}</span>,
			<span><Translate value="components.tabs.emailsToBeSent" />
			{" (" + this.state.emailsToBeSentCount + ")"}</span>,
		];
		
		const tabs = [
			<TabItem dashboard={'ApproveProcessNotes'} 
				onLoad={(processNotesCount) => {
					this.setState({processNotesCount: processNotesCount});
					this.props.onLoad(
					processNotesCount 
					+ this.state.toBePricedCount 
					+ this.state.emailsToBeSentCount);
				}}
			/>,
			<Formula dashboard={'ToBePriced'} 
				onLoad={(toBePricedCount) => {
					this.setState({toBePricedCount: toBePricedCount});
					this.props.onLoad(
					this.state.processNotesCount 
					+ toBePricedCount 
					+ this.state.emailsToBeSentCount);
				}}
			/>,
			<TabItem dashboard={'EmailsToBeSent'} 
				onLoad={(emailsToBeSentCount) => {
					this.setState({emailsToBeSentCount: emailsToBeSentCount});
					this.props.onLoad(
					this.state.processNotesCount 
					+ this.state.toBePricedCount 
					+ emailsToBeSentCount);
				}}
			/>, 
		];
	
		return (
			<div>
			<TabContainer tabTitles={tabTitles} tabs={tabs}/>
			</div>
		);
	}
}

export default ActionableItems;
