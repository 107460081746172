import React from 'react';
import ReactGA from "react-ga4";
import Highcharts from 'highcharts';
import Config from '../Config';
import { I18n } from 'react-i18nify';
import config from '../Config';
const { Translate } = require('react-i18nify');


export var pieChartOptions = {
	/*exporting: {
		enabled: true,
		buttons: {
			contextButton: {
				enabled: true,
				menuItems: ["printChart", "downloadPDF", "downloadPNG"],
			},
		},
	},*/
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		plotShadow: false,
		type: 'pie',
		width: 800,
		height: 600,
		zoomType: "xy",
	},
	title: {
		text: ''
	},
	lang: {
        decimalPoint: '.',
        thousandsSep: ','
    },
	tooltip: {
		pointFormat: '<b>{point.name}</b>: <b>Sales Total:</b>${point.y:,.2f} <b>Sales Goals:</b>${point.x:,.2f} - <b>{point.percentage:.2f}%</b>'
	},
	plotOptions: {
		pie: {
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: true,
				// format: '<b>{point.name}</b>: {point.percentage:.1f} %',
				style: {
					color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
				},

			}
		}
	},
	legend: {
		layout: 'horizontal',
	},
	series: [{
		// showInLegend: true,
		name: 'Sales Goals',
		colorByPoint: true,
		data: [], // dataAccountsTotal
		 /*data: [{
            name: 'Chrome',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'Internet Explorer',
            y: 11.84
        }, {
            name: 'Firefox',
            y: 10.85
        }]*/
	 }]
	
};

export var barChartOptions = {
  chart: {
		type: 'column',
		width: 800,
        height: 600,
        zoomType: "y",
	},
	title: {
		text: 'Sales Goals'
	},
	xAxis: {
		categories: [], // categories,
		title: {
			text: null
		},
	},
	lang: {
        decimalPoint: '.',
        thousandsSep: ','
    },
	/*
	xAxis: {
        categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ],
        crosshair: true
    },*/
	yAxis: {
		min: 0,
		title: {
			text: 'Amount',
			align: 'high'
		},
		labels: {
			overflow: 'justify'
		}
	},
	tooltip: {
		valueSuffix: ' ',
		pointFormat: '<b>{series.name}</b>: ${point.y:,.2f}'
	},
	plotOptions: {
		bar: {
			dataLabels: {
				enabled: false
			}
		}
	},
	legend: {
		layout: 'vertical',
		align: 'right',
		verticalAlign: 'top',
		x: -40,
		y: 80,
		floating: true,
		borderWidth: 1,
		backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
		shadow: true
	},
	credits: {
		enabled: false
	},
	series: [
	{
		name: 'Sales Goals',
		data: [], //dataSalesGoals
	}, 
	{
		name: 'Sales Totals',
		data: [], // dataSalesTotals
	}]
	/*
	series: [{
        name: 'Tokyo',
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
    }, {
        name: 'New York',
        data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]

    }, {
        name: 'London',
        data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]

    }]
	*/
};



export var lineChartOptions = {
	chart: {
        zoomType: "y",
	},
    title: {
        text: ''
    },

    subtitle: {
        text: ''
    },
	xAxis: {
		categories: [], // categories,
		title: {
			text: null
		},
	},
	/*
	xAxis: {
        categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ],
        crosshair: true
    },*/
    yAxis: {
        title: {
            text: ''
        }
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
    },
	tooltip: {
		pointFormat: '<b>${point.y:,.2f}</b>'
	},
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            // pointStart: 2010
        }
    },
    series: [{
		name: 'Sales Goals',
		data: [], //dataSalesGoals
	}, 
	{
		name: 'Sales Totals',
		data: [], // dataSalesTotals
	}],
	/*
    series: [{
        name: 'Installation',
        data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
    }, {
        name: 'Manufacturing',
        data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
    }, {
        name: 'Sales & Distribution',
        data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
    }, {
        name: 'Project Development',
        data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
    }, {
        name: 'Other',
        data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
    }],
    */
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    },
	lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
};

export const lab_request_lookup_id = 133;
export const credit_memo_lookup_id = 134;
export const sales_order_lookup_id = 135;

export const objMonths = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

// console.log("common.js - I18n._locale: ", I18n._locale);
export const constants = {
	// Order Types
	lab_request_lookup_id: 133,
	credit_memo_lookup_id: 134,
	sales_order_lookup_id: 135,
	// Order Statuses
	order_status_entered: 117,
	order_status_confirmed: 118,
	order_status_quoted: 119,
	order_status_invoiced: 120,
	order_status_shipped_invoiced: 121,
	order_status_shipped: 122,
	order_status_received: 123,
	// Lab Request Statuses
	lab_request_status_entered: 124,
	lab_request_status_confirmed: 125,
	lab_request_status_completed: 126,
	// Credit Memo Statuses
	credit_memo_status_entered: 129,
	credit_memo_status_confirmed: 130,
	credit_memo_status_completed: 131,
	// Lab Request Delivery Types
	delivery_type_no_draw_downs: 41,
	//	Reports
	aging_report_id: 17,
	batch_variance_id: 30,
	batch_and_color_match_duration_report_id: 33,
	batch_ticket_report_id: 2,
	bill_of_lading_report_id: 16,
	blending_ticket_report_id: 11,
	bucket_label_report_id: 14,
	color_match_report_id: 29,
	call_report_summary_id:38,
	component_cost_price_change_report_id: 32,
	credit_memo_report_id: 13,
	customer_lbs_sold_report_id: 21,
	contains_components_report_id: 22,
	formula_change_reason_report_id: 28,
	formula_cost_price_change_report_id: 31,
	formula_cost_report_id: 1,
	historical_report_id: 25,
	inventory_transactions_report_id: 26,
	inventory_variance_report_id: 27,
	invoice_report_id: 3,
	invoice_summary_report_id: 24,
	lab_request_report_id: 10,
	lbs_sold_report_id: 8,
	late_loss_damage_for_sales_order_report_id:37,
	late_loss_damage_for_purchase_order_report_id:36,
	order_listing_report_id: 23,
	packing_slip_report_id: 12,
	purchase_order_report_id: 4,
	sales_order_report_id: 9,
	voc_report_id:39,
	salesorder_lr_shipment_report_id: 40,
	top_component_report_id: 41,
	trending_aging_report_id: 42,
	ph_viscosity_report_id: 43,
	
	// Hazard Pictograms
	corrosion_id: 82,
	exclamation_point_id: 83,
	flame_id: 84,
	respiratory_id: 213,
	toxic_id: (Config.env == 'mex' ? 234 : 220),
	// customer
	customer_type_id: 7,
	sales_type_id: 8,
	vip_type_id: 9,
	admin_type_id: 10,
	labtech_type_id: 11,
	rnd_type_id: 12,
	finance_type_id: 13,
	sales_manager_type_id: 170,
	production_type_id: 171,
	
	// formula types
	formula_type_base: 88,
	formula_type_finished_ink: 89,
	formula_type_varnish: 90,
	formula_type_other: 91,
	formula_type_additive: 163,
	formula_type_dispersion: 164,
	formula_type_opv: 165,
	formula_type_blending_ink: 166,
	
	// UM codes
	um_code_kg: 157,
	um_code_lb: 158,
	
	// Call Reports
	call_report_type_general: (Config.env == 'mex' ? 232 : 218),
	call_report_type_press_run: (Config.env == 'mex' ? 233 : 219),
	call_report_type_blending: (Config.env == 'mex' ? 256 : (Config.env == 'dev' ? 226 : 234)),
	
	lr_type_color_match_lookup_id: (Config.env == 'mex' ? 246 : 224),
	lr_type_rnd_lookup_id: (Config.env == 'mex' ? 247 : 225),
	lr_type_repull_lookup_id: (Config.env == 'mex' ? 267 : (Config.env == 'dev' ? 241 : 242)),
	
	expenditure_category_airline: (Config.env == 'mex' ? 248 : 226),
	expenditure_category_lodging: (Config.env == 'mex' ? 249 : 227),
	expenditure_category_restaurant: (Config.env == 'mex' ? 250 : 228),
	expenditure_category_car_rental: (Config.env == 'mex' ? 251 : 229),
	expenditure_category_auto_expense: (Config.env == 'mex' ? 252 : 230),
	expenditure_category_parking: (Config.env == 'mex' ? 253 : 231),
	expenditure_category_cellphone: (Config.env == 'mex' ? 254 : 232),
	expenditure_category_misc: (Config.env == 'mex' ? 255 : 233),
	
	// Misc
	call_report_file_size_limit: 10, // in <MB
	
	// Viscosity Cup Id - Din 4
	viscosity_cup_id_din4: 159,
	
	deptEmailsList: (Config.env == 'mex' ? {
		'labmx@bcminks.com': 'Lab',
		'RandDmx@bcminks.com': 'R&D',
		'atamez@bcminks.com': 'Customer Service',
		'operales@bcminks.com': 'Accounting',
		'operales@bcminks.com': 'Operations',
	} : {
		'lab@bcminks.com': 'Lab',
		'RandD@bcminks.com': 'R&D',
		'pmiller@bcminks.com': 'Customer Service',
		'rburton@bcminks.com': 'Accounting',
		'cleonard@bcminks.com': 'Operations',
	}),
	default_internal_order: (Config.env == 'mex' ? "I" : "E"),
	default_um_code: (Config.env == 'mex' ? 157 : 158),
	internal_customer_id: (Config.env == 'mex' ? 1095 : 499),
	// Dropdown Constants
	filterAbsoluteDensity: (Config.env == 'mex' ? 'Densidad absoluta' : 'Absolute Density'),
	filterAll: (Config.env == 'mex' ? 'Todos' : 'All'),
	filterActive: (Config.env == 'mex' ? 'Activa' : 'Active'),
	filterInactive: (Config.env == 'mex' ? 'Inactiva' : 'Inactive'),
	filterBcmInkOrders: (Config.env == 'mex' ? 'Órdenes de tinta BCM' : 'BCM Ink Orders'),
	filterNonBcmInkOrders: (Config.env == 'mex' ? 'Órdenes de tinta no BCM' : 'Non BCM Ink Orders'),
	filterColorMatchRepull: (Config.env == 'mex' ? 'Coincidencia de color y retracción' : 'Color Match & Repull'),
	filterColorMatch: (Config.env == 'mex' ? 'Coincidencia de color' : 'Color Match'),
	filterRnd: 'R & D',
	filterRepull: (Config.env == 'mex' ? 'Retirar' : 'Repull'),
	completed: (Config.env == 'mex' ? 'Terminado' : 'Completed'),
	incomplete: (Config.env == 'mex' ? 'Incompleta' : 'Incomplete'),
	general: (Config.env == 'mex' ? 'General' : 'General'),
	pressRun: (Config.env == 'mex' ? 'Presione Ejecutar' : 'Press Run'),
	blending: (Config.env == 'mex' ? 'Sistema de mezcla' : 'Blending'),
	salesOrder: (Config.env == 'mex' ? 'Órdenes de venta' : 'Sales Order'),
	labRequest: (Config.env == 'mex' ? 'Solicitud de laboratorio' : 'Lab Request'),
	include: (Config.env == 'mex' ? 'Incluir' : 'Include'),
	exclude: (Config.env == 'mex' ? 'Excluir' : 'Exclude'),
	only: (Config.env == 'mex' ? 'Solamente' : 'Only'),
	setup: (Config.env == 'mex' ? 'Preparar' : 'Setup'),
	production: (Config.env == 'mex' ? 'Producción' : 'Production'),
	jobMultiColorReport: (Config.env == 'mex' ? 'Informe de trabajo (multicolor)' : 'Job Color'),
	individualColorReport: (Config.env == 'mex' ? 'Informe de color individual' : 'Individual Color'),
	pressSpecificReport: (Config.env == 'mex' ? 'Informe específico de prensa' : 'Press Specific'),
	orderDate: (Config.env == 'mex' ? 'Fecha de orden' : 'Order Date'),
	shipDate: (Config.env == 'mex' ? 'Fecha de envío' : 'Ship Date'),
	domestic: (Config.env == 'mex' ? 'Nacional' : 'Domestic'),
	international: (Config.env == 'mex' ? 'Internacional' : 'International'),
	formula: (Config.env == 'mex' ? 'Fórmula' : 'Formula'),
	base: (Config.env == 'mex' ? 'Base' : 'Base'),
	finishedInk: (Config.env == 'mex' ? 'Tinta acabada' : 'Finished Ink'),
	varnish: (Config.env == 'mex' ? 'Barniz' : 'Varnish'),
	other: (Config.env == 'mex' ? 'Otro' : 'Other'),
	additive: (Config.env == 'mex' ? 'Aditivo' : 'Additive'),
	dispersion: (Config.env == 'mex' ? 'Dispersión' : 'Dispersion'),
	opv: (Config.env == 'mex' ? 'OPV' : 'OPV'),
	blendingInk: (Config.env == 'mex' ? 'Mezcla de tinta' : 'Blending Ink'),
	filterSales: (Config.env == 'mex' ? 'Ventas' : 'Sales'),
	filterProfit: (Config.env == 'mex' ? 'Lucro' : 'Profit'),
	filterTopCustomer: (Config.env == 'mex' ? 'Clientes principales' : 'Top Customers'),
	filterTopFormulas: (Config.env == 'mex' ? 'Fórmulas principales' : 'Top Formulas'),
	filterTopSalesReps: (Config.env == 'mex' ? 'Representantes de ventas superiores' : 'Top Sales Reps'),

	// Quickbooks mode - 0: Sandbox, 1: Production
	qboMode: 1,
	qboTerms: {
		136: ['8', 'Net 30'],
		137: ['15', 'NET 45'],
		138: ['9', 'Net 60'],
		139: ['11', '1% 10 NET 30'],
		140: ['12', '2% 10 NET 30'],
		141: ['50', '1% 10, Net 60'],
		142: ['52', '2% 10, Net 60'],
		// 143: ['12', 'Pre-Paid'],
		144: ['6', 'Due on receipt'],
		167: ['49', '1% 15/Net 30'],
		168: ['25', '2% 30, Net 45'],
		169: ['36', '1% 15, Net 60'],
		214: ['60', 'Net 90'],
		// 216: ['17', '2% 15 net 30'],
		257: ['66', '30/60/90']
	},
	qboFormulaTypes: {
		88: {"Id": "840", "Name": "Base","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		89: {"Id": "841", "Name": "Finished Ink","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		90: {"Id": "838", "Name": "Varnish","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		91: {"Id": "119", "Name": "Other","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		163: {"Id": "839", "Name": "Additive","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		164: {"Id": "843", "Name": "Dispersion","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		165: {"Id": "842", "Name": "OPV","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
		166: {"Id": "844", "Name": "Blending Ink","IncomeAccountRef": {"value": "218", "name": "Sales -Inks"}},
	},
	qboMiscCharges: {
		'fuelSurchargeAmt': {"Id": "29", "Name": "FSC", "Description": "FUEL SURCHARGE", "IncomeAccountRef": {"value": "214", "name": "Sales -Freight"}},
		'freightChargeAmt': {"Id": "31", "Name": "FRT", "Description": "FREIGHT CHARGE", "IncomeAccountRef": {"value": "214", "name": "Sales -Freight"}},
		'skidChargeAmt': {"Id": "11", "Name": "Skid", "Description": "Skid Charge", "IncomeAccountRef": {"value": "212", "name": "Sales -Skids"}},
		
	},
	call_report_category_customer: '1',
	call_report_category_vendor: '2',
	call_report_category_shipper: '3',
};
/*{
			'sameeullahqazi@gmail.com': 'Samee Gmail',
			'sameeullahqazi@yahoo.com': 'Samee Yahoo',
			'samee@lamproslabs.com': 'Samee Lampros',
		}*/

export const validationFieldsCaptions = {
	customerId: "Customer",
	orderTypeLookupId: "Order Type",
	orderStatusLookupId: "Order Status",
	salesRepActorId: "Sales Rep",
	shippingAddressId: "Shipping Address",
	scheduledDeliveryDate: "Must Deliver By",
	formulaId: "Formula",
	containerId: "Container",
	quantity: "Quantity",
	umOrdered: "Ordered",
	name: "Name",
	formulaTypeLookupId: "Formula Type",
	formulaStatusLookupId: "Formula Status",
	substrates: "Substrates",
	aniloxes: "Aniloxes",
	formulaPhMin: "Min PH",
	formulaPhMax: "Max PH",
	formulaViscosityMin: "Min Viscosity",
	formulaViscosityMax: "Max Viscosity",
	viscosityCupLookupId: "Viscosity Cup",
	formulaHazardHealth: "Health",
	formulaHazardFlammability: "Flammability",
	formulaHazardReactivity: "Reactivity",
	formulaHazardPersonalProtection: "Personal Protection",
	customers: "Customers",
	newFormulaChangeReason: "Formula Change Reason",
	formulaComponents: "Formula Components",
	percentUsed: "Percent Used",
	componentId: "Component",
	componentVendorId: "Vendor",
	componentStatusLookupId: "Component Status",
	price: "Price",
	vendorId: "Vendor",
	statusId: "Status",
	orderDate: "Order Date",
	dueDate: "Due Date",
	toDo: "Task",
	dueDateTime: "Due Date",
};

export const containerKgWeightsToOverride = (Config.env == 'mex' ? 
	{
		1: 8,
		2: 45,
		3: 450,
		4: 2250,
		5: 50,
	} : 
	{
		1: 4,
		2: 20,
		3: 200,
		4: 1000,
		5: 22,
	}
);

export const orderTypes = {
	[credit_memo_lookup_id]: 'Credit Memo', // <Translate value='components.labels.creditMemo' />, 
	[lab_request_lookup_id]: 'Lab Request', // <Translate value='components.labels.labRequest' />, 
	[sales_order_lookup_id]: 'Sales Order', // <Translate value='components.labels.salesOrder' />,
};

export const currencySymbols = {
	'en_us': '$',
	'es_mx': 'Mex$',
};

export const weightSymbols = {
	'en_us': 'LBs',
	'es_mx': 'KGs',
};

export const sampleList = {
	'99': 'Wet',
	'102': 'Print',
	'100': 'Other',
};

export const toleranceMethodsList = {
	'59': 'Lab',
	'60': 'cmc',
	'61': '2000',
	'62': '94',
};

export const spanishCallReportLabels = {
	"generalCallReport": ["General Call Report", "Reporte General de Visita"],
	"pressRunCallReport": ["Press Run Call Report", "Reporte De visita a cliente"],
	"salesPerson": ["Salesperson:", "Vendedor"],
	"callDate": ["Call Date:", "Fecha:"],
	"meetingObjectives": ["*Meeting Objective(s):", "*Objetivos de Visita"],
	"meetingSummary": ["*Meeting Summary:", "*Resumen de Visita"],
	"metWith": ["Met With:", "Asistentes:"],
	"supervisedRun": ["Supervised Run:", "Supervisor"],
	"assisted": ["Assisted:", "Asistente:"],
	"bcmReps": ["BCM Rep(s)", "Representante(s) BCM"],
	"mainTakeaways": ["Main Takeaways", "Detalle:s "],
	"followUps": ["Follow ups", "Pasos a seguir:"],
	"press": ["*Press:", "*Maquina"],
	"substrate": ["*Substrate:", "*Substarto"],
	"pressSpeed": ["*Press Speed:", "*Velocidad:"],
	"notes": ["Notes:", "Notas:"],
	"initialNotes": ["*Initial Setup Notes:", "Notas de configuración inicial"],
	"pressRunObjectives": ["*Press Run Objective(s):", "Objetivo de Corrida :"],
	"station": ["Station", "Estacion"],
	"toleranceMethod": ["Tolerance Method", "Metodo de Tolerancia"],
	"formula": ["*Formula", "*Formula"],
	"batch": ["*Batch", "*Lote"],
	"anilox": ["*Anilox", "*Anilox"],
	"dryerPct": ["*Dryer %", "*Seca %"],
	"uv": ["*U/V", "UV"],
	"pH": ["pH", "pH"],
	"viscosity": ["Viscosity", "Viscosidad"],
	"account": ["Account:", "Cliente"],
	"jobDesc": ["Job Desc.:", "Descripcion de Trabajo:"],
	"pressRunSummary": ["*Press Run Summary:", "Resumen de corrida"],
	"notesAdjustments": ["*Adjustment Notes - (please indicate any adjustments required to ink):", "Notas - (Por favor indique ajustes requeridos en la tinta):"],
	"finalNotes": ["*Final Notes:", "Notas finales:"],
	"actionItems": ["Action Item(s):", "Accion"],
	"createTask": ["Create Task", "Crear Tarea"],
	"nextStep": ["*Next Step:", "Proximo paso"],
	"dueDate": ["Due Date:", "Fecha a terminar"],
	"initialInkConditions": ["Initial Ink Conditions", "Condiciones Iniciales de Tinta"],
	"finalInkConditions": ["Final Ink Conditions", "Condiciones Finales de Tinta"],
	"viscosity": ["Viscosity", "Viscosidad"],
	"dE": ["DE", "DE"],
	"formulaCol": ["Formula", "Formula"],
	"noActionRequired": ["No Action Required", "No se requiere accion"],
};

export function trimChars(str) {
	const charsToTrim = "*:";
	// console.log("trimmed: " + ("* Hello:").trim());
	str = str.replace(/:$/, '').replace(/^\*/, ''); // str.trim(charsToTrim);
	return str;
}

export function getIndexByFieldName(objPressStationInfo, field, value) {
	const keys = Object.keys(objPressStationInfo);
	// console.log("getIndexByFieldName() - objPressStationInfo: ", objPressStationInfo, ", field: ", field, ", value: ", value);
	for(const index in keys) {
		const i = keys[index];
		if(objPressStationInfo[i] != undefined && objPressStationInfo[i][field] == value) 
			return i;
	}
	return -1;
}

export function isCallReportLabel(index, value) {
	const matchEnglish = trimChars(spanishCallReportLabels[index][0].toLowerCase());
	const matchSpanish = trimChars(spanishCallReportLabels[index][1].toLowerCase());
	value = trimChars(value.toLowerCase());
	return matchEnglish == value || matchSpanish == value;
}

export function getCallReportLabelIndex(value) {
	if(value != null) {
		// if(value == '*Press Run Objective(s):')
			// console.log("value: ", value, ", trimmed: ", trimChars(value));
		let keys = Object.keys(spanishCallReportLabels);
		for(let i in keys) {
			if(isCallReportLabel(keys[i], "" + value))
				return keys[i];
		}
	} else {
		return null;
	}
}

// Quickbooks API
export function quickbooksAPI(objParams) {
	if(Config.env == 'mex')
		return "";
	
	if(objParams['accept'] == null)
		objParams['accept'] = "application/json";
	
	objParams['mode'] = constants.qboMode;
	// console.log("constants.qboMode: ", constants.qboMode);
	// return getAPI("common/api" + http_build_query(objParams)).then(res => {
	return getAPI("common/qboApi" + http_build_query(objParams)).then(res => {
		// console.log("res: ", res, ", res['fault']: ", res['fault'], ", res['fault']['type']: ", res['fault']['type']);
		// In case of an invalid access token, 
		// if(res['error'] != null && res['error'] == '401') {
		if(res['fault'] != null && res['fault']['type'] == 'AUTHENTICATION') {
			// console.log("auth issue!");
			// alert("QBO Authentication error: " + res['fault']['error'][0]['message'] + "\nPlease refresh a QBO access token and save it to MyInkIQ->Settings->QBO Config");
			return getAPI("common/qbrefreshtoken?mode=" + constants.qboMode).then(resAuth => {
				if(resAuth['accessToken'] != null) {
					// return getAPI("common/api" + http_build_query(objParams)).then(res2 => {
					return getAPI("common/qboApi" + http_build_query(objParams)).then(res2 => {
						console.log("res2: ", res2);
						if(res2 && res2.Fault != null) {
							var objErr = res2.Fault.Error[0];
							var errMsg = objErr.code + ": " + objErr.Message + " - " + objErr.Detail + "\n";
							alert(errMsg);
						}
						return res2;
					});
				} else {
					// console.log("resAuth: " + resAuth);
					alert("Couldn't refresh access token! Please check if your refresh token has expired.");
				}
			})
		} else {
			
			// console.log("res: ", res);
			if(res && res.Fault != null) {
				var objErr = res.Fault.Error[0];
				var errMsg = objErr.code + ": " + objErr.Message + " - " + objErr.Detail + "\n";
				alert(errMsg);
			}
			return res;
		}
	});
}


export function getAPI(endpoint){
    return fetch(Config.baseURL + endpoint,
    {
    	credentials: "include",
    })
    .then((response) => response.json())
    .then((responseData) => {
    	// console.log("getAPI() - endpoint: ", endpoint, ", responseData: ", responseData);
    	if(responseData['error']) {
    		console.log("API Error upon calling endpoint '" + endpoint+ "': ", responseData['error']);
    	}
      return responseData;
    })
    .catch(error => {
    	console.log("error calling endpoint " + endpoint + ": ", error);
    	// alert("error calling endpoint " + endpoint + ": " + error);
    });
}

export function postAPI(endpoint, body) {
    return fetch(Config.baseURL + endpoint,
    {
    	method: "post",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
			// 'Authorization': 'Basic '+btoa('username:password'), 
		},
		body: JSON.stringify(body),
    })
    .then((response) => response.json())
    .then((responseData) => {
    	console.log("Calling POST API Endpoint '" + endpoint + "' with body: ", body, ", result: ", responseData);
    	if(responseData.success == undefined) {
			// alert("API Error upon calling endpoint: " + responseData['error']);		
    	}
      return responseData;
    })
    .catch(error => {
    	console.log("error calling endpoint " + endpoint + ": ", error);
    	alert("error calling endpoint " + endpoint + ": " + error);
    });
}

export function postAPIUrlEncoded(endpoint, body) {
	const queryString = http_build_query(body);
	const postURL = Config.baseURL + endpoint + queryString;

    return fetch(postURL,
    {
    	method: "post",
		credentials: "include",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"Connection": "keep-alive",
		},
		body: JSON.stringify(body),
    })
    .then((response) => response.json())
    .then((responseData) => {
    	console.log("Calling POST API (Url Encoded) Endpoint '" + endpoint + "' with body: ", body, ", result: ", responseData);
    	if(responseData.success == undefined) {
    		alert("API Error upon calling endpoint (Url Encoded): " + responseData['error']);
    	}
      return responseData;
    })
    .catch(error => {
    	console.log("error calling endpoint " + endpoint + ": ", error);
    	alert("error calling endpoint " + endpoint + ": " + error);
    });
}


export function postAPIMultipartForm(endpoint, file) {
	let formData = new FormData();
	formData.append('body', file);
	
    return fetch(Config.baseURL + endpoint,
    {
    	method: "POST",
		body: formData,
    })
    .then((response) => response.json())
    .then((responseData) => {
    	console.log("Calling postAPIMultipartForm() '" + endpoint + "' with file: ", file, ", result: ", responseData);
    	if(responseData.success == undefined) {
			// alert("API Error upon calling endpoint: " + responseData['error']);		
    	}
      return responseData;
    })
    .catch(error => {
    	console.log("error calling endpoint " + endpoint + ": ", error);
    	alert("error calling endpoint " + endpoint + ": " + error);
    });
}

export function getCurrencyConversion(){
    return fetch(Config.currencyURL,
    {
    	credentials: "include",
    })
    .then((response) => response.json())
    .then((responseData) => {
      return responseData;
    })
    .catch(error => console.log("error calling getCurrencyConversion(): ", error));
}


// If no valueField is specified, then copy the entire row to the index
export function mapArrayToObject(arr, keyField, valueField, useTranslation = false) {
	var obj = {}; // this.state.contactList.map((obj, key) => ({[obj.id]: obj.name}) );
	for(var i in arr) 
	{
		var key = arr[i][keyField];
		if(!valueField)
		{
			obj[key] = arr[i];
		}
		else
		{
			var splitValueField = valueField.split(',');
			var value = '';
			for(var j in splitValueField)
				value += arr[i][splitValueField[j]] ? arr[i][splitValueField[j]] + " " : "";
			obj[key] = value;
		}
		if(useTranslation) {
			obj[key] = <Translate value={"components.labels." 
				+ toCamelCase(obj[key].trim())} />;
		}
	}
	return obj;
}

export function mapObjectToArray(obj) {
	return Object.keys(obj).map((key) => ({value: key, label: obj[key]}));
}

export function reverseDateForReport(mexicanDate) { // DD/MM/YYYY
	if (mexicanDate) {
		let arrayDate = mexicanDate.split("/");
		let day = arrayDate[0];
		
		let month = arrayDate[1];
		
		let date = month + '/' + day + '/' + arrayDate[2];
		return date;
	}	
}

export function convertTimestampToDate(value, bTime = false, b24HourFormat = false, bStandardDateFormat = false) {
	let objDate = new Date(value);
	let month = objDate.getMonth() + 1;
	month = month < 10 ? '0' + month : month;
	let day = objDate.getDate();
	let year = objDate.getFullYear();
	day = day < 10 ? '0' + day : day;
	
	let date = (Config.env == 'mex' ? day  + '/' + month : month  +'/' + day)
		+ '/' + year;
	
	if(bStandardDateFormat)
		date = year + '-' + month + '-' + day;
	
	if(bTime)
	{
		let hours = objDate.getHours();
		let ampm = "";
		if(!b24HourFormat) {
			ampm = hours >= 12 ? ' PM' : ' AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
		}
		let minutes = objDate.getMinutes();
		minutes = minutes < 10 ? '0' + minutes : minutes;
		let seconds = objDate.getSeconds();
		seconds = seconds < 10 ? '0' + seconds : seconds;
		// let strTime = hours + ':' + minutes + ':' + seconds + ampm;
		let strTime = hours + ':' + minutes + ampm;
		date += ' ' + strTime;
	}
	return date;
}


export function convertDateToTimestamp(date, format = "mm/dd/yy")
{
	var value = null;
	var splitDate = date.split("/");
	if(splitDate.length != 3)
	{
		date = date.split("-");
		date = (Config.env == 'mex' ? date[0] + "/" + date[1] : date[1] + "/" + date[0]) + "/" + date[2];
	}
	
	if(Config.env == 'mex') {
		date = splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2];
	}
	
	// console.log("date to convert: ", date);

	var objDate = new Date(date);
	value = objDate.getTime();

	return value;
}

export function convertISOtoShortDate(isoDate, bShowTime = false) {
	
	const arrISODate = isoDate.split(" ");
	const datePart = arrISODate[0];
	const timePart = arrISODate[1] ? arrISODate[1] : "";
	
	const arrDatePart = arrISODate[0].split("-");
	const day = arrDatePart[2];
	const month = arrDatePart[1];
	const year = arrDatePart[0];
	
	let newDate = Config.env == 'mex' ? day + "/" + month : month + "/" + day;
	newDate += "/" + year;
	
	if(bShowTime)
		newDate += " " + timePart;
		
	// const objDate = new Date(isoDate);
	// let newDate = convertTimestampToDate(objDate, false);
	// console.log("newDate in convertISOtoShortDate(): ", newDate);
	return newDate;
}

// Add numDays to the timestamp Date
// returns a timestamp value
export function addDaysToDateTimestamp(date, numDays, bConsiderWeekend = false, timeStr = "") {
	if(timeStr == undefined) timeStr = "";
	let currentDate = convertDateToTimestamp(convertTimestampToDate(date) + timeStr);
	let result = currentDate;
	let weekendDays = 0;
	
	for(let i = 0; i < numDays; i++) {
		// Add a day
		result += 86400000;
		if(bConsiderWeekend) {
			// Check for a Saturday or Sunday
			const dateResult = new Date(result);
			const dayOfWeek = dateResult.getDay();
			// console.log("dayOfWeek: ", dayOfWeek);
			if(dayOfWeek == 0 || dayOfWeek == 6) {
				weekendDays++;
			}
		}
	}
	result += (weekendDays * 86400000);
	if(bConsiderWeekend) {
		const dateResult = new Date(result);
		const dayOfWeek = dateResult.getDay();
		// console.log("dayOfWeek: ", dayOfWeek);
		if(dayOfWeek == 0 || dayOfWeek == 6) {
			const numDaysToAdd = dayOfWeek == 6 ? 2 : 1;
			result += (numDaysToAdd * 86400000);
		}
	}
	return result;
}

export function http_build_query(obj, separator = '&')
{
	let seperator = null;
	let queryString = "";
	for(const key in obj)
	{
		seperator = seperator ? "&" : "?"; 
		queryString += (seperator + key + "=" + obj[key]);
	}
	return queryString;
}

export function calculateExtPrice(price, umOrdered, discountAmt) {
	var extPrice = price * umOrdered - discountAmt;
	return extPrice;
}

export function getLoggedInUser() {
	return sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
}

export function logout() {
	// console.log("Calling the global logout function");
	sessionStorage.removeItem('user');
}

export function openBirtReport(reportName, objParams, format = "pdf", asAttachment = true, bPrint = false) {
	const loggedInUser = getLoggedInUser();
	 //console.log("reportName: ", reportName, ", params: ", params);
	
	//	WE NEED TO MAKE A CLONE OF THE PARAMS 
	//	IN ORDER TO PREVENT THE VALUES FROM GETTING MODIFIED!
	const params = JSON.parse(JSON.stringify(objParams));
	// console.log("params in openBirtReport(): ", params);
	params.__report = 'BCMInks/' + reportName + '.rptdesign';
	// Default report format should be PDF
	if(params.__format == undefined)
		params.__format = format;
	if(params.__asattachment == undefined)
		params.__asattachment = asAttachment;
	if(params.__parameterpage == undefined)
		params.__parameterpage = false;
	
	// Reverse the Dates for Mexico
	if(Config.env == 'mex') {
		if(params.startDate)
			params.startDate = reverseDateForReport(params.startDate);
		if(params.endDate)
			params.endDate = reverseDateForReport(params.endDate);
		if(params.compareFromDate)
			params.compareFromDate = reverseDateForReport(params.compareFromDate);
		if(params.compareToDate)
			params.compareToDate = reverseDateForReport(params.compareToDate);
		if(params.shipDate)
			params.shipDate = reverseDateForReport(params.shipDate);
		
		// Pass in the locale for Mexico
		if(params.__locale == undefined)
			params.__locale = Config.locale;
	}
	
	// compareFromDate and compareToDate cannot contain empty values so it's best not to pass them if empty
	if(reportName != "BCM_Historical") {
		// But this applies to reports other than Historical Reports in which case both params are needed
		if(params.compareFromDate === '') {
			delete params.compareFromDate;
		}
		if(params.compareToDate === '') {
			delete params.compareToDate;
		}
		
		// Samee - As with compare from and to dates, startDate and endDate should probably NOT be passed to the report if they're blank
		// or maybe not yet...
		
		if(params.startDate === '') {
			delete params.startDate;
		}
		if(params.endDate === '') {
			delete params.endDate;
		}
		
	}
	
	if(reportName == "BCM_Formulas_Containing_Component") {
		// console.log("params.component: ", params.component);
		
		// parse component value into comma separated ids and names
		const componentIDs = params.component.map(obj => obj.value).join(',');
		params.componentNames = params.component.map(obj => obj.label).join(',');
		params.customerName = "";
		// console.log("componentIDs: ", componentIDs, "params.componentNames: ", params.componentNames, ", params.customerName: ", params.customerName);
		params.component = componentIDs;
	}
	
	if(params.tmpColorId) {
		params.colorId = params.tmpColorId;
		delete params.tmpColorId;
	}
	
	if(params.tmpComparisonCriteria) {
		params.comparisonCriteria = params.tmpComparisonCriteria;
		delete params.tmpComparisonCriteria;
	}
	
	params.__locale = Config.env == 'mex' ? 'es_MX': 'en_US';
	params.env = Config.env == 'mex' ? 'mex': (Config.env != null ? Config.env : "prod"); // Config.env;
	params.currencyConversionUnit = loggedInUser.currencyConversionUnit;
	params.dt = (new Date).getTime();
	params.actorId = loggedInUser.id;
	var reportURL = Config.reportURL;
	if (Config.env == 'mex'){
          if (params.__format == 'html'){
			 //  console.log("replace https with http");
			  reportURL = reportURL.replace('https://','http://' );
		  }
	}
	// console.log("Config.env: ", Config.env, ", params.__format: ", params.__format, ", reportURL: ", reportURL)
	
	// bPrint = true;
	if(bPrint) {
		var uniqueName = new Date();
		var windowUrl = reportURL + "?__report=BCMInks/BCM_CofA.rptdesign&company=BCMInks&logo=https://bcminks.myinkiq.com/myinkiq-0.1.0/images/companies/1&id=30498&type=F&customer=880&locale=en_US&fmt=pdf&setupMode=false&mode=0&dt=1592310751733&__format=pdf&__asattachment=false&env=prod";
		var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');
		// printWindow.document.write("");
		setTimeout(function() {
			// printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}, 3000);
		
	} else {
		window.open(reportURL + http_build_query(params));
	}
}

export function calculateTaxRate(formData, taxRate, taxExempt, totalOrderAmt) {
	// console.log("calculateTaxRate() - this.state.customerInfo: ", this.state.customerInfo);
	if(taxRate && !taxExempt) {
		formData['taxAmount'] = (totalOrderAmt * taxRate) / 100;
		formData['grandTotal'] = totalOrderAmt + 
			formData['taxAmount'];
	}
	else {
		formData['taxAmount'] = null;
		formData['grandTotal'] = totalOrderAmt;
	}
	return formData;
}

const userPermissions = {
	// Customer
	7: {
		buttons: {
			save: true,
			cancel: true,
		},
		reports: {
			
		}
	},
	// Sales
	8: {},
	// VIP
	9: {},
	// Admin
	10: {},
	// Lab Tech
	11: {},
	// R & D
	12: {},
	// Finance
	13: {},
	// Sales Manager
	170: {},
	//	Production
	171: {},
}



/*
Params:
------------------------
const formData = {
	name: "",
	age: -1,
	address: "",
	phone: "",
	marks: "asd",
};
const validationFields = {
	name: "required",
	age: "required,numeric,positive",
	address: "",
	marks: "required,numeric",
};
*/
export function validateForm(formData, validationFields, otherData = null) {

	let result = true;
	let errors = {};
	Object.keys(validationFields).map((field) => {
		const validationString = validationFields[field];
		
		let errMsg = "";
		let value = formData[field];
		if(otherData != null){
			if(value == undefined && otherData[field] != undefined)
				value = otherData[field];
		}
		
		const arrValidators = validationString.split(',').forEach((validator, i) => {
		
			const arrValidator = validator.split(':');
			const ruleName = arrValidator[0];
			const ruleValue = arrValidator[1] != undefined ? arrValidator[1] : null;
			// console.log("ruleName: ", ruleName, ", field: ", field, ", value: ", value);
			
			switch(ruleName) {
				case 'required':
					if (value === 0) 
						break;					
					if(!value)
						errMsg = "Cannot be left blank";
					break;

				case 'notEmpty': //used for lists, do not use with required		
					if(Array.isArray(value)) {
						if(value.length == 0) {
							errMsg = "Must choose at least one value";
						}
					} else {
						errMsg = "Must choose at least one value";
					}
					break;
				case 'numeric':
					if(value && isNaN(value))
						errMsg = "Must be numeric";
					break;
				
				case 'positive':
					// Why should value necessarily be 'true'?! 
					// We're checking for a positive value; 0 will always be considered as a false!
					if(value <= 0) // if(value && value <= 0)
						errMsg = "Must be a positive value";
					break;
					
				case 'min':
					if(value < ruleValue)
						errMsg = "Must be greater than " + ruleValue;
					break;
				
				case 'max':
					if(value > ruleValue)
						errMsg = "Must be less than " + ruleValue;
					break;

				case 'length':
					if (value && value.length < ruleValue)
						errMsg = "Must be at least " + ruleValue + " characters long";
					break;
				
				case 'maxChars':
					if (value && value.length > ruleValue)
						errMsg = "Cannot exceed " + ruleValue + " characters";
					break;
				
				default:
					break;
			}
			if(errMsg) {
				result = false;
				return;
			}
		});
		
		errors[field] = errMsg;
	});
	
	return {result: result, errors: errors};
}

export function showFormValidationAlert(validationErrors) {
	let strErrMsg = "";
	Object.keys(validationErrors).forEach(key => {
		if(validationErrors[key]) {
			const field = validationFieldsCaptions[key] ? validationFieldsCaptions[key] : key;
			strErrMsg += field + ": " + validationErrors[key] + "\n";
		}
	});
	alert(strErrMsg);
}

export function resetObject(obj) {
	let errors = {};
	Object.keys(obj).map((key) => {
		errors[key] = "";
	});
	return errors;
}

/*
	Merges the contents of obj2 into obj1
*/
export function mergeObjects(obj1, obj2) {
	let result = obj1;
	Object.keys(obj2).forEach((key) => {
		result[key] = obj2[key];
	});
	return result;
}

export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
      .toUpperCase();
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function toCurrency(str, locale = null) {
	if(isNaN(str)) str = 0.0;
	// if(locale == null)
	//	locale = Config.locale.toLowerCase();

	// const currencySymbol = currencySymbols[locale];
	const currencySymbol = getCurrencySymbol(locale);
	return currencySymbol + parseFloat(str).toLocaleString('en-US', {maximumFractionDigits:2}); // .toFixed(2);
}

export function getCurrencySymbol(locale = null) {
	if(locale == null)
		locale = Config.locale;
		
	locale = locale.toLowerCase().trim();
	return currencySymbols[locale];
	
}

export function getWeightSymbol(locale = null) {
	if(locale == null)
		locale = Config.locale;
		
	locale = locale.toLowerCase().trim();
	return weightSymbols[locale];
	
}

export function getDefaultBillOfLadingInstructions(skidCount, weight, scheduledDeliveryDate, umCodeLookupId) {
	let instructions = "NON-FLAMMABLE, NON-HAZARDOUS\n\nPRINTING INKS - CLASS 55\n\n{2} SKID {0}\n\nHOT! RUSH! HOT!\n\nDELIVERY DATE: {1}\n\nTHANK YOU FOR YOUR GOOD SERVICE";
	instructions = instructions.replace("{2}", skidCount);
	if(skidCount > 1)
		instructions = instructions.replace("SKID", "SKIDS");
	instructions = instructions.replace("{1}", 
		convertTimestampToDate(scheduledDeliveryDate));
		
	let emptyContainerWeight = Config.emptyContainerWeight;
	let unitWeight = Config.weightSymbol;
	if(umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US"){ 
		emptyContainerWeight = Math.round(emptyContainerWeight / Config.weightConversionUnit);
		unitWeight = 'KG';
	}
	
	let totalWeight = weight + (emptyContainerWeight * skidCount);
	// console.log("getDefaultBillOfLadingInstructions() - emptyContainerWeight: ", emptyContainerWeight, ", skidWeight: ", weight);
	
	instructions = instructions.replace("{0}", 
		"" + (totalWeight) 
	 	+ " " + unitWeight + "s");
	
	return instructions;
}

export function showAddress(addressInfo) {
	const address1 = addressInfo.address1;
	const address2 = addressInfo.address2;
	const city = addressInfo.city;
	const state = addressInfo.state;
	const zip = addressInfo.zipCode ? addressInfo.zipCode : addressInfo.zip;
	

	let address = address1;
	
	if(address2)
		address += ", " + address2;
	if(city)
		address += ", " + city;
	if(state)
		address += ", " + state;
	if(zip)
		address += ", " + zip;
		
	return address;
}

export function toCamelCase(str) {
    return str
        .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
        .replace(/\s/g, '')
        .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
}

export function sendEmail(emailsDto, response, emailTemplateId, success, failure, customer)
{
	let emailRecipients = "";
	if(response && response.data) {
		response.data.forEach(contact => {
			if (emailRecipients == "")
				emailRecipients = contact.emailAddress;
			else
				emailRecipients += ";" + contact.emailAddress;
		});
	}
	
	//Create email object with recipient list.
	if (emailsDto.toAddresses == null || emailsDto.toAddresses.length == 0)
		emailsDto.toAddresses = emailRecipients;
	else
		emailsDto.toAddresses += ";" + emailRecipients;
	
	//now that we have the email template and email "To" recipient list, let's send the email
	// var parms = null;
	// if (emailsDto.reportParams != null && emailsDto.reportParams != "")
	// 	parms = emailsDto.reportParams;
	
	// if (emailsDto.reportName != null && emailsDto.reportName != "") {
	// 	if (parms == null)
	// 		parms = "&reportName=" + emailsDto.reportName;
	// 	else
	// 		parms += "&reportName=" + emailsDto.reportName ;
	// }
	// if (emailsDto.companyName != null && emailsDto.companyName != "") {
	// 	if (parms == null)
	// 		parms = "&name=" + emailsDto.companyName;
	// 	else
	// 		parms += "&name=" + emailsDto.companyName ;
	// }
						
	postAPI("emails/" + emailTemplateId + "/send", emailsDto).then(res => {console.log("email sent!")});
}

export function saveSpecOutput(specOutput) {
	// console.log("saveSpecOutput() - specOutput: ", specOutput);
	let specContent = "Formula_Number,Formula_Name,Pct_Component,Component_Name,ReferenceFormula_Number,Component_Number\n";
	specOutput.forEach(row => {
		const str = row.componentName;
		const refFormulaNumber = str.substring(str.indexOf("(")+ 1, str.indexOf(")"));
		
		specContent += row.formulaNumber + "," 
		+ row.formulaName + "," + row.percentUsed + "," 
		+ row.componentName + "," + refFormulaNumber + "," 
		+ row.componentNumber + "\n";
	});
	let FileSaver = require('file-saver');
	var blob = new Blob([specContent], 
		{type: "text/csv;charset=utf-8"});
	FileSaver.saveAs(blob, "specOutput" + (new Date()).getTime() + ".csv");
}

export function roundOff(num, dec) {
	var divisor = Math.pow(10, dec);
	return Math.round(num * divisor) / divisor;
}

export class MyInkIQReactGA {
  
  constructor() {
	// console.log("MyInkIQReactGA constructor called");
	const measurementId = Config.env == 'mex' ? 'G-RMPLY71DPQ' : 'G-ZHEBCNM8Z3'; // 'G-ZHEBCNM8Z3' or G-N7BLEDBLPW
	ReactGA.initialize(measurementId);
  }
  
  pageView() {
	// console.log("MyInkIQReactGA pageView() called");
	ReactGA.pageview("/");
  }
  
  pageViewParams(page, title) {
	// console.log("MyInkIQReactGA pageView() called with params - page: ", page, ", title: ", title);
	ReactGA.send({ hitType: "pageview", page: page, title: title });
  }
  
  gaEvent(category, action, label, value, nonIteraction, transport) {
	console.log("MyInkIQReactGA gaEvent() called with params - category: ", category, ", action: ", action);
	let params = 
	{
	  category: category,
	  action: action,
	};
	if(label != null)
		params['label'] = label;
	if(value != null)
		params['value'] = value;
	if(nonIteraction != null)
		params['nonIteraction'] = nonIteraction;
	if(transport != null)
		params['transport'] = transport;
	
	// Send a custom event
	ReactGA.event(params);
  }
}