import {spanishCallReportLabels, trimChars} from '../common_components/common.js';

export default {
	"labels":{
		"search": "Search",
		"user": "User", //"User ▾",
		"settings": "Settings",
		"logout": "Log out",
		"allRightsReserved": "ALL RIGHTS RESERVED.",
		"records": "records",
		"totalLowerCase": "total",
		// Labels on Listing Pages
		"show": "Show",
		"type": "Type",
		//////////////////// GRID COLUMNS //////////////////////
		//	Orders
		"orderId": "Order #",
		"orderDate": "Order Date",
		"orderType": "Order Type",
		"customer": "Customer",
		"note": "Note",
		"status": "Status",
		"progress": "Progress",
		"shipper": "Shipper",
		"shipDate": "Ship Date",
		"numberOfSkids": "# skids",
		"instructions": "Instructions",
		"calculatedSummary": "Calculated Summary",
		"editableReportSummary": "Editable Report Summary",
		"description": "Description",
		"addAddress": "Add Address",
		// Batches
		"formulaNumber": "Formula #",
		"name": "Name",
		"container": "Container",
		"quantity": "Quantity",
		"lbsOrdered": "Lbs. Ordered",
		"LBsOrdered": "LBs Ordered",
		"KGsOrdered": "KGs Ordered",
		"combine": "Combine",
		// Lab Requests
		"labRequestId": "Lab Request #",
		"dueDate": "Due Date",
		"deliveryDate": "Delivery Date",
		// Matches
		"colorName": "Color Name",
		"matchedBy": "Matched By",
		"completedBy": "Completed By",
		"anilox": "Anilox",
		// Actionable Items - Approve Process Notes
		"approved": "Approved",
		// Actionable Items - To be Priced
		"numOfComp": "# of Comp",
		"numOfOrders": "# of Orders",
		"rmc": "RMC",
		// Emails to be Sent
		"formulaName": "Formula Name",
		"price": "Price",
		// Sales Goals
		"salesGoals": "Sales Goals",
		// New vendor
		"recentPurchaseOrders": "Recent Purchase Orders",
		// New shipper
		"recentSalesOrders": "Recent Sales Orders",
		"orderNumber": "Order Number",
		"scheduledDeliveryDate": "Scheduled Delivery Date",
		
		// Orders
		"amount": "Amount",
		// Formulas
		"substrate": "Substrate",
		"duration": "Duration",
		// Components
		"component": "Component",
		"components": "Components",
		"componentId": "Component #",
		"componentNumber": "Component Number",
		"vendor": "Vendor",
		"vendors": "Vendors",
		"numOfFormulas": "# of Formulas",
		"dateEntered": "Date Entered",
		"lastUsed": "Last Used",
		
		// P.O.
		"poId": "PO #",
		"notes": "Notes",
		
		// OrderForm
		"poNumber": "PO Number",
		"salesRep": "Sales Rep",
		"contact": "Contact",
		"consignment": "Consignment",
		"consignmentBilling": "Consignment Billing",
		"isLate": "Is Late",
		"amountReceived": "Amount Received",
		"amountClaimed": "Amount Claimed",
		"latecomments": "Comments",
		"reason": "Reason",
		"address": "Address",
		"shippingAddress": "Shipping Address",
		"billingAddress": "Billing Address",
		"email": "Email",
		"orderPreferences": "Order Preferences",
		"invoiceNotes": "Invoice Notes",
		"customerNotes": "Customer Notes",
		"billOfLading": "Bill of Lading",
		"invoiceDate": "Invoice Date",
		"shipDate": "Ship Date",
		"newDeliveryDate": "New Delivery Date",
		"trackingNumber": "Tracking #",
		"mustDeliverByDate": "Must Deliver by Date",
		"discount": "Discount",
		"skidCharge": "Sales Tax", // "Skid Charge",
		"freightCharge": "Freight Charge",
		"fuelCharge": "Fuel Charge",
		"total": "Total",
		"kgsOrdered": "KG Ordered",
		"unitPrice": "Unit Price",
		"extPrice": "Ext. Price",
		"matchTo": "Match To",
		"substrates": "Substrates",
		"inkType": "Ink Type",
		"opVarnish": "O/P Varnish",
		"colorTraps": "Color Traps",
		"needPrints": "Need Prints",
		"sendPrints": "Send Prints",
		"needLSDPrints": "Need LSD Prints",
		"sendLSDPrints": "Send LSD Prints",
		"deliveryType": "Delivery Type",
		"shipperTrackingNumber": "Shipper Tracking #",
		"lbsShipped": "Lbs Shipped",
		"LBsShipped": "Lbs Shipped",
		"KGsShipped": "KGs Shipped",
		"LBsMade": "Lbs Made",
		"KGsMade": "KGs Made",
		"colorMatchName": "Color Match Name",
		"matchToPMS": "Match to PMS",
		"matchToGCMI": "Match to GCMI",
		"matchToLiquidSample": "Match to Liquid Sample",
		"matchToPrintSample": "Match to Print Sample",
		"matchToOther": "Match to Other",
		"formula": "Formula",
		"formulas": "Formulas",
		'taxAmount': 'IVA',
		'iva': "IVA",
		'grandTotal': 'Grand Total',
		'convertedAmount': 'Converted Amount',
		'amountPaid': 'Amount Paid',
		'numDrawDowns': '# of Draw Downs',
		'bcmRep': 'BCM Rep',
		
		//Formulas form
		"sequenceNumber": "Seq #",
		"percentUsed": "Percent Used",
		"costPerLb": "Cost per Lb",
		"costPerLB": "Cost per LB",
		"costPerKG": "Cost per KG",
		"cost": "Cost",
		"pigment": "Pigment",
		"resin": "Resin",
		"number": "Number",
		
		"prefix": "Prefix",
		"suffix": "Suffix",
		"min": "Min",
		"max": "Max",
		"minPH": "Min PH",
		"maxPH": "Max PH",
		"viscosity": "Viscosity",
		"minViscosity": "Min Viscosity",
		"maxViscosity": "Max Viscosity",
		"viscosityCup": "Viscosity Cup",
		"health": "Health",
		"flammability": "Flammability",
		"reactivity": "Reactivity",
		"personalProtection": "Personal Protection",
		"hazardPictograms": "Hazard Pictograms",
		"customers" : "Customers",
		"lastOrdered": "Last Ordered",
		"rawMaterialCost": "Raw Mat. Cost",
		"customerColorName": "Customer Color Name",
		"customerFormulaNotes": "Customer Formula Notes",
		"visibleToCustomer": "Visible to Customer",
		"priceBreakdown": "Price Breakdown",
		"shrinkageMarkup": "Shrinkage Markup",
		"basePrice": "Base Price",
		"markup": "Markup",
		"markups": "Markups",
		"allMarkups": "All Markups",
		"shipping": "Shipping",
		"priceChart": "Price Chart",
		"effectiveDate": "Effective Date",
		"expirationDate": "Expiration Date",
		"sequence": "Sequence",
		// Ink Components
		"leadTime": "Lead Time",
		"backupVendor": "Backup Vendor",
		"inventoryCount": "Inventory Count",
		"inventory": "Inventory",
		"minInventory": "Min Inventory",
		// P.O
		"weight": "Weight",
		"totalPrice": "Total Price",
		"received": "Received",
		"receivedDate": "Received Date",
		"lateComment": "Comments",
		
		// Login
		"blankUsernamePassword": "Username or Password cannot be left blank.",
		"invalidUsernamePassword": "Invalid username or password",
		"loginTitle": "Login to MyInkIQ",
		"username": "Username",
		"password": "Password",
		
		// Alerts
		"salesOrderSaved": "Sales Order has been saved successfully",
		"salesOrderDelete": "Sales Order has been deleted successfully",
		"formulaSaved": "Formula has been saved successfully",
		"formulaCustomerSaved": "Formula Customer info has been saved successfully",
		"formulaDeleted": "Formula has been deleted successfully",
		"componentSaved": "Component has been saved successfully",
		"componentDeleted": "Component has been deleted successfully",
		"poSaved": "Purchase Order has been saved successfully",
		"poDeleted": "Purchase Order has been deleted successfully",
		
		// delete confirmation prompts
		"deleteFormulaConfirmationPrompt": "Are you sure you want to delete this formula?",
		"deleteLabRequestConfirmationPrompt": "Are you sure you want to delete this lab request?",
		"deleteInkComponentConfirmationPrompt": "Are you sure you want to delete this ink component?",
		"deleteOrderConfirmationPrompt": "Are you sure you want to delete this order?",
		"deletePurchaseOrderConfirmationPrompt": "Are you sure you want to delete this purchase order?",
		"deleteSubstrateConfirmationPrompt": "Are you sure you want to delete this substrate?",
		"deleteShipperConfirmationPrompt": "Are you sure you want to delete this shipper?",
		"deleteVendorConfirmationPrompt": "Are you sure you want to delete this vendor?",
		"deleteUserConfirmationPrompt": "Are you sure you want to delete this user?",
		"deleteTaskConfirmationPrompt": "Are you sure you want to delete this task?",
		"deleteCallReportConfirmationPrompt": "Are you sure you want to delete this call report?",
		
		"closeConfirmationPrompt": "You have unsaved changes, are you sure you want to close?",
		"inactivateUserConfirmationPrompt": "Are you sure you want to inactivate this user?",
		
		"validationError": "Data not saved, fix errors in the form.",
		"updateInventoryConfirmationPrompt": "Do you want to update the inventory now or some other time?",
		"bUpdateLater": "Update Later",
		"pickDateTime": "Pick a Date/Time",
		"maxContainerWtError": "Weight Ordered is over the max weight for the container.  Please adjust Quantity.",

		
		// data filters
		'filterAll': 'All',
		'filterCompleted': 'Completed',
		'filterNew': 'New',
		'filterSaved': 'Saved',
		'filterSubmitted': 'Submitted',
		'filterActive': 'Active',
		'filterExperimental': 'Experimental',
		'filterInactive': 'Inactive',
		'filterProspect': 'Prospect',
		'filterMostPopular': 'Most Popular Based On Orders Used',
		'filterCreditMemo': 'Credit Memo',
		'filterLabRequest': 'Lab Request',
		'filterSalesOrder': 'Sales Order',
		'filterBcmInkOrders': 'BCM Ink Orders',
		'filterNonBcmInkOrders': 'Non BCM Ink Orders',
		'filterColorMatch': 'Color Match',
		'filterRnd': 'R & D',
		'filterColorMatchRepull': 'Color Match & Repull',
		'filterAdditive': 'Additive',
		'filterBase': 'Base',
		'filterBlendingInk': 'Blending Ink',
		'filterDispersion': 'Dispersion',
		'filterFinishedInk': 'Finished Ink',
		'filterOpv': 'OPV',
		'filterOther': 'Other',
		'filterVarnish': 'Varnish',

		// Login
		'changePassword': 'Change Password',
		'changePasswordPrompt': 'For security reasons, you must update your password.',
		'confirmPassword': 'Confirm Password',
		'resetPassword': 'Reset Password',
		'forgotPassword': 'Forgot password?',
		'resetCode': 'Reset Code',
		'completed': 'Completed',
		'changeReason': 'Change Reason',

		// Order types
		'creditMemo': 'Credit Memo',
		'labRequest': 'Lab Request',
		'salesOrder': 'Sales Order',

		// Order statuses
		'entered': 'Entered',
		'confirmed': 'Confirmed',
		'completed': 'Completed',
		'started': 'Started',
		'quoted': 'Quoted',
		'invoiced': 'Invoiced',
		'shippedInvoiced': 'Shipped/Invoiced',
		'shipped': 'Shipped',
		'received': 'Received',
		'active': 'Active',
		'inactive': 'Inactive',
		'experimental': 'Experimental',

		// Ink types
		'additive': 'Additive',
		'base': 'Base',
		'blendingInk': 'Blending Ink',
		'dispersion': 'Dispersion',
		'finishedInk': 'Finished Ink',
		'opv': 'OPV',
		'other': 'Other',
		'varnish': 'Varnish',
		
		'fuelChargeToBeAdded': 'Fuel Charge to be added',
		'freightChargeToBeAdded': 'Freight Charge to be added',
		'plasticDrumsToBeAdded': 'Plastic Drums to be added',
		'useSpectro1': 'Use Spectro1',
		
		'transparent': 'Transparent',
		'opaque': 'Opaque',
		
		// Tasks
		'taskId': 'Task #',
		'task': 'Task',
		'assignedTo': 'Assigned To',
		'toDo': 'To Do',
		'incomplete': 'Incomplete',
		'taskTitle': 'Task Title',
		'dueDateTime': 'Due Date',
		'createTask': 'Create another Task',
		
		// Call Reports
		'callReports': 'Call Reports',
		'general': 'General',
		'pressRun': 'Press Run',
		'blending': 'Blending System',
		'jobDesc': 'Job Desc',
		'date': 'Date',
		'tasksAssigned': 'Tasks Assigned',
		'tasksCompleted': 'Tasks Completed',
		'contacts': 'Contacts',
		'salesReps': 'Sales Reps',
		'deptEmails': 'Department(s) to Email',
		'objectives': 'Objectives',
		'summary': 'Summary',
		'initialNotes': trimChars(spanishCallReportLabels['initialNotes'][0]),
		'initialToleranceMethod': 'Initial Tolerance Method',
		'finalNotes': trimChars(spanishCallReportLabels['finalNotes'][0]),
		'finalToleranceMethod': 'Final Tolerance Method',
		'batch': 'Batch',
		'pHInitial': 'Initial pH',
		'viscosityInitial': 'Initial Viscocity',
		'toleranceInitial': 'Initial Tolerance',
		'pHFinal': 'Final pH',
		'viscosityFinal': 'Final Viscocity',
		'toleranceFinal': 'Final Tolerance',
		'sampleCollected': 'Sample Collected',
		'nextStep': 'Next Step',
		'uploadCallReport': 'Upload Press Run Call Report',
		'uploadBlendingCallReport': 'Upload Blending System Call Report',
		'dEInitial': 'Initial DE',
		'dEFinal': 'Final DE',
		'densityInitial': 'Initial Density',
		'densityFinal': 'Final Density',
		'takeaway': 'Takeaway',
		'account': trimChars(spanishCallReportLabels['account'][0]),
		'jobDesc': trimChars(spanishCallReportLabels['jobDesc'][0]),
		'salesPerson': spanishCallReportLabels['salesPerson'][0],
		'callDate': spanishCallReportLabels['callDate'][0],
		'meetingObjectives': spanishCallReportLabels['meetingObjectives'][0],
		'meetingSummary': spanishCallReportLabels['meetingSummary'][0],
		'metWith': spanishCallReportLabels['metWith'][0],
		'supervisedRun': spanishCallReportLabels['supervisedRun'][0],
		'assisted': spanishCallReportLabels['assisted'][0],
		'bcmReps': spanishCallReportLabels['bcmReps'][0],
		'mainTakeaways': spanishCallReportLabels['mainTakeaways'][0],
		'followUps': spanishCallReportLabels['followUps'][0],
		'press': spanishCallReportLabels['press'][0],
		'substrate': spanishCallReportLabels['substrate'][0],
		'pressSpeed': spanishCallReportLabels['pressSpeed'][0],
		'pressRunObjectives': spanishCallReportLabels['pressRunObjectives'][0],
		'toleranceMethod': spanishCallReportLabels['toleranceMethod'][0],
		'station': spanishCallReportLabels['station'][0],
		'dryerPct': spanishCallReportLabels['dryerPct'][0],
		'uv': spanishCallReportLabels['uv'][0],
		'pH': spanishCallReportLabels['pH'][0],
		'pressRunSummary': spanishCallReportLabels['pressRunSummary'][0],
		'notesAdjustments': spanishCallReportLabels['notesAdjustments'][0],
		'actionItems': spanishCallReportLabels['actionItems'][0],
		'nextStep': spanishCallReportLabels['nextStep'][0],
		'dueDate': spanishCallReportLabels['dueDate'][0],
		'initialInkConditions': spanishCallReportLabels['initialInkConditions'][0],
		'finalInkConditions': spanishCallReportLabels['finalInkConditions'][0],
		'dE': spanishCallReportLabels['dE'][0],
		'formulaCol': spanishCallReportLabels['formulaCol'][0],
		'lrType': 'LR Type',
		'month': 'Month',
		'year': 'Year',
		'prospectCallsPerMonth': 'Prospect Calls/Month',
		'numCalls': 'Number of Calls',
		'salesGoal': 'Sales Goal',
		'expenseBudget': 'Expense Budget',
		'vocWeight': 'VOC Weight %',
		'density': 'Density (Lbs/Gal)',
		'ammoniaWeight': 'Ammonia %',
	},
}