import React, { Component } from 'react';
import {Form, Row, Col, Modal, Button} from 'react-bootstrap';

import DataDropdown from '../common_components/DataDropdown.js';
import InputText from '../common_components/InputText.js';
// import DataGrid from '../common_components/DataGrid.js';
import { getAPI, postAPI, mapArrayToObject, getLoggedInUser, resetObject, validateForm, showFormValidationAlert, convertTimestampToDate, constants, sendEmail} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import '../FormPage.css';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');


class TaskFormDeleteConfirmation extends Component {
    onDelete() {
        this.props.onConfirm();
        this.props.onClose();
    }

    render() {
        return(<Modal show={this.props.visible}>
            <Modal.Body><Translate value='components.labels.deleteTaskConfirmationPrompt' /></Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
                <Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
            </Modal.Footer>
        </Modal>);
    }
}

const validationFields = {
	title: "required",
	toDo: "required",
	// customerId: "required",
	assignedToSalesRepId: "required",
	dueDateTime: "required",
};



class NewTaskForm extends Component {
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		this.state = {
			title: "New Task",
			errors: resetObject(validationFields),
			formData: {
				taskCategoryId: constants.call_report_category_customer, // this.props.taskCategoryId ? this.props.taskCategoryId : constants.call_report_category_customer,
				title:"1",
				toDo: null,
				customerId:null, // this.props.callReportCustomerId ? this.props.callReportCustomerId : null,
				vendorId:null, // this.props.callReportVendorId ? this.props.callReportVendorId : null,
				shipperId:null, // this.props.callReportShipperId ? this.props.callReportShipperId : null,
				assignedToSalesRepId:null,
				id:0,
				dueDateTime: null,
				notes: null,
				completed: false,
				model:"com.phototype.myinkiq.model.vos.dtos::TasksDto",
				retired:false,
				createdDate:null,
				createdBy:null,
				modifiedBy:null,
				modifiedDate:null,
			},
			showDeleteConfirmation: false,
			activeCustomers: [],
			activeVendors: [],
			activeShippers: [],
			salesRepList: [],
			createNewTask: false,
			initialData: {},
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.getCustomerData = this.getCustomerData.bind(this);
		this.initData = this.initData.bind(this);
		this.onCreateNewTaskChanged = this.onCreateNewTaskChanged.bind(this);
	}
	
	async initData() {
		var customerId = '';
		
		
		
		// Getting Customers list to select from
		const loggedInUser = getLoggedInUser();
		let bVIPOrSalesManager = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
		let activeCustomers = [];
		let activeVendors = [];
		let activeShippers = [];


		if(this.props.activeCustomers != null) {
			activeCustomers = this.props.activeCustomers;
		} else {
			// Get a list of all active customers
			await getAPI("customers/list").then(resCustomers => {
				// Samee - should include ALL customers
				activeCustomers = resCustomers['data']; // .filter(obj => obj.status == 'Active');
			});

			if(!bVIPOrSalesManager) {
				// In case of a non VIP, Check if there are any user customers specific to the logged in user
				await getAPI("users/" + loggedInUser.id + "/customers").then(resUserCustomers => {
					const userCustomers = resUserCustomers['data'];
					// If so, filter the customer list accordingly 
					if(userCustomers.length) {
						activeCustomers = userCustomers.map(userCustomer => activeCustomers.filter(activeCustomer => activeCustomer.id == userCustomer.customerId)[0]);
					}
				});
			}
		}
		
		// Get a list of all active vendors
		if(this.props.activeVendors != null) {
			activeVendors = this.props.activeVendors;
		}  else {
			await getAPI("vendors/list").then(resVendors => {
				activeVendors = resVendors['data']; // .filter(obj => obj.status == 'Active');
				
			});
		}
		// console.log("vendors fetched");
		
		if(this.props.activeShippers != null) {
			activeShippers = this.props.activeShippers;
		}  else {
			// Get a list of all active shippers
			await getAPI("shippers/list").then(resShippers => {
				activeShippers = resShippers['data']; // .filter(obj => obj.status == 'Active');
			});
		}
		
		if(this.props.editCustomerId > 0) {
			activeCustomers = activeCustomers.filter(row => row.id == this.props.editCustomerId);
		}
		else if(this.props.callReportCustomerId > 0) {
			activeCustomers = activeCustomers.filter(row => row.id == this.props.callReportCustomerId);
		}
		
		if(this.props.editVendorId > 0) {
			activeVendors = activeVendors.filter(row => row.id == this.props.editVendorId);
		}
		else if(this.props.callReportVendorId > 0) {
			activeVendors = activeVendors.filter(row => row.id == this.props.callReportVendorId);
		}
		
		if(this.props.editShipperId > 0) {
			activeShippers = activeShippers.filter(row => row.id == this.props.editShipperId);
		}
		else if(this.props.callReportShipperId > 0) {
			activeShippers = activeShippers.filter(row => row.id == this.props.callReportShipperId);
		}
		
		this.setState({
			activeCustomers: activeCustomers,
			activeVendors: activeVendors,
			activeShippers: activeShippers,
		});
		
		var formData = this.state.formData;
		/*
		if (activeCustomers.length == 1){
			var customerId = activeCustomers[0].id;
			formData['customerId'] = customerId;
			this.setState({
				formData: formData,
			});
			// this.getCustomerData(customerId);
		} else if (activeVendors.length == 1){
			var vendorId = activeVendors[0].id;
			formData['vendorId'] = vendorId;
			this.setState({
				formData: formData,
			});
			// this.getCustomerData(vendorId);
		} else if (activeShippers.length == 1){
			var shipperId = activeShippers[0].id;
			formData['shipperId'] = shipperId;
			this.setState({
				formData: formData,
			});
			// this.getCustomerData(shipperId);
		}
		*/
	}
	
	
	async getCustomerData(customerId) {
		
		const loggedInUser = getLoggedInUser();
		
		// var labTechs = [];
		// await getAPI("users/labtechsnrnds?bVIPs=1").then(resLabTechs => {
		//	labTechs = resLabTechs['data'].map(obj => ({actorId: obj.id, displayName: obj.name, emailAddress: obj.emailAddress}));
		// });
		
		// getAPI("customers/" + customerId + "/salesreps").then(resUsersSalesRep => {
		await getAPI("users/salesreps?bShowAll=1").then(resUsersSalesRep => {
			let formData = this.state.formData;
			// Autopopulate and set to the default sales rep;
			if(resUsersSalesRep['data'].length > 0)
				if(this.props.editTaskId == null)
					if(formData.assignedToSalesRepId == null)
						formData.assignedToSalesRepId = resUsersSalesRep['data'][0]['actorId'];
			
			let salesRepList = resUsersSalesRep['data'];
			this.setState({
				salesRepList: resUsersSalesRep['data'].map(obj => ({actorId: obj.id, displayName: obj.name, emailAddress: obj.emailAddress})),
				formData: formData,
			});
			/*
			let bVIPOrSalesManager = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
			
			if(bVIPOrSalesManager) {
				getAPI("users/salesreps?bLabTechsRnDs=1").then(resUsersSalesRep => {
					// console.log("resUsersSalesRep['data']: ", resUsersSalesRep['data']);
					this.setState({
						salesRepList: resUsersSalesRep['data'].map(obj => ({actorId: obj.id, displayName: obj.name, emailAddress: obj.emailAddress})),
						formData: formData,
					});
				});
			} else {
				// console.log("salesRepList: ", salesRepList);
				this.setState({
					salesRepList: salesRepList.concat(labTechs),
					formData: formData,
				});
			}
			*/
		});
	}
	
	onCreateNewTaskChanged(event) {
		 const val = event.target.checked;
		 // console.log("onCreateNewTaskChanged() - val: ", val);
		 this.setState({
			 createNewTask: val
		 });
	}

	componentDidMount() {
		
		this.initData();
		
		if(this.props.editCallReportTaskRow != null) {
			// console.log("this.props.editCallReportTaskRow: ", this.props.editCallReportTaskRow);
			this.getCustomerData(this.props.editCallReportTaskRow['customerId']);
			this.setState({
				title: this.props.editCallReportTaskRow.taskId,
				formData: this.props.editCallReportTaskRow,
			});
		} 
		else if(this.props.editTaskId != null)
		{
			var editTaskId = this.props.editTaskId;
			
			getAPI("/tasks/" + editTaskId).then(resTask => {
				const formData = resTask['data'][0];
				let initialData = JSON.parse(JSON.stringify(formData));
				const title = 'Task #: ' + editTaskId;
				this.getCustomerData(formData['customerId']);
				this.setState({
					title: title,
					formData: formData,
					initialData: initialData,
				});
			});
		}
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var formData = this.state.formData;
		var value = '';
		
		
		if(name == 'customerId' || name == 'vendorId' || name == 'shipperId') {
			value = event.value;
			formData[name] = value;
			this.setState({
			  formData: formData,
			});
			this.getCustomerData(value);
		} else {
			switch(name) 
			{
				case 'dueDateTime':
					value = event._d ? event._d.getTime() : event;
					break;
				
				default:
					value = target.type === 'checkbox' ? target.checked : target.value;
			}
			
			formData[name] = value;
			this.setState({
			  formData: formData
			});
		}
		// console.log("value: ", value);
	}
	
	onSave() {
	
		const validation = validateForm(this.state.formData, validationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			let formData = this.state.formData;
			
					
			// Check if Task form was NOT opened from a call report
			console.log("this.props.callReportId: ", this.props.callReportId);
			if(this.props.callReportId == null) {
				postAPI("tasks", formData).then(resTask => {
					var taskId = resTask['data'][0]['id'];
					this.reactGA.gaEvent("Task", "Save", "Task saved successfully.");
					
					// Checking if a task has just been completed
					console.log("initialData.completed: ", this.state.initialData.completed, ", completed: ", formData.completed);
					if(!this.state.initialData.completed && formData.completed) {
						// Send Task Completed Email here
						console.log("Sending Task Completed Email....");
						const loggedInUser = getLoggedInUser();
						
						let emailsDto = {};
						var fromEmail = "app@myinkiq.com";
						var fromName = "BCM MyInkIQ";
						console.log("salesRepList: ", this.state.salesRepList, ", formData: ", formData);
						var assignedToSalesRep = this.state.salesRepList.filter(row => row.actorId == formData.assignedToSalesRepId)[0];
						console.log("assignedToSalesRep: ", assignedToSalesRep);
						if(assignedToSalesRep != null) {
							var toAddresses = assignedToSalesRep.emailAddress;
							
							emailsDto.customerId = this.props.callReportCustomerId;
							
							emailsDto.emailType = "Task Completed";
							emailsDto.user = loggedInUser;
							console.log("this.state.activeCustomers: ", this.state.activeCustomers, ", this.state.activeVendors: ", this.state.activeVendors, "this.state.activeShippers: ", this.state.activeShippers);
							
							var taskCompletedEmailId = 0;
							var customerVendorShipperName = "";
							var customerVendorShipperLabel = "";
							if(this.state.formData.taskCategoryId == constants.call_report_category_customer) {
								taskCompletedEmailId = 42;
								customerVendorShipperLabel = "Customer";
								customerVendorShipperName = this.state.activeCustomers.filter(row => row.id == formData.customerId)[0]['name'];
							} else if(this.state.formData.taskCategoryId == constants.call_report_category_vendor) {
								taskCompletedEmailId = 43;
								customerVendorShipperLabel = "Vendor";
								customerVendorShipperName = this.state.activeVendors.filter(row => row.id == formData.vendorId)[0]['name'];
							} else if(this.state.formData.taskCategoryId == constants.call_report_category_shipper) {
								taskCompletedEmailId = 44;
								customerVendorShipperLabel = "Shipper";
								customerVendorShipperName = this.state.activeShippers.filter(row => row.id == formData.shipperId)[0]['name'];
							}
							
							
							const dueDateTimeFormatted = this.state.formData.dueDateTime == null || isNaN(this.state.formData.dueDateTime) || this.state.formData.dueDateTime == 0 ? this.state.formData.dueDateTime : convertTimestampToDate(this.state.formData.dueDateTime);
			
							var emailBody = "The following task has been completed:<br />Task #: " + formData.id + " <br />Title: " + formData.toDo;
							emailBody += "<br />" + customerVendorShipperLabel + ": " + customerVendorShipperName;
							emailBody += "<br />Sales Rep: " + assignedToSalesRep.displayName;
							emailBody += "<br />Due Date: " + dueDateTimeFormatted;
							emailBody += "<br />Notes: " + formData.notes;
							emailsDto.body = emailBody
							emailsDto.fromEmail = fromEmail;
							emailsDto.fromName = fromName;
							emailsDto.replyTo = fromEmail;
							emailsDto.subject = "Task Completed";
							// emailsDto.toAddresses = toAddresses;
							console.log("emailBody: ", emailBody);
							

							getAPI("/emails/useremails?emailId=" + taskCompletedEmailId).then(resUserEmails => {
								toAddresses = resUserEmails['data'].map(row => row.emailAddress).join(";");
								console.log("toAddresses: ", toAddresses);
								emailsDto.toAddresses = toAddresses;
								sendEmail(emailsDto, null, 0);
							});
							
						}
					}
					alert("Task has been saved successfully");
					this.props.onSave(this.state.createNewTask);
				});
			} else {
				this.props.onSave(JSON.parse(JSON.stringify(formData)), this.state.createNewTask);
			}

			// Reset Form if the create new Task checkbox is checked.
			if(this.state.createNewTask) {
				
				formData.id = 0;
				formData.title = "1";
				formData.toDo = "";
				formData.customerId = "";
				formData.assignedToSalesRepId = "";
				formData.dueDateTime = "";
				formData.notes = "";
				formData.completed = false;
				this.setState({formData: formData, title: 'New Task'});
			}
		}
	}
	
	onDelete() {
		postAPI("tasks/" + this.state.formData.id, this.state.formData).then(resTask => {

			// alert(<Translate value="components.labels.componentDeleted" />);
			alert("Task has been deleted successfully");
			this.reactGA.gaEvent("Task", "Delete", "Task deleted successfully.");
			this.props.onSave();
		});
	}
	
	shouldComponentUpdate() {
		// var bUpdate = this.state.vendorsList.length > 0;
		return true;
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		
		var activeCustomersMapped = this.state.activeCustomers.filter(row => row != undefined).map((obj, key) => ({value: obj.id, label: obj.name}));
		const salesRepListMapped = this.state.salesRepList.map(obj => [obj.actorId, obj.displayName]);
		const customerName = this.state.formData.customerId ? 
			activeCustomersMapped.filter(obj => obj.value == this.state.formData.customerId)[0] : null;
		
		var activeVendorsMapped = this.state.activeVendors.filter(row => row != undefined).map((obj, key) => ({ value: obj.id, label: obj.name }));
		const vendorContactsMapped = {};
		var vendorName = this.state.formData.vendorId ?
			activeVendorsMapped.filter(obj => obj.value == this.state.formData.vendorId)[0] : null;
		
		var activeShippersMapped = this.state.activeShippers.filter(row => row != undefined).map((obj, key) => ({ value: obj.id, label: obj.name }));;
		const shipperContactsMapped = {};
		var shipperName = this.state.formData.shipperId ?
			activeShippersMapped.filter(obj => obj.value == this.state.formData.shipperId)[0] : null;

		
		const dueDateTimeFormatted = this.state.formData.dueDateTime == null || isNaN(this.state.formData.dueDateTime) || this.state.formData.dueDateTime == 0 ? this.state.formData.dueDateTime : convertTimestampToDate(this.state.formData.dueDateTime);

		const bDeleteEnabled = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
		const bShowCreateTaskChkbox = this.props.editCallReportTaskRow == null && this.props.editTaskId == null;
		
		return(
			<Modal show={this.props.visible} bsSize="large">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={6}>
								<DataDropdown label={<Translate value="components.labels.Category" />} 
									id={"taskCategoryId"} name={"taskCategoryId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.taskCategoryId} 
									data={{
										[constants.call_report_category_customer]: "Customer",
										[constants.call_report_category_vendor]: "Vendor", 
										[constants.call_report_category_shipper]: "Shipper"
									}} 
									value={this.state.formData.taskCategoryId} 
								/>
								
								{false && <InputText label={<Translate value="components.labels.taskTitle" />} 
									id={"title"} name={"title"} action={this.handleInputChanged} 
									value={this.state.formData.title} required
									errMsg={this.state.errors.title}
								/>
								}
								
								<InputText 
									label={<Translate value="components.labels.task" />} id={"toDo"} 
									name={"toDo"} action={this.handleInputChanged} type={"TextArea"} 
									value={this.state.formData.toDo} required errMsg={this.state.errors.toDo}
								/>
								
								{this.state.formData.taskCategoryId == constants.call_report_category_customer && <InputText 
									label={<Translate value="components.labels.customer" />} 
									id={"customerId"} name={"customerId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.customerId} type={"SingleSelect"} 
									value={customerName} data={activeCustomersMapped} 
								/>}
								
								{this.state.formData.taskCategoryId == constants.call_report_category_vendor && <InputText 
									label={<Translate value="components.labels.Vendor" />} 
									id={"vendorId"} name={"vendorId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.vendorId} type={"SingleSelect"} 
									value={vendorName} data={activeVendorsMapped} 	
								/>}
									
								{this.state.formData.taskCategoryId == constants.call_report_category_shipper && <InputText 
									label={<Translate value="components.labels.Shipper" />} 
									id={"shipperId"} name={"shipperId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.shipperId} type={"SingleSelect"} 
									value={shipperName} data={activeShippersMapped} 
								/>}
								
								<InputText label={<Translate value="components.labels.completed" />} 
									id={"completed"} name={"completed"} 
									action={this.handleInputChanged} type={"CheckBox"} 
									value={this.state.formData.completed}
								/>
								<DataDropdown label={<Translate value="components.labels.bcmRep" />} 
									id={"assignedToSalesRepId"} name={"assignedToSalesRepId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.assignedToSalesRepId} data={salesRepListMapped} 
									value={this.state.formData.assignedToSalesRepId} 
								/>
								
								<InputText 
									label={<Translate value="components.labels.dueDateTime" />} 
									type={"DateTime"} id={"dueDateTime"} 
									name={"dueDateTime"} action={this.handleInputChanged} 
									value={dueDateTimeFormatted} required
									errMsg={this.state.errors.dueDateTime}
								/>
								
								<InputText 
									label={<Translate value="components.customers.notes" />} id={"notes"} 
									name={"notes"} action={this.handleInputChanged} type={"TextArea"} 
									value={this.state.formData.notes}  
								/>
								{bShowCreateTaskChkbox && 
								<InputText label={<Translate value="components.labels.createTask" />} 
									id={"createTask"} name={"createTask"} 
									action={this.onCreateNewTaskChanged} type={"CheckBox"} 
									value={this.state.createTask}
								/>}
							</Col>
						</Row>
					</Form>
					<TaskFormDeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
					/>
				</Modal.Body>
				<Modal.Footer>
					{this.props.editTaskId && !this.props.cannotSave ? 
					<Button 
						bsStyle='danger' 
						onClick={() => this.setState({showDeleteConfirmation: true})}
						disabled={!bDeleteEnabled}
					>
						<Translate value="components.buttons.deleteTask" />
					</Button> : ''}
					<Button onClick={this.props.onClose}>
						<Translate value="components.buttons.close" />
					</Button>
					{!this.props.cannotSave && 
					<Button bsStyle="primary" onClick={this.onSave} >
						<Translate value="components.buttons.saveChanges" />
					</Button>
					}
				</Modal.Footer>
			</Modal>
		);
	}
}

export default NewTaskForm;