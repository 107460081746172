export default {
    "Inventory":{
        "components": "components#",
        "Component_Description": "Component Description",
        "status": "Status",
        "committed Qty": "Committed Qty",
        "Min Qty": "Min Qty",
        "Physical": "Physical Qty",
        "Actual": "Actual Qty",
		"totalCost": "Total Cost",
		"orderQty": "On Order Qty",
    }
}
