import React, { Component } from 'react';
import {Row, Col, Button, Form, Modal} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid.js';
import InputText from '../common_components/InputText.js';
import DataDropdown from '../common_components/DataDropdown.js';
import OrderForm from '../orders/OrderForm.js';
import NewFormulaForm from './NewFormulaForm';
import saveAs from 'file-saver';
import { getAPI, postAPI, convertTimestampToDate, getLoggedInUser, constants, saveSpecOutput} from '../common_components/common.js';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');



class Formula extends Component {
	constructor(props){
		super(props);
		this.state = {
			data: [],
			status: 'Active',
			type: '',
			newFormulaFormVisible: false,
			editFormulaFormVisible: false,
			editFormulaId: null,
			editOrderId: null,
			idField: 'id',
			loading: true,
			autoRefresh: true,
			interval: null,
			filterColumns: {
				orderId: "",
				formulaNumber: "",
				name: "",
				formulaStatusName: "",
				formulaSubstrateName: "",
				formulaAnilox: "",
			},
			showSpecOutput: false,
			specOutputDate: '',
		};
		
		this.formulaTypeChanged = this.formulaTypeChanged.bind(this);
		this.formulaStatusChanged = this.formulaStatusChanged.bind(this);
		this.showEditFormulaForm = this.showEditFormulaForm.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.onColumnFilterChanged = this.onColumnFilterChanged.bind(this);
	}
	  refreshData() {
	  	this.setState({loading: true});
	  	const loggedInUser = getLoggedInUser();
		// console.log("dataIn: ", dataIn);
		
		
	  	// console.log("Formula::refreshData() called - this.props: ", this.props);
	  	let customerId = 0;
	  	if(loggedInUser.userTypeId == constants.customer_type_id) {
	  		customerId = loggedInUser.customerId;
	  	}
	  	
		// let endpoint = "formulas/list?cid=" + customerId + "&start=0&limit=99000&companyId=1";
		let endpoint = "formulas/search?start=0&limit=99000&cid=" + customerId + "&companyId=1&search=";
		switch(this.props.dashboard) {
			
			case 'ToBePriced':
				endpoint = "formulas/pricelist?companyId=1&search=";
				this.setState({
					idField: 'formulaId'
				});
				break;
		}
		
		getAPI(endpoint).then(res => {
			if(this.props.dashboard) {
				this.props.onLoad(res['data'].length);
			}
			this.setState({
				loading: false,
				data: res['data'],
			});
		});
	  }
	  
	  
	  componentDidMount() {
		this.refreshData();
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.editFormulaFormVisible) {
				// console.log("Formula::componentDidMount() - refreshData being called....");
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	  }
	  
	  componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	formulaTypeChanged(event) {
		var val = event.target.value;
		/*
		var newFilter = this.state.filter;
		if(val != '')
			newFilter['formulaType'] = {'op': '=', 'val': val};
		else
			delete newFilter['formulaType'];
		*/
		this.setState({
			// filter: newFilter
			type: val,
		});
	}
	
	formulaStatusChanged(event) {
		var val = event.target.value;
		/*
		var newFilter = this.state.filter;
		if(val != '')
			newFilter['formulaStatusName'] = {'op': '=', 'val': val};
		else
			delete newFilter['formulaStatusName'];
		*/
		this.setState({
			// filter: newFilter,
			status: val,
		});
	}
	
	showEditFormulaForm(dataIn) {
		const loggedInUser = getLoggedInUser();
		// console.log("dataIn: ", dataIn);
		
		// Customer cannot view Finished ink
		if(loggedInUser.userTypeId == constants.customer_type_id) {
			if(loggedInUser.accessFormulasCode != 'E') {
				if(dataIn.formulaType != "Blending Ink") {
					return false;
				}
			}
		}
		this.setState({
			editFormulaId: dataIn[this.state.idField],
			editFormulaFormVisible: true,
		});
	}
	
	onLinkClick(event) {
		const editId = event.target.id;
		const varName = event.target.name;
		// console.log("Formula::onLinkClick() - editId: ", editId, ", varName: ", varName);
		this.setState({
			[varName]: editId,
		});
	}
	
	onColumnFilterChanged(event) {
		const filterColumn = event.target.name;
		const filterText = event.target.value;
		// console.log("Formula::onColumnFilterChanged() - filterColumn: ", filterColumn, ", filterText: ", filterText);
		
		let filterColumns = this.state.filterColumns;
		filterColumns[filterColumn] = filterText;
		this.setState({
			filterColumns: filterColumns,
		})
		
	}

	render() {
		const loggedInUser = getLoggedInUser();
		const bSalesPerson = loggedInUser.userTypeId == constants.sales_type_id;
		const bCustomer = loggedInUser.userTypeId == constants.customer_type_id;
		
		let bShowFormulaButton = !bCustomer || (bCustomer && loggedInUser.accessFormulasCode == 'E');
		// console.log("Formula::render() - this.state.filterColumns: ", this.state.filterColumns);
		var formulaStatus = {
			'Active': 'Active', // <Translate value='components.labels.filterActive' />,
			'Experimental': 'Experimental', // <Translate value='components.labels.filterExperimental' />,
			'Inactive': 'Inactive', // <Translate value='components.labels.filterInactive' />,
			'Most Popular Based On Orders Used': 'Most Popular Based On Orders Used', // <Translate value='components.labels.filterMostPopular' />,
			'': 'All', // <Translate value='components.labels.filterAll' />,
		};
		
		var formulaTypes = {
			'': 'All', // <Translate value='components.labels.filterAll' />, 
			'Additive': 'Additive', // <Translate value='components.labels.filterAdditive' />, 
			'Base': 'Base', // <Translate value='components.labels.filterBase' />, 
			'Blending Ink': 'Blending Ink', // <Translate value='components.labels.filterBlendingInk' />, 
			'Dispersion': 'Dispersion', // <Translate value='components.labels.filterDispersion' />, 
			'Finished Ink': 'Finished Ink', // <Translate value='components.labels.filterFinishedInk' />, 
			'OPV': 'OPV', // <Translate value='components.labels.filterOpv' />, 
			'Other': 'Other', // <Translate value='components.labels.filterOther' />, 
			'Varnish': 'Varnish', // <Translate value='components.labels.filterVarnish' />, 
		};
		
		var formatColumns = {
			0: {
				'columnFilter': this.state.filterColumns['orderId'],
				'link': {
					'column': 'orderId', 
					'var': 'editOrderId', 'func': this.onLinkClick
				}
			}, 
			1: {'date': true},
			2: {'columnFilter': this.state.filterColumns['formulaNumber']},
			3: {'columnFilter': this.state.filterColumns['name']},
			4: {'columnFilter': this.state.filterColumns['formulaStatusName']},
			5: {'columnFilter': this.state.filterColumns['formulaSubstrateName']},
			6: {'columnFilter': this.state.filterColumns['formulaAnilox']},
			10: {'currency': true},
			'callbackColumnFilter': this.onColumnFilterChanged,
		};
		
		var data = this.state.data;
		var columns = [
			<Translate value="components.labels.orderId" />,
			<Translate value="components.labels.orderDate" />,
			<Translate value="components.labels.formulaNumber" />,
			<Translate value="components.labels.name" />,
			<Translate value="components.labels.status" />,
			<Translate value="components.labels.substrate" />,
			<Translate value="components.labels.anilox" />,
			<Translate value="components.labels.numOfComp" />,
			<Translate value="components.labels.numOfOrders" />,
			<Translate value="components.labels.duration" />,
			<Translate value="components.labels.rmc" />,
		];
		var	fields = ['orderId', 'orderDate', 'formulaNumber', 'name', 'formulaStatusName', 'formulaSubstrateName', 'formulaAnilox', 'componentCount', 'orderCount', 'duration', 'amount'];
		
		if(bSalesPerson) {
			columns.pop();
			fields.pop();
			delete(formatColumns[10]);
		}
		
		if(bCustomer) {
			columns = [
				<Translate value="components.labels.orderId" />,
				<Translate value="components.labels.orderDate" />,
				<Translate value="components.labels.formulaNumber" />,
				<Translate value="components.labels.name" />,
				<Translate value="components.labels.status" />,
				<Translate value="components.labels.substrate" />,
				<Translate value="components.labels.anilox" />,
				<Translate value="components.labels.numOfOrders" />,
				<Translate value="components.labels.price" />,
			];
			fields = ['orderId', 'orderDate', 'formulaNumber', 'name', 'formulaStatusName', 'formulaSubstrateName', 'formulaAnilox', 'orderCount', 'customerPrice'];
			formatColumns = {
				0: {
					'columnFilter': this.state.filterColumns['orderId'],
					'link': {
						'column': 'orderId', 
						'var': 'editOrderId', 'func': this.onLinkClick
					}
				}, 
				1: {'date': true},
				2: {'columnFilter': this.state.filterColumns['formulaNumber']},
				3: {'columnFilter': this.state.filterColumns['name']},
				4: {'columnFilter': this.state.filterColumns['formulaStatusName']},
				5: {'columnFilter': this.state.filterColumns['formulaSubstrateName']},
				6: {'columnFilter': this.state.filterColumns['formulaAnilox']},
				8: {'currency': true},
				'callbackColumnFilter': this.onColumnFilterChanged,
			};
		}
		
		if(!this.props.dashboard)
		{
			for(const filterColumn in this.state.filterColumns) {
				const filterText = this.state.filterColumns[filterColumn];
				if(filterText)
					data = data.filter(obj => 
						("" + obj[filterColumn]).toLowerCase()
							.indexOf(filterText.toLowerCase()) > -1 
					);
			}

		}
		
		switch(this.props.dashboard) {
			
			case 'ToBePriced':
				columns = [
					<Translate value="components.labels.orderId" />,
					<Translate value="components.labels.orderDate" />,
					<Translate value="components.labels.formulaNumber" />,
					<Translate value="components.labels.name" />,
					<Translate value="components.labels.status" />,
					<Translate value="components.labels.numOfComp" />,
					<Translate value="components.labels.numOfOrders" />,
					<Translate value="components.labels.customer" />,
					<Translate value="components.labels.rmc" />,
				];
				fields = ['orderId', 'orderDate', 'formulaNumber', 'name', 'orderStatusName', 'componentCount', 'orderCount', 'customerName', 'amount'];
				formatColumns= {
					0: {'link': {'column': 'orderId', 'var': 'editOrderId', 'func': this.onLinkClick}}, 
					1:{'date': true}, 
					8:{'currency': true},
				};
				break;
		}
		
		if(this.state.type != '') {
			data = data.filter(obj => obj.formulaType == this.state.type);
		}
		
		if(this.state.status != '') {
			data = data.filter(obj => obj.formulaStatusName == this.state.status);
		}
		
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		
		
		const editFormulaForm = this.state.editFormulaFormVisible ? 
			<NewFormulaForm
			onClose={() => this.setState({editFormulaFormVisible: false})}
			onSave={() => {
				this.setState({editFormulaFormVisible: false}); 
				// Samee - temporarily disable this so that the sort remains persistent
				// this.refreshData();
				}
			}
			visible={this.state.editFormulaFormVisible}
			editFormulaId={this.state.editFormulaId}
		/> : "";
		const newFormulaForm = this.state.newFormulaFormVisible ? 
			<NewFormulaForm
			onClose={() => this.setState({newFormulaFormVisible: false})}
			onSave={() => {
				this.setState({newFormulaFormVisible: false});
				this.refreshData();
				}
			}
			visible={this.state.newFormulaFormVisible}
		/> : "";
		
		var orderFormContent = this.state.editOrderId != null ? <OrderForm
			visible={this.state.editOrderId != null}
			onClose={() => this.setState({editOrderId: null})}
			onSave={() => {
				this.setState({editOrderId: null});
				this.refreshData();
			}}
			editOrderId={this.state.editOrderId}
		/> : "";
		
		
		var specOutputModal = this.state.showSpecOutput ? 
		<Modal 
			show={this.state.showSpecOutput} 
			onHide={() => this.setState({showSpecOutput: false})}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<Translate value='components.reports.specSystemReport' />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='form-page' horizontal>
					<InputText type='DateTime' 
						label={<Translate value='components.reports.exportSinceDate' />} 
						action={event => {
							const value = event._d ? 
								convertTimestampToDate(event._d, false, false, true) : event;
							// console.log("value: ", value);
							this.setState({specOutputDate: value});
						}}
					/>
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='primary' 
					onClick={() => this.setState({showSpecOutput: false})}
				>
					<Translate value='components.buttons.close' />
				</Button>
				<Button bsStyle='primary' 
					onClick={() => {
						const loggedInUser = getLoggedInUser();
						const cid = loggedInUser.customerId;
						getAPI("formulas/export/speccustjson?fid=0&cid="
								+ cid + "&d="
								+ this.state.specOutputDate).then(resSpecOutput => {
							// console.log("resSpecOutput: ", resSpecOutput);
							if(resSpecOutput['data'].length == 0)
								alert("No Blending Formulas have been created since the specified date.");
							else
								saveSpecOutput(resSpecOutput['data']);
						});
					}}>
					<Translate value='components.buttons.export' />
				</Button>
			</Modal.Footer>
		</Modal> : "";
		
		return (
			<div>
				<DataGrid
					loading={this.state.loading}
					columns={columns}
					importantColumns={[1, 6]}
					fields={fields}
					data={data}
					formatColumns={formatColumns} 
					onDoubleClick={this.showEditFormulaForm}
					onSort={(data) => {this.setState({data:data})}}
				/>
				<Form class='form-page' horizontal>
					<Row>
						<Col md="4">
							{!this.props.dashboard && <DataDropdown 
							label={<Translate value="components.labels.status" />} 
							data={formulaStatus} action={this.formulaStatusChanged} 
							value={this.state.status}
							/>}
						</Col>
						<Col md="4">
							{!this.props.dashboard && <DataDropdown 
							label={<Translate value="components.labels.type" />} 
							data={formulaTypes} action={this.formulaTypeChanged} 
							value={this.state.type}
							/>}
						</Col>
						<Col md="4">
							{bShowFormulaButton &&
							<Button bsSize='sm' onClick={() => this.setState({newFormulaFormVisible: true})}  disabled={loggedInUser.accessFormulasCode == 'V'}><Translate value="components.buttons.newFormula" /></Button>
							}
							&nbsp;
							{bCustomer &&
							<Button bsSize='sm' 
								onClick={() => this.setState({showSpecOutput: true})}
							>
								<Translate value="components.reports.specOutput" />
							</Button>
							}
						</Col>
					</Row>
				</Form>



				{newFormulaForm}
				{editFormulaForm}
				{orderFormContent}
				{specOutputModal}
			</div>
		);
	}
}

export default Formula;
