import React, { Component } from 'react';
import {Grid, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import TabContainer from '../common_components/TabContainer.js';
import readXlsxFile from 'read-excel-file'

// import Customer from '../customers/Customer.js';
// import Inventory from '../inventory/Inventory.js';
// import TabItem from './TabItem.js';
import Order from '../orders/Order.js';
import OrderForm from '../orders/OrderForm.js';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown.js';
import NewFormulaForm from '../formulas/NewFormulaForm.js';
// import ActionableItems from './ActionableItems.js';
import CustomerPopin from '../customers/CustomerPopin.js';
import SalesGoals from './SalesGoals.js';
import MonthlyExpenditures from './MonthlyExpenditures.js';
import MonthlyProspectCalls from './MonthlyProspectCalls.js';
// import Task from '../tasks/Task.js';
import NewTaskForm from '../tasks/NewTaskForm';
import NewCallReportForm from '../call-reports/NewCallReportForm';
import {getLoggedInUser, getAPI, postAPI, convertTimestampToDate, convertDateToTimestamp, generateUUID, postAPIUrlEncoded, openBirtReport, lab_request_lookup_id, constants, sampleList, toleranceMethodsList, spanishCallReportLabels, isCallReportLabel, getCallReportLabelIndex, getIndexByFieldName} from '../common_components/common.js';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
import Octicon, { Sync } from '@githubprimer/octicons-react';
import {MyInkIQReactGA} from '../common_components/common.js'; 

const { Translate, Localize } = require('react-i18nify');

const bcm_ink_orders = '1';
const non_bcm_ink_orders = '2';
const color_match = '3';
const color_match_repull = '6';
const r_n_d = '4';
const bcm_inks_usa = 'BCM INKS USA, INC.';

const task_complete = "Yes";
const task_incomplete = "No";

	
const BCMInkFilter = {
	'0': constants.filterAll, // <Translate value='components.labels.filterAll' />,
	[bcm_ink_orders]: constants.filterBcmInkOrders, // <Translate value='components.labels.filterBcmInkOrders' />,
	[non_bcm_ink_orders]: constants.filterNonBcmInkOrders, // <Translate value='components.labels.filterNonBcmInkOrders' />,
};

const LrFilter = {
	'5': constants.filterAll, // <Translate value='components.labels.filterAll' />,
	[color_match_repull]: constants.filterColorMatchRepull, // <Translate value='components.labels.filterColorMatchRepull' />,
	[color_match]: constants.filterColorMatch, // <Translate value='components.labels.filterColorMatch' />,
	[r_n_d]: constants.filterRnd, // <Translate value='components.labels.filterRnd' />,
};

const TaskTypesFilter = {
	'': constants.filterAll, // <Translate value='components.labels.filterAll' />,
	[task_complete]: constants.completed, // <Translate value='components.labels.completed' />,
	[task_incomplete]: constants.incomplete, // <Translate value='components.labels.incomplete' />,
};

const general_callreport = "General";
const pressrun_callreport = "Press Run";
//const blending_callreport = "Blending System "
	
const CallReportTypesFilter = {
	'': constants.filterAll, // <Translate value='components.labels.filterAll' />,
	[general_callreport]: constants.general, // <Translate value='components.labels.general' />,
	[pressrun_callreport]: constants.pressRun, // <Translate value='components.labels.pressRun' />,
	//[blending_callreport]: constants.blending, // <Translate value='components.labels.blending' />
};

class HomePage extends Component {

	constructor(props){
		super(props);
		this.reactGA = new MyInkIQReactGA();
		this.state = {
			ordersData: [],
			quotedOrdersData: [],
			batchesData: [],
			labRequestsData: [],
			matchesData: [],
			approvedProcessNotesData: [],
			toBePricedData: [],
			emailsToBeSentData: [],
			tasksData: [],
			callReportsData: [],
			
			showBCMInksOrders: non_bcm_ink_orders, // non_bcm_ink_orders, // BCM Orders, Non BCM Orders , All
			showColorMatch: color_match_repull,
			tabIndex: 0,
			
			bLoadingOrders: false,
			bLoadingBatches: false,
			bLoadingLabRequests: false,
			bLoadingMatches: false,
			bLoadingApprovedProcessNotes: false,
			bLoadingToBePriced: false,
			bLoadingEmailsToBeSent: false,
			bLoadingTasks: false,
			bLoadingCallReports: false,
			
			autoRefresh: true,
			intervalBatches: null,
			salesPeopleCustomers: {},
			taskType: task_incomplete,
			taskCategory: "", // constants.call_report_category_customer,
			callReportCategory: "", // constants.call_report_category_customer,
			callReportType: '',
		};
		
		this.refreshOrdersAndLabRequestsData = this.refreshOrdersAndLabRequestsData.bind(this);
		this.refreshBatchesData = this.refreshBatchesData.bind(this);
		this.refreshMatchesData = this.refreshMatchesData.bind(this);
		this.refreshApprovedProcessNotesData = this.refreshApprovedProcessNotesData.bind(this);
		this.refreshToBePricedData = this.refreshToBePricedData.bind(this);
		this.refreshEmailsToBeSentData = this.refreshEmailsToBeSentData.bind(this);
		this.refreshTasksData = this.refreshTasksData.bind(this);
		this.refreshCallReportsData = this.refreshCallReportsData.bind(this);
		this.onLrChanged = this.onLrChanged.bind(this);
		this.onBCMInksOrdersChanged = this.onBCMInksOrdersChanged.bind(this);
		this.onTaskTypeChanged = this.onTaskTypeChanged.bind(this);
		this.onTaskCategoryChanged = this.onTaskCategoryChanged.bind(this);
		this.onCallReportCategoryChanged = this.onCallReportCategoryChanged.bind(this);
		this.onCallReportTypeChanged = this.onCallReportTypeChanged.bind(this);
	}
	
	onBCMInksOrdersChanged(event) {
		const val = event.target.value;
		// console.log("val in onBCMInksOrdersChanged() : ", val);
		this.setState({
			showBCMInksOrders: val,
		});
	}

	onLrChanged(event) {
		const val = event.target.value;
		// console.log("val in onBCMInksOrdersChanged() : ", val);
		this.setState({
			showColorMatch: val,
		});
	}
	
	onTaskTypeChanged(event) {
		const val = event.target.value;
		// console.log("val in onTaskTypeChanged() : ", val);
		this.setState({
			taskType: val,
		});
	}
	
	onTaskCategoryChanged(event) {
		const val = event.target.value;
		// console.log("val in onTaskCategoryChanged() : ", val);
		this.setState({
			taskCategory: val,
		});
	}
	
	onCallReportCategoryChanged(event) {
		const val = event.target.value;
		// console.log("val in onCallReportCategoryChanged() : ", val);
		this.setState({
			callReportCategory: val,
		});
	}
	
	onCallReportTypeChanged(event) {
		const val = event.target.value;
		// console.log("val in onCallReportTypeChanged() : ", val);
		this.setState({
			callReportType: val,
		});
	}
	
	componentDidMount() {
		const loggedInUser = getLoggedInUser();
		if(loggedInUser.userTypeId == 8) {// In case of Sales People, store in their customers for later
			getAPI("users/" + loggedInUser.id + "/customers").then(resSalesPeopleCustomers => {
				let objSalesPeopleCustomerIDs = {};
				resSalesPeopleCustomers['data'].map(row => {
					const customerId = row.customerId;
					objSalesPeopleCustomerIDs[customerId] = 1;
				});
				this.setState({
					salesPeopleCustomers: objSalesPeopleCustomerIDs,
				});
			});
		}
		// console.log("HomePage::componentDidMount() called");
		// Refresh Orders Data
		this.refreshOrdersAndLabRequestsData();
		this.refreshBatchesData();
		this.refreshMatchesData();
		this.refreshApprovedProcessNotesData();
		this.refreshToBePricedData();
		this.refreshEmailsToBeSentData();
		this.refreshTasksData();
		this.refreshCallReportsData();
		this.reactGA.pageViewParams("/dashboard", "Dashboard View" );
	}
	
	componentWillUnmount() {
		// console.log("HomePage::componentWillUnmount() called - this.state.intervalBatches: ", this.state.intervalBatches);
		clearInterval(this.state.intervalBatches);
		
		this.setState({
			intervalBatches: null,
			autoRefresh: false,
		})
	}
	
	refreshOrdersAndLabRequestsData() {
		this.setState({bLoadingOrders: true});
		// let endpoint = "salesorders/list";
		let endpoint = "salesorders/list?bDashboard=1";
		getAPI(endpoint).then(resOrders => {
			let ordersData = resOrders['data'].filter(obj => {
				// const currentDate = Date.parse(new Date());
				// const bIsDue = (currentDate - obj.dueDate) > 3600; // 1 hour offset
				return obj.salesOrderType == "Sales Order" && 
					(obj.statusName == "Entered" 
					|| obj.statusName == "Confirmed" 
					|| obj.statusName == "Shipped"
					|| obj.statusName == "Customer Entered"
					// || (obj.statusName == "Quoted" && bIsDue)
					);
			});
			const bgColors = {0: '', 1: '#ffaaaa', 2: '#FFDEAD'};
			// Highlighting 100% Completed orders as green
			ordersData = ordersData.map(obj => {
				const percentCompleted = obj.batchesCompletedPercentage;
				
				
				obj.bgColor = bgColors[obj.openOrderType] != undefined ? bgColors[obj.openOrderType] : '';
				
				// looking out for any floating point screw-ups
				if(percentCompleted >= 0.99999) 
					obj.bgColor = "#aaffaa";
				
				
				
				return obj;
			});
			
			const quotedOrdersData = resOrders['data'].filter(obj => {
				// const currentDate = Date.parse(new Date());
				// const bIsDue = (currentDate - obj.dueDate) > 3600; // 1 hour offset
				return (obj.statusName == "Quoted"); // && bIsDue);
			});

			
			let labRequestsData = resOrders['data'].filter(obj => obj.salesOrderType == "Lab Request" && 
							(obj.statusName == "Entered" 
							|| obj.statusName == "Confirmed" 
							|| obj.statusName == "Shipped"
							|| obj.statusName == "Customer Entered"));
			
			// Highlighting 100% Completed lab requests as green
			labRequestsData = labRequestsData.map(obj => {
				const percentCompleted = obj.batchesCompletedPercentage;
				// looking out for any floating point screw-ups
				if(percentCompleted >= 0.99999) 
					obj.bgColor = "#aaffaa";	
				return obj;
			});
			
			this.setState({
				ordersData: ordersData,
				quotedOrdersData: quotedOrdersData,
				labRequestsData: labRequestsData,
				bLoadingOrders: false,
			});
		});
	}
	
	refreshBatchesData() {
		this.setState({bLoadingBatches: true});
		const endpoint = "formulas/batches";
		getAPI(endpoint).then(resOrders => {
			let data = resOrders['data'];
			data = data.map(obj => {
				obj.bgColor = obj.combinedBatchNumber != null 
					? 'yellow' : null;
					return obj;
			});
			/*
			const interval = setInterval(() => {
				if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
					console.log("HomeBatches::refreshBatchesData() - refreshBatchesData being called....");
					this.refreshBatchesData();	  
				}
			}, Config.keepConnInterval);
			*/
			this.setState({
				batchesData: data,
				bLoadingBatches: false,
				// intervalBatches: interval,
			});
		});
	}
	
	
	refreshMatchesData() {
		this.setState({bLoadingMatches: true});
		getAPI("salesorders/colormatches").then(resMatches => {
			this.setState({
				matchesData: resMatches['data'],
				bLoadingMatches: false,
			});
		});
	}
	
	refreshApprovedProcessNotesData() {
		this.setState({bLoadingApprovedProcessNotes: true});
		getAPI("customers/notes/unapproved").then(resApprovedProcessNotes => {
			this.setState({
				approvedProcessNotesData: resApprovedProcessNotes['data'],
				bLoadingApprovedProcessNotes: false,
			});
		});
	}
	
	refreshToBePricedData() {
		this.setState({bLoadingToBePriced: true});
		getAPI("formulas/pricelist?companyId=1&search=").then(resToBePriced => {
			this.setState({
				toBePricedData: resToBePriced['data'],
				bLoadingToBePriced: false,
			});
		});
	}
	
	refreshEmailsToBeSentData() {
		this.setState({bLoadingEmailsToBeSent: true});
		getAPI("formulas/emaillist?companyId=1&search=").then(resEmailsToBeSent => {
			this.setState({
				emailsToBeSentData: resEmailsToBeSent['data'],
				bLoadingEmailsToBeSent: false,
			});
		});
	}

	refreshTasksData() {
		const currentDate = convertDateToTimestamp(convertTimestampToDate((new Date()).getTime()));
		this.setState({bLoadingTasks: true});
		getAPI("tasks/search").then(resTasks => {
			this.setState({
				tasksData: resTasks['data'].map(obj => {
					const dueDateTime = convertDateToTimestamp(convertTimestampToDate(obj.dueDateTime));
					obj.bgColor = !obj.completed && currentDate > dueDateTime ? '#ffaaaa' : null;
					
					let customerVendorShipperName = obj.customerId != null ? obj.customerName : (
						obj.vendorId != null ? obj.vendorName : (
							obj.shipperId != null ? obj.shipperName : ""
						)
					);
					obj['customerVendorShipperName'] = customerVendorShipperName;
					
					return obj;
				}),
				bLoadingTasks: false,
			});
		});

	}
	
	refreshCallReportsData() {
		this.setState({bLoadingCallReports: true});
		// Show only call reports with incomplete tasks for the past week
		// getAPI("callreports/search?complete=0&week=1").then(resTasks => {
		getAPI("callreports/search?week=1").then(resTasks => {
			let callReportsData = resTasks['data'].map(obj => {
				let customerVendorShipperName = obj.customerId != null ? obj.customerName : (
					obj.vendorId != null ? obj.vendorName : (
						obj.shipperId != null ? obj.shipperName : ""
					)
				);
				obj['customerVendorShipperName'] = customerVendorShipperName;
				return obj;
			});
			// console.log("callReportsData: ", callReportsData);
			this.setState({
				callReportsData: callReportsData,
				bLoadingCallReports: false,
			});
		});		
	}
	

	render(){
		// console.log("HomePage::render() called ");
		const loggedInUser = getLoggedInUser();
		const amountField = Config.locale.toLowerCase() == 'es_mx' ? 'grandTotal' : 'amount';
		
		let ordersData = this.state.ordersData;
		let quotedOrdersData = this.state.quotedOrdersData;
		let batchesData = this.state.batchesData;
		let labRequestsData = this.state.labRequestsData;
		let matchesData = this.state.matchesData;
		let approvedProcessNotesData = this.state.approvedProcessNotesData;
		let toBePricedData = this.state.toBePricedData;
		let emailsToBeSentData = this.state.emailsToBeSentData;
		let tasksData = this.state.tasksData;
		let callReportsData = this.state.callReportsData;
		
		
		const ordersFields = ['id', 'orderDate', 'salesOrderType', 'customerName', 'note', 'statusName', 'batchesCompletedPercentage', 'batchesCount', 'shipper', 'shipDate', amountField];
		const batchesFields = ['orderId', 'orderDate', 'formulaNumber', 'name', 'orderStatusName', 'customerName', 'container', 'quantity', 'lbsOrdered', 'orderFormulaId'];
		const labRequestsFields = ['id', 'orderDate', 'dueDate', 'customerName', 'note', 'statusName', 'deliveryType', 'batchesCompletedPercentage', 'batchesCount', 'shipDate', 'lrType'];
		const matchesFields = ['orderId', 'dueDate', 'customerName', 'name', 'matchedBy', 'anilox'];
		const approvedProcessNotesFields = ['customerName', 'note', 'id'];
		const toBePricedFields = ['orderId', 'orderDate', 'formulaNumber', 'name', 'orderStatusName', 'componentCount', 'orderCount', 'customerName', 'amount'];
		const emailsToBeSentFields = ['id', 'formulaNumber', 'name', 'customerName', 'amount'];
		const tasksFields = ['customerName', 'assignedToSalesRepName', 'toDo', 'dueDateTime', 'completedYesNo'];
		const callReportsFields = ['customerName', 'callReportType', 'callDate', 'jobDesc', 'numTasksAssigned', 'numTasksCompleted', 'salesReps'];
		
		const ordersFormatColumns= {1:{'date': true}, 
		6: {'percentage': true, 'bgColor': 'bgColor'}, 9:{'date': true}, 10:{'currency': true}};
		const batchesFormatColumns= {
			0: {'link': {'column': 'orderId', 'var': 'editOrderId', 'func': this.onLinkClick}}, 
			1:{'date': true}, 
			3: {'link': {'column': 'formulaId', 'var': 'editFormulaId', 'func': this.onLinkClick}}, 
		};
		const labRequestsFormatColumns= {1:{'date': true}, 2:{'date': true}, 
			3: {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}},
			7: {'percentage': true, 'bgColor': 'bgColor'},
			9:{'date': true}
		};
		const matchesFormatColumns= {1:{'date': true}, 
			2: {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}}};
		const approvedProcessNotesFormatColumns= {2:{'checkbox': this.state.chkNotesToBeApproved, 'callbackCheckbox': this.onNotesApprovedChanged}};
		const toBePricedFormatColumns= {
			0: {'link': {'column': 'orderId', 'var': 'editOrderId', 'func': this.onLinkClick}}, 
			1:{'date': true}, 
			8:{'currency': true},
		};		
		const emailsToBeSentFormatColumns= {
			0:{
				'checkbox': this.state.chkEmailsToBeSent, 
				'callbackCheckbox': this.onEmailsToBeSentChanged, 
				'headerCheckbox': true
			},
			4:{
				'currency': true
			},
		};
		
		const tasksFormatColumns = {
			3: {'date': true, bgColor: 'bgColor'},
			0: {'link': 
				{'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}
			}
		};
		
		const callReportsFormatColumns = {
			0: {'link': 
				{'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}
			},
			2: {'date': true},
		};
		
		
		
		if(this.state.showBCMInksOrders != '0')
		{
			console.log("Config.bcmInksId: ", Config.bcmInksId);
			switch(this.state.showBCMInksOrders)
			{
				case bcm_ink_orders:
					ordersData = ordersData.filter(obj => obj.customerId == Config.bcmInksId);
					quotedOrdersData = quotedOrdersData.filter(obj => obj.customerId == Config.bcmInksId);
					batchesData = batchesData.filter(obj => obj.customerId == Config.bcmInksId);
					labRequestsData = labRequestsData.filter(obj => obj.customerId == Config.bcmInksId);
					matchesData = matchesData.filter(obj => obj.customerId == Config.bcmInksId);
					
					break;
				
				case non_bcm_ink_orders:
					ordersData = ordersData.filter(obj => obj.customerId != Config.bcmInksId);
					quotedOrdersData = quotedOrdersData.filter(obj => obj.customerId != Config.bcmInksId);
					batchesData = batchesData.filter(obj => obj.customerId != Config.bcmInksId);
					labRequestsData = labRequestsData.filter(obj => obj.customerId != Config.bcmInksId);
					matchesData = matchesData.filter(obj => obj.customerId != Config.bcmInksId);
					break;
			}
		}

		if(this.state.showColorMatch  != '5')
		{
			switch(this.state.showColorMatch)
			{
				case color_match:
					labRequestsData = labRequestsData.filter(obj => obj.lrType == 'Color Match');
					matchesData = matchesData.filter(obj => obj.lrType == 'Color Match');
					break;

				case r_n_d:
					labRequestsData	= labRequestsData.filter(obj => obj.lrType == 'R&D');
					matchesData = matchesData.filter(obj => obj.lrType == 'R&D');
					break;
				
				case color_match_repull:
					labRequestsData	= labRequestsData.filter(obj => (obj.lrType == 'Repull' || obj.lrType == 'Color Match'));
					matchesData = matchesData.filter(obj => (obj.lrType == 'Repull' || obj.lrType == 'Color Match'));
					break;
			}
		}
		
		if(this.state.taskType != '') {
			tasksData = tasksData.filter(obj => obj.completedYesNo == this.state.taskType);
		}

		// console.log("this.state.callReportCategory: ", this.state.callReportCategory, ", callReportsData: ", callReportsData);
		
		if(this.state.taskCategory != '') {
			tasksData = tasksData.filter(obj => obj.taskCategoryId == this.state.taskCategory);
		}
		// console.log("this.state.taskCategory: ", this.state.taskCategory, ", tasksData: ", tasksData);

		if(this.state.callReportType != '') {
			callReportsData = callReportsData.filter(obj => obj.callReportType == this.state.callReportType);
		}
		
		// console.log("this.state.callReportCategory: ", this.state.callReportCategory, ", callReportsData: ", callReportsData);
		if(this.state.callReportCategory != '') {
			callReportsData = callReportsData.filter(obj => obj.callReportCategoryId == this.state.callReportCategory);
		}
		
		
		
				
		// Global Search
		if(this.props.search) {
			ordersData = ordersData.filter(obj => {
				const strSearchString = ordersFields.map((field, i) => { 
					return ordersFormatColumns[i] && ordersFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			quotedOrdersData = quotedOrdersData.filter(obj => {
				const strSearchString = ordersFields.map((field, i) => { 
					return ordersFormatColumns[i] && ordersFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			batchesData = batchesData.filter(obj => {
				const strSearchString = batchesFields.map((field, i) => { 
					return batchesFormatColumns[i] && batchesFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			labRequestsData = labRequestsData.filter(obj => {
				const strSearchString = labRequestsFields.map((field, i) => { 
					return labRequestsFormatColumns[i] && labRequestsFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			matchesData = matchesData.filter(obj => {
				const strSearchString = matchesFields.map((field, i) => { 
					return matchesFormatColumns[i] && matchesFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			approvedProcessNotesData = approvedProcessNotesData.filter(obj => {
				const strSearchString = approvedProcessNotesFields.map((field, i) => { 
					return approvedProcessNotesFormatColumns[i] && approvedProcessNotesFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			toBePricedData = toBePricedData.filter(obj => {
				const strSearchString = toBePricedFields.map((field, i) => { 
					return toBePricedFormatColumns[i] && toBePricedFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			emailsToBeSentData = emailsToBeSentData.filter(obj => {
				const strSearchString = emailsToBeSentFields.map((field, i) => { 
					return emailsToBeSentFormatColumns[i] && emailsToBeSentFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			tasksData = tasksData.filter(obj => {
				const strSearchString = tasksFields.map((field, i) => { 
					return tasksFormatColumns[i] && tasksFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
			
			callReportsData = callReportsData.filter(obj => {
				const strSearchString = callReportsFields.map((field, i) => { 
					return callReportsFormatColumns[i] && callReportsFormatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		const orderCount = ordersData.length;
		const quotedOrdersCount = quotedOrdersData.length;
		const batchesCount = batchesData.length;
		const labRequestsCount = labRequestsData.length;
		const matchesCount = matchesData.length;
		const approvedProcessNotesCount = approvedProcessNotesData.length;
		const toBePricedCount = toBePricedData.length;
		const emailsToBeSentCount = emailsToBeSentData.length;
		const tasksCount = tasksData.length;
		const callReportsCount = callReportsData.length;
		
		const actionableItemsCount = approvedProcessNotesCount + toBePricedCount + emailsToBeSentCount;
		
		let tabTitles = [
			 <span><Translate value="components.tabs.orders" />
				{" (" + orderCount + ")"}</span>,
			 <span><Translate value="components.tabs.batches" />
				{" (" + batchesCount + ")"}</span>,
			<span><Translate value="components.tabs.quoted" />{" (" + quotedOrdersCount + ")"}</span>,
			 <span><Translate value="components.tabs.labRequests" />
				{" (" + labRequestsCount + ")"}</span>,
			 <span><Translate value="components.tabs.matches" />
				{" (" + matchesCount + ")"}</span>,
			 <span><Translate value="components.tabs.actionableItems" />
			 {" (" + actionableItemsCount + ")"}</span>,
			 <Translate value="components.tabs.goals" />,
			 <span><Translate value="components.tabs.tasks" />
				{" (" + tasksCount + ")"}</span>,
			<span><Translate value="components.tabs.callReports" />
				{" (" + callReportsCount + ")"}</span>,
			<Translate value="components.tabs.expenditures" />,
			<Translate value="components.tabs.prospectCalls" />,
				
		];
	
		let tabs = [
			<HomeOrders data={ordersData} 
				bLoading={this.state.bLoadingOrders} 
				refreshData={this.refreshOrdersAndLabRequestsData}
				search={this.props.search}
				fields={ordersFields}
				formatColumns={ordersFormatColumns}
				amountField={amountField}
				showBCMInksOrders={this.state.showBCMInksOrders}
				showBCMInksOrdersChanged={this.onBCMInksOrdersChanged}
				salesPeopleCustomers={this.state.salesPeopleCustomers}
			/>,
			<HomeBatches data={batchesData} 
				bLoading={this.state.bLoadingBatches} 
				refreshData={this.refreshBatchesData}
				search={this.props.search}
				fields={batchesFields}
				formatColumns={batchesFormatColumns}
				amountField={amountField}
				showBCMInksOrders={this.state.showBCMInksOrders}
				showBCMInksOrdersChanged={this.onBCMInksOrdersChanged}
			/>,
			<HomeOrders data={quotedOrdersData} 
				bLoading={this.state.bLoadingOrders} 
				refreshData={this.refreshOrdersAndLabRequestsData}
				search={this.props.search}
				fields={ordersFields}
				formatColumns={ordersFormatColumns}
				amountField={amountField}
				showBCMInksOrders={this.state.showBCMInksOrders}
				showBCMInksOrdersChanged={this.onBCMInksOrdersChanged}
				salesPeopleCustomers={this.state.salesPeopleCustomers}
			/>,
			<HomeLabRequests data={labRequestsData} 
				bLoading={this.state.bLoadingLabRequests} 
				refreshData={this.refreshOrdersAndLabRequestsData}
				search={this.props.search}
				fields={labRequestsFields}
				formatColumns={labRequestsFormatColumns}
				amountField={amountField}
				showBCMInksOrders={this.state.showBCMInksOrders}
				showBCMInksOrdersChanged={this.onBCMInksOrdersChanged}
				showLrChanged={this.onLrChanged}
				showColorMatch={this.state.showColorMatch}
				salesPeopleCustomers={this.state.salesPeopleCustomers}
			/>,
			<HomeMatches data={matchesData} 
				bLoading={this.state.bLoadingMatches} 
				refreshData={this.refreshMatchesData}
				search={this.props.search}
				fields={matchesFields}
				formatColumns={matchesFormatColumns}
				amountField={amountField}
				showBCMInksOrders={this.state.showBCMInksOrders}
				showBCMInksOrdersChanged={this.onBCMInksOrdersChanged}
				showLrChanged={this.onLrChanged}
				showColorMatch={this.state.showColorMatch}
			/>,
			<HomeActionableItems 
				approvedProcessNotesData={approvedProcessNotesData}
				toBePricedData={toBePricedData}
				emailsToBeSentData={emailsToBeSentData}
				bLoadingApprovedProcessNotes={this.state.bLoadingApprovedProcessNotes}
				bLoadingToBePriced={this.state.bLoadingToBePriced}
				bLoadingEmailsToBeSent={this.state.bLoadingEmailsToBeSent}
				refreshApprovedProcessNotesData={this.refreshApprovedProcessNotesData}
				refreshToBePricedData={this.refreshToBePricedData}
				refreshEmailsToBeSentData={this.refreshEmailsToBeSentData}
				search={this.props.search}
				approvedProcessNotesFields={approvedProcessNotesFields}
				toBePricedFields={toBePricedFields}
				emailsToBeSentFields={emailsToBeSentFields}
				approvedProcessNotesFormatColumns={approvedProcessNotesFormatColumns}
				toBePricedFormatColumns={toBePricedFormatColumns}
				emailsToBeSentFormatColumns={emailsToBeSentFormatColumns}
			/>,
			<SalesGoals search={this.props.search} />,
			<Task data={tasksData} 
				bLoading={this.state.bLoadingTasks} 
				refreshData={this.refreshTasksData}
				search={this.props.search}
				fields={tasksFields}
				formatColumns={tasksFormatColumns}
				amountField={amountField}
				taskCategory={this.state.taskCategory}
				taskCategoryChanged={this.onTaskCategoryChanged}
				taskType={this.state.taskType}
				taskTypeChanged={this.onTaskTypeChanged}
			/>,
			<CallReport 
				data={callReportsData} 
				bLoading={this.state.bLoadingCallReports} 
				refreshData={this.refreshCallReportsData}
				refreshTasksData={this.refreshTasksData}
				search={this.props.search}
				fields={callReportsFields}
				formatColumns={callReportsFormatColumns}
				amountField={amountField}
				callReportCategory={this.state.callReportCategory}
				callReportCategoryChanged={this.onCallReportCategoryChanged}
				callReportType={this.state.callReportType}
				callReportTypeChanged={this.onCallReportTypeChanged}
			/>,
			<MonthlyExpenditures search={this.props.search} />,
			<MonthlyProspectCalls search={this.props.search} />,
		];
	
		const tabsShown = [];
		const tabTitlesShown = [];
		
	
		//	Adding the Orders
		if(loggedInUser.accessOrdersCode != 'N') {
			tabsShown.push(tabs[0]);
			tabTitlesShown.push(tabTitles[0]);
		}
	
	
		// Proceed only if the  User type is other than a customer
		const userTypeId = loggedInUser.userTypeId;
		if(userTypeId != 7) {
			//	Adding the Batches tab
			if(loggedInUser.accessFormulasCode != 'N') {
				tabsShown.push(tabs[1]);
				tabTitlesShown.push(tabTitles[1]);
			}
		
			const dashboardTabsByUserType = {
				10: [2, 3, 4],
				11: [3, 4],
				12: [3, 4],
				13: [2, 3, 4],
				171: [3, 4],
				8: [2, 3, 4, 6, 9, 10],
				170: [2, 3, 4, 6, 9, 10],
				9: [2, 3, 4, 5, 6, 9, 10],
			};
		
			for(const i in dashboardTabsByUserType[userTypeId]) {
				const tabIndex = dashboardTabsByUserType[userTypeId][i];
				tabsShown.push(tabs[tabIndex]);
				tabTitlesShown.push(tabTitles[tabIndex]);
			}
		} else {
			tabsShown.push(tabs[3]);
			tabTitlesShown.push(tabTitles[3]);
		}

		if(userTypeId != 7) {
			// Adding Tasks tab
			tabsShown.push(tabs[7]);
			tabTitlesShown.push(tabTitles[7]);
			
			// Adding Call Reports tab
			tabsShown.push(tabs[8]);
			tabTitlesShown.push(tabTitles[8]);
		}
	
	
		return (
			<div>
			<TabContainer tabTitles={tabTitlesShown} tabs={tabsShown} />
			</div>
		);
	}
}


class HomeOrders extends Component {

	constructor(props){
		super(props);
	
		this.state = {
			bShowOrderForm: false,
			editOrderId: null,
			editCustomerId: null,
			interval: null,
		}
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeOrders::componentDidMount() - refreshOrdersData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeBatches::componentWillUnmount() - refreshBatchesData being called....");
		clearTimeout(this.state.interval);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.props.data;
		
		var orderFormContent = this.state.editOrderId || this.state.bShowOrderForm ? <OrderForm
			visible={this.state.editOrderId || this.state.bShowOrderForm}
			onClose={() => this.setState({editOrderId: null, bShowOrderForm: false})}
			onSave={() => {
				this.setState({
					bShowOrderForm: false,
				});
				this.props.refreshData();
			}}
			editOrderId={this.state.editOrderId}
		/> : "";
		
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
		/> : "";
		
		const columns = [
			<Translate value="components.labels.orderId" />,
			<Translate value="components.labels.orderDate" />,
			<Translate value="components.labels.orderType" />,
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.note" />,
			<Translate value="components.labels.status" />,
			<Translate value="components.labels.progress" />,
			'#', 
			<Translate value="components.labels.shipper" />,
			<Translate value="components.labels.shipDate" />,
			<Translate value="components.labels.amount" />,
		];
		
		let formatColumns = this.props.formatColumns;
		if(loggedInUser.userTypeId != constants.customer_type_id) {
			formatColumns[3] = {'link': {'column': 'customerId', 'var': 'editCustomerId', 
			'func': (event) => {
				const editId = event.target.id;
				const varName = event.target.name;
				// console.log("editId: ", editId, ", varName: ", varName);
				
				if(loggedInUser.userTypeId == 8) {
					const salesPeopleCustomers = this.props.salesPeopleCustomers;
					if(salesPeopleCustomers[editId] == undefined)
						return false;
				}
				
				this.setState({
					[varName]: editId,
				});
			}}};
		}		

		const amountField = this.props.amountField;
		const totalField = amountField;
		
		let totalValue = 0;
		data.forEach(row => {
			totalValue += row[amountField];
		});
		const formatTotal = {'currency': true};
		
		return (
			<div>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns}
					fields={this.props.fields} 
					data={data} 
					formatColumns={formatColumns} 
					txtSearch={this.props.txtSearch}
					onDoubleClick={(dataIn) => {
						// Sales People cannot double click to open orders other than their own customers
						const loggedInUser = getLoggedInUser();
						if(loggedInUser.userTypeId == 8) {
							const salesPeopleCustomers = this.props.salesPeopleCustomers;
							// If the Sales person does not have access to all customers
							// and the order does not belong to their own customer
							if(!loggedInUser.allCustomerAccessFlag && salesPeopleCustomers[dataIn.customerId] == undefined)
								return false;
						}
						
						
						this.setState({
							editOrderId: dataIn.id,
							bShowOrderForm: true,
						});
					}}
					heightOffset={this.props.dashboard ? 200 : 150}
					totalField={totalField}
					totalValue={totalValue}
					formatTotal={formatTotal}
				/>
				<Form class='form-page' horizontal>
					<Row>
						<Col md={2}></Col>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.props.showBCMInksOrdersChanged} data={BCMInkFilter} 
								value={this.props.showBCMInksOrders} 
							/>
						</Col>
						<Col md={4}>
							<Button bsSize='sm' 
								onClick={() => {
									this.setState({
										bShowOrderForm: true,
										editOrderId: null,
									});
								}} 
								disabled={loggedInUser.accessOrdersCode == 'V'}
							>
								<Translate value="components.buttons.newSalesOrder" />
							</Button>
						</Col>
					</Row>
				</Form>
				{orderFormContent}
				{customerFormContent}
			</div>
		)
	}
}

class HomeBatches extends Component {


	constructor(props){
		super(props);
	
		this.state = {
			editFormulaId: null,
			bShowFormulaForm: false,
			editOrderId: null,
			bShowOrderForm: false,
			chkBatchOrderIds: {},
			interval: null,
			saveDisabled: false,
		}
		
		this.onCombineBatchesClicked = this.onCombineBatchesClicked.bind(this);
		this.onUncombineBatchesClicked = this.onUncombineBatchesClicked.bind(this);
		this.onBatchOrderIdChanged = this.onBatchOrderIdChanged.bind(this);
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeBatches::componentDidMount() - refreshBatchesData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeBatches::componentWillUnmount() called.... clearing timeout");
		clearTimeout(this.state.interval);
	}
	
	onBatchOrderIdChanged(event) {
		let chkBatchOrderIds = this.state.chkBatchOrderIds;
		const checked = event.target.checked;
		const value = event.target.value;
		const name = event.target.name;
		const stateVarName = "chkBatchOrderIds"; // event.target.id;
		const id = event.target.id;
		// console.log("Order::onBatchOrderIdChanged(): name: ", name, ", value: ", value, ", checked: ", checked);
		
		// Select All checkbox
		if(name == 'orderFormulaId')
		{
			for(const i in this.state.data)
			{
				const orderFormulaId = this.state.data[i]['orderFormulaId'];
				if(checked)
					chkBatchOrderIds[orderFormulaId] = true;
				else
					delete chkBatchOrderIds[orderFormulaId];
			}
		}
		else
		{
			const batchRow = this.props.data.filter(obj => obj.orderFormulaId == value);
			// console.log("batchRow: ", batchRow, ", value: ", value);
			const cbn = batchRow && batchRow[0].combinedBatchNumber ? batchRow[0].combinedBatchNumber : true;
			if(checked)
				chkBatchOrderIds[value] = cbn;
			else
				delete chkBatchOrderIds[value];
		}
		// console.log("chkBatchOrderIds in Order::onBatchOrderIdChanged() : ", chkBatchOrderIds);
		this.setState({
			chkBatchOrderIds: chkBatchOrderIds,
		});
	}
	
	onCombineBatchesClicked(event) {
		event.preventDefault();
		this.setState({ saveDisabled: true });
		const orderFormulaIds = Object.keys(this.state.chkBatchOrderIds).join(",");
		const cbn = generateUUID(); // This has be calculated somehow
		const body = {ids: orderFormulaIds, cbn: cbn};
		// console.log("body: ", body);
		postAPIUrlEncoded("salesorders/combinebatches", body).then(result => {
			this.setState({saveDisabled: false, chkBatchOrderIds:{}});
			if(result.success) {
				// Open Birt Report
				openBirtReport('BCM_Batch_Ticket_Combined', {
						batchNumber: cbn,
					}
				);
				this.props.refreshData();
			}
		});
	}
	
	onUncombineBatchesClicked(event) {
		event.preventDefault();
		const orderFormulaIds = Object.keys(this.state.chkBatchOrderIds).join(",");
		const body = {ids: orderFormulaIds};
		// console.log("body: ", body);
		postAPIUrlEncoded("salesorders/uncombinebatches", body).then(result => {
			if(result.success) {
				this.props.refreshData();
			}
		});
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.props.data;
		
		var orderFormContent = this.state.editOrderId || this.state.bShowOrderForm ? <OrderForm
			visible={this.state.editOrderId || this.state.bShowOrderForm}
			onClose={() => this.setState({editOrderId: null, bShowOrderForm: false})}
			onSave={() => {
				this.setState({
					bShowOrderForm: false,
				});
				this.props.refreshData();
			}}
			editOrderId={this.state.editOrderId}
		/> : "";
		
		var formulaFormContent = this.state.editFormulaId || this.state.bShowFormulaForm ? <NewFormulaForm
			visible={this.state.editFormulaId != null || this.state.bShowFormulaForm}
			onClose={() => this.setState({editFormulaId: null, bShowFormulaForm: false})}
			editFormulaId={this.state.editFormulaId}
		/> : "";
		
		const columns = [
			<Translate value="components.labels.orderId" />,
			<Translate value="components.labels.orderDate" />,
			<Translate value="components.labels.formulaNumber" />,
			<Translate value="components.labels.name" />,
			<Translate value="components.labels.status" />,
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.container" />,
			<Translate value="components.labels.quantity" />,
			<Translate value={"components.labels." + Config.weightSymbol + "sOrdered"} />,
			<Translate value="components.labels.combine" />,
		];

		let formatColumns = this.props.formatColumns;
		formatColumns[9] = {
			checkbox: this.state.chkBatchOrderIds, 
			callbackCheckbox: this.onBatchOrderIdChanged, 
			// colVisibleBound: 'combinedBatchNumber',
			bgColor: 'bgColor',
		};

		formatColumns[0] = {'link': {'column': 'orderId', 'var': 'editOrderId', 'func': (event) => {
			const editId = event.target.id;
			const varName = event.target.name;
			// console.log("editId: ", editId, ", varName: ", varName);
			this.setState({
				[varName]: editId,
			});
		}}};
		formatColumns[3] = {'link': {'column': 'formulaId', 'var': 'editFormulaId', 'func': (event) => {
			const editId = event.target.id;
			const varName = event.target.name;
			// console.log("editId: ", editId, ", varName: ", varName);
			this.setState({
				[varName]: editId,
			});
		}}};

		return (
			<div>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns}
					fields={this.props.fields} 
					data={data} 
					formatColumns={formatColumns} 
					txtSearch={this.props.txtSearch}
					onDoubleClick={(dataIn) => {
						this.setState({
							editOrderId: dataIn.orderId,
							bShowOrderForm: true,
						});
					}}
				/>
				<Form class='form-page' horizontal>
					<Row>
						<Col md={2}></Col>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.props.showBCMInksOrdersChanged} data={BCMInkFilter} 
								value={this.props.showBCMInksOrders} 
							/>
						</Col>
						<Col md={4}>
							<span>
								<Button
									bsSize='sm'
									onClick={this.onCombineBatchesClicked}
									disabled={loggedInUser.accessOrdersCode == 'V' || this.state.saveDisabled}>
									<Translate value="components.buttons.combineBatches" />
								</Button>
								&nbsp;
								<Button
									bsSize='sm'
									onClick={this.onUncombineBatchesClicked}
									disabled={loggedInUser.accessOrdersCode == 'V'}>
									<Translate value="components.buttons.uncombineBatches" />
								</Button>
								&nbsp;
								<Button
									bsSize='sm'
									onClick={() => this.setState({bShowFormulaForm: true, editFormulaId: null})}
									disabled={loggedInUser.accessFormulasCode == 'V'}>
									<Translate value="components.buttons.newFormula" />
								</Button>
							</span>
						</Col>
					</Row>
				</Form>
				{orderFormContent}
				{formulaFormContent}
			</div>
		)
	}
}

class HomeLabRequests extends Component {


	constructor(props){
		super(props);
	
		this.state = {
			editOrderId: null,
			bShowLabRequestForm: false,
			editCustomerId: null,
			interval: null,
		}
	}
	
	componentDidMount() {
		/*
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				console.log("HomeLabRequests::componentDidMount() - refreshLabRequestsData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
		*/
	}
	
	componentWillUnmount() {
		// console.log("HomeLabRequests::componentWillUnmount() called.... clearing timeout");
		clearTimeout(this.state.interval);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const columns = [
			<Translate value="components.labels.labRequestId" />,
			<Translate value="components.labels.orderDate" />,
			<Translate value="components.labels.deliveryDate" />,
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.note" />,
			<Translate value="components.labels.status" />,
			<Translate value="components.labels.deliveryType" />,
			<Translate value="components.labels.progress" />,
			'#',
			<Translate value="components.labels.shipDate" />,
			<Translate value="components.labels.lrType" />,
		];
		let formatColumns = this.props.formatColumns;
		formatColumns[3] = {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': (event) => {
			const editId = event.target.id;
			const varName = event.target.name;
			// console.log("editId: ", editId, ", varName: ", varName);
			if(loggedInUser.userTypeId == 8) {
				const salesPeopleCustomers = this.props.salesPeopleCustomers;
				if(salesPeopleCustomers[editId] == undefined)
					return false;
			}
			this.setState({
				[varName]: editId,
			});
		}}};
		
		var labRequestFormContent = this.state.bShowLabRequestForm ? <OrderForm
			visible={this.state.bShowLabRequestForm}
			onClose={() => this.setState({bShowLabRequestForm: false, editOrderId: null})}
			onSave={() => {
				this.props.refreshData(); 
				this.setState({
					tabIndex: 2,
				});
			}}
			orderTypeLookupId={lab_request_lookup_id}
			editOrderId={this.state.editOrderId}
		/> : '';
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
		/> : "";
		
		return (
			<div>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns}
					fields={this.props.fields} 
					data={this.props.data} 
					formatColumns={formatColumns} 
					txtSearch={this.props.txtSearch}
					onDoubleClick={(dataIn) => {
						// Sales People cannot double click to open orders other than their own customers
						const loggedInUser = getLoggedInUser();
						if(loggedInUser.userTypeId == 8) {
							const salesPeopleCustomers = this.props.salesPeopleCustomers;
							if(salesPeopleCustomers[dataIn.customerId] == undefined)
								return false;
						}
						this.setState({
							editOrderId: dataIn.id,
							bShowLabRequestForm: true,
						});
					}}
				/>
				<Form class='form-page' horizontal>
					<Row>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.props.showLrChanged} data={LrFilter} 
								value={this.props.showColorMatch} 
							/>
						</Col>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.props.showBCMInksOrdersChanged} data={BCMInkFilter} 
								value={this.props.showBCMInksOrders} 
							/>
						</Col>
						<Col md={4}>
							<Button bsSize='sm' 
								onClick={() => this.setState({bShowLabRequestForm: true})} 
								disabled={loggedInUser.accessOrdersCode == 'V'}>
								<Translate value="components.buttons.newLabRequest" />
							</Button>
						</Col>
					</Row>
				</Form>
				{labRequestFormContent}
				{customerFormContent}
			</div>
		);
	}
}

class HomeMatches extends Component {


	constructor(props){
		super(props);
	
		this.state = {
			editOrderId: null,
			bShowOrderForm: false,
			editCustomerId: null,
			interval: null,
		}
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeMatches::componentDidMount() - refreshMatchesData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeMatches::componentWillUnmount() called.... clearing timeout");
		clearTimeout(this.state.interval);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const columns = [
			<Translate value="components.labels.labRequestId" />,
			<Translate value="components.labels.dueDate" />,
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.colorName" />,
			<Translate value="components.labels.matchedBy" />,
			<Translate value="components.labels.anilox" />,
		];
		let formatColumns = this.props.formatColumns;
		formatColumns[2] = {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func':  (event) => {
			const editId = event.target.id;
			const varName = event.target.name;
			// console.log("editId: ", editId, ", varName: ", varName);
			this.setState({
				[varName]: editId,
			});
		}}};
		
		var orderFormContent = this.state.editOrderId || this.state.bShowOrderForm ? <OrderForm
			visible={this.state.editOrderId || this.state.bShowOrderForm}
			onClose={() => this.setState({editOrderId: null, bShowOrderForm: false})}
			onSave={this.handleSaveOrder}
			editOrderId={this.state.editOrderId}
		/> : "";
		
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
		/> : "";
		
		return (
			<div>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns}
					fields={this.props.fields} 
					data={this.props.data} 
					formatColumns={formatColumns} 
					onDoubleClick={(dataIn) => {
						this.setState({
							editOrderId: dataIn.orderId,
							bShowOrderForm: true,
						});
					}}
				/>
				<Form class='form-page' horizontal>
					<Row>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.props.showLrChanged} data={LrFilter} 
								value={this.props.showColorMatch} 
							/>
						</Col>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.props.showBCMInksOrdersChanged} data={BCMInkFilter} 
								value={this.props.showBCMInksOrders} 
							/>
						</Col>
						<Col md={4}>
							<Button bsSize='sm' 
								onClick={() => {
									this.setState({
										bShowOrderForm: true,
										editOrderId: null,
									});
								}} 
								disabled={loggedInUser.accessOrdersCode == 'V'}
							>
								<Translate value="components.buttons.newSalesOrder" />
							</Button>
						</Col>
					</Row>
				</Form>
				{orderFormContent}
				{customerFormContent}
			</div>
		)
	}
}

class HomeActionableItems extends Component {


	constructor(props){
		super(props);

		this.state = {
		};

	}
	render(){
		const approvedProcessNotesCount = this.props.approvedProcessNotesData.length;
		const toBePricedCount = this.props.toBePricedData.length;
		const emailsToBeSentCount = this.props.emailsToBeSentData.length;
		
		const tabTitles = [
			<span><Translate value="components.tabs.approvedProcessNotes" />
			{" (" + approvedProcessNotesCount + ")"}</span>,
			<span><Translate value="components.tabs.toBePriced" />
			{" (" + toBePricedCount + ")"}</span>,
			<span><Translate value="components.tabs.emailsToBeSent" />
			{" (" + emailsToBeSentCount + ")"}</span>,
		];
		
		const tabs = [
			<HomeApprovedProcessNotes 
				bLoading={this.props.bLoadingApprovedProcessNotes} 
				refreshData={this.props.refreshApprovedProcessNotesData}
				fields={this.props.approvedProcessNotesFields}
				formatColumns={this.props.approvedProcessNotesFormatColumns}
				data={this.props.approvedProcessNotesData}
			/>,
			<HomeToBePriced 
				bLoading={this.props.bLoadingToBePriced} 
				refreshData={this.props.refreshToBePricedData}
				fields={this.props.toBePricedFields}
				formatColumns={this.props.toBePricedFormatColumns}
				data={this.props.toBePricedData}
			/>,
			<HomeEmailsToBeSent 
				bLoading={this.props.bLoadingEmailsToBeSent} 
				refreshData={this.props.refreshEmailsToBeSentData}
				fields={this.props.emailsToBeSentFields}
				formatColumns={this.props.emailsToBeSentFormatColumns}
				data={this.props.emailsToBeSentData}
			/>, 
		];
	
		return (
			<div>
			<TabContainer tabTitles={tabTitles} tabs={tabs}/>
			</div>
		);
	}
}

class HomeApprovedProcessNotes extends Component {


	constructor(props){
		super(props);
	
		this.state = {
			editCustomerId: null,
			chkNotesToBeApproved: {},
			interval: null,
		}
		this.onNotesApprovedChanged = this.onNotesApprovedChanged.bind(this);
		this.approveNotes = this.approveNotes.bind(this);
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeApprovedProcessNotes::componentDidMount() - refreshMatchesData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeApprovedProcessNotes::componentWillUnmount() called.... clearing timeout");
		clearTimeout(this.state.interval);
	}
	
	onNotesApprovedChanged(event) {
		let chkNotesToBeApproved = this.state.chkNotesToBeApproved;
		const checked = event.target.checked;
		const value = event.target.value;
		const name = event.target.name;
		// console.log("TabItem::onNotesApprovedChanged() - name: ", name, ", value: ", value, ", checked: ", checked, ", chkNotesToBeApproved: ", chkNotesToBeApproved);
		
		if(checked)
			chkNotesToBeApproved[value] = true;
		else
			delete chkNotesToBeApproved[value];
		
		this.setState({
			chkNotesToBeApproved: chkNotesToBeApproved,
		});
	}
	
	approveNotes(event) {
		event.preventDefault();
		const loggedInUser = getLoggedInUser();
		const idsNotes = Object.keys(this.state.chkNotesToBeApproved);
		// console.log("idsNotes: ", idsNotes);
		if(idsNotes.length) {
			for(const i in idsNotes) {
				const noteId = idsNotes[i];
				const objNote = this.props.data.filter(obj => obj.id == noteId)[0];
				// console.log("noteId: ", noteId, ", objNote: ", objNote);
				objNote.approvedBy = loggedInUser.userName;
				objNote.approvedDate = (new Date()).getTime();
				objNote.model = "com.phototype.myinkiq.model.vos.dtos::CustomerNotesDto";
				objNote.name = null;
				
				postAPI("/customers/" + noteId + "/notes/unapproved", objNote).then(resNoteApproved => {				
					if(i == idsNotes.length - 1) {
						// console.log("All notes approved, refreshing data....");
						this.props.refreshData();
					}
				});
			}
		}
	}
	
	render() {
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
			defaultKey={5}
		/> : "";
		
		const columns = [
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.note" />,
			<Translate value="components.labels.approved" />,
		];
		let formatColumns = this.props.formatColumns;
		formatColumns[2] = {'checkbox': this.state.chkNotesToBeApproved, 'callbackCheckbox': this.onNotesApprovedChanged};
	
		return (
			<div>
				<br />
				<Row>
					<Col md="4"></Col>
					<Col md="4">
					</Col>
				</Row>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns} 
					fields={this.props.fields} 
					data={this.props.data} 
					formatColumns={formatColumns} 
					clickable={true} 
					onDoubleClick={(dataIn) => {
						this.setState({
							editCustomerId: dataIn.customerId,
						});
					}}
					/>
				<div>
					<Button onClick={this.approveNotes} ><Translate value="components.buttons.approveNotes" /></Button>&nbsp;
				</div>
				{customerFormContent}
			</div>
		)
	}
}

class HomeToBePriced extends Component {


	constructor(props){
		super(props);
	
		this.state = {
			editFormulaId: null,
			showFormulaForm: false,
			interval: null,
		}
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeToBePriced::componentDidMount() - refreshMatchesData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeToBePriced::componentWillUnmount() called.... clearing timeout");
		clearTimeout(this.state.interval);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const bSalesPerson = loggedInUser.userTypeId == constants.sales_type_id;
		const bCustomer = loggedInUser.userTypeId == constants.customer_type_id;
		const columns = [
			<Translate value="components.labels.orderId" />,
			<Translate value="components.labels.orderDate" />,
			<Translate value="components.labels.formulaNumber" />,
			<Translate value="components.labels.name" />,
			<Translate value="components.labels.status" />,
			<Translate value="components.labels.numOfComp" />,
			<Translate value="components.labels.numOfOrders" />,
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.rmc" />,
		];
		let formatColumns = this.props.formatColumns;
		
		var formulaFormContent = this.state.editFormulaId || this.state.showFormulaForm ? <NewFormulaForm
			visible={this.state.editFormulaId != null || this.state.showFormulaForm}
			onClose={() => this.setState({editFormulaId: null, showFormulaForm: false})}
			editFormulaId={this.state.editFormulaId}
		/> : "";
		
		return (
			<div>
				<br />
				<Row>
					<Col md="4"></Col>
					<Col md="4">
					</Col>
				</Row>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns} 
					fields={this.props.fields} 
					data={this.props.data} 
					formatColumns={formatColumns} 
					onDoubleClick={(dataIn) => {
						this.setState({
							editFormulaId: dataIn.formulaId,
							showFormulaForm: true,
						});
					}}
					/>
				<div>
					{!bCustomer &&
					<Button bsSize='sm' 
						onClick={() => this.setState({showFormulaForm: true, editFormulaId: null})}  
						disabled={loggedInUser.accessFormulasCode == 'V'}>
							<Translate value="components.buttons.newFormula" />
					</Button>
					}
				</div>
				{formulaFormContent}
			</div>
		)
	}
}

class HomeEmailsToBeSent extends Component {


	constructor(props){
		super(props);
	
		this.state = {
			editFormulaId: null,
			chkNotesToBeApproved: {},
			chkEmailsToBeSent: {},
			interval: null,
		}
		this.onEmailsToBeSentChanged = this.onEmailsToBeSentChanged.bind(this);
		this.sendEmail = this.sendEmail.bind(this);
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeEmailsToBeSent::componentDidMount() - refreshMatchesData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeEmailsToBeSent::componentWillUnmount() called.... clearing timeout");
		clearTimeout(this.state.interval);
	}
	
	onEmailsToBeSentChanged(event) {
		let chkEmailsToBeSent = this.state.chkEmailsToBeSent;
		const checked = event.target.checked;
		const value = event.target.value;
		const name = event.target.name;
		const stateVarName = "chkEmailsToBeSent"; // event.target.id;
		const id = event.target.id;
		
		// console.log("TabItem::onEmailsToBeSentChanged() - id: ", id, ", name: ", name, ", value: ", value, ", checked: ", checked, ", stateVarName: ", stateVarName, ", chkEmailsToBeSent: ", chkEmailsToBeSent);
		if(name == 'id')
		{
			for(const i in this.props.data)
			{
				const id = this.props.data[i]['id'];
				if(checked)
					chkEmailsToBeSent[id] = true;
				else
					delete chkEmailsToBeSent[id];
			}
		}
		else
		{
			if(checked)
				chkEmailsToBeSent[value] = true;
			else
				delete chkEmailsToBeSent[value];
		}
		
		this.setState({
			chkEmailsToBeSent: chkEmailsToBeSent,
		});
	}
	
	sendEmail(sendYN) {
		// event.preventDefault();
		
		// console.log("TabItem::sendEmail() - this.state.chkEmailsToBeSent: ", this.state.chkEmailsToBeSent, ", sendYN: ", sendYN);
		const pids = Object.keys(this.state.chkEmailsToBeSent);
		if(pids.length) {
			const cspids = pids.join(";");
			const endpoint = "/formulas/recipients?sendEmail=" + sendYN+ "&cfpids=" + cspids
			getAPI(endpoint).then(res => {
				this.props.refreshData();
			});
			
		}
		
	}
	
	render() {
		const columns = [
			'', 
			<Translate value="components.labels.formulaNumber" />,
			<Translate value="components.labels.formulaName" />,
			<Translate value="components.labels.customer" />,
			<Translate value="components.labels.price" />,
		];
		let formatColumns = this.props.formatColumns;
		formatColumns[0] = {
			'checkbox': this.state.chkEmailsToBeSent, 
			'callbackCheckbox': this.onEmailsToBeSentChanged, 
			'headerCheckbox': true
		};
		var formulaFormContent = this.state.editFormulaId || this.state.showFormulaForm ? <NewFormulaForm
			visible={this.state.editFormulaId != null}
			onClose={() => this.setState({editFormulaId: null})}
			editFormulaId={this.state.editFormulaId}
		/> : "";
		return (
			<div>
				<br />
				<Row>
					<Col md="4"></Col>
					<Col md="4">
					</Col>
				</Row>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns} 
					fields={this.props.fields} 
					data={this.props.data} 
					formatColumns={formatColumns} 
					onDoubleClick={(dataIn) => {
						this.setState({
							editFormulaId: dataIn.formulaId,
						});
					}}
				/>
				<div>
					<Button onClick={() => this.sendEmail('N')} >
						<Translate value="components.buttons.noEmail" />
					</Button>&nbsp;
					<Button onClick={() => this.sendEmail('Y')} >
						<Translate value="components.buttons.sendEmail" />
					</Button>
				</div>
				{formulaFormContent}
			</div>
		);
	}
}

class Task extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editTaskFormVisible: false,
			editTaskId: null,
			editCustomerId: null,
			interval: null,
		};
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeOrders::componentDidMount() - refreshOrdersData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeBatches::componentWillUnmount() - refreshBatchesData being called....");
		clearTimeout(this.state.interval);
	}

	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.props.data;
		
		const editTaskForm = this.state.editTaskFormVisible ? <NewTaskForm
				onClose={() => this.setState({editTaskFormVisible: false})}
				onSave={(bCreateNewTask) => {
					this.setState({editTaskFormVisible: bCreateNewTask});
					this.props.refreshData();
				}}
				visible={this.state.editTaskFormVisible}
				editTaskId={this.state.editTaskId}></NewTaskForm> : "";
		
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
		/> : "";
		
		
		
		let customerVendorShipperColumnName = "customerVendorShipperName";
		let customerVendorShipperColumnLabel = "Customer/Vendor/Shipper";
		
		if(this.props.taskCategory == constants.call_report_category_customer) {
			customerVendorShipperColumnName = "customerName";
			customerVendorShipperColumnLabel = "Customer";
		} else if(this.props.taskCategory == constants.call_report_category_vendor) {
			customerVendorShipperColumnName = "vendorName";
			customerVendorShipperColumnLabel = "Vendor";
		} else if(this.props.taskCategory == constants.call_report_category_shipper) {
			customerVendorShipperColumnName = "shipperName";
			customerVendorShipperColumnLabel = "Shipper";
		}
		
		let fields = [
			customerVendorShipperColumnName, 
			'assignedToSalesRepName', 
			'toDo', 
			'dueDateTime', 
			'completedYesNo'
		];

		let columns = [
			<Translate value={"components.labels." + customerVendorShipperColumnLabel} />,
			<Translate value="components.labels.assignedTo" />,
			<Translate value="components.labels.toDo" />,
			<Translate value="components.labels.dueDate" />,
			<Translate value="components.labels.completed" />,
		];
		
		const formatColumns = this.props.formatColumns;		
		formatColumns[0] = {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func':  (event) => {
			const editId = event.target.id;
			const varName = event.target.name;
			// console.log("editId: ", editId, ", varName: ", varName);
			this.setState({
				[varName]: editId,
			});
		}}};
		return (
			<div>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns}
					fields={fields}
					data={data}
					formatColumns={formatColumns}
					txtSearch={this.props.txtSearch}
					onDoubleClick={(dataIn) => {
						// Sales People cannot double click to open orders other than their own customers
						this.setState({
							editTaskId: dataIn.taskId,
							editTaskFormVisible: true,
						});
					}}
				/>
				<Row>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.Category" />} 
								action={this.props.taskCategoryChanged} 
								data={{
									[constants.call_report_category_customer]: "Customer",
									[constants.call_report_category_vendor]: "Vendor", 
									[constants.call_report_category_shipper]: "Shipper"
								}} 
								value={this.props.taskCategory}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.type" />} 
								action={this.props.taskTypeChanged} data={TaskTypesFilter} 
								value={this.props.taskType}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Button bsSize='sm' onClick={() => this.setState({editTaskId: null, editTaskFormVisible: true})}><Translate value="components.buttons.newTask" /></Button>
					</Col>
				</Row>
				{editTaskForm}
				{customerFormContent}
			</div>
		);
	}
}


class CallReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editCallReportFormVisible: false,
			editCallReportId: null,
			editCustomerId: null,
			interval: null,
			bShowUploadCallReportForm: false,
			//bShowUploadBlendingCallReportForm:false,
		};
	}
	
	componentDidMount() {
		const interval = setInterval(() => {
			// if(this.state.autoRefresh) { // && !this.state.showOrderForm) {
				// console.log("HomeOrders::componentDidMount() - refreshOrdersData being called....");
				this.props.refreshData();	  
			// }
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		// console.log("HomeBatches::componentWillUnmount() - refreshBatchesData being called....");
		clearTimeout(this.state.interval);
	}
	

	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.props.data;
		
		const editCallReportForm = this.state.editCallReportFormVisible ? <NewCallReportForm
				onClose={() => this.setState({editCallReportFormVisible: false})}
				onSave={() => {
					this.setState({editCallReportFormVisible: false});
					this.props.refreshData();
				}}
				visible={this.state.editCallReportFormVisible}
				editCallReportId={this.state.editCallReportId}
				refreshTasksData={this.props.refreshTasksData}></NewCallReportForm> : "";
		// console.log("this.state.editCustomerId: ", this.state.editCustomerId);
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
		/> : "";
		
		let customerVendorShipperColumnName = "customerVendorShipperName";
		let customerVendorShipperColumnLabel = "Customer/Vendor/Shipper";
		let customerVendorShipperIdColumn = 'customerId';
		let editCustomerVendorShipperId = 'editCustomerId';

		
		if(this.props.callReportCategory == constants.call_report_category_customer) {
			customerVendorShipperColumnName = "customerName";
			customerVendorShipperColumnLabel = "Customer";
			customerVendorShipperIdColumn = 'customerId';
			editCustomerVendorShipperId = 'editCustomerId';
		} else if(this.props.callReportCategory == constants.call_report_category_vendor) {
			customerVendorShipperColumnName = "vendorName";
			customerVendorShipperColumnLabel = "Vendor";
			customerVendorShipperIdColumn = 'vendorId';
			editCustomerVendorShipperId = 'editVendorId';
		} else if(this.props.callReportCategory == constants.call_report_category_shipper) {
			customerVendorShipperColumnName = "shipperName";
			customerVendorShipperColumnLabel = "Shipper";
			customerVendorShipperIdColumn = 'shipperId';
			editCustomerVendorShipperId = 'editShipperId';
		}
		
		let fields = [
			customerVendorShipperColumnName, 
			'callReportType', 
			'callDate', 
			'jobDesc', 
			'numTasksAssigned', 
			'numTasksCompleted', 
			'salesReps'
		];
		
		let columns = [
			<Translate value={"components.labels." + customerVendorShipperColumnLabel} />,
			<Translate value="components.labels.type" />,
			<Translate value="components.labels.date" />,
			<Translate value="components.labels.jobDesc" />,
			<Translate value="components.labels.tasksAssigned" />,
			<Translate value="components.labels.tasksCompleted" />,
			<Translate value="components.labels.bcmReps" />,
		];
		
		const formatColumns = this.props.formatColumns;
		formatColumns[0] = {'link': {'column': customerVendorShipperIdColumn, 'var': editCustomerVendorShipperId, 'func':  (event) => {
			const editId = event.target.id;
			const varName = event.target.name;
			// console.log("editId: ", editId, ", varName: ", varName);
			this.setState({
				[varName]: editId,
			});
		}}};
		return (
			<div>
				<DataGrid 
					loading={this.props.bLoading}
					columns={columns}
					fields={fields}
					data={data}
					formatColumns={formatColumns}
					txtSearch={this.props.txtSearch}
					onDoubleClick={(dataIn) => {
						// Sales People cannot double click to open orders other than their own customers
						this.setState({
							editCallReportId: dataIn.callReportId,
							editCallReportFormVisible: true,
						});
					}}
				/>
				<Row>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.Category" />} 
								action={this.props.callReportCategoryChanged} 
								data={{
									[constants.call_report_category_customer]: "Customer",
									[constants.call_report_category_vendor]: "Vendor", 
									[constants.call_report_category_shipper]: "Shipper"
								}} 
								value={this.props.callReportCategory}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.type" />} 
								action={this.props.callReportTypeChanged} data={CallReportTypesFilter} 
								value={this.props.callReportType}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Button bsSize='sm' onClick={() => this.setState({editCallReportId: null, editCallReportFormVisible: true})}><Translate value="components.buttons.newCallReport" /></Button>
						&nbsp;
						<Button bsSize='sm' onClick={() => this.setState({bShowUploadCallReportForm: true})}>
							<Translate value="components.buttons.uploadCallReport" />
						</Button>
					</Col>
				</Row>
				{editCallReportForm}
				{customerFormContent}
				<CallReportSelectFile
					visible={this.state.bShowUploadCallReportForm}
					refreshData={this.props.refreshData}
					onUpload={() => {
						console.log("Calling this.props.refreshData()");
						this.props.refreshData();
						this.setState({bShowUploadCallReportForm: false});
					}}
					onClose={() => {
						this.setState({bShowUploadCallReportForm: false});
					}}
					/>
					
			</div>
		);
	}
}

/*class BlendingCallReportSelectFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bLoading: false,
			objErrors: {},
			bProcessed: false,
			pdfLocation:null,
		};
		
	}
    
	handelInputChangedBlendingCallReport(event,name){
		const input = event.target;
		var pdfLocation = this.state.pdfLocation
		let data ={};
		data['callReportTypeLookupId'] = constants.call_report_type_blending;
		switch(name) {
			case 'pdfLocation':
				pdfLocation = input.files[0];
				break;
		}
        this.setState({
			data: data,
			pdfLocation: pdfLocation,
		  });
		console.log(this.state)
		// console.log(this.state, this.props)  

	}
	componentDidMount() {
		// console.log('test')
	}

	render() {
		
	    return(<Modal show={this.props.visible}>
				<Modal.Header>
						<Modal.Title><Translate value='components.labels.uploadBlendingCallReport' /></Modal.Title>
					</Modal.Header>
				<Modal.Body>
					 <input type="file" 
					 name = "pdfLocation"
					 onChange={(data, fileInfo) => this.handelInputChangedBlendingCallReport(data,"pdfLocation")} 
					 />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => this.onSelected()} disabled={this.state.bLoading}>
						{this.state.bLoading ? 'Processing' : <Translate value='components.buttons.upload' />}
					</Button>
					<Button onClick={this.props.onClose}><Translate value='components.buttons.close' /></Button>
				</Modal.Footer>
			</Modal>);
	}

	

	
	

}*/


class CallReportSelectFile extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			bLoading: false,
			objErrors: {},
			bProcessed: false,
		};
		
	}

    async onParseCallReportXLS(event) {
		// console.log("onParseCallReportXLS() - target: ", event.target);
		const input = event.target;
		this.setState({bLoading: true, objErrors: {}, bProcessed: false});
		const callReportTokens = {
			
		};
		
		// `rows` is an array of rows
		let data = {
		};
		
		let callReportContacts = [];
		let callReportTakeaways = [];
		let callReportTasks = [];
		let callReportSalesReps = [];
		let callReportStationDetails = [];
		
		let arrCustomerPressStationIds = [];
		let arrCustomerPressStations = [];
		let arrStationFormulas = [];
		let arrStationBatches = [];
		let arrStationAniloxes = [];
		let arrStationDryerPct = [];
		let arrStationUV = [];
		
		let objPressStationInfo = {};
		
		let arrInitialConditions = [];
		let arrFinalConditions = [];

		await readXlsxFile(input.files[0]).then((rows) => {
			
			const numRows = rows.length;
			// console.log("rows: ", rows);
			for(let i = 0; i < numRows; i++) {
				const row = rows[i];
				// console.log("row: ", row, ", i: ", i);
				if(row[3] == spanishCallReportLabels["generalCallReport"][0]) {
					data['callReportTypeLookupId'] = constants.call_report_type_general;
					data['spanish'] = false;
					break;
				} else if(row[3] == spanishCallReportLabels["pressRunCallReport"][0]) {
					data['callReportTypeLookupId'] = constants.call_report_type_press_run;
					data['spanish'] = false;
					break;
				} else if(row[3] == spanishCallReportLabels["generalCallReport"][1]) {
					data['callReportTypeLookupId'] = constants.call_report_type_general;
					data['spanish'] = true;
					break;
				} else if(row[3] == spanishCallReportLabels["pressRunCallReport"][1]) {
					data['callReportTypeLookupId'] = constants.call_report_type_press_run;
					data['spanish'] = true;
					break;
				}
				
			}
			
			let currentlyReading = null;
			let numStations = 0;
			let maxStationIndex = 9;
			
			rows.forEach((row, i) => {
				let skipRow = false;
				let indexRow0 = 0;
				switch(data['callReportTypeLookupId']) {
					////////////////////////////// GENERAL CALL REPORT ///////////////////////////
					case constants.call_report_type_general:
						// Sales Person
						
						switch(getCallReportLabelIndex(row[4])) {
							case "salesPerson":
								data["salesPerson"] = row[6]; // .getTime();
								break;
						}
						
						// Call Date
						switch(getCallReportLabelIndex(row[5])) {
							case "callDate":
								data["callDate"] = row[6].getTime();
								break;
							
						}
						/*
						if(isCallReportLabel("callDate", row[5])) {
							data["callDate"] = row[6].getTime();
						}
						*/
						indexRow0 = getCallReportLabelIndex(row[0]);
						switch(indexRow0) {
							case "meetingObjectives":
								data['objectives'] = row[2];
								currentlyReading = null;
								break;
							
							case "meetingSummary":
								data['summary'] = row[2];
								break;
							
							case "metWith":
							case "mainTakeaways":
							case "followUps":
								currentlyReading = indexRow0;
								break;
							
						}
						
						if(currentlyReading != null) {

							switch(currentlyReading) {
								case "metWith":
									if(row[1] != null)
										callReportContacts.push(row[1]);
									
									if(row[5] != null)
										callReportContacts.push(row[5]);
									
									break;
								
								case "mainTakeaways":
									if(row[3] != null)
										callReportTakeaways.push(row[3]);
									break;
								
								case "followUps":
									if(row[3] != null && row[3].toLowerCase() != spanishCallReportLabels["noActionRequired"][0].toLowerCase() && row[3].toLowerCase() != spanishCallReportLabels["noActionRequired"][1].toLowerCase())
										callReportTasks.push({taskName: row[3], salesRep: row[5]});
									break;
							}
						}
						
						break;
					
					////////////////////////////// PRESS RUN CALL REPORT ///////////////////////////
					case constants.call_report_type_press_run:
						// Sales Person
						switch(getCallReportLabelIndex(row[6])) {
							case "salesPerson":
								data["salesPerson"] = row[8]; // .getTime();
								break;
						}

						
						// Call Date
						switch(getCallReportLabelIndex(row[7])) {
							case "callDate":
								data["callDate"] = row[8].getTime();
								break;
							
						}
						
						const indexRow6 = getCallReportLabelIndex(row[6]);
						switch(indexRow6) {
							case "assisted":
								currentlyReading = indexRow6;
								skipRow = true;
								break;
						}

						indexRow0 = getCallReportLabelIndex(row[0]);
						switch(indexRow0) {

							case "press":
								data["pressName"] = row[2];
								break;

							case "substrate":
								data["substrateName"] = row[2];
								break;

							case "pressSpeed":
								data["pressSpeed"] = row[2];
								break;

							case "initialNotes":								
								data["notes"] = row[1];
								break;

							case "pressRunObjectives":
							case "station":
							case "pressRunSummary":
							case "finalNotes":
							case "notesAdjustments":
							case "actionItems":
							case "nextStep":
							case "initialInkConditions":
							case "finalInkConditions":
								currentlyReading = indexRow0;
								skipRow = true;
								break;
						}

						if(!skipRow) {
							if(currentlyReading != null) {

								switch(currentlyReading) {
									case "assisted":
										if(row[1] != null)
											callReportContacts.push(row[1]);
										
										if(row[6] != null)
											callReportSalesReps.push(row[6]);
										
										break;
									
									case "pressRunObjectives":
										if(row[1] != null) {
											data['objectives'] = row[1];
											currentlyReading = null;
										}
										break;
									
									case "notesAdjustments":
										if(row[0] != null) {
											data['initialNotes'] = row[0];
											currentlyReading = null;
										}
										break;
									
									case "finalNotes":
										if(row[0] != null) {
											data['finalNotes'] = row[0];
											currentlyReading = null;
										}
									
									case "pressRunSummary":
										if(row[0] != null) {
											data['summary'] = row[0];
											currentlyReading = null;
										}
										break;
									
									case "actionItems":
										if(row[2] != null && row[2].toLowerCase() != spanishCallReportLabels["noActionRequired"][0].toLowerCase() && row[2].toLowerCase() != spanishCallReportLabels["noActionRequired"][1].toLowerCase()) {
											callReportTasks.push({taskName: row[2], salesRep: row[7]});
										}
										break;
										
									case "nextStep":
										if(row[1] != null) {
											data['nextStep'] = row[1];
										}
										if(row[8] != null) {
											data['dueDate'] = row[8].getTime();
											currentlyReading = null;
										}
										break;
									
									case "station":
										for(let j = 2; j <= maxStationIndex; j++) {
											const stationNumber = j - 1;
											if(row[j] != null) {
												const indexInnerRow0 = getCallReportLabelIndex(row[0]);
												switch(indexInnerRow0) {
													case "formula":
														/*
														if(row[j] != null) {
															arrStationFormulas.push({value: row[j]});
															numStations++;
														} else {
															maxStationIndex = numStations + 1;
															skipRow = true;
														}
														*/
														if(objPressStationInfo[stationNumber] == undefined)
															objPressStationInfo[stationNumber] = {};
														console.log("formula number: ", row[j]);
														objPressStationInfo[stationNumber]['formulaNumber'] = "" + row[j];
														break;
													
													case "batch":
														const arrBatchNumber = row[j].split("-");
														const batchNumberId = arrBatchNumber[1] != undefined ? arrBatchNumber[1] : arrBatchNumber[0];
														// arrStationBatches.push({id: batchNumberId, value: row[j]});
														// objPressStationInfo[stationNumber]['formulaBatchNumberId'] = batchNumberId;
														objPressStationInfo[stationNumber]['formulaBatchNumber'] = "" + row[j];						
														break;
													
													case "anilox":
														// arrStationAniloxes.push({value: row[j]});
														objPressStationInfo[stationNumber]['customerPressStationAnilox'] = "" + row[j];
														break;
													
													case "dryerPct":
															let dryerPct = isNaN(row[j]) ? 0 : row[j];
															// arrStationDryerPct.push(dryerPct);
															objPressStationInfo[stationNumber]['dryerPct'] = dryerPct;
														break;
													
													case "uv":
														// arrStationUV.push(row[j] != null);
														objPressStationInfo[stationNumber]['uv'] = row[j] != null;
														break;
												}
											}
											// if(skipRow)
											//	break;
										}
										break;
									
									case "initialInkConditions":
										if(row[0] != null && row[0] != 0 && row[2] != null && row[3] != null 
											&& row[0] != spanishCallReportLabels["formulaCol"][0] && row[2] != spanishCallReportLabels["pH"][0] && row[3] != spanishCallReportLabels["viscosity"][0] && row[3] != spanishCallReportLabels["viscosity"][1] && row[5] != spanishCallReportLabels["dE"][0] 
											//&& row[6] != spanishCallReportLabels["density"][0]
											){
											const initialStationNumber = getIndexByFieldName(objPressStationInfo, 'formulaNumber', row[0]);
											objPressStationInfo[initialStationNumber]['pHInitial'] = row[2];
											objPressStationInfo[initialStationNumber]['viscocityInitial'] = row[3];
											objPressStationInfo[initialStationNumber]['toleranceInitialInitial'] = row[5];
											objPressStationInfo[initialStationNumber]['densityInitial'] = row[6];
											
											// arrInitialConditions.push({pH: row[2], viscosity: row[3], tolerance: row[5]});
											if(row[9] == 'X') {
												data['initialToleranceMethod'] = row[7];
											}
										}
										break;
									
									case "finalInkConditions":
										if(row[0] != null && row[0] != 0 && row[2] != null && row[3] != null 
											&& row[0] != spanishCallReportLabels["formulaCol"][0] && row[2] != spanishCallReportLabels["pH"][0] && row[3] != spanishCallReportLabels["viscosity"][0] && row[3] != spanishCallReportLabels["viscosity"][1] && row[5] != spanishCallReportLabels["dE"][0] 
											//&& row[6] != spanishCallReportLabels["density"][0]
											){
											// let samplesCollected = row[7] == 'X' ? 'Wet' : (row[8] == 'X' ? 'Print' : 'Other');
											const finalStationNumber = getIndexByFieldName(objPressStationInfo, 'formulaNumber', row[0]);
											
											let samplesCollected = [];
											if(row[7] == 'X') samplesCollected.push("Wet");
											if(row[8] == 'X') samplesCollected.push("Print");
											if(row[9] == 'X') samplesCollected.push("Other");
											
											objPressStationInfo[finalStationNumber]['pHFinal'] = row[2];
											objPressStationInfo[finalStationNumber]['viscosityFinal'] = row[3];
											objPressStationInfo[finalStationNumber]['toleranceFinal'] = row[5];
				                            objPressStationInfo[finalStationNumber]['densityFinal'] = row[6];
											objPressStationInfo[finalStationNumber]['samplesCollected'] = samplesCollected.join(",");
											
											// arrFinalConditions.push({pH: row[2], viscosity: row[3], tolerance: row[5], samplesCollected: samplesCollected.join(",")});
										}
										break;
								}
							}
						}
						break;
				}
				
				if(!skipRow) {
					// Customer Name
					switch(getCallReportLabelIndex(row[0])) {
						case "account":
							data['customerName'] = row[2];
							break;
						
						case "jobDesc":
							data['jobDesc'] = row[2];
							break;
					}
				}
			});
		});
		
		
		
		
		let customerId = 0;
		let objErrors = this.state.objErrors;
		// Get Customers List
		await getAPI("customers/list").then(resCustomers => {
			// Samee - should include ALL customers
			
			const strCustomerName = data['customerName'].toLowerCase();
			const customerRow = resCustomers['data'].filter(obj => obj.name.toLowerCase().indexOf(strCustomerName) > -1)[0];
			if(customerRow != null) {
				customerId = customerRow['id'];
				data['customerId'] = customerId;
			}
			else {
				objErrors['Customer Name'] = data['customerName'];
			}
		});
		
				
		
		
		//	Getting customer contacts
		await getAPI("customers/" + customerId + "/contacts/list").then(resContacts => {
			callReportContacts = callReportContacts.map(contactName => {
				let strContactName = contactName.toLowerCase();
				const contactRow = resContacts['data'].filter(obj => obj.name.toLowerCase().indexOf(strContactName) > -1)[0];
				// console.log("strContactName: ", strContactName, ", contactRow: ", contactRow);
				
				return contactRow != null ? 
				{customerContactId: contactRow.id, customerContactName: contactRow.name} : 
				{customerContactId: null, customerContactName: ''};
				
				if(contactRow != null) {
					return {customerContactId: contactRow.id, customerContactName: contactRow.name};
				} else {
					if(objErrors['Customer Contact'] == undefined)
						objErrors['Customer Contact'] = data['strContactName'];
					else
						objErrors['Customer Contact'] += (", " + data['strContactName']);
					return {customerContactId: null, customerContactName: ''};
				}
			});
		});
		
		// Getting Sales Reps
		await getAPI("users/salesreps").then(resSalesReps => {
			// console.log("callReportTasks: ", callReportTasks, ", resSalesReps: ", resSalesReps['data']);
			callReportSalesReps = callReportSalesReps.map(strSalesRepName => {
				strSalesRepName = strSalesRepName.toLowerCase();
				const row = resSalesReps['data'].filter(obj => obj.name.toLowerCase().indexOf(strSalesRepName) > -1)[0];
				return row != null ? 
				{salesRepId: row.id, salesRepName: row.name} : 
				{salesRepId: null, salesRepName: ''};
			});
			
			//	Getting Call Report Tasks
			callReportTasks = callReportTasks.map(task => {
				const taskName = task['taskName'];
				const strSalesRepName = task['salesRep'].toLowerCase();
				let assignedToSalesRepId = null;
				const row = resSalesReps['data'].filter(obj => obj.name.toLowerCase().indexOf(strSalesRepName) > -1)[0];
				if(row != null) {
					assignedToSalesRepId = row['id'];
				}
				else {
					assignedToSalesRepId = null;
					if(objErrors['Sales Reps'] == undefined)
						objErrors['Sales Reps'] = task['salesRep'];
					else
						objErrors['Sales Reps'] += (", " + task['salesRep']);
				}
				return {toDo: taskName, title: '1', customerId: customerId, assignedToSalesRepId: assignedToSalesRepId, model:"com.phototype.myinkiq.model.vos.dtos::TasksDto"};
			});
		});
		
		
		
		
		if(data['callReportTypeLookupId'] == constants.call_report_type_general) {
			//	Getting Call Report Takeaways
			callReportTakeaways = callReportTakeaways.map(takeawayName => {
				return {takeawayName: takeawayName, model:"com.phototype.myinkiq.model.vos.dtos::CallReportsTakeawaysDto"};
			});

		} else if(data['callReportTypeLookupId'] == constants.call_report_type_press_run) {
			
			
			// Getting Customer Press Id
			let customerPressId = null;
			await getAPI("customers/" + customerId + "/presses/list").then(resPresses => {
				
				const strPressName = data['pressName'].split(" ")[0].toLowerCase();
				// console.log("customerId: ", customerId , ", resPresses: ", resPresses['data'], ", strPressName: ", strPressName);
				const row = resPresses['data'].filter(obj => obj.pressName.toLowerCase().indexOf(strPressName) > -1)[0];

				customerPressId = 0;
				if(row != undefined) {
					customerPressId = row['id'];
				} else {
					objErrors['Customer Press'] = data['pressName'];
				}
					
				data['customerPressId'] = customerPressId;
			});
			
			// Getting Press Speed
			data['pressSpeed'] = data['pressSpeed']; // .split("/")[0];
			
			// Getting substrateLookupId
			let substrateLookupId = null;
			await getAPI("/common/substrates").then(resSubstrates => {
				const strSubstrateName = data['substrateName'].split(" ")[0].toLowerCase();
				const row = resSubstrates['data'].filter(obj => obj.name.toLowerCase().indexOf(strSubstrateName) > -1)[0];
				substrateLookupId = null;
				
				if(row != undefined) {
					substrateLookupId = row['id'];
				} else {
					objErrors['Substrate'] = data['substrateName'];
				}
				
				data['substrateLookupId'] = substrateLookupId;
			});
			
			// Getting initialTolerance and finalTolerance Methods
			Object.keys(toleranceMethodsList).forEach(key => {
				if(toleranceMethodsList[key] == data['initialToleranceMethod'])
					data['initialToleranceMethod'] = key;
				
				// if(toleranceMethodsList[key] == data['finalToleranceMethod'])
				//	data['finalToleranceMethod'] = key;
				
			});

			// console.log("data parsed: ", data);
			// console.log("callReportContacts: ", callReportContacts);
			// console.log("callReportSalesReps: ", callReportSalesReps);
			// console.log("callReportTasks: ", callReportTasks);
			// console.log("callReportTakeaways: ", callReportTakeaways);
			// console.log("callReportStationDetails: ", callReportStationDetails);

			
			
			/////////////////// Getting Station details /////////////////////////
			
			// Customer Press Station Id
			await getAPI("customers/" + customerId + "/presses/" + customerPressId + "/stations").then(resPressStations => {
				// console.log("resPressStations: ", resPressStations);
				// arrStationFormulas.forEach((formulaNumber, j) => {
				Object.keys(objPressStationInfo).forEach(j => {
					// const row = resPressStations['data'].filter(obj => obj.stationNumber == (j + 1))[0];
					const row = resPressStations['data'].filter(obj => obj.stationNumber == j)[0];
					let customerPressStationId = 0;
					if(row != undefined) {
						customerPressStationId = row['id'];
					} else {
						// objErrors['Press Station'] = (j + 1);
						objErrors['Press Station'] = j;
					}
					// arrCustomerPressStationIds.push(customerPressStationId);
					objPressStationInfo[j]['customerPressStationId'] = customerPressStationId;
				});
			});
			
			// Get Customer Formulas
			// Get Customer Formula Id
			await getAPI("customers/" + customerId + "/formulas").then(resFormulas => {
				// console.log("resFormulas: ", resFormulas);
				// arrStationFormulas = arrStationFormulas.map(obj => {
				Object.keys(objPressStationInfo).forEach(j => {
					const obj = objPressStationInfo[j];
					// const row = resFormulas['data'].filter(row => row.formula == obj.value)[0];
					const row = resFormulas['data'].filter(row => row.formula.toLowerCase() == obj['formulaNumber'].toLowerCase())[0];
					let formulaId = null;
					if(row != undefined) {
						formulaId = row['formulaId'];
					} else {
						formulaId = null;
						// if(objErrors['Formula'] == undefined)
						// 	objErrors['Formula'] = obj['formulaNumber']; // obj.value;
						// else
						//	objErrors['Formula'] += (", " + obj['formulaNumber']); // obj.value);
					}
					// return {id: formulaId, value: obj.value};
					objPressStationInfo[j]['formulaId'] = formulaId;
					objPressStationInfo[j]['formulaNumber'] = obj['formulaNumber'];
				});
			});

			// Get Customer Batches
			// Customer Batch Id
			
			// await getAPI("/formulas/batches").then(resBatches => {
			await getAPI("/customers/" + customerId + "/formulas/0/batches").then(resBatches => {
				// console.log("resBatches: ", resBatches);
				Object.keys(objPressStationInfo).forEach(j => {
					const obj = objPressStationInfo[j];
					const row = resBatches['data'].filter(row => row.batchNumber.toLowerCase() == obj['formulaBatchNumber'].toLowerCase())[0];
					let formulaBatchNumberId = null;
					if(row != undefined) {
						formulaBatchNumberId = row['orderFormulaId'];
					} else {
						formulaBatchNumberId = null;
						// if(objErrors['Formula'] == undefined)
						// 	objErrors['Formula'] = obj['formulaNumber']; // obj.value;
						// else
						//	objErrors['Formula'] += (", " + obj['formulaNumber']); // obj.value);
					}
					// return {id: formulaId, value: obj.value};
					objPressStationInfo[j]['formulaBatchNumberId'] = formulaBatchNumberId;
					objPressStationInfo[j]['formulaBatchNumber'] = obj['formulaBatchNumber'];
				});
			});
			

			// Customer Press Station Anilox Id
			
			// await arrCustomerPressStationIds.forEach((customerPressStationId, j) => {
			const aniloxURL = "customers/" + customerId + "/presses/" + customerPressId + "/aniloxes";
			await getAPI(aniloxURL).then(resAniloxes => {
				// console.log("resAniloxes: ", resAniloxes);
				
				// arrStationAniloxes = arrStationAniloxes.map((obj, j) => {
				Object.keys(objPressStationInfo).forEach(j => {
					const obj = objPressStationInfo[j];
					// const rowAnilox = resAniloxes['data'].filter(row => row.anilox == obj.value && row.stationNumber == (j + 1))[0];
					const rowAnilox = resAniloxes['data'].filter(row => row.anilox == obj['customerPressStationAnilox'] && row.stationNumber == j)[0];
					let stationAniloxId = null;
					if(rowAnilox != null) {
						stationAniloxId = rowAnilox['id'];
					} else {
						if(objErrors['Anilox'] == undefined)
							objErrors['Anilox'] = obj['customerPressStationAnilox']; // obj.value;
						else
							objErrors['Anilox'] += (", " + obj['customerPressStationAnilox']); // obj.value);
					}
					// return {id: stationAniloxId, value: obj.value};
					objPressStationInfo[j]['customerPressStationAniloxId'] = stationAniloxId;
				});
				
				// const row = resAniloxes['data'].filter(row => row.anilox == arrStationAniloxes[j]['value'])[0]
				// arrStationAniloxes[j]['id'] = row != null ? row['id'] : null;
			});
			// });
			// const aniloxURL = "customers/" + this.props.customerId + "/presses/" + this.props.customerPressId + "/stations/" + this.props.editCallReportStationDetailRow.customerPressStationId +"/aniloxes"
			
			
			
			// console.log("arrCustomerPressStationIds: ", arrCustomerPressStationIds, ", arrStationFormulas: ", arrStationFormulas, ", arrStationBatches: ", arrStationBatches, ", arrStationAniloxes: ", arrStationAniloxes, ", arrStationDryerPct: ", arrStationDryerPct, ", arrStationUV: ", arrStationUV, ", arrInitialConditions: ", arrInitialConditions, ", arrFinalConditions: ", arrFinalConditions);
			// console.log("objErrors: ", objErrors);
			/*
			arrStationFormulas.forEach((formulaNumber, j) => {
				callReportStationDetails.push({
					customerPressStationId: arrCustomerPressStationIds[j],
					// customerPressStationNumber: j + 1,
					formulaId: arrStationFormulas[j]['id'],
					// formulaNumber: arrStationFormulas[j]['value'],
					formulaBatchNumberId: arrStationBatches[j]['id'],
					// formulaBatchNumber: arrStationBatches[j]['value'],
					customerPressStationAniloxId: arrStationAniloxes[j]['id'],
					// customerPressStationAnilox: arrStationAniloxes[j]['value'],
					dryerPct: arrStationDryerPct[j],
					uv: arrStationUV[j] == null ? false : arrStationUV[j],
					pHInitial: arrInitialConditions[j]['pH'],
					viscosityInitial: arrInitialConditions[j]['viscosity'],
					toleranceInitial: arrInitialConditions[j]['tolerance'],
					pHFinal: arrFinalConditions[j]['pH'],
					viscosityFinal: arrFinalConditions[j]['viscosity'],
					toleranceFinal: arrFinalConditions[j]['tolerance'],
					samplesCollected: arrFinalConditions[j]['samplesCollected'],
				});
			});
			*/
			
			// console.log("objPressStationInfo initial: ", objPressStationInfo);
			callReportStationDetails = Object.values(objPressStationInfo).map(obj => ({
				customerPressStationId: obj.customerPressStationId,
				formulaId: obj.formulaId,
				formulaNumber: obj.formulaNumber,
				formulaBatchNumberId: obj.formulaBatchNumberId,
				formulaBatchNumber: obj.formulaBatchNumber,
				customerPressStationAniloxId: obj.customerPressStationAniloxId,
				dryerPct: obj.dryerPct,
				uv: obj.uv,
				pHInitial: obj.pHInitial,
				viscosityInitial: obj.viscosityInitial,
				toleranceInitial: obj.toleranceInitial,
				densityInitial: obj.densityInitial,
				pHFinal: obj.pHFinal,
				viscosityFinal: obj.viscosityFinal,
				toleranceFinal: obj.toleranceFinal,
				densityFinal: obj.densityFinal,
				samplesCollected: obj.samplesCollected,
			}));
			// console.log("callReportStationDetails: ", callReportStationDetails);
		
		}
		
		
		if(Object.keys(this.state.objErrors).length > 0) {
			this.setState({bLoading: false, objErrors: objErrors, bProcessed: true});
		} else {
			
			postAPI("callreports", data).then(resCallReport => {
				var callReportId = resCallReport['data'][0]['id'];
				
				// Add Call Report Contacts
				callReportContacts.forEach(row => {
					row['callReportId'] = callReportId;
					postAPI("callreports/" + callReportId + "/contacts", row); 
				});
				
				// Adding Call Report Tasks
				callReportTasks.forEach(row => {
					row['callReportId'] = callReportId;
					postAPI("tasks", row); 
				});
				
				if(data['callReportTypeLookupId'] == constants.call_report_type_general) {
					// Adding Call Report Takeaways
					callReportTakeaways.forEach(row => {
						row['callReportId'] = callReportId;
						postAPI("callreports/" + callReportId + "/takeaways", row); 
					});
				} else if(data['callReportTypeLookupId'] == constants.call_report_type_press_run) {
					// Adding Call Report SalesReps
					callReportSalesReps.forEach(row => {
						row['callReportId'] = callReportId;
						postAPI("callreports/" + callReportId + "/salesreps", row); 
					});
					
					// Adding Call Report Station Details
					let promises = [];
					callReportStationDetails.forEach(row => {
						row['callReportId'] = callReportId;
						promises.push(postAPI("callreports/" + callReportId + "/stationdetails", row)); 
					});
					Promise.all(promises);
				}
				this.props.onUpload();
				
				this.setState({bLoading: false, objErrors: objErrors, bProcessed: true});
			});
			
		}
		
    }
	
    render() {
		
		let htmlErrors = '';
		
		if(Object.keys(this.state.objErrors).length > 0)
			htmlErrors = Object.keys(this.state.objErrors).map(key => 
			<li style={{color: 'red'}}>{key} - {this.state.objErrors[key]} not found.</li>
		);
		
        return(<Modal show={this.props.visible}>
			<Modal.Header>
					<Modal.Title><Translate value='components.labels.uploadCallReport' /></Modal.Title>
				</Modal.Header>
            <Modal.Body>
				 <input type="file" onChange={(data, fileInfo) => this.onParseCallReportXLS(data)} />
					 {htmlErrors}
			</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => this.onSelected()} disabled={this.state.bLoading}>
					{this.state.bLoading ? 'Processing' : <Translate value='components.buttons.upload' />}
				</Button>
                <Button onClick={this.props.onClose}><Translate value='components.buttons.close' /></Button>
            </Modal.Footer>
        </Modal>);
    }
}



export default HomePage;
