import React, { Component } from 'react';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown.js';
import InputText from '../common_components/InputText.js';
import NewInkComponentForm from '../ink-components/NewInkComponentForm.js';
import I18n from '../translations/myinkiq-i18n.js';
import { getAPI, postAPI, mapArrayToObject, convertTimestampToDate, getLoggedInUser, openBirtReport} from '../common_components/common.js';
import Config from '../Config';
import {Button, Col, Form, Row, Modal} from "react-bootstrap";
const { Translate, Localize } = require('react-i18nify');

class Inventory extends Component {
    constructor(props){
        super(props);
        this.state = {
            columns: [<Translate value="components.Inventory.components" />,
                <Translate value="components.Inventory.Component_Description" />,
                <Translate value="components.Inventory.status" />,
                <Translate value="components.Inventory.committed Qty" />,
				<Translate value="components.Inventory.orderQty" />,
                <Translate value="components.Inventory.Min Qty" />,
                <Translate value="components.Inventory.Physical" />,
                <Translate value="components.Inventory.Actual" />,
				<Translate value="components.Inventory.Cost" />,
				<Translate value="components.Inventory.totalCost" />,
				],
            fields: ['componentNumber', 'name', 'status', 'committedQty', 'orderQty', 'minQty', 'physicalQuantity', 'afterQty', 'componentCost', 'totalCost'],// 'actualQty',],
            data: [],
            filter: [],
            status: 'Active',
            offset: 0,
            limit: 2500,
            inventoryStatus: '',
            editInventoryId: null,
            editInkComponentFormVisible: false,
            loading: true,
			autoRefresh: false,
			interval: null,
            // isLoaded: false, // Not sure if we need this
            modifiedItems: {}, // Rows whose physical quantity has been modified
			bShowUpdateConfirmation: false, // Whether to show/hide the update confirmation form
        };
        this.showEditInkComponentForm = this.showEditInkComponentForm.bind(this);
        this.inventoryStatusChanged = this.inventoryStatusChanged.bind(this);
        this.inventoryTypeChanged = this.inventoryTypeChanged.bind(this);
        this.onChangeQuantity = this.onChangeQuantity.bind(this);
        this.onUpdateActualQuantity = this.onUpdateActualQuantity.bind(this);
		this.onConfirmUpdateInventory = this.onConfirmUpdateInventory.bind(this);
    }

    showEditInkComponentForm(dataIn) {
        // Grabs the UID for the customer. In this case it is at index 0.
        this.setState({
            editInventoryId: dataIn['id'],
            editInkComponentFormVisible: true,
        })
        // console.log(this.state.editInventoryId);
    }
    
    inventoryStatusChanged(event) {
		let val = event.target.value;
		this.setState({
			status: val,
		});
	}
    
    onChangeQuantity(event) {
    	// console.log("onChangeQuantity() called - event.target.id: ", event.target.id, ", event.target.value: ", event.target.value);
    	let modifiedItems = this.state.modifiedItems;
    	const id = event.target.id;
    	const value = event.target.value;
    	if(value !== '' && !isNaN(value)) {
			const modifiedRow = this.state.data.filter(obj => obj.id == id)[0];
			modifiedRow.physicalQuantity = value;
			// modifiedRow.afterQty = value;
			modifiedItems[id] = modifiedRow;
			// console.log("modifiedRow: ", modifiedRow, ", modifiedItems: ", modifiedItems);
			
			// Samee - Do we really need to call this POST endpoint upon changing the physical quantity?
			// I doubt it!
			// postAPI("components/component/" + id, modifiedRow).then(res => {
				this.setState({
					modifiedItems: modifiedItems,
				});
			// });
    	}
    }
    
	
	onConfirmUpdateInventory(bUpdateLater, date) {
		console.log("onConfirmUpdateInventory() called! - bUpdateLater: ", bUpdateLater, ", date: ", date);
		let pModifiedDate = 0;
		if(bUpdateLater) {
			// Update Later - Add to process queue
			pModifiedDate = date;
		}
		this.onUpdateActualQuantity(pModifiedDate);
	}
	
    onUpdateActualQuantity(pModifiedDate) {
    	// console.log("onUpdateActualQuantity() called: ");
    	/*this.state.modifiedItems.forEach(obj => {
    		obj.model = "com.phototype.myinkiq.model.vos.dtos::InventoryListDto";
    		postAPI("components/inventory", obj).then(res => {});
    	});*/
    	let counter = 0;
    	for(const i in this.state.modifiedItems) {
    		const obj = this.state.modifiedItems[i];
    		obj.model = "com.phototype.myinkiq.model.vos.dtos::InventoryListDto";
    		postAPI("components/inventory?modifiedDate=" + pModifiedDate, obj).then(res => {
    			if(++counter == Object.keys(this.state.modifiedItems).length) {
    				// console.log("counter: ", counter);
    				this.refreshData();
    			}
    		});
    	}
		
		
    }

	refreshData() {
		const bgColors = {0: '#ffaaaa', 1: '#FFFF00', 2: '#aaffaa'};
		
        this.setState({loading: true});
        this.setState({status: 'Active'})
		getAPI("components/inventory/list")
		.then(
			(result) => {
				let data = result['data'];
				data = result['data'].map(obj => {
					const srcCompareVal = obj.afterQty; //  - Math.abs(obj.committedQty); // obj.actualQty - obj.committedQty;
					const targetCompareVal = obj.minQty;
					
					/*
					obj.bgColor = '#ffaaaa';
					if(srcCompareVal >= (targetCompareVal) &&
						srcCompareVal <= ((targetCompareVal * 0.10) + targetCompareVal))
						obj.bgColor = "#FFFF00";
					else if(srcCompareVal > ((targetCompareVal * 0.10) + targetCompareVal))
						obj.bgColor = "#aaffaa";
					*/
					obj.bgColor = bgColors[obj.bgColorIndex] != undefined ? bgColors[obj.bgColorIndex] : '';
					obj['totalCost'] = obj.componentCost * obj.afterQty; 
										
					return obj;
				});
				// console.log("data upon load: ", data);
				// Samee - We need to reset the modified items object once Update Actual Quantity has been clicked
				this.setState({loading: false, data: data, modifiedItems: {}});
			},
			(error) => {this.setState({loading: false, error});}
		);
		/*
		getAPI("components/inventory/list")
            .then(
                result => {
                    console.log('inventory data fetched via API call');
                    this.setState({
                        isLoaded: true,
                        data: result['data'],
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
            */
	}

    componentDidMount() {
        this.refreshData();	
		this.setState({
			inventoryStatus: 'Active',
		});
		// const interval = setInterval(async () => {
		// 	if(this.state.autoRefresh && !this.state.editInkComponentFormVisible) {
		// 		console.log("InkComponent::componentDidMount() - refreshData being called....");
		// 		this.refreshData();	  
		// 	}
		// }, Config.keepConnInterval);
		// this.setState({interval: interval});
    }
    
    componentWillUnmount() {
		clearInterval(this.state.interval);
	}


    inventoryTypeChanged(event) {
        let val = event.target.value;
        /*
		var filter = this.state.filter;
		if(val != ''){
			filter['status'] = val;
			this.setState({filter: filter});
		}else{
			delete filter['status'];
			this.setState({filter: filter});
		}*/
		this.setState({
			inventoryStatus: val,
		});
    }
    render() {
        // console.log('Order render, this.props.txtSearch: ', this.props.txtSearch);
        let inventoryTypes = {
            '': 'All',
            'Active': 'Active',
            'Inactive': 'Inactive',
            'Experimental': 'Experimental',
        };
        
        const formatColumns = {
			6: {'textField': 'id', 'callbackText': this.onChangeQuantity},
			7: {'bgColor': 'bgColor'},
			8: {decimals: 4},
			9: {showTotal: true, decimals: 2},
        };
		
		
        let data = this.state.data;
        
        if(this.state.inventoryStatus != '')
		{
			data = this.state.data.filter(obj => obj.status == this.state.inventoryStatus);
		}
        if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
        const editInkComponentForm = this.state.editInkComponentFormVisible ? <NewInkComponentForm
            onClose={() => this.setState({editInkComponentFormVisible: false})}
            onSave={() => {
                this.setState({editInkComponentFormVisible: false});
                this.refreshData();
            }}
            visible={this.state.editInkComponentFormVisible}
            editComponentId={this.state. editInventoryId}></NewInkComponentForm> : "";

		let totalValue = 0;
		data.forEach(row => {
			totalValue += row["totalCost"];
		});

        return (
            <div>
                <DataGrid
                	loading={this.state.loading}
                    columns={this.state.columns}
                    importantColumns={[1, 2]}
                    fields={this.state.fields}
                    txtSearch={this.props.txtSearch}
                    data={data}
                    onDoubleClick={this.showEditInkComponentForm}
                    txtSearch={this.props.txtSearch}
                    formatColumns={formatColumns}
					totalField={"totalCost"}
					totalValue={totalValue}
                />
                <Row>
                    <Col md="6">
                        <Form class='form-page' horizontal>
                            <DataDropdown label='Status' action={this.inventoryTypeChanged} 
                            	data={inventoryTypes} value={this.state.status} />
                        </Form>
                    </Col>
                    <Col md="6">
                        <Button bsSize='sm' onClick={() => this.setState({newInkComponentFormVisible: true})} >
                            <Translate value="components.buttons.newComponent" />
                        </Button>
                        &nbsp;
                        <Button bsSize='sm' onClick={() => this.setState({bShowUpdateConfirmation: true})} >
                            <Translate value="components.buttons.updateActual" />
                        </Button>
						 &nbsp;
						<Button  bsSize='sm'
							onClick={() => openBirtReport('BCM_Inventory_MyInkIQ', {
									status: this.state.status,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.buttons.pdf" />
						</Button>
						&nbsp;
						<Button  bsSize='sm'
							onClick={() => openBirtReport('BCM_Inventory_MyInkIQ', {
									status: this.state.status,
									__format: 'xlsx',
									'__emitterid': 'uk.co.spudsoft.birt.emitters.excel.XlsxEmitter',
									'__ExcelEmitter.DisplayRowColHeadings': true,
									'__ExcelEmitter.SingleSheet': true
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.buttons.xls" />
						</Button>
                    </Col>
                </Row>

                { editInkComponentForm  }
				<UpdateInventoryConfirmation
					visible={this.state.bShowUpdateConfirmation}
					onConfirm={this.onConfirmUpdateInventory}
					onClose={() => this.setState({bShowUpdateConfirmation: false})}
				/>

            </div>
        );
    }

}

class UpdateInventoryConfirmation extends Component {
	constructor(props){
		super(props);
		this.state = {
			updateDate: new Date().getTime(),
			bUpdateLater: true,
		}
	}
	
	onUpdate() {
		this.props.onConfirm(this.state.bUpdateLater, this.state.updateDate);
		this.props.onClose();
	}

	render() {
		const updateDate = isNaN(this.state.updateDate) || this.state.updateDate == 0 ? this.state.updateDate :
		 convertTimestampToDate(this.state.updateDate, true);
		 
		return(<Modal show={this.props.visible}>
			<Modal.Body>
				<Translate value='components.labels.updateInventoryConfirmationPrompt' />
				<InputText label={<Translate value="components.labels.pickDateTime" />} id={"bUpdateLater"} name={"bUpdateLater"} 
					action={(event) => {
						// console.log("event.target: ", event.target.checked);
						this.setState({
						  bUpdateLater: event.target.checked,
						});
					}} type={"CheckBox"} value={this.state.bUpdateLater} />
				{this.state.bUpdateLater && <InputText 
					label={<Translate value="components.labels.date" />} 
					type={"DateTimestamp"} id={"updateDate"} 
					name={"updateDate"} 
					action={(event) => {
						// console.log("event: ", event, ", this: ", this);
						var value = event._d ? event._d.getTime() : event;
						this.setState({
						  updateDate: value,
						});
					}} 
					value={updateDate}
				/>}
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onUpdate()}><Translate value='components.buttons.update' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}



export default Inventory;
