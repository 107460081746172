import React, { Component } from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataDropdown from '../common_components/DataDropdown';
import {mapArrayToObject, getAPI, postAPI, validateForm, getLoggedInUser, getCurrencySymbol, showFormValidationAlert} from '../common_components/common.js';
import Config from '../Config';
import '../FormPage.css'
const { Translate } = require('react-i18nify');

const validationFields = {
	displayName: "required",
	address1: "required",
	city: "required",
	zipCode: "required",
	countryId: "required",
	stateId: Config.locale.toLowerCase() == 'en_us' ? "required" : "",
};

export default class Company extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			errors: {},
			formData: {
				id: 0,
				city: "",
				addressId: null,
				address1: "",
				address2: "",
				name: "",
				displayName: "",
				rfc: "",
				applicationName: "",
				faxNumber: "",
				phoneNumber: "",
				website: "",
				zipCode: "",
				logoLocation: 0,
				countryId: null,
				stateId: null,
				themeId: null,
				formulaContainerCost: 0.0,
				formulaMiscCharge: 0.0,
				formulaShrinkageMarkupPercent: 0.0,
				overheadMarkupPercent: 0.0,
				shippingCost: 0.0,
				
				deltaETypeDefaultLookupId: null,
				deviceTypeId: null,
				defaultIlluminantId: null,
				inkToleranceDefault: 0,
				baseToleranceDefault: 0,
				model:"com.phototype.myinkiq.model.vos.dtos::CompaniesDto",
				observerAngleDefaultLookupId: null,
				qcReadsCount: 0,
				targetReadsCount: 0,
				
				taxRate: null,
				currencyConversionUnit: null,
				
			},
			
			countriesList: [],
			statesList: [],
			observerAnglesList: [],
			illuminantsList: [],
			deltaETypesList: [],
			devicesList: [],

		};
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		let formData = this.state.formData;
		getAPI("/companies").then(resCompany => {
			formData = resCompany['data'][0];
		
			getAPI("/companies").then(resCompany => {
				getAPI("/companies/observerangles").then(resObserverAngles => {
					getAPI("/companies/illuminants").then(resIlluminants => {
						getAPI("/companies/deltaetypes").then(resDeltaETypes => {
							getAPI("/common/devices").then(resDevices => {
								getAPI("/common/countries").then(resCountries => {
									this.setState({
										formData: formData,
										countriesList: resCountries['data'],
										observerAnglesList: resObserverAngles['data'],
										illuminantsList: resIlluminants['data'],
										deltaETypesList: resDeltaETypes['data'],
										devicesList: resDevices['data'],
										countriesList: resCountries['data'],
									});
									
									if(formData['countryId'])
									{
										const countryId = formData['countryId'];
										getAPI("/common/states/" + countryId).then(resStates => {
											this.setState({
												statesList: resStates['data'],
											});
										});
									}
								});
							});
						});
					});
				});
			});
		});
	}
	
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	onSave() {
		const validation = validateForm(this.state.formData, validationFields);
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			// IF FORM IS VALIDATED PERFORM SAVE OPERATION HERE
			postAPI("companies", this.state.formData).then(resCompanies => {
				alert("Company info Saved successfully.");
			});
		}
	}

	render() {
		const countriesList = mapArrayToObject(this.state.countriesList, 'id', 'name');
		const statesList = mapArrayToObject(this.state.statesList, 'id', 'name');
		const observerAnglesList = mapArrayToObject(this.state.observerAnglesList, 'id', 'value');
		const illuminantsList = mapArrayToObject(this.state.illuminantsList, 'id', 'value');
		const deltaETypesList = mapArrayToObject(this.state.deltaETypesList, 'id', 'value');
		const devicesList = mapArrayToObject(this.state.devicesList, 'id', 'name');
		
		const loggedInUser = getLoggedInUser();
		
		return(
			<Form className='form-page' horizontal style={{maxHeight: 'none'}}>
				<Row>
					<Col md={6}>
						<h4><Translate value='components.settings.companyInfo' /></h4>
						<InputText required={validationFields.displayName == 'required'}
							label={<Translate value='components.settings.displayName' />} 
							name={'displayName'} value={this.state.formData.displayName} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.displayName 
								? this.state.errors.displayName : ""}
						/>
						{Config.locale.toLowerCase() == 'es_mx' && 
						<InputText
							label={<Translate value='components.settings.rfc' />} 
							name={'rfc'} value={this.state.formData.rfc} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.rfc 
								? this.state.errors.rfc : ""}
						/>}
						<InputText required={validationFields.address1 == 'required'} 
							label={<Translate value='components.settings.address1' />} 
							name={'address1'} value={this.state.formData.address1} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.address1 
								? this.state.errors.address1 : ""}
						/>
						<InputText label={<Translate value='components.settings.address2' />} 
							name={'address2'} value={this.state.formData.address2} 
							action={this.handleInputChanged} 
						/>
						<InputText required={validationFields.city == 'required'}
							label={<Translate value='components.settings.city' />} 
							name={'city'} value={this.state.formData.city} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.city 
								? this.state.errors.city : ""}
						/>
						<InputText required={validationFields.zipCode == 'required'} 
							label={<Translate value='components.settings.zipCode' />} 
							name={'zipCode'} value={this.state.formData.zipCode} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.zipCode 
								? this.state.errors.zipCode : ""}
						/>
						<DataDropdown required={validationFields.countryId == 'required'}  
							label={<Translate value='components.settings.country' />} 
							data={countriesList} name={'countryId'} 
							value={this.state.formData.countryId} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.countryId 
								? this.state.errors.countryId : ""}
						/>
						<DataDropdown required={validationFields.stateId == 'required'}  
							label={<Translate value='components.settings.state' />} 
							data={statesList} name={'stateId'} value={this.state.formData.stateId} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.stateId 
								? this.state.errors.stateId : ""}
						/>
						<InputText label={<Translate value='components.settings.phone' />} name={'phoneNumber'} value={this.state.formData.phoneNumber} action={this.handleInputChanged} />
						<InputText label={<Translate value='components.settings.fax' />} name={'faxNumber'} value={this.state.formData.faxNumber} action={this.handleInputChanged} />
						<InputText label={<Translate value='components.settings.website' />} name={'website'} value={this.state.formData.website} action={this.handleInputChanged} />
						<InputText 
							label={<Translate value='components.settings.taxRate' />} 
							name={'taxRate'} 
							value={this.state.formData.taxRate} 
							action={this.handleInputChanged} 
						/>
					</Col>
					<Col md={6}>
						<h4><Translate value='components.settings.formulaCostInfo' />&nbsp;({getCurrencySymbol()})</h4>
						<InputText 
							label={<span>
								<Translate value='components.settings.formulaMiscCharge' />
							&nbsp;({getCurrencySymbol()})</span>} 
							name={'formulaMiscCharge'} value={this.state.formData.formulaMiscCharge} 
							action={this.handleInputChanged} />
						<InputText 
							label={<span>
								<Translate value='components.settings.formulaContainerCost' />
								&nbsp;({getCurrencySymbol()})
							</span>} 
							name={'formulaContainerCost'} 
							value={this.state.formData.formulaContainerCost} 
							action={this.handleInputChanged} />
						<InputText 
							label={<Translate value='components.settings.formulaShrinkageAmount' />} 
							name={'formulaShrinkageMarkupPercent'} 
							value={this.state.formData.formulaShrinkageMarkupPercent} 
							action={this.handleInputChanged} 
						/>
						<InputText 
							label={<Translate value='components.settings.overheadMarkup' />} 
							name={'overheadMarkupPercent'} 
							value={this.state.formData.overheadMarkupPercent} 
							action={this.handleInputChanged} 
						/>
						<InputText 
							label={<span>
								<Translate value='components.settings.shippingCost' />
							&nbsp;({getCurrencySymbol()})</span>} 
							name={'shippingCost'} value={this.state.formData.shippingCost} 
							action={this.handleInputChanged} />

						<h4><Translate value='components.settings.defaultMeasurementConditions' /></h4>
						<DataDropdown label={<Translate value='components.settings.illuminant' />} name={'defaultIlluminantId'} value={this.state.formData.defaultIlluminantId} data={illuminantsList} action={this.handleInputChanged} />
						<DataDropdown label={<Translate value='components.settings.observerAngle' />} name={'observerAngleDefaultLookupId'} value={this.state.formData.observerAngleDefaultLookupId} data={observerAnglesList} action={this.handleInputChanged} />
						<DataDropdown label={<Translate value='components.settings.deltaE' />} name={'deltaETypeDefaultLookupId'} value={this.state.formData.deltaETypeDefaultLookupId} data={deltaETypesList} action={this.handleInputChanged} />
						<DataDropdown label={<Translate value='components.settings.device' />} name={'deviceTypeId'} value={this.state.formData.deviceTypeId} data={devicesList} action={this.handleInputChanged} />
						<InputText label={<Translate value='components.settings.baseTolerance' />} name={'baseToleranceDefault'} value={this.state.formData.baseToleranceDefault} action={this.handleInputChanged} />
						<InputText label={<Translate value='components.settings.inkTolerance' />} name={'inkToleranceDefault'} value={this.state.formData.inkToleranceDefault} action={this.handleInputChanged} />
						<InputText label={<Translate value='components.settings.numQcReads' />} name={'qcReadsCount'} value={this.state.formData.qcReadsCount} action={this.handleInputChanged} />
						<InputText label={<Translate value='components.settings.numTargetReads' />} name={'targetReadsCount'} value={this.state.formData.targetReadsCount} action={this.handleInputChanged} />
						{loggedInUser.userName == 'mmurphy' && 
							<InputText 
							label={<Translate value='components.settings.currencyConversionUnit' />} 
							name={'currencyConversionUnit'} 
							value={this.state.formData.currencyConversionUnit} 
							action={this.handleInputChanged} />
						}
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<Button bsSize='sm'><Translate value='components.settings.cancel' /></Button>
						&nbsp;
						<Button bsSize='sm' bsStyle="primary" onClick={this.onSave}>
							<Translate value='components.settings.save' />
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}
