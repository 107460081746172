export default {
	'reports': {
		'accountingReports': 'Informes de contabilidad',
		'agingReport': 'Informe de envejecimiento',
		'trendingAgingReport': 'Informe de tendencias de envejecimiento',
		'creditMemo': 'Nota de crédito',
		'inventoryTransactionsReport': 'Informe de transacciones de inventario',
		'inventoryVarianceReport': 'Informe de variación de inventario',
		'invoiceSummaryReport': 'Informe de resumen de factura',
		'orderListingReport': 'Informe de listado de pedidos',
		'purchaseOrder': 'Orden de compra',
		'shippingReports': 'Informes de envío',
		'billOfLading': 'Guía de carga',
		'bucketLabel': 'Etiqueta de cubo',
		'packingSlip': 'Hoja de embalaje',
		'customerReports': 'Informes del cliente',
		'blendingTicket': 'Boleto de mezcla',
		'historicalReports': 'Informes históricos',
		'lbsSoldReport': 'Informe Lbs vendido',
		'LBsSoldReport': 'Informe Lbs vendido',
		'KGsSoldReport': 'Informe Kgs vendido',
		'formulaReports': 'Informes de fórmula',
		'containsComponentsReport': 'Contiene el informe de componentes',
		'formulaCostReport': 'Informe de costos de fórmula',
		'formulaChangeReasonReport': 'Informe de motivo de cambio de fórmula',
		'colorMatchReport': 'Informe de conversión de coincidencia de color',
		'callReportSummary': 'Informe de resumen de informes de llamadas',
		'batchVarianceReport': 'Informe de variación de lote',
		'formulaCostPriceChangeReport': 'Informe de cambio de precio de costo de fórmula',
		'componentCostPriceChangeReport': 'Informe de cambio de precio de costo de componente',
		'batchAndColorMatchDurationReport':'Informe de duración de la coincidencia de colores y lotes',
		'lateLossDamageReportForSalesOrder': 'Informe tardío de daños perdidos para pedidos de cliente',
		'lateLossDamageReportForpurchaseOrder': 'Informe tardío de daños perdidos para la orden de compra',
		'lbsSoldReports': 'Informes vendidos LBs',
		'LBsSoldReports': 'Informes vendidos LBs',
		'KGsSoldReports': 'Informes vendidos KGs',
		'salesOrderReports': 'Informes de orden de ventas',
		'vocReport': 'Informe VOC',
		'batchTicket': 'Boleto de lote',
		'invoice': 'Factura',
		'labRequest': 'Solicitud de laboratorio',
		'labRequestForm': 'Formulario de solicitud de laboratorio',
		'salesOrder': 'Órdenes de venta',
		'reportOptions': 'Opciones de informe',
		'viewReport': 'Vista del informe',
		'customer': 'Cliente',
		'shipper': 'Expedidor',
		'vendor': 'Vendedor',
		'creditMemo': 'Nota de crédito',
		'from': 'A partir de',
		'to': 'Hasta',
		'hideDetails': 'Ocultar detalles',
		'shipDate': 'Fecha de envío',
		'formula': 'Fórmula',
		'reportType': 'Tipo de informe',
		'jobNameOrDescription': 'Nombre o descripción del trabajo',
		'colorOrFormula': 'Color o formula',
		'pressSpecific': 'Prensa específica',
		'comparisonCriteria': 'Criterios de comparación',
		'dateFrom': 'Fecha de',
		'dateTo': 'Fecha a',
		'timeFrom': 'Tiempo desde',
		'timeTo': 'Hora de',
		'compareFrom': 'Comparar de',
		'compareTo': 'Comparar con',
		'mode': 'Modo',
		'salesRep': 'Representante de ventas',
		'dateType': 'Tipo de fecha',
		'location': 'Ubicación',
		'regularBilling': 'Facturación regular',
		'consignment': 'Envío',
		'consignmentBilling': 'Facturación de consignación',
		'profitability': 'Rentabilidad',
		'orderType': 'Tipo de orden',
		'formulaType': 'Tipo de fórmula',
		'internalOrders': 'Órdenes internas',
		'priceFrom': 'Precio desde',
		'priceTo': 'Precio a',
		'basedUpon': 'Basado en',
		'component': 'Componente',
		'componentNumber': 'Número de componente',
		'batchTicketNumber': 'Número de ticket de lote',
		'jobMultiColorReport': 'Informe de trabajo (multicolor)',
		'individualColorReport': 'Informe de color individual',
		'pressSpecificReport': 'Informe específico de prensa',
		'setup': 'Preparar',
		'production': 'Producción',
		'both': 'Ambos',
		'orderDate': 'Fecha de orden',
		'domestic': 'Nacional',
		'international': 'Internacional',
		'all': 'Todas',
		'base': 'Base',
		'finishedInk': 'Tinta acabada',
		'varnish': 'Barniz',
		'other': 'Otro',
		'additive': 'Aditivo',
		'dispersion': 'Dispersión',
		'opv': 'OPV',
		'blendingInk': 'Mezcla de tinta',
		'include': 'Incluir',
		'exclude': 'Excluir',
		'only': 'Solamente',
		'summary': 'Resumen',
		'internationalCustomers': 'Clientes internacionales',
        'internalBatches': 'Lotes internos',
		'colorMatch': 'Coincidencia de color',
		'rnd': 'R & D',
		'repull': 'Retirar',
		'lrType': 'Tipo de lr',
		'matchedBy': 'Emparejado por',
		'salesOrderLRShipment': 'Orden de venta y envío LR',
		'topComponent': 'Componente superior',
		
		// Used to send locale parameter to reports
		'__locale': 'es_MX',
		'specOutput': 'Salida de especificaciones',
		'reportFormat': 'Formato',
		'specSystemReport': 'Informe del sistema de especificaciones',
		'exportSinceDate': 'Exportar desde la fecha',
		'newSales': 'Nuevas Ventas',
		'groupBy': 'Agrupar por',
		'sortBy': 'Ordenar por',
		'showTrendGraph' : 'Mostrar gráfico de tendencia',
		'bcmMex': 'BCM Mexico',
		'phViscosityReport': 'Informe de pH y Viscosidad',
		'specOutOfPh': 'Solo pH fuera de especificación',
		'specOutOfViscosity': 'Viscosidad fuera de especificación solamente',
		'highPh': 'pH alto',
		'lowPh': 'pH bajo',
		'highViscosity': 'Alta viscosidad',
		'lowViscosity': 'Baja viscosidad',
		
		
	}
}
