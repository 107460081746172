export default {
	'reports': {
		'accountingReports': 'Accounting Reports',
		'agingReport': 'Aging Report',
		'trendingAgingReport': 'Trending Aging Report',
		'creditMemo': 'Credit Memo',
		'inventoryTransactionsReport': 'Inventory Transactions Report',
		'inventoryVarianceReport': 'Inventory Variance Report',
		'invoiceSummaryReport': 'Invoice Summary Report',
		'orderListingReport': 'Order Listing Report',
		'purchaseOrder': 'Purchase Order',
		'shippingReports': 'Shipping Reports',
		'billOfLading': 'Bill of Lading',
		'bucketLabel': 'Bucket Label',
		'packingSlip': 'Packing Slip',
		'customerReports': 'Customer Reports',
		'blendingTicket': 'Blending Ticket',
		'historicalReports': 'Historical Reports',
		'lbsSoldReport': 'Lbs Sold Report',
		'LBsSoldReport': 'Lbs Sold Report',
		'KGsSoldReport': 'KGs Sold Report',
		'formulaReports': 'Formula Reports',
		'containsComponentsReport': 'Contains Components Report',
		'formulaChangeReasonReport': 'Formula Change Reason Report',
		'batchVarianceReport': 'Batch Variance Report',
		'formulaCostPriceChangeReport': 'Formula Cost Price Change Report',
		'componentCostPriceChangeReport': 'Component Cost Price Change Report',
		'batchAndColorMatchDurationReport': 'Batch and Color Match Duration Report',
		'lateLossDamageReportForSalesOrder': 'Late Lost Damage Report For Sales Orders',
		'lateLossDamageReportForPurchaseOrder': 'Late Lost Damage Report For Purchase Orders',
		'formulaCostReport': 'Formula Cost Report',
		'lbsSoldReports': 'LBS Sold Reports',
		'LBsSoldReports': 'LBs Sold Reports',
		'colorMatchReport': 'Color Match Conversion Report',
		'callReportSummary': 'Call Reports Summary Report',
		'KGsSoldReports': 'KGs Sold Reports',
		'salesOrderReports': 'Sales Order Reports',
		'vocReport': 'VOC Report',
		'batchTicket': 'Batch Ticket',
		'invoice': 'Invoice',
		'labRequest': 'Lab Request',
		'labRequestForm': 'Lab Request Form',
		'salesOrder': 'Sales Order',
		'reportOptions': 'Report Options',
		'viewReport': 'View Report',
		'customer': 'Customer',
		'shipper': 'Shipper',
		'openOrders': 'Open Orders',
		'vendor': 'Vendor',
		'creditMemo': 'Credit Memo',
		'from': 'From',
		'to': 'To',
		'hideDetails': 'Hide Details?',
		'shipDate': 'Ship Date',
		'formula': 'Formula',
		'reportType': 'Report Type',
		'jobNameOrDescription': 'Job Name or Description',
		'colorOrFormula': 'Color or Formula',
		'pressSpecific': 'Press Specific',
		'comparisonCriteria': 'Comparison Criteria',
		'dateFrom': 'Date From',
		'dateTo': 'Date To',
		'timeFrom': 'Time From',
		'timeTo': 'Time To',
		'compareFrom': 'Compare From',
		'compareTo': 'Compare To',
		'mode': 'Mode',
		'salesRep': 'Sales Rep',
		'dateType': 'Date Type',
		'location': 'Location',
		'regularBilling': 'Regular Billing',
		'consignment': 'Consignment',
		'consignmentBilling': 'Consignment Billing',
		'profitability': 'Profitability',
		'orderType': 'Order Type',
		'formulaType': 'Formula Type',
		'internalOrders': 'Internal Orders',
		'priceFrom': 'Price From',
		'priceTo': 'Price To',
		'basedUpon': 'Based Upon',
		'component': 'Component',
		'componentNumber': 'Component Number',
		'batchTicketNumber': 'Batch Ticket Number',
		'jobMultiColorReport': 'Job (Multi Color) Report',
		'individualColorReport': 'Individual Color Report',
		'pressSpecificReport': 'Press Specific Report',
		'setup': 'Setup',
		'production': 'Production',
		'both': 'Both',
		'orderDate': 'Order Date',
		'domestic': 'Domestic',
		'international': 'International',
		'all': 'All',
		'base': 'Base',
		'finishedInk': 'Finished Ink',
		'varnish': 'Varnish',
		'other': 'Other',
		'additive': 'Additive',
		'dispersion': 'Dispersion',
		'opv': 'OPV',
		'blendingInk': 'Blending Ink',
		'include': 'Include',
		'exclude': 'Exclude',
		'only': 'Only',
		'colorMatch': 'Color Match',
		'rnd': 'R & D',
		'repull': 'Repull',
		'lrType': 'LR Type',
		'matchedBy': 'Matched By',
		'summary': 'Summary',
		'internationalCustomers': 'International Customers',
		'internalBatches': 'Internal Batches',
		'salesOrderLRShipment': 'Sales Order & LR Shipment',
		'topComponent': 'Top Component',

		// Used to send locale parameter to reports
		'__locale': '',
		'specOutput': 'Spec Output',
		'reportFormat': 'Format',
		'specSystemReport': 'Spec System Report',
		'exportSinceDate': 'Export Since Date',
		'newSales': 'New Sales',
		'groupBy': 'Group By',
		'sortBy': 'Sort By',
		'showTrendGraph' : 'Show Trend Graph',
		'bcmMex': 'BCM Mexico',
		'phViscosityReport': 'pH And Viscosity Report',
		'specOutOfPh': 'Out of Spec pH Only',
		'specOutOfViscosity': 'Out of Spec Viscosity Only',
		'highPh': 'High pH',
		'lowPh': 'Low pH',
		'highViscosity': 'High Viscosity',
		'lowViscosity': 'Low Viscosity',
	}
}
