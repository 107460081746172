import React, { Component } from 'react';
import { Nav, NavItem, OverlayTrigger, Tooltip, Button, ButtonGroup } from 'react-bootstrap';
import Octicon, { Gear, Dashboard, Graph, Database, CreditCard, Organization, Checklist, Package, Note, Sync, Tasklist, Report, MortarBoard} from '@githubprimer/octicons-react'
import './Sidebar.css'
import {getLoggedInUser} from '../common_components/common.js';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');
class Sidebar extends Component{
	//defining constant for mapping eventkeys to page names for the navigate function
	
	constructor(props){
		super(props);
		
		const loggedInUser = getLoggedInUser();
		const locale = loggedInUser.spanishFlag ? "es" : "en"; // "es"
		I18n.setLocale(locale);
		//this.handleSelect.bind(this);
	}

	//constants for handling navigation events

	//function obviously handles nav selection
	handleSelect = (selectedKey) =>{
	  //alert(`selected ${selectedKey}`);
	  console.log("Sidebar::handleSelect(): ", selectedKey);
	  this.props.navigateTo(selectedKey);
	}

	render(){
		const tabTitles = [
			<Translate value="components.nav.dashboard" />,
			<Translate value="components.nav.orders" />,
			<Translate value="components.nav.formulas" />,
			<Translate value="components.nav.components" />,
			<Translate value="components.nav.po" />,
			<Translate value="components.nav.customers" />,
			<Translate value="components.nav.inventory" />,
			<Translate value="components.nav.settings" />,
			<Translate value="components.nav.reports" />,
			<Translate value="components.nav.Training" />,
		];

		const tabIcons = [
			Dashboard,
			Checklist,
			Graph,
			Database,
			CreditCard,
			Organization,
			Package,
			Gear,
			Note,
			MortarBoard,
		];
		
		const permissionFields = [
			null,
			'accessOrdersCode',
			'accessFormulasCode',
			'accessComponentsCode',
			'accessPOCode',
			'accessCustomersCode',
			'accessInventoryCode',
			null,
			null,
			null,
		];
		const loggedInUser = getLoggedInUser();
		// console.log("Sidebar::render() - loggedInUser: ", loggedInUser);
		const userTypeId = loggedInUser.userTypeId;
		
		let tabItemIndexes = [0];
		for(const i in permissionFields) {
			const permissionField = permissionFields[i];
			if(permissionField != null && loggedInUser[permissionField] != 'N')
				tabItemIndexes.push(i);
		}
		
		// When to add the Settings tab
		if(loggedInUser['accessVendorsCode'] != 'N'
		|| loggedInUser['accessShippersCode'] != 'N'
		|| loggedInUser['userMaintenanceFlag']
		|| loggedInUser['editCompanyFlag'])
			tabItemIndexes.push(7);
			
		// When to add the Reports tab
		tabItemIndexes.push(8);
		tabItemIndexes.push(9);

		return(
			<div id="Sidebar-container" >
				<Nav
					bsStyle="pills"
					stacked={!this.props.top}
					justified={this.props.top}
					activeKey={this.props.selectedKey}
					onSelect={this.handleSelect}
					style={{
						marginTop: '1em',
					}}
				>
					{tabItemIndexes.map(i =>
						<NavItem eventKey={parseInt(i) + 1}>
							<OverlayTrigger
								placement='right'
								overlay={<Tooltip>{tabTitles[i]}</Tooltip>}
								trigger={this.props.top && []}
							>
								<div>
									<Octicon size='medium' verticalAlign='middle' icon={tabIcons[i]} />
									{this.props.top && <div style={{fontSize: '.9em'}}>{tabTitles[i]}</div>}
								</div>
							</OverlayTrigger>
						</NavItem>
					)}
					{!this.props.top && <hr />}
					<Button
						onClick={() => this.props.refresh()}
					>
						<Octicon icon={Sync} size='medium' />
						{this.props.top && <div style={{fontSize: '.9em'}}>
							<Translate value='components.buttons.refresh' />
						</div>}
					</Button>
				</Nav>
			</div>
		);
	}
}

export default Sidebar;
