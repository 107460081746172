export default {
    "Inventory":{
        "components": "componente#",
        "Component_Description": "Descripción de Componente",
        "status": "Estado",
        "committed Qty": "Cantidad comprometida",
        "Min Qty": "Min Cantidad",
        "Physical": "Cantidad física",
        "Actual": "Cantidad real",
		"totalCost": "Coste total",
		"orderQty": "Ordene la Cantidad",
    }
}
