import React, { Component } from 'react';
import {Form, Row, Col, Button, Modal} from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataGrid from '../common_components/DataGrid';
import DataDropdown from '../common_components/DataDropdown.js';
import {mapArrayToObject, convertTimestampToDate, convertDateToTimestamp, getAPI, postAPI, getLoggedInUser, resetObject, validateForm, toCurrency, calculateTaxRate, openBirtReport, constants, showFormValidationAlert, quickbooksAPI} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');

const completed_status_id = 145;
const submitted_status_id = 148;

const late = {
	"late": "Late",
	"lost": "Lost",
	"damage": "Damaged",
}

class PurchaseOrderFormDeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deletePurchaseOrderConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}

const validationFields = {
	vendorId: "required",
	statusId: "required",
	orderDate: "required",
	dueDate: "required",
};

const validationFieldsContainer = {
	quantity: "required,numeric",
	componentId: "required",
	containerId: "required",
	containerWeight: "required,numeric",
	umOrdered: "required,numeric",
};

const validationFieldsPOInstallment = {
	umReceived: "required,numeric",
};


class NewPurchaseOrderForm extends Component {
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		this.state = {
			title: "New Purchase Order",
			// bLateLostDamaged:false,
			bReasonLateFlag: false,
			bReasonLostFlag: false,
			bReasonDamagedFlag: false,
			currentDate: new Date(),
			errors: resetObject(validationFields),
			formData: {
				contactId:0,
				dueDate:0,
				emailSentDate:null,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::PurchaseOrdersDto",
				name:null,
				notes:"",
				orderDate:new Date(),
				statusId:146, // New
				shippingAddressId:1, // New
				submittedDate:null,
				completedDate:null,
				totalPoAmt:0.0,
				vendorId:null,
				vendorName:null,
				taxAmount: null,
				grandTotal: null,
				totalPoConvertedAmt: null,
				bLateLostDamaged:false,
				lateLostDamagedReason:null,
				lateLostDamagedAmountClaimed:null,
				lateLostDamagedAmountReceived:null,
				lateLostDamagedComment:null,
				lateLostDamagedDeliveryDate:new Date(),
			},
			vendorData: {},
			orderComponents: [], // Purchase Order Components
			
			vendorsList: [],
			poStatuses: [],
			componentsList: [],
			containersList: [],
			showNewComponentForm: false,
			editOrderComponentRow: null,
			deletedOrderComponents: [],
			selectedComponentRowIndex: null,
			showDeleteConfirmation: false,
			initStatusId: null,
			taxRate: null,
			companyInfo: null,
		};
		this.chkCompletedChecked = this.chkCompletedChecked.bind(this);
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.getVendorData = this.getVendorData.bind(this);
		this.onAddComponent = this.onAddComponent.bind(this);
		this.onRemoveComponent = this.onRemoveComponent.bind(this);
		this.editOrderComponent = this.editOrderComponent.bind(this);
		this.componentRowSelected = this.componentRowSelected.bind(this);
		this.saveOrderComponent = this.saveOrderComponent.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.getQboPOInfo = this.getQboPOInfo.bind(this);
	}
	
	getVendorData(vendorId, vendorSelected = false) {
		getAPI("/vendors/" + vendorId).then(resVendor => {
			const vendorData = resVendor['data'][0];
			let formData = this.state.formData;
			
			if(vendorSelected)
				formData.notes = vendorData.comments;
				
			this.setState({
				vendorData: vendorData,
				formData: formData,
			})
		});
	}
	
	getQboPOInfo() {
		let qboLineItemInfo = [];
							
		this.state.orderComponents.forEach(function(row, i){
			qboLineItemInfo.push({ 
				"Description": row.name, 
				"DetailType": "ItemBasedExpenseLineDetail", 
				// "Amount": row.quantity * row.price, 
				"Amount": row.umOrdered * row.price, 
				"ItemBasedExpenseLineDetail": { 
					"BillableStatus": "NotBillable",
					// "Qty": row.quantity, 
					"Qty": row.umOrdered, 
					"UnitPrice": row.price,
					"ItemRef": {
						"value": row.qboComponentRefId,
						"name": row.name
					},
					"ClassRef": {
						"value": "200000000001496495",
						"name": "USA"
					},
				}
			});
		});
		
		let qboPOInfo = {
			"DocNumber": this.state.formData.id,
			"Line": qboLineItemInfo,
			"VendorRef": {
			 "value": this.state.vendorData.qboVendorRefId,
			 "name": this.state.vendorData.name
			},
			"APAccountRef": {
			 "value": "205",
			 "name": "Accounts Payable -Trade USA"
			},
			"TaxCodeRef": {
				"value": "NON",
			},
			"CustomField": [
			],
			"DueDate": convertTimestampToDate(this.state.formData.dueDate, false, false, true),
			"TotalAmt": this.state.formData.grandTotal
		};
		return qboPOInfo;
	}
	
	componentDidMount() {
	
		getAPI("vendors/list").then(resVendorsList => {
			getAPI("components/list").then(resComponentsList => {
				getAPI("purchaseorders/statuses").then(resPOStatuses => {
					getAPI("formulas/containers/list").then(resContainersList => {
						getAPI("companies").then(resCompanies => {
						
							this.setState({
								vendorsList: resVendorsList['data'],
								poStatuses: resPOStatuses['data'],
								componentsList: resComponentsList['data'],
								containersList: resContainersList['data'],
								initStatusId: this.state.formData.statusId,
								taxRate: resCompanies['data'][0]['taxRate'],
								companyInfo: resCompanies['data'][0],
							});
					
							if(this.props.editPurchaseOrderId != null)
							{
								var editPurchaseOrderId = this.props.editPurchaseOrderId;
								getAPI("purchaseorders/" + editPurchaseOrderId).then(resPO => {
									getAPI("purchaseorders/" + editPurchaseOrderId + "/components").then(resPOComponents => {
										var formData = resPO['data'][0];
										var orderComponents = [];
										for(const i in resPOComponents['data'])
										{
											var orderComponent = resPOComponents['data'][i];
											const componentId = orderComponent['componentId'];
											// orderComponent['componentName'] = resComponentsList['data'].filter(obj => obj.id == componentId)[0]['name'];
									
											const containerId = orderComponent['containerId'];
											orderComponent['containerName'] = resContainersList['data'].filter(obj => obj.id == containerId)[0]['name'];
									
											orderComponent['totalPrice'] = orderComponent['price'] * orderComponent['umOrdered'];
											orderComponent['model'] = "com.phototype.myinkiq.model.vos.dtos::PurchaseOrderComponentsDto";
										
											orderComponents.push(orderComponent);
										}
										
										if(!formData['taxAmount']) {
											// Tax applies only to Mexican companies
											const taxExempt = Config.locale.toLowerCase() != 'es_mx';
											formData = calculateTaxRate(formData, this.state.taxRate,
												taxExempt, formData['totalPoAmt']);
										}
										
										this.setState({
											formData: formData,
											orderComponents: orderComponents,
											title: "P.O.# " + formData['id'],
											initStatusId: formData['statusId'],
										});
								
										this.getVendorData(formData.vendorId, false);
										// console.log("QboPOInfo: ", this.getQboPOInfo());
									});
								});
							}
						});
					});
				});
			});
		});
	}
	
	chkCompletedChecked(event) {
		const checked = event.target.checked;
		const value = event.target.value;
		let orderComponents = this.state.orderComponents;
		/*
		const rowIndex = orderComponents.findIndex((row) => {
			return row.id == value;
		});*/
		const rowIndex = value;
		// console.log("checked: ", checked, ", value: ", value, ", rowIndex: ", rowIndex);
		if(rowIndex > -1) {
			orderComponents[rowIndex]['receivedFlag'] = checked;
			orderComponents[rowIndex]['bHasChanged'] = true;
		}
		
		this.setState({
			orderComponents: orderComponents
		});
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		
		var value = '';
		switch(name) 
		{
		
			case 'vendorId':
				value = event.value;
				this.getVendorData(value, true);
				break;
			
			case 'bLateLostDamaged':
				
				var formData = this.state.formData;
				//value = event.target.checked
				//formData['lateLostDamagedReason'] = "";
				value  = event.target.checked;
				
				formData['lateLostDamagedReason'] = "";
				formData['bLateLostDamaged'] = event.target.checked;
				this.setState({
					//bLateLostDamaged : event.target.checked,
					bReasonLateFlag : false,
					bReasonLostFlag : false,
					bReasonDamagedFlag :false,
					// stateData: stateData,
					formData : formData,
				});
				break;
	
			case 'lateLostDamagedReason':
				var formData = this.state.formData;
				value = event.target.value;
				//formData['lateLostDamagedReason'] = event.target.value;
				formData[name] = value ;
				this.setState({
					formData : formData,
				});
				if(this.state.formData.lateLostDamagedReason == "late"){
					this.setState({
						bReasonLateFlag : true,
						bReasonLostFlag : false,
						bReasonDamagedFlag : false,
					});
				}
				
				else if(this.state.formData.lateLostDamagedReason == "lost"){
					this.setState({
						bReasonLostFlag : true,
						bReasonLateFlag : false,
						bReasonDamagedFlag : false,
					});
				}		
				else if(this.state.formData.lateLostDamagedReason == "damage"){	
					this.setState({
						bReasonDamagedFlag : true,
						bReasonLateFlag : false,
						bReasonLostFlag : false,
					});			
				}
					
				else {
					this.setState({
						bReasonLateFlag : false,
						bReasonLostFlag : false,
						bReasonDamagedFlag : false,
					});
				}
					
				break;
			
				case 'lateLostDamagedDeliveryDate':
					var formData = this.state.formData;
					value = event._d ? event._d.getTime() : event;
					formData[name] = value;
					this.setState({
					  formData: formData,
					});
					break;
			default:

				switch(name) {
				
						case 'orderDate':
						case 'dueDate':
							value = event._d ? event._d.getTime() : event;
							break;	
						
						default:
							value = target.type === 'checkbox' ? target.checked : target.value;
							break;
				}
				break;
		}
		
		var formData = this.state.formData;
		var stateData = this.state;
		formData[name] = value;
		stateData[name] = value;
		
		this.setState({
		  formData: formData,
		  stateData: stateData,
		});
		
	}
	
	onAddComponent(event) {
		event.preventDefault();
		
		this.setState({
			editOrderComponentRow: null,
			selectedComponentRowIndex: null,
			showNewComponentForm: true,
		});
	}
	
	componentRowSelected(rowIndex) {
		this.setState({
			selectedComponentRowIndex: rowIndex,
		});
	}
	
	editOrderComponent(editRow) {
		this.setState({
			editOrderComponentRow: editRow,
			showNewComponentForm: true,
		});
	}
	
	onRemoveComponent(event) {
		event.preventDefault();
		
		const selectedComponentRowIndex = this.state.selectedComponentRowIndex;
		if(selectedComponentRowIndex != null) {		
			var orderComponents = this.state.orderComponents;
			var deletedOrderComponents = this.state.deletedOrderComponents;
			
			const orderComponent = this.state.orderComponents[selectedComponentRowIndex];
			
			// Samee - Warn the user in case the PO is in submitted or completed state
			if(this.state.initStatusId == submitted_status_id || this.state.initStatusId == completed_status_id) {
				const ans = window.confirm("This is a confirmed PO, you must contact the vendor if deleting this line item");
				if(!ans) return false;
			}
			
			
			if(orderComponent.id > 0)
				deletedOrderComponents.push(orderComponent);
		
			orderComponents.splice(selectedComponentRowIndex, 1);
			this.setState({
				orderComponents: orderComponents,
				deletedOrderComponents: deletedOrderComponents,
			});
		}
	}
	
	saveOrderComponent(orderComponent) {
		var orderComponents = this.state.orderComponents;
		orderComponent.bHasChanged = true;
		if(this.state.selectedComponentRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			orderComponents[this.state.selectedComponentRowIndex] = orderComponent;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			orderComponents.push(orderComponent);
		}
		var totalPoAmt = 0.0;
		for(const i in orderComponents)
			totalPoAmt += orderComponents[i]['totalPrice'];
		
		var formData = this.state.formData;
		formData['totalPoAmt'] = totalPoAmt;
		
		const taxExempt = Config.locale.toLowerCase() != 'es_mx';
		formData = calculateTaxRate(formData, this.state.taxRate, taxExempt, totalPoAmt);
		
		this.setState({
			orderComponents: orderComponents,
			formData: formData,
			showNewComponentForm: false,
		});
	}
	
	onSave() {
		const loggedInUser = getLoggedInUser();
		// PERFORM VALIDATION CHECKS HERE
		let validation = validateForm(this.state.formData, validationFields);

	
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			let formData = this.state.formData;
			
			const locale = Config.locale.toLowerCase().trim();
			const vendorLocale = this.state.vendorData.locale != null 
				? this.state.vendorData.locale.toLowerCase().trim() : '';
			
			
			// Checking and setting status to Completed in case all components are received
			let poCompleted = this.state.orderComponents.length > 0;
			this.state.orderComponents.forEach(component => {
				if(!component.receivedFlag) {
					poCompleted = false;
					return;	
				}
			});
			// console.log("poCompleted: ", poCompleted);
			if(this.state.initStatusId != completed_status_id) {
				// Why is this needed??
				// if(poCompleted)
					// formData.statusId = completed_status_id;
				
				if(formData.statusId == completed_status_id) {
					formData.completedDate = (new Date()).getTime();
				}
				
				
			}
			
			// console.log("this.state.initStatusId: ", this.state.initStatusId, ", formData.statusId: ", formData.statusId);
			
			
			
			
			let totalPoConvertedAmt = this.state.formData.totalPoConvertedAmt;
			// console.log("this.state.initStatusId: ", this.state.initStatusId, ", this.state.formData.statusId: ", this.state.formData.statusId, ", formData.totalPoAmt: ", formData.totalPoAmt);
			if(this.state.initStatusId != completed_status_id 
				&& formData.statusId == completed_status_id) {
				if(locale != vendorLocale) {
					// console.log(", locale: ", locale, ", vendorLocale: ", vendorLocale);
					const currencyConversionUnit = loggedInUser.currencyConversionUnit;
					if(locale == 'en_us' && vendorLocale == 'es_mx') {
						// totalPoConvertedAmt = formData.totalPoAmt / currencyConversionUnit;
						totalPoConvertedAmt = formData.grandTotal / currencyConversionUnit;
					} else if(locale == 'es_mx' && vendorLocale == 'en_us') {
						// totalPoConvertedAmt = formData.totalPoAmt * currencyConversionUnit;
						totalPoConvertedAmt = formData.grandTotal * currencyConversionUnit;
					}
					formData.totalPoConvertedAmt = totalPoConvertedAmt;
				}
			}
			
			let bSendPOEmail = false;
			if(this.state.initStatusId != submitted_status_id) {
				if(formData.statusId == submitted_status_id) {
					formData.submittedDate = (new Date()).getTime();
					if(formData.emailSentDate == null) {
						bSendPOEmail = true;
					}
				}
			}

			
			
			// console.log("totalPoConvertedAmt: ", totalPoConvertedAmt);
			postAPI("/purchaseorders", formData).then(resPO => {
				var purchaseOrderId = resPO['data'][0]['id'];
				formData.id = purchaseOrderId;
				this.reactGA.gaEvent("Purchase Order", "Save", "Purchase Order saved successfully.");
				
				// Saving PO to QBO - when a PO is submitted
				if(this.state.initStatusId != submitted_status_id) {
					if(formData.statusId == submitted_status_id) {
						
						const vendorName = this.state.vendorData.name;
						const qboVendorReferenceNum = this.state.vendorData.qboVendorRefId;
						
						if(qboVendorReferenceNum > 0) {
							/*
							let qboLineItemInfo = [];
							
							this.state.orderComponents.forEach(function(row, i){
								qboLineItemInfo.push({ 
									"Description": row.name, 
									"DetailType": "ItemBasedExpenseLineDetail", 
									"Amount": row.quantity * row.price, 
									"ItemBasedExpenseLineDetail": { 
										"BillableStatus": "NotBillable",
										"Qty": row.quantity, 
										"UnitPrice": row.price,
										"BillableStatus": "NotBillable",
										"ItemRef": {
											"value": row.qboComponentRefId,
											"name": row.name
										},
										"ClassRef": {
											"value": "200000000001496495",
											"name": "USA"
										},
									}
								});
							});
							
							let qboPOInfo = {
								"DocNumber": formData.id,
								"Line": qboLineItemInfo,
								"VendorRef": {
								 "value": this.state.vendorData.qboVendorRefId,
								 "name": this.state.vendorData.name
								},
								"APAccountRef": {
								 "value": "205",
								 "name": "Accounts Payable -Trade USA"
								},
								"TaxCodeRef": {
									"value": "NON",
								},
								"CustomField": [
								],
								"DueDate": convertTimestampToDate(formData.dueDate, false, false, true),
								"TotalAmt": this.state.formData.grandTotal
							};
							
							*/
							let qboPOInfo = this.getQboPOInfo();
							// console.log("PO request: ", qboPOInfo);
							
							quickbooksAPI({
								// endpoint: "/v3/company/realmId/purchaseorder?minorversion=40",
								endpoint: "/v3/company/realmId/purchaseorder?minorversion=65&include=allowduplicatedocnum",
								method: "POST",
								contentType: "application/json",
								data: encodeURIComponent(JSON.stringify(qboPOInfo)),
							}).then(invoice => {
								console.log("created credit memo: ", invoice);
								if(invoice && invoice.error == null) {
									// alert("Purchase Order successfully added to quickbooks sandbox!");
								} else {
									// alert(invoice.error + " - Please copy/paste and send the following msg: " + JSON.stringify(qboPOInfo));
								}
							});
						} else {
							// alert("The vendor '" + vendorName + "' does not exist in QBO or is not linked with MyInkIQ!");
						}
					}
				}
				
				
				var addOrderComponentPromises = [];
				for(const i in this.state.orderComponents) {
					var orderComponent = this.state.orderComponents[i];
					orderComponent['purchaseOrderId'] = purchaseOrderId;
					if(orderComponent.bHasChanged != undefined && orderComponent.bHasChanged)
					{
						delete(orderComponent.bHasChanged);
						addOrderComponentPromises.push(postAPI("/purchaseorders/" + purchaseOrderId + "/components", orderComponent).then(resComponent => {
							if(resComponent.error) {
								alert("Error " + resComponent.error.status + ": " + resComponent.error.error);
							}
						}));
					}
				}
				
				var deleteOrderComponentPromises = [];
				for(const i in this.state.deletedOrderComponents) {
					const orderComponent = this.state.deletedOrderComponents[i];
					deleteOrderComponentPromises.push(postAPI("/purchaseorders/" + purchaseOrderId + "/components/" + orderComponent.id, orderComponent).then(resOrderComponent => {
						if(resOrderComponent.error != null) {
							alert("Error " + resOrderComponent.error.status + ": " + resOrderComponent.error.error);
						}
					}));
					
				}
				
				
				///////////////////////// Sending PO Email //////////////////////////////
				if(bSendPOEmail) {
					//request email template for Quote from REST Service.

					let emailParams = "name=Purchase Order&locale=" + 
						(Config.locale.toLowerCase() == 'es_mx' ? 'es_us' : 'en_us'); 
						// (loggedInUser.spanishFlag ? "es_us":"en_us");
	
					// console.log("emailParams: ", emailParams, "formData.emailSentDate: ", formData.emailSentDate);

					getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
						console.log("resEmailTemplate: ", resEmailTemplate);
						if(!resEmailTemplate.success) {
							alert("Error fetching email template.");
						} else {
							const emailTemplate = resEmailTemplate['data'][0];
			
							let logoLocation = Config.baseURL + "images/companies/" 
							+ this.state.companyInfo.id;
							logoLocation = this.state.companyInfo.logoLocation;
			
							let parms = "&purchaseOrder=" + formData.id
							+ "&company=" + this.state.companyInfo.name + "&env=" 
							+ Config.env + "&logo=" + logoLocation;
			
							let body = emailTemplate.body + "<BR/><BR/>" 
							+ emailTemplate.signature + "<BR/><BR/>" 
							+ (emailTemplate.privacyDisclosure != null 
							 ? emailTemplate.privacyDisclosure : "");
			
							// console.log("parms: ", parms, "this.state.companyInfo: ", this.state.companyInfo, ", body: ", body);
			
			
							let queryString = "parms=" + encodeURIComponent(parms) 
							+ "&title=Purchase Order&body=" + encodeURIComponent(body) 
							+ "&name=" + this.state.companyInfo.name 
							+ "&reportName=BCM_Purchase_Order.rptdesign&vid=" 
							+ this.state.formData.vendorId
							+ "&purchaseOrder=" + formData.id;
			 
							getAPI("emails/" + emailTemplate.emailTemplateId 
								+ "/buildAndSendPO?" + queryString ).then(resEmailSent => {
								// console.log("resEmailSent: ", resEmailSent);
								if(!resEmailSent.success) {
									alert("Error sending email");
								}
								else {
									formData.emailSentDate = (new Date()).getTime();
								}
							});
						}

					});
				}
				
				
				
				
				Promise.all(addOrderComponentPromises)
				.then(() => {
					Promise.all(deleteOrderComponentPromises)
					.then(() => {
						// alert(<Translate value="components.labels.poSaved" />);
						alert("Purchase Order has been saved successfully");
						this.props.onSave();
					});
				});
			});
		}
	}
	
	onDelete() {
		postAPI("purchaseorders/" + this.state.formData.id, this.state.formData).then(resOrder => {
			console.log("resOrder: ", resOrder);
			if(resOrder['error'] != undefined) {
				alert("Error deleting PO: " + resOrder['error']['error']);
			} else {
				// alert(<Translate value="components.labels.poDeleted" />);
				alert("Purchase Order has been deleted successfully");
				this.reactGA.gaEvent("Purchase Order", "Delete", "Purchase Order deleted successfully.");
			}
			this.props.onSave();
		});
	}

	render() {
		const loggedInUser = getLoggedInUser();
		
		var statusesMapped = mapArrayToObject(this.state.poStatuses, 'id', 'value');
		
		const dueDate = isNaN(this.state.formData.dueDate) || this.state.formData.dueDate == 0 ? this.state.formData.dueDate : 
		convertTimestampToDate(this.state.formData.dueDate);
		
		const orderDate = isNaN(this.state.formData.orderDate) || this.state.formData.orderDate == 0 ? this.state.formData.orderDate : 
		convertTimestampToDate(this.state.formData.orderDate);

		//const newDeliveryDateFormatted = isNaN(this.state.formData.lateLostDamagedDeliveryDate) || this.state.formData.lateLostDamagedDeliveryDate == 0  ? this.state.formData.lateLostDamagedDeliveryDate: 
		//convertTimestampToDate(this.state.formData.lateLostDamagedDeliveryDate);

		//const bReasonDamagedFlag = this.state.formData.lateLostDamagedReason == "damage";
		//const bReasonLostFlag = this.state.formData.lateLostDamagedReason == "lost";
		//const bReasonLateFlag = this.state.formData.lateLostDamagedReason == "late";
		
		const receivedDate = isNaN(this.state.formData.receivedDate) || this.state.formData.receivedDate == 0 ? this.state.formData.receivedDate : 
		convertTimestampToDate(this.state.formData.receivedDate);

		const vendorsMapped = this.state.vendorsList.map((obj, key) => ({value: obj.id, label: obj.name}));
		const vendorName = this.state.formData.vendorId ? 
			vendorsMapped.filter(obj => obj.value == this.state.formData.vendorId)[0] : null;
			
		const selectedVendorId = this.state.formData.vendorId;
		// console.log("this.state.vendorData: ", vendorLocale);
		const vendorLocale = this.state.vendorData.locale 
				? this.state.vendorData.locale.toLowerCase().trim() : null;
				
		const filteredComponents = this.state.componentsList.filter(obj => obj.vendorId == selectedVendorId || obj.backupVendorId == selectedVendorId);
		
		var componentsColumns = [<Translate value="components.labels.quantity" />, 
			<Translate value="components.labels.component" />, 
			<Translate value="components.labels.note" />, 
			<Translate value="components.labels.container" />, 
			<Translate value="components.labels.weight" />, 
			<Translate value={"components.labels." + Config.weightSymbol + "sOrdered"} />, 
			<Translate value="components.labels.unitPrice" />, 
			<Translate value="components.labels.totalPrice" />, 
			<Translate value="components.labels.received" />, 
			<Translate value="components.labels.receivedDate"/>,
			];
		var componentsFields = ['quantity', 'name', 'componentNote', 'containerName', 'containerWeight', 'umOrdered', 'price', 'totalPrice', null,
		'receivedDate'];
		
		var formatColumns = {
			6: {currency: vendorLocale},
			7: {currency: vendorLocale},
			8: {
					checkbox: {}, 
					colCheckBound: 'receivedFlag', 
					colDisabledBound: 'receivedFlag', 
					// disabled: this.state.formData.statusId != submitted_status_id, // Submitted
					callbackCheckbox: this.chkCompletedChecked
				},
			9: {
                   date:true
			   },	
		};
		
		const editOrderComponentRow = this.state.editOrderComponentRow;
		const orderComponentForm = this.state.showNewComponentForm ? 
		(<NewPurchaseOrderComponentForm
			visible={this.state.showNewComponentForm}
			components={filteredComponents}
			onClose={() => {
				this.setState({showNewComponentForm: false});
			}}
			onSave={this.saveOrderComponent}
			editOrderComponentRow={editOrderComponentRow} 
			containersList={this.state.containersList}
			vendorLocale={vendorLocale}
			vendorId={this.state.formData.vendorId} 
			initStatusId={this.state.initStatusId}
			/>) 
			: '';

		

		var content = <Form className='form-page' horizontal>
			<Row>
				<Col md={4}>
					<InputText label={<Translate value="components.labels.vendor" />} required 
						id={"vendorId"} name={"vendorId"} action={this.handleInputChanged} 
						type={"SingleSelect"} data={vendorsMapped} value={vendorName}
						errMsg={this.state.errors.vendorId}
					/>
					<DataDropdown label={<Translate value="components.labels.status" />} required 
						id={'statusId'} name={'statusId'} data={statusesMapped} 
						action={this.handleInputChanged} value={this.state.formData.statusId} 
						errMsg={this.state.errors.statusId}
					/>
					<InputText label={<Translate value="components.labels.dueDate" />} 
						type={"DateTime"} id={'dueDate'} name={'dueDate'} 
						action={this.handleInputChanged} value={dueDate} 
						errMsg={this.state.errors.dueDate}
					/>
					<InputText label={<Translate value="components.labels.orderDate" />} 
						type={"DateTime"} required id={'orderDate'} name={'orderDate'} 
						action={this.handleInputChanged} value={orderDate} 
						errMsg={this.state.errors.orderDate}
					/>
					

				</Col>
				{true ? <div>
					<Col md={4}>
						<InputText type='Label' 
							value={this.state.vendorData.name ? this.state.vendorData.name : ''} 
							label={<Translate value='components.labels.contact' />} 
						/>
						<InputText type='Label' 
							value={this.state.vendorData.contactEmail ? 
								this.state.vendorData.contactEmail : ''} 
							label={<Translate value='components.labels.email' />} 
						/>
						<InputText type='Label' 
							value={this.state.vendorData.phoneNumber ? 
								this.state.vendorData.phoneNumber : ''} 
							label={<Translate value='components.customers.phone' />} 
						/>
						<InputText type='Label' 
							value={this.state.vendorData.faxNumber ? 
								this.state.vendorData.faxNumber : ''} 
							label={<Translate value='components.customers.fax' />} 
						/>
					</Col>
					<Col md={4}>
						<InputText type='Label' 
							value={
								(this.state.vendorData.address1 ? 
								this.state.vendorData.address1 : '')
								+ (this.state.vendorData.address2 ? 
								', ' + this.state.vendorData.address2 : '')
								+ (this.state.vendorData.city ? 
								' ' + this.state.vendorData.city : '')
								+ (this.state.vendorData.stateName ? 
								', ' + this.state.vendorData.stateName : '')
								+ (this.state.vendorData.zipCode ? 
								' ' + this.state.vendorData.zipCode : '')
								} 
							label={<Translate value='components.labels.address' />} 
						/>
						<DataDropdown label={<Translate value="components.labels.Shipping Address" />}  
							id={'shippingAddressId'} name={'shippingAddressId'} data={{1: "Existing Address", 2: "BCM Inks Loveland, 161 Commerce Drive, Loveland, OH 45140"}} 
							action={this.handleInputChanged} value={this.state.formData.shippingAddressId} 
							errMsg={this.state.errors.shippingAddressId}
						/>
					</Col>
				</div> : ''}
			</Row>
			<Row>
				<Col md={12}>
					<h4><Translate value="components.labels.components" /></h4>
					<DataGrid columns={componentsColumns} fields={componentsFields} 
						onDoubleClick={this.editOrderComponent} 
						formatColumns={formatColumns}
						onSelect={this.componentRowSelected} data={this.state.orderComponents} 
					/>
					<Button onClick={this.onAddComponent} 
						disabled={loggedInUser.accessPOCode == 'V'}>
						<Translate value="components.buttons.add" />
					</Button>&nbsp;
					<Button 
						onClick={this.onRemoveComponent} 
						disabled={loggedInUser.accessPOCode == 'V'}>
						<Translate value="components.buttons.delete" />
					</Button>
					{orderComponentForm}
				</Col>
			</Row>
			<Row>
				<Col md={4}>
					<InputText type={"TextArea"} 
						label={<Translate value="components.labels.notes" />} id={"notes"} 
						name={"notes"} action={this.handleInputChanged} 
						value={this.state.formData.notes}>
					</InputText>
				</Col>
				<Col md={4}>
					<InputText type={"Label"} 
						label={<Translate value="components.labels.total" />}
						value={toCurrency(this.state.formData.totalPoAmt, vendorLocale)}>
					</InputText>
					{this.state.formData.taxAmount && <span>
						<InputText type='Label' 
							label={<Translate value="components.labels.iva" />} 
							value={toCurrency(this.state.formData.taxAmount, vendorLocale)} />
						<InputText type='Label' 
							label={<Translate value="components.labels.grandTotal" />} 
							value={toCurrency(this.state.formData.grandTotal, vendorLocale)} />
					</span>}
					{this.state.formData.totalPoConvertedAmt != null && 
						<InputText type={"Label"} 
							label={<Translate value="components.labels.amountPaid" />}
							value={toCurrency(this.state.formData.totalPoConvertedAmt)}>
						</InputText>
					}
				</Col>
				
			</Row>
		</Form>

		return(<div>
			<Modal show={this.props.visible} bsSize="large" >
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{content}
					<PurchaseOrderFormDeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
					/>
				</Modal.Body>
				<Modal.Footer>
					{this.props.editPurchaseOrderId ? <Button
						onClick={() => this.setState({showDeleteConfirmation: true})}
						bsStyle='danger'
						disabled={loggedInUser.accessPOCode == 'V'}
					>
						<Translate value='components.buttons.deleteOrder' />
					</Button> : ''}
					&nbsp;
					{this.props.editPurchaseOrderId && 
					loggedInUser.userReports[constants.purchase_order_report_id] && 
					<Button 
						onClick={() => openBirtReport('BCM_Purchase_Order', {
								purchaseOrder: this.state.formData.id,
							}
						)}
						bsStyle={Config.reportBtnStyle}
					>
						<Translate value="components.reports.purchaseOrder" />
					</Button>
					}
					&nbsp;
					<Button onClick={() => {
						this.props.onClose();
						console.log("QboPOInfo: ", this.getQboPOInfo());
					}}><Translate value="components.buttons.close" /></Button>
					{(loggedInUser.userName == 'mmurphy' || loggedInUser.userName == 'rburton') && 
					<Button onClick={() => {
						var qboEntityType = 'PurchaseOrder';
						let qboPOInfo = this.getQboPOInfo();
						quickbooksAPI({
							endpoint: "/v3/company/realmId/query?query=",
							query: "select * from " + qboEntityType + " where DocNumber='" + this.state.formData.id + "'",
						}).then(invoices => {
							// console.log("invoices: ", invoices);
							if(invoices['QueryResponse']['PurchaseOrder'] != null && invoices['QueryResponse']['PurchaseOrder'].length > 0) {
								alert('PO already exists in QBO');
							} else {
								quickbooksAPI({
									endpoint: "/v3/company/realmId/purchaseorder?minorversion=65&include=allowduplicatedocnum",
									method: "POST",
									contentType: "application/json",
									data: encodeURIComponent(JSON.stringify(qboPOInfo)),
								}).then(invoice => {
									// console.log("created invoice: ", invoice);
									if(invoice == null) {
										alert('PO could not be transferred successfully!');
									}
								});
							}

						});
						// console.log("getQboPOInfo: ", this.getQboPOInfo());
					}}>
						<Translate value="components.buttons.Transfer to QBO" />
					</Button>
				}
					<Button 
						onClick={this.onSave} bsStyle="primary" 
						disabled={loggedInUser.accessPOCode == 'V'}
					><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal>
			
		</div>);
	}
}

export default NewPurchaseOrderForm;

class NewPurchaseOrderComponentForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "New Component",
			errors: resetObject(validationFieldsContainer),
			formData: {
				componentId:null,
				componentNote:"",
				containerId:null,
				containerWeight:0,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::PurchaseOrderComponentsDto",
				name:"",
				price:0.0,
				totalPrice: 0.0,
				purchaseOrderId:0,
				quantity:0,
				receivedFlag:false,
				receivedDate:"",
				comments:"",
				umOrdered:0,
			},
			vendorContainerPricing: [],
			containersList: [],
			poInstallmentsReceived: [],
			showNewPOInstallmentForm: false,
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onSaveInstallment = this.onSaveInstallment.bind(this);
		this.getVendorContainerPricing = this.getVendorContainerPricing.bind(this);
	}
	
	async getVendorContainerPricing(componentId, poComponentId) {
		await getAPI("components/" + componentId + "/vendors/containers").then(resVendorContainerPricing => {
			getAPI("purchaseorders/component/" + poComponentId + "/installments").then(resPOInstallments => {
				this.setState({
					vendorContainerPricing: resVendorContainerPricing['data'],
					poInstallmentsReceived: resPOInstallments['data'],
				});
			});
		});
	}

	async componentDidMount() {
		if(this.props.editOrderComponentRow) { // If this is not null
			await this.getVendorContainerPricing(this.props.editOrderComponentRow.componentId, this.props.editOrderComponentRow.id);
			this.setState({
				formData: this.props.editOrderComponentRow,
				title: "Component: " + this.props.editOrderComponentRow['name'],
			});
		}
	}
	
	async handleInputChanged(event, name) {
		const target = event.target;
		var value = '';
		switch(name) 
		{
		
			case 'componentId':
				var formData = this.state.formData;
				value = event.value;
				var componentRow = this.props.components.filter(obj => obj.id == value)[0];
				var poComponentId = this.props.editOrderComponentRow != null ? this.props.editOrderComponentRow.id : 0;
				
				await this.getVendorContainerPricing(value, poComponentId);
				let unitPrice = componentRow['costPerUm'];
				const vendorId = this.props.vendorId;
				
				
				if(formData['containerId'] > 0)
				{
					var containerRow = this.props.containersList.filter(obj => obj.id == formData['containerId'])[0];
					formData['containerWeight'] = containerRow ? containerRow['weight'] : 0;
				
					const priceRow = this.state.vendorContainerPricing.filter(obj => obj.vendorId == vendorId 
						&& obj.containerId == formData['containerId'])[0];

					if(priceRow != undefined) {
						unitPrice = priceRow['price'];
						if(!isNaN(priceRow['weight']))
							formData['containerWeight'] = priceRow['weight'];
					}
				}
				
				
				formData['componentId'] = value;
				formData['price'] = unitPrice;
				formData['umOrdered'] = Math.floor(formData.containerWeight * formData.quantity);
				formData['totalPrice'] = formData.umOrdered * formData.price;
				//Check for the backup name
				if ((componentRow['backupVendorComponentName'] != null || componentRow['backupVendorComponentName'] != "") && componentRow["backupVendorId"] === vendorId) {
					formData['name'] = componentRow['backupVendorComponentName'];
				}
				else {
					formData['name'] = componentRow['name'];
				}
				
				if(componentRow['component'])
					formData['name'] += " (" + componentRow['component'] + ")";
				

				this.setState({
					formData: formData
				});
				break;

			case 'receivedDate':
				var formData = this.state.formData;
				value = event._d ? event._d.getTime() : event;
				formData['receivedDate'] = value;
				this.setState({
					formData: formData
				});
				break;	
				
			default:
				value = target.type === 'checkbox' ? target.checked : target.value;
				switch(name) {
					case 'quantity':
						var formData = this.state.formData;
						formData['quantity'] = value;
						formData['umOrdered'] = Math.floor(value * formData.containerWeight);
						formData['totalPrice'] = formData.umOrdered * formData.price;
						this.setState({
							formData: formData
						});
						break;
					
					case 'containerId':
						var formData = this.state.formData;
						formData['containerId'] = value;
						
						let unitPrice = formData.price;
						if(formData.componentId > 0) {
							var componentRow = this.props.components.filter(obj => obj.id == formData.componentId)[0];
							if(componentRow != undefined)
								unitPrice = componentRow['costPerUm'];
						}
						const vendorId = this.props.vendorId;
						
						var containerRow = this.props.containersList.filter(obj => obj.id == value)[0];
						formData['containerWeight'] = containerRow ? containerRow['weight'] : 0;
						if(formData['containerId'] > 0)
						{
							const priceRow = this.state.vendorContainerPricing.filter(obj => obj.vendorId == vendorId 
								&& obj.containerId == formData['containerId'])[0];

							if(priceRow != undefined) {
								unitPrice = priceRow['price'];
								// Fetch weight and calculate ordered
								if(!isNaN(priceRow['weight']))
									formData['containerWeight'] = priceRow['weight'];
								// formData['umOrdered'] = priceRow['weight'] * (isNaN(formData['quantity']) ? 0 : formData['quantity']);
							}
						}
						
						formData['umOrdered'] = Math.floor(formData.containerWeight * formData.quantity);
						formData['price'] = unitPrice;
						formData['totalPrice'] = formData.umOrdered * unitPrice;
						formData['containerName'] = containerRow ? containerRow['name'] : "";
						this.setState({
							formData: formData
						});
						break;
					
					case 'containerWeight':
						var formData = this.state.formData;
						formData['containerWeight'] = value;
						formData['umOrdered'] = Math.floor(value * formData.quantity);
						formData['totalPrice'] = formData.umOrdered * formData.price;
						this.setState({
							formData: formData
						});
						break;
						
					case 'umOrdered':
						var formData = this.state.formData;
						formData['umOrdered'] = value;
						formData['totalPrice'] = formData.umOrdered * formData.price;
						this.setState({
							formData: formData
						});
						break;	
					default:
						break;
				}
				break;
		}
		
		var formData = this.state.formData;
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
		
	}
	
	onSave(event) {
		event.preventDefault();
		
		// PERFORM VALIDATION CHECKS HERE	
		let validation = validateForm(this.state.formData, validationFieldsContainer);

		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			var orderComponent = this.state.formData;
			this.props.onSave(orderComponent);
		}
	}
	
	onSaveInstallment(poInstallment) {
		console.log("poInstallment: ", poInstallment);
		let totalAmountReceived = 0;
		this.state.poInstallmentsReceived.forEach(row => {
			totalAmountReceived += row.umReceived;
		});
		const umReceived = isNaN(poInstallment.umReceived) ? 0 : parseInt(poInstallment.umReceived);
		// console.log("totalAmountReceived: ", totalAmountReceived, ", this.state.formData.umOrdered: ", this.state.formData.umOrdered);
		if(totalAmountReceived + umReceived > this.state.formData.umOrdered) {
			alert("Total Received amount cannot exceed " + this.state.formData.umOrdered + "!");
			return false;
		}
			
		const poComponentId = poInstallment.purchaseOrderComponentId;
		postAPI("/purchaseorders/component/" + poComponentId + "/installments", poInstallment).then(resPOInstallment => {
			if(resPOInstallment.error) {
				alert("Error saving PO installment - " + resPOInstallment.error.status + ": " + resPOInstallment.error.error);
			}
			else {
				alert("PO Installment saved successfully");
				getAPI("purchaseorders/component/" + poComponentId + "/installments").then(resPOInstallments => {
					this.setState({
						poInstallmentsReceived: resPOInstallments['data'],
						showNewPOInstallmentForm: false,
					});
				});
			}
		});
	}
	
	render() {
		// console.log("props: ", this.props);
		const loggedInUser = getLoggedInUser();
		const containersMapped = mapArrayToObject(this.props.containersList, 'id', 'name');
		// const filteredComponents = this.props.components.map((obj, key) => ({id: obj.id, label: obj.name}));
		const selectedVendorId = this.props.vendorId;
		const filteredComponents = this.props.components.map((obj, key) => {
			// {value: obj.id, label: obj.name + (obj.component ? " (" + obj.component + ")" : "")}
			let value = obj.id;
			let label = obj.backupVendorId == selectedVendorId && obj.backupVendorComponentName ? obj.backupVendorComponentName : obj.name;
			if(obj.component)
				label += " (" + obj.component + ")";
			return {value: value, label: label};
		});
		const componentName = this.state.formData.componentId ? 
			filteredComponents.filter(obj => obj.value == this.state.formData.componentId)[0] : null;
		const vendorLocale = this.props.vendorLocale;
		
		const poInstallmentFields = ['umReceived', 'receivedDate'];
		const poInstallmentColumns = ['Quantity', 'Date'];
		const formatColumns = {
			1: {
                date:true
			},	
		};
		
		const poInstallmentReceivedForm = this.state.showNewPOInstallmentForm ? 
		(<NewPurchaseOrderInstallmentsReceived
			visible={this.state.showNewPOInstallmentForm}
			onClose={() => this.setState({showNewPOInstallmentForm: false})}
			onSaveInstallment={this.onSaveInstallment}
			editOrderComponentRow={this.state.formData} 
			/>) 
			: '';
		
		var content = <Form className='form-page' horizontal>
			<InputText label={<Translate value="components.labels.quantity" />} required 
				id={'quantity'} name={'quantity'} action={this.handleInputChanged} 
				value={this.state.formData.quantity} 
				errMsg={this.state.errors.quantity}
				disabled={this.state.poInstallmentsReceived.length > 0 || this.state.formData.receivedFlag}
			/>
			<InputText label={<Translate value="components.labels.component" />} required 
				id={'componentId'} name={'componentId'} action={this.handleInputChanged} 
				type={"SingleSelect"} data={filteredComponents} value={componentName} 
				errMsg={this.state.errors.componentId}
				disabled={this.state.poInstallmentsReceived.length > 0 || this.state.formData.receivedFlag}
			/>
			<DataDropdown label={<Translate value="components.labels.container" />} required 
				id={'containerId'} name={'containerId'} action={this.handleInputChanged} 
				data={containersMapped} value={this.state.formData.containerId} 
				errMsg={this.state.errors.containerId}
				disabled={this.state.poInstallmentsReceived.length > 0 || this.state.formData.receivedFlag}
			/>
			<InputText label={<Translate value="components.labels.weight" />} required 
				id={'containerWeight'} name={'containerWeight'} action={this.handleInputChanged} 
				value={this.state.formData.containerWeight} 
				errMsg={this.state.errors.containerWeight}
				disabled={this.state.poInstallmentsReceived.length > 0 || this.state.formData.receivedFlag}
			/>
			<InputText label={<Translate value={"components.labels." + Config.weightSymbol + "sOrdered"} />} required 
				id={'umOrdered'} name={'umOrdered'} action={this.handleInputChanged} 
				value={this.state.formData.umOrdered} 
				errMsg={this.state.errors.umOrdered}
				disabled={this.state.poInstallmentsReceived.length > 0 || this.state.formData.receivedFlag}
			/>
			<InputText type={"Label"} 
				label={<Translate value="components.labels.unitPrice" />} required 
				id={'price'} name={'price'} 
				value={toCurrency(this.state.formData.price, vendorLocale)}
			/>

			<InputText type={"Label"} 
				label={<Translate value="components.labels.totalPrice" />} required 
				id={'totalPrice'} name={'totalPrice'} 
				value={toCurrency(this.state.formData.totalPrice, vendorLocale)}
			/>
			<InputText type={"TextArea"} label={<Translate value="components.labels.note" />} 
				id={"componentNote"} name={"componentNote"} 
				action={this.handleInputChanged} 
				value={this.state.formData.componentNote}>
			</InputText>
			<InputText label={<Translate value="components.labels.received" />} type={'CheckBox'} 
				id={'receivedFlag'} name={'receivedFlag'} 
				action={this.handleInputChanged} 
				value={this.state.formData.receivedFlag} 
			/>
			<InputText label={<Translate value="components.labels.receivedDate" />} 
						type={"DateTime"} required id={'receivedDate'} name={'receivedDate'} 
						action={this.handleInputChanged} value={this.state.formData.receivedDate} 
						
			/>
			<InputText type={"TextArea"} label={<Translate value="components.labels.comments" />} 
				id={"comments"} name={"comments"} 
				action={this.handleInputChanged} 
				value={this.state.formData.comments}>
			</InputText>
			{this.props.editOrderComponentRow != null && 
			this.props.initStatusId != null && 
			this.props.initStatusId != 146 && 
			<span>
				<DataGrid columns={poInstallmentColumns} 
					fields={poInstallmentFields} 
					formatColumns={formatColumns}
					onSelect={this.componentRowSelected} 
					data={this.state.poInstallmentsReceived} 
				/>
				<Button onClick={() => {this.setState({showNewPOInstallmentForm: true})}}> 
					<Translate value="components.buttons.add" />
				</Button>&nbsp;
			</span>
			}
			{poInstallmentReceivedForm}
		</Form>

		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
			</Modal.Body>
			<Modal.Footer>
				<Button 
					onClick={this.props.onClose}
					 disabled={loggedInUser.accessPOCode == 'V'}>
					<Translate value="components.buttons.close" />
				</Button>
				<Button 
					onClick={this.onSave} bsStyle="primary"
					disabled={loggedInUser.accessPOCode == 'V'}
					>
					<Translate value="components.buttons.saveChanges" />
				</Button>
			</Modal.Footer>
		</Modal>);
	}
}

class NewPurchaseOrderInstallmentsReceived extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "New Installment Received",
			errors: resetObject(validationFieldsPOInstallment),
			formData: {
				purchaseOrderComponentId: (this.props.editOrderComponentRow != null ? this.props.editOrderComponentRow.id : null),
				receivedDate:null,
				umReceived:0,
				comments:"",
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::PurchaseOrderInstallmentsReceivedDto",
				
			},
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	

	async componentDidMount() {
		if(this.props.editPOInstallmentReceived) { // If this is not null
			this.setState({
				formData: this.props.editPOInstallmentReceived,
			});
		}
	}
	
	async handleInputChanged(event, name) {
		const target = event.target;
		var value = '';
		
		switch(name) 
		{

			case 'receivedDate':
				var formData = this.state.formData;
				value = event._d ? event._d.getTime() : event;
				formData['receivedDate'] = value;
				this.setState({
					formData: formData
				});
				break;
			
			default:
				value = target.value;
		}
		
		var formData = this.state.formData;
		// console.log("name: ", name, ", value: ", value);
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
		
	}
	
	onSave(event) {
		event.preventDefault();
		
		// PERFORM VALIDATION CHECKS HERE	
		let validation = validateForm(this.state.formData, validationFieldsPOInstallment);

		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			var poInstallmentReceived = this.state.formData;
			this.props.onClose();
			this.props.onSaveInstallment(poInstallmentReceived);
		}
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const vendorLocale = this.props.vendorLocale;
			
		var content = <Form className='form-page' horizontal>
			<InputText label={<Translate value={"components.labels." + Config.weightSymbol + "sReceived"} />} required 
				id={'umReceived'} name={'umReceived'} action={this.handleInputChanged} 
				value={this.state.formData.umReceived} 
				errMsg={this.state.errors.umReceived}
			/>
			<InputText label={<Translate value="components.labels.receivedDate" />} 
						type={"DateTime"} required id={'receivedDate'} name={'receivedDate'} 
						action={this.handleInputChanged} value={this.state.formData.receivedDate} 
						
			/>
			<InputText type={"TextArea"} label={<Translate value="components.labels.comments" />} 
				id={"comments"} name={"comments"} 
				action={this.handleInputChanged} 
				value={this.state.formData.comments}>
			</InputText>
		</Form>

		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
			</Modal.Body>
			<Modal.Footer>
				<Button 
					onClick={this.props.onClose}
					 disabled={loggedInUser.accessPOCode == 'V'}>
					<Translate value="components.buttons.close" />
				</Button>
				<Button 
					onClick={this.onSave} bsStyle="primary"
					disabled={loggedInUser.accessPOCode == 'V'}
					>
					<Translate value="components.buttons.saveChanges" />
				</Button>
			</Modal.Footer>
		</Modal>);
	}
}