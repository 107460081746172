import React, { Component } from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataDropdown from '../common_components/DataDropdown';
import {mapArrayToObject, getAPI, postAPI, validateForm, getLoggedInUser, getCurrencySymbol, showFormValidationAlert} from '../common_components/common.js';
import Config from '../Config';
import '../FormPage.css'
const { Translate } = require('react-i18nify');

const validationFields = {
	realmId: "required",
	scope: "required",
	authCode: "required",
	accessToken: "required",
	refreshToken: "required",
};

export default class QBOConfig extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			errors: {},
			formData: {
				id: 0,
				appId: '',
				clientId: '',
				clientSecret: '',
				refreshTokenURI: '',
				redirectURI: '',
				baseURI: '',
				realmId: '',
				authCode: '',
				refreshToken: '',
				code: '',
				scope: '',
				intuitApiKey: '',
				accessToken: '',
				accessTokenExpireTime: '',
				refreshTokenExpireTime: '',
				mode: true,				
			},
			
			countriesList: [],
			statesList: [],
			observerAnglesList: [],
			illuminantsList: [],
			deltaETypesList: [],
			devicesList: [],

		};
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		let formData = this.state.formData;
		getAPI("/quickbooks?mode=1").then(resCompany => {
			let formData = resCompany['data'][0];
			this.setState({
				formData: formData,
			});
		});
	}
	
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	onSave() {
		const validation = validateForm(this.state.formData, validationFields);
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			// IF FORM IS VALIDATED PERFORM SAVE OPERATION HERE
			postAPI("quickbooks", this.state.formData).then(resCompanies => {
				alert("QBO Config Saved successfully.");
			});
		}
	}

	render() {
		
		const loggedInUser = getLoggedInUser();
		
		return(
			<Form className='form-page' horizontal style={{maxHeight: 'none'}}>
				<Row>
					<Col md={12}>
						<h4><Translate value='components.settings.QBO Config' /></h4>
						<InputText required={validationFields.realmId == 'required'}
							label={<Translate value='components.settings.Realm Id' />} 
							name={'realmId'} value={this.state.formData.realmId} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.realmId 
								? this.state.errors.realmId : ""}
						/>
						<InputText required={validationFields.scope == 'required'}
							label={<Translate value='components.settings.Scope' />} 
							name={'scope'} value={this.state.formData.scope} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.scope 
								? this.state.errors.scope : ""}
						/>
						<InputText required={validationFields.authCode == 'required'}
							label={<Translate value='components.settings.Auth Code' />} 
							name={'authCode'} value={this.state.formData.authCode} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.authCode 
								? this.state.errors.authCode : ""}
						/>
						<InputText required={validationFields.accessToken == 'required'}
							label={<Translate value='components.settings.Access Token' />} 
							name={'accessToken'} value={this.state.formData.accessToken} 
							action={this.handleInputChanged} 
							type='TextArea'
							errMsg={this.state.errors.accessToken 
								? this.state.errors.accessToken : ""}
						/>
						<InputText required={validationFields.refreshToken == 'required'}
							label={<Translate value='components.settings.Refresh Token' />} 
							name={'refreshToken'} value={this.state.formData.refreshToken} 
							action={this.handleInputChanged} 
							errMsg={this.state.errors.refreshToken 
								? this.state.errors.refreshToken : ""}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<Button bsSize='sm'><Translate value='components.settings.cancel' /></Button>
						&nbsp;
						<Button bsSize='sm' bsStyle="primary" onClick={this.onSave}>
							<Translate value='components.settings.save' />
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}
