import React, { Component } from 'react';
import './Spinner.css'

// adapted from https://loading.io/css/
export default class Spinner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hue: Math.random() * 360,
		};
	}

	render() {
		return(
			<div className='data-grid-container'>
				<div class="lds-ellipsis">
					<div style={{background: 'hsla(' + this.state.hue + ',80%,67%,1)'}}></div>
					<div style={{background: 'hsla(' + this.state.hue + ',80%,67%,1)'}}></div>
					<div style={{background: 'hsla(' + this.state.hue + ',80%,67%,1)'}}></div>
					<div style={{background: 'hsla(' + this.state.hue + ',80%,67%,1)'}}></div>
				</div>
			</div>
		);
	}
}
