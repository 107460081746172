import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

class PopupMenu extends React.Component {
	render() {
		return(
			<DropdownButton
				title={this.props.link}
				bsStyle='primary'
				bsSize='sm'
				pullRight
			>
				{this.props.options.map(option => <MenuItem
					key={option}
					onClick={() => option.onClick()}
				>
					{option.label}
				</MenuItem>)}
			</DropdownButton>
		)
	}
}

export default PopupMenu;
