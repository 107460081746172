export default {
	"nav":{
		"dashboard": "Tablero",
		"orders": "Pedidos",
		"components": "Componentes",
		"formulas": "Fórmulas",
		"po": "Ordenes de compra",
		"customers": "Clientes",
		"inventory": "Inventario",
		"settings": "Ajustes",
		"reports": "Informes",
		"tasks": "Tasks",
		"CRMReports": "Informes de CRM",
	}
}
