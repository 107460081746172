export default {
	"tabs":{
		"orders": "Pedidos",
		"batches": "Lotes",
		"labRequests": "Peticiones de laboratorio",
		"matches": "Partidos",
		"actionableItems": "Artículos accionables",
		"goals": "Metas",
		"approvedProcessNotes": "Notas de proceso aprobadas",
		"toBePriced": "A un precio",
		"emailsToBeSent": "Correos electrónicos para enviar",
		"customers" : "Clientes",
		"formulas": "Fórmulas",
		"quoted": "Citado",
		"tasks": "Tareas",
		"callReports": "Informes de llamadas",
		"salesSection": "Sección de ventas",
		"expenditures": "Gastos",
		"prospectCalls": "Llamadas", // "Llamadas de posibles clientes",
		"substrates": "Sustratos",
		"viscosityCups": "Copas de viscosidad",
	}
}
