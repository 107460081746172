import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid.js';
import NewShipperForm from './NewShipperForm';
import {getAPI, convertTimestampToDate, getLoggedInUser} from '../common_components/common.js';
import Config from '../Config';
const { Translate } = require('react-i18nify');

export default class Shippers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiUrl: Config.baseURL + 'shippers/list',
			columns: [
				<Translate value='components.settings.company' />,
				<Translate value='components.settings.contact' />,
				<Translate value='components.settings.location' />,
				<Translate value='components.settings.phone' />,
				<Translate value='components.settings.status' />,
				<Translate value='components.settings.lastUpdated' />,
			],
			fields: ['name', 'contactName', 'location', 'phoneNumber', 'retired', 'lastUpdatedDate'],
			formatColumns: {5: {'date': true}},
			data: [],
			offset: 0,
			limit: 2500,
			showNewShipperForm: false,
			editShipperId: null,
			loading: true,
			autoRefresh: true,
			interval: null,
		};
		this.showEditShipperForm = this.showEditShipperForm.bind(this);
	}

	refreshData() {
		this.setState({loading: true});
		getAPI("shippers/list").then(resShippers => {
			this.setState({
				data: resShippers['data'],
				loading: false,
			});
		});	
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showNewShipperForm) {
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	showEditShipperForm(dataIn) {
		this.setState({
			editShipperId: dataIn['id'],
			showNewShipperForm: true,
		});
	}

	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.state.data;
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return this.state.formatColumns[i] && this.state.formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		const editShipperForm = this.state.showNewShipperForm ? <NewShipperForm
					visible={this.state.showNewShipperForm}
					onClose={() => this.setState({showNewShipperForm: false})}
					onSave={() => {
						this.setState({showNewShipperForm: false});
						this.refreshData();
					}}
					editShipperId={this.state.editShipperId}
				/> : "";
		return (
			<div>
				<DataGrid
					columns={this.state.columns}
					fields={this.state.fields}
					data={data}
					formatColumns={this.state.formatColumns}
					onDoubleClick={this.showEditShipperForm}
					loading={this.state.loading}
					heightOffset={190}
				/>
				<Button bsSize='sm' onClick={() => this.setState({showNewShipperForm: true, editShipperId: null})} disabled={loggedInUser.accessShippersCode == 'V'}>
					<Translate value='components.settings.newShipper' />
				</Button>
				{editShipperForm}
			</div>
		);
	}
}
