export default {
	"tooltips": {
		"jobInterface": "Color ConneXion permite al usuario iniciar sesión y acceder a la aplicación Color ConneXion.",
		"admin": "Cualquier usuario con los derechos de seguridad 'Admin' puede configurar a otros usuarios como administradores de la aplicación Color ConneXion y controlar las funciones relacionadas con Color ConneXion.",
		"setTarget": "Permite a cualquier usuario establecer / restablecer objetivos según corresponda en la aplicación Color ConneXion.",
		"delete": "Permite a cualquier usuario eliminar trabajos y colores según corresponda en la aplicación Color ConneXion.",

		"email": "Dirección de correo electrónico perteneciente a este contacto. La dirección de correo electrónico se utilizará para enviar notificaciones relacionadas con GSTS, HFi, etc.",
		"contactType": "El tipo de contacto permite que ciertos grupos de contactos se agrupen y se muestren en listas, etc.",
		"email": "El correo electrónico es un campo obligatorio ya que es necesario para restablecer la contraseña del usuario.",
		"missingEmail": "Debe ingresar la dirección de correo electrónico antes de que el usuario pueda ser un facilitador de gráficos.",
		"password": "Introduzca la contraseña para el usuario. La contraseña debe constar de al menos 1 letra mayúscula, 1 número, 1 letra minúscula y debe tener al menos 7 caracteres alfanuméricos de longitud.",
		"email": "El correo electrónico es un campo obligatorio ya que es necesario para restablecer la contraseña del usuario.",
		"vendorName": "Ingrese el nombre del vendedor.",
		"shipperName": "Ingrese el nombre del remitente.",
		"createColor": "Permite a los usuarios crear colores (y realizar cualquier función incluida en ese proceso).",
		"jobInterface": "Interfaz de trabajo permite al usuario iniciar sesión y acceder a la aplicación Interfaz de trabajo.",
		"admin": "Cualquier usuario con los derechos de seguridad 'Admin' puede configurar a otros usuarios como administradores de la aplicación de interfaz de trabajo y controlar las funciones relacionadas con la interfaz de trabajo.",
		"setTarget": "Permite a cualquier usuario establecer / restablecer objetivos según corresponda en la aplicación Interfaz de trabajo.",
		"delete": "Permite a cualquier usuario eliminar trabajos y colores según corresponda en la aplicación Interfaz de trabajo.",
		"userMaintRights": "Permite al usuario acceder al grupo de permisos 'Derechos de usuario'.",
		"userMaintAccess": "Permite al usuario acceder al grupo de permisos 'Acceso a programas'.",
		"userMaintCustomer": "Permite al usuario administrar el grupo de permisos 'Acceso de clientes'.",
		"userMaint": "Permite al usuario realizar cualquier tipo de mantenimiento en la pantalla de Usuario. Este derecho es necesario para que el usuario pueda guardar / eliminar información del usuario.",
		"allowSetTarget": "Permite al usuario establecer o restablecer objetivos en la aplicación Interfaz de trabajo.",
		"userMaintReports": "Permite al usuario acceder al grupo de permisos 'Report Access'.",
		"maintainJobFlag": "Permite al usuario crear y editar trabajos.",
		"deactivateFormula": "Permite al usuario desactivar fórmulas cuando la fórmula ya no es necesaria.",
		"performReads": "Permite al usuario realizar lecturas en un color o fórmula y también ingresar metadatos para la lectura (viscosidad, pH y porcentaje de secado).",
		"jobNotesFlag": "Permite al usuario agregar notas a un trabajo.",
		"hazardList": "Los pictogramas de peligro disponibles para elegir son:",
		"bucketReport": "Para imprimir la etiqueta del cubo, seleccione una fórmula en la orden de venta y verifique que se haya completado con el control de calidad. Sólo se pueden imprimir fórmulas de control de calidad o completadas.",
		"customer": "Haga doble clic en el nombre del cliente para abrir la pantalla del cliente.",
		"processNotesExist": "Las notas de proceso existen para este cliente. Haga clic en el signo de exclamación para navegar a la pantalla de notas de proceso.",
		"completedItem": "Los elementos no se pueden eliminar de una solicitud de laboratorio que ya se han convertido en fórmulas.",
		"completedItems": "Una solicitud de laboratorio no se puede eliminar una vez que se hayan completado las coincidencias de colores.",
		"noSendEmail": "Elimina la fórmula de la lista sin enviar un correo electrónico al cliente.",
		"sendEmail": "Envía un correo electrónico al cliente con el precio de la fórmula para las fórmulas seleccionadas.",
		"download": "Indica qué colores descargar como archivos CxF o MIF.",
		"readSuccess": "Leer con éxito!",
		"deactivated": "Las lecturas no se pueden realizar en una fórmula inactiva.",
		"previous": "Carga la medida previa, si existe.",
		"next": "Carga la siguiente medición, si existe.",
		"last": "Devuelve la medida más reciente.",
		"first": "Devuelve la medida más antigua.",
		"duplicateCheck": "Compara el nombre y la descripción del trabajo con los trabajos existentes y notifica al usuario si ya existe un trabajo con el mismo nombre / descripción.",
		"download": "Indica qué fórmulas descargar como archivos CxF o MIF.",
		"search": "Ingrese el término para buscar y presione la tecla 'ENTER'.",
		"filter": "Borra el filtro.",
		"download": "Indica qué color coincide para descargar como archivos CxF o MIF.",
		"instrumentsDisabledFlag": "Impide que el usuario cambie las secciones del instrumento al realizar una lectura",
		"visibleToCustomerFlag": "Si una fórmula es visible para un cliente",
		"shipmentOutlierAccessFlag": "Permite el acceso del usuario a la casilla de verificación Envío atípico en Pedidos de clientes",
	}
}
