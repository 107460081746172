import React, { Component } from 'react';
import {Row, Col, Button, Modal, Form, Panel} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid';
import InputText from '../common_components/InputText';
import DataDropdown from '../common_components/DataDropdown';
import {mapArrayToObject, getAPI, postAPI, getLoggedInUser, mergeObjects, resetObject, validateForm, showFormValidationAlert} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import {contactTypes} from '../common_components/commonData.js';
import Config from '../Config';
import '../FormPage.css';
import NewPurchaseOrderForm from '../purchase-orders/NewPurchaseOrderForm';
import NewCallReportForm from '../call-reports/NewCallReportForm';
import NewTaskForm from '../tasks/NewTaskForm';
const { Translate } = require('react-i18nify');


class DeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deleteVendorConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}

const validationFields = {
	name: "required",
	contactName: "",
	address1: "required",
	address2: "",
	city: "required",
	countryId: "required",
	stateId: Config.locale.toLowerCase() == 'en_us' ? "required" : "",
	zipCode: "required",
	phoneNumber: "required",
	faxNumber: "",
	contactEmail: "",	
};


const validationFieldsBilling = {
	vendorBillingName: "required",
	vendorBillingContactName: "",
	billingAddress1: "required",
	billingAddress2: "",
	billingCity: "required",
	billingCountryId: "required",
	billingStateId: Config.locale.toLowerCase() == 'en_us' ? "required" : "",
	billingZipCode: "required",
};

const validationFieldsContact = {
	name: "required",
};

class NewVendorForm extends Component {
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		this.state = {
			title: "New Vendor",
			isBillingInfoSameAsContactInfo: false,
			errors: mergeObjects(resetObject(validationFields), 
						resetObject(validationFieldsBilling)),
			formData: {
				id: 0,
				name: "",
				address1: "",
				address2: "",
				city: "",
				countryId: 1,
				stateId: Config.dummyStateId,
				zipCode: "",
				contactEmail: "",
				contactName: "",
				comments: "",
				phoneNumber: "",
				faxNumber: "",
				genericCommodityFlag: false,
				paymentOptionLookupId: null,
				minOrderQty: 0,
				addressId: 0,
				payCode: null,
				billingAddressId: 0,
				billingAddress1: "",
				billingAddress2: "",
				billingCity: "",
				billingCountryId: 1,
				billingStateId: Config.dummyStateId,
				billingZipCode: "",
				vendorBillingContactName: "",
				vendorBillingName: "",
				retired: false,
				model:"com.phototype.myinkiq.model.vos.dtos::VendorsDto",
				locale: null,
			},
			countriesList: [],
			statesList: [],
			paymentTermsList: [],
			vendorOrders: [],
			showDeleteConfirmation: false,
			showNewPurchaseOrderForm: false,
			vendorContacts: [],
			vendorCallReports: [],
			vendorTasks: [],
			showNewContactForm: false,
			editVendorContactRow: null,
			deletedVendorContacts: [],
			selectedContactRowIndex: null,
			contactTypeList: [],
			editCallReportFormVisible: false,
			editCallReportId: null,
			editTaskFormVisible: false,
			editTaskId: null,
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.editVendorContact = this.editVendorContact.bind(this);
		this.vendorContactSelected = this.vendorContactSelected.bind(this);
		this.onAddContact = this.onAddContact.bind(this);
		this.onRemoveContact = this.onRemoveContact.bind(this);
		this.saveVendorContact = this.saveVendorContact.bind(this);
	}
	
	componentDidMount() {
		getAPI("common/paymentterms").then(resPaymentTerms => {
			getAPI("common/countries").then(resCountries => {
				getAPI("/common/states/1").then(resStates => {
					this.setState({
						paymentTermsList: resPaymentTerms['data'],
						countriesList: resCountries['data'],
						statesList: resStates['data'],
					});
				});
				if(this.props.editVendorId) {
					const vendorId = this.props.editVendorId;
					getAPI("/vendors/" + vendorId).then(resVendor => {
						getAPI("/vendors/orders?vendorId=" + vendorId).then(resVendorOrders => {
							getAPI("common/vendorShipperContacts?vendorId=" + vendorId + "&shipperId=0").then(resVendorContacts => {
								getAPI("callreports/search?vendorId=" + vendorId).then(resVendorCallReports => {
									getAPI("tasks/search?vendorId=" + vendorId).then(resVendorTasks => {
										
									
										let formData = resVendor['data'][0];
										const isBillingInfoSameAsContactInfo = formData['billingAddressId'] == formData['addressId'];
										// console.log("formData in edit mode: ", formData);
										formData['stateId'] = formData['stateId'] == null ? 
											Config.dummyStateId : formData['stateId'];
										formData['billingStateId'] = formData['billingStateId'] == null ? 
											Config.dummyStateId : formData['billingStateId'];
										if(isBillingInfoSameAsContactInfo) {
											formData['billingAddress1'] = null;
											formData['billingAddress2'] = null;
											formData['billingCity'] = null;
											formData['billingCountryId'] = 0;
											formData['billingStateId'] = Config.dummyStateId;
											formData['billingZipCode'] = null;
										}
										formData['model'] = "com.phototype.myinkiq.model.vos.dtos::VendorsDto";
										this.setState({
											formData: formData,
											isBillingInfoSameAsContactInfo: isBillingInfoSameAsContactInfo,
											title: "Vendor Id: " + vendorId,
											vendorOrders: resVendorOrders['data'],
											vendorContacts: resVendorContacts['data'],
											vendorCallReports: resVendorCallReports['data'],
											vendorTasks: resVendorTasks['data'],
										});
									});
								});
							});
						});
					});
					
				}
				
			});
		});
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let formData = this.state.formData;
		let isBillingInfoSameAsContactInfo = this.state.isBillingInfoSameAsContactInfo;
		
		switch(name) {
			case 'countryId':
			case 'stateId':
			case 'billingCountryId':
			case 'billingStateId':
				formData[name] = value == '' || value == null ? Config.dummyStateId : value;
				break;
		
			case 'isBillingInfoSameAsContactInfo':
				isBillingInfoSameAsContactInfo = !isBillingInfoSameAsContactInfo;
				if(isBillingInfoSameAsContactInfo) {
					formData['billingAddressId'] = formData['addressId'];
					formData['billingAddress1'] = null;
					formData['billingAddress2'] = null;
					formData['billingCity'] = null;
					formData['billingCountryId'] = 0;
					formData['billingStateId'] = Config.dummyStateId;
					formData['billingZipCode'] = null;
				}
				else {
					formData['billingAddressId'] = 0;
				}
				break;
				
			default:
				formData[name] = value;
			
		}
		
		this.setState({
		  formData: formData,
		  isBillingInfoSameAsContactInfo: isBillingInfoSameAsContactInfo
		});
	}
	
	vendorContactSelected(rowIndex) {
		// console.log("vendorContactSelected - rowIndex: ", rowIndex);
		this.setState({
			selectedContactRowIndex: rowIndex,
		});
	}
	
	editVendorContact(editRow) {
		// console.log("editVendorContact: ", editRow);
		this.setState({
			editVendorContactRow: editRow,
			showNewContactForm: true,
		});
	}
	
	onAddContact(event) {
		event.preventDefault();
		
		this.setState({
			editVendorContactRow: null,
			selectedContactRowIndex: null,
			showNewContactForm: true,
		});
	}
	
	
	onRemoveContact(event) {
		event.preventDefault();
		
		const selectedContactRowIndex = this.state.selectedContactRowIndex;
		if(selectedContactRowIndex >= 0) {		
			var vendorContacts = this.state.vendorContacts;
			var deletedVendorContacts = this.state.deletedVendorContacts;
			
			const vendorContact = this.state.vendorContacts[selectedContactRowIndex];
			
			if(vendorContact.id > 0)
				deletedVendorContacts.push(vendorContact);
		
			vendorContacts.splice(selectedContactRowIndex, 1);
			this.setState({
				vendorContacts: vendorContacts,
				deletedVendorContacts: deletedVendorContacts,
			});
		}
	}
	
	saveVendorContact(vendorContact) {
		var vendorContacts = this.state.vendorContacts;
		vendorContact.bHasChanged = true;
		if(this.state.selectedContactRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			vendorContacts[this.state.selectedContactRowIndex] = vendorContact;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			vendorContacts.push(vendorContact);
		}
		
		this.setState({
			vendorContacts: vendorContacts,
			showNewContactForm: false,
		});
	}
	
	onSave() {
		// PERFORM VALIDATION CHECKS HERE
		let validation = validateForm(this.state.formData, validationFields);

		if(!this.state.isBillingInfoSameAsContactInfo) {
			const billingValidation = validateForm(this.state.formData, validationFieldsBilling);
			validation.errors = mergeObjects(validation.errors, billingValidation.errors);
			validation.result = validation.result && billingValidation.result;
		}
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			// IF FORM IS VALIDATED PERFORM SAVE OPERATION HERE
			postAPI("vendors", this.state.formData).then(resVendor => {
			if(resVendor['success'])
			{
				alert("Vendor info Saved successfully.");
				this.reactGA.gaEvent("Vendor", "Save", "Vendor saved successfully.");
				var vendorId = resVendor.data[0].id;
				var addVendorContactPromises = [];
				for(const i in this.state.vendorContacts) {
					var vendorContact = this.state.vendorContacts[i];
					vendorContact['vendorId'] = vendorId;
					if(vendorContact.bHasChanged != undefined && vendorContact.bHasChanged)
					{
						delete(vendorContact.bHasChanged);
						addVendorContactPromises.push(postAPI("/common/vendorShipperContacts", vendorContact).then(resContact => {
							if(resContact.error) {
								alert("Error " + resContact.error.status + ": " + resContact.error.error);
							}
						}));
					}
				}
				
				var deleteVendorContactPromises = [];
				for(const i in this.state.deletedVendorContacts) {
					const vendorContact = this.state.deletedVendorContacts[i];
					deleteVendorContactPromises.push(postAPI("/common/vendorShipperContacts/" + vendorContact.id, vendorContact).then(resContact => {
						if(resContact.error != null) {
							alert("Error " + resContact.error.status + ": " + resContact.error.error);
						}
					}));
					
				}
				Promise.all(addVendorContactPromises)
				.then(() => {
					Promise.all(deleteVendorContactPromises)
					.then(() => {
						// alert(<Translate value="components.labels.poSaved" />);
						// alert("Vendor Contact info has been saved successfully");
						this.props.onSave();
					});
				});
			}
			else
			{
				alert(resVendor['error']);
			}
		});
		}
	}
	
	onDelete() {
		postAPI("vendors/" + this.state.formData.id, this.state.formData).then(resVendor => {
			// alert(<Translate value="components.labels.poDeleted" />);
			alert("Vendor has been deleted successfully");
			this.reactGA.gaEvent("Vendor", "Delete", "Vendor deleted successfully.");
			this.props.onSave();
		});
	}

	render() {
		const loggedInUser = getLoggedInUser();
		const paymentTypes = {
			'P': 'Pre-Paid', // <Translate value='components.settings.prePaid' />,
			'C': 'Collect', // <Translate value='components.settings.collect' />,
			'T': 'Third Party', // <Translate value='components.settings.thirdParty' />,
		};
		
		const locales = {
			"EN_US": "USA",
			"ES_MX": "MEXICO",
		};

		const countriesList = mapArrayToObject(this.state.countriesList, 'id', 'name');
		const statesList = mapArrayToObject(this.state.statesList, 'id', 'name');
		const paymentTerms = mapArrayToObject(this.state.paymentTermsList, 'id', 'name');
		
		var contactsColumns = [
			<Translate value="components.labels.Name" />, 
			<Translate value="components.labels.Position" />, 
			<Translate value="components.labels.Email" />, 
			<Translate value="components.labels.Phone" />, 
		];
		var contactsFields = ['name', 'typeLookupName', 'emailAddress', 'phoneNumber'];
		
		var formatContactsColumns = {
		};
		
		const vendorContactForm = this.state.showNewContactForm ? 
		(<NewVendorContactForm
			visible={this.state.showNewContactForm}
			onClose={() => {
				this.setState({showNewContactForm: false});
			}}
			editVendorContactRow={this.state.editVendorContactRow} 
			onSave={this.saveVendorContact}
			/>) 
			: '';
		const editCallReportForm = this.state.editCallReportFormVisible ? 
		(<NewCallReportForm
			onClose={() => this.setState({editCallReportFormVisible: false})}
			onSave={() => {
				this.setState({editCallReportFormVisible: false});
			}}
			visible={this.state.editCallReportFormVisible}
			editCallReportId={this.state.editCallReportId}
			cannotSave={1}
		/>) : "";
		const editTaskForm = this.state.editTaskFormVisible ? 
		(<NewTaskForm
			onClose={() => this.setState({editTaskFormVisible: false})}
			onSave={() => {
				this.setState({editTaskFormVisible: false});
			}}
			visible={this.state.editTaskFormVisible}
			editTaskId={this.state.editTaskId}
			cannotSave={1}
		/>) : "";
				
		var content = <Form className='form-page' horizontal>
			<Row>
				<Col md={4}>
					<h4>{<Translate value='components.settings.vendorInfo' />}</h4>
					<InputText required label={<Translate value='components.settings.name' />} 
						name={'name'} value={this.state.formData.name} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.name}
					/>
					<InputText label={<Translate value='components.settings.contact' />}  
						name={'contactName'} value={this.state.formData.contactName} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.contactName}
					/>
					<InputText required label={<Translate value='components.settings.address1' />}  
						name={'address1'} value={this.state.formData.address1} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.address1}
					/>
					<InputText label={<Translate value='components.settings.address2' />}  
						name={'address2'} value={this.state.formData.address2} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.address2}
					/>
					<InputText required label={<Translate value='components.settings.city' />}  
						name={'city'} value={this.state.formData.city} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.city}
					/>
					<DataDropdown required label={<Translate value='components.settings.country' />}  
						data={countriesList} name={'countryId'} 
						value={this.state.formData.countryId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.countryId}
					/>
					<DataDropdown required={validationFields.stateId == 'required'} 
						label={<Translate value='components.settings.state' />} 
						data={statesList} name={'stateId'} 
						value={this.state.formData.stateId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.stateId}
					/>
					<InputText required label={<Translate value='components.settings.zipCode' />} 
						name={'zipCode'} value={this.state.formData.zipCode} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.zipCode}
					/>
					<InputText required label={<Translate value='components.settings.phone' />} 
						name={'phoneNumber'} value={this.state.formData.phoneNumber} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.phoneNumber}
					/>
					<InputText label={<Translate value='components.settings.fax' />} 
						name={'faxNumber'} value={this.state.formData.faxNumber} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.faxNumber}
					/>
					<InputText label={<Translate value='components.settings.email' />} 
						name={'contactEmail'} value={this.state.formData.contactEmail} 
						action={this.handleInputChanged}
						errMsg={this.state.errors.contactEmail}
					/>
					<DataDropdown label={<Translate value='components.settings.paymentType' />}  name={'payCode'} value={this.state.formData.payCode} action={this.handleInputChanged} data={paymentTypes} />
					<InputText type='CheckBox' label={<Translate value='components.settings.inactive' />}  name={'retired'} value={this.state.formData.retired} action={this.handleInputChanged} />
				</Col>
				<Col md={4}>
					<h4>{<Translate value='components.settings.billingInfo' />}</h4>
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.sameAsContactInfo' />} 
						name={'isBillingInfoSameAsContactInfo'} action={this.handleInputChanged} 
						value={this.state.isBillingInfoSameAsContactInfo} 
					/>
					<InputText disabled={this.state.isBillingInfoSameAsContactInfo} required 
						label={<Translate value='components.settings.name' />}  
						name={'vendorBillingName'} value={this.state.formData.vendorBillingName} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.vendorBillingName}
					/>
					<InputText disabled={this.state.isBillingInfoSameAsContactInfo} 
						label={<Translate value='components.settings.contact' />} 
						name={'vendorBillingContactName'} 
						value={this.state.formData.vendorBillingContactName} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.vendorBillingContactName}
					/>
					<InputText disabled={this.state.isBillingInfoSameAsContactInfo} required 
						label={<Translate value='components.settings.address1' />} 
						name={'billingAddress1'} value={this.state.formData.billingAddress1} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.billingAddress1}
					/>
					<InputText disabled={this.state.isBillingInfoSameAsContactInfo} 
						label={<Translate value='components.settings.address2' />}  
						name={'billingAddress2'} 
						value={this.state.formData.billingAddress2} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.billingAddress2}
					/>
					<InputText disabled={this.state.isBillingInfoSameAsContactInfo} required 
						label={<Translate value='components.settings.city' />}  
						name={'billingCity'} 
						value={this.state.formData.billingCity} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.billingCity}
					/>
					<DataDropdown disabled={this.state.isBillingInfoSameAsContactInfo} required 
						label={<Translate value='components.settings.country' />} 
						data={countriesList} name={'billingCountryId'} 
						value={this.state.formData.billingCountryId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.billingCountryId}
					/>
					<DataDropdown disabled={this.state.isBillingInfoSameAsContactInfo} 
						required={validationFieldsBilling.billingStateId == 'required'} 
						label={<Translate value='components.settings.state' />} 
						data={statesList} name={'billingStateId'} 
						value={this.state.formData.billingStateId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.billingStateId}
					/>
					<InputText disabled={this.state.isBillingInfoSameAsContactInfo} required 
						label={<Translate value='components.settings.zipCode' />} 
						name={'billingZipCode'} value={this.state.formData.billingZipCode} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.billingZipCode}
					/>
				</Col>
				<Col md={4}>
					<h4>{<Translate value='components.settings.other' />}</h4>
					<InputText type='CheckBox' label={<Translate value='components.settings.genericCommodityVendor' />} name={'genericCommodityFlag'} value={this.state.formData.genericCommodityFlag} action={this.handleInputChanged} />
					<DataDropdown label={<Translate value='components.settings.paymentTerms' />} name={'paymentOptionLookupId'} value={this.state.formData.paymentOptionLookupId} data={paymentTerms} action={this.handleInputChanged} />
					<DataDropdown label={<Translate value='components.settings.locale' />} 
						name={'locale'} value={this.state.formData.locale} data={locales} 
						action={this.handleInputChanged} />
					<InputText label={<Translate value='components.settings.minOrderQuantity' />} name={'minOrderQty'} value={this.state.formData.minOrderQty} action={this.handleInputChanged} />
					<InputText type='TextArea' label={<Translate value='components.settings.comments' />} name={'comments'} value={this.state.formData.comments} action={this.handleInputChanged} />
				</Col>
			</Row>
			<Row>
			<h4><Translate value='components.labels.recentPurchaseOrders' /></h4>
				<DataGrid
					loading={false}
					columns={[
						<Translate value='components.labels.orderDate' />,
						<Translate value='components.labels.dueDate' />,
						<Translate value='components.labels.status' />,
						<Translate value='components.labels.poNumber' />,
						<Translate value='components.labels.amount' />,
					]}
					fields={['orderDate', 'dueDate', 'status', 'id', 'amount']}
					data={this.state.vendorOrders}
					filter={{}}
					formatColumns={{
						0: {date: true},
						1: {date: true},
						4: {currency: this.state.formData.locale ? this.state.formData.locale : 'EN_US'}
					}}
					limit={1000}
				/>
				<Button
					onClick={() => this.setState({showNewPurchaseOrderForm: true})}
					 disabled={loggedInUser.accessPOCode == 'V'}
				>
					<Translate value='components.buttons.newPurchaseOrder' />
				</Button>
			</Row>
			<Row>
				<Col md={12}>
					<h4><Translate value="components.labels.Contacts" /></h4>
					{true && 
					<span>
						<DataGrid columns={contactsColumns} fields={contactsFields} 
							onDoubleClick={this.editVendorContact} 
							formatColumns={formatContactsColumns} 
							onSelect={this.vendorContactSelected} 
							data={this.state.vendorContacts} 
						/>
						<Button onClick={this.onAddContact}>
							<Translate value="components.buttons.add" />
						</Button>&nbsp;
						<Button 
							onClick={this.onRemoveContact}>
							<Translate value="components.buttons.delete" />
						</Button>
						{vendorContactForm}
						{editCallReportForm}
						{editTaskForm}
					</span>}
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<h4><Translate value="components.labels.Call Reports" /></h4>
					<DataGrid 
						columns={[
							<Translate value="components.labels.date" />,
							<Translate value="components.labels.type" />,
							<Translate value="components.labels.jobDesc" />,
							<Translate value="components.labels.tasksAssigned" />,
							<Translate value="components.labels.tasksCompleted" />,
						]} 
						fields={['callDate', 'callReportType', 'jobDesc', 'numTasksAssigned', 'numTasksCompleted']} 
						formatColumns={{
							0: {date: true},
						}} 
						data={this.state.vendorCallReports} 
						onDoubleClick={(dataIn) => {
							// Sales People cannot double click to open orders other than their own customers
							this.setState({
								editCallReportId: dataIn.callReportId,
								editCallReportFormVisible: true,
							});
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<h4><Translate value="components.labels.Tasks" /></h4>
					<DataGrid 
						columns={[
							<Translate value="components.labels.Vendor" />,
							<Translate value="components.labels.assignedTo" />,
							<Translate value="components.labels.toDo" />,
							<Translate value="components.labels.dueDate" />,
							<Translate value="components.labels.completed" />,
						]} 
						fields={['vendorName', 'assignedToSalesRepName', 'toDo', 'dueDateTime', 'completedYesNo']} 
						formatColumns={{
							3: {date: true},
						}} 
						data={this.state.vendorTasks}
						onDoubleClick={(dataIn) => {
							// Sales People cannot double click to open orders other than their own customers
							console.log("dataIn: ", dataIn);
							this.setState({
								editTaskId: dataIn.taskId,
								editTaskFormVisible: true,
							});
						}}
					/>
				</Col>
			</Row>
		</Form>

		return(<Modal show={this.props.visible} bsSize="large">
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
				<NewPurchaseOrderForm
					visible={this.state.showNewPurchaseOrderForm}
					onConfirm={() => {}}
					onClose={() => this.setState({showNewPurchaseOrderForm: false})}
				/>
				<DeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
				/>
			</Modal.Body>
			<Modal.Footer>
				{this.props.editVendorId ? 
					<Button 
						bsStyle='danger' 
						onClick={() => this.setState({showDeleteConfirmation: true})}
						 disabled={loggedInUser.accessVendorsCode == 'V'}
					>
					<Translate value="components.buttons.deleteVendor" />
				</Button> : ''}
				<Button onClick={this.props.onClose}>{<Translate value='components.settings.close' />}</Button>
				<Button 
					onClick={this.onSave} bsStyle='primary'
					 disabled={loggedInUser.accessVendorsCode == 'V'}>
					{<Translate value='components.settings.saveChanges' />}
				</Button>
			</Modal.Footer>
		</Modal>);
	}
}

export default NewVendorForm;


class NewVendorContactForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "New Vendor Contact",
			errors: resetObject(validationFieldsContact),
			formData: {
				id: 0,
				vendorId: 0,
				name: "",
				typeLookupId: 0,
				typeLookupName: "",
				emailAddress: "",
				phoneNumber: "",
				mobileNumber: "",
				spanishFlag: false,
				retired: false
			},
			contactTypeList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	async componentDidMount() {
		var contactTypeList = [];
		contactTypes()
		.then(contactTypeData => {
			// console.log("contactTypeData: ", contactTypeData);
			this.setState({
				contactTypeList: contactTypeData
			});
		});
		if(this.props.editVendorContactRow) { // If this is not null
			this.setState({
				formData: this.props.editVendorContactRow,
				title: "Contact: " + this.props.editVendorContactRow['name'],
			});
		}
	}
	
	async handleInputChanged(event, name) {
		var formData = this.state.formData;
		const target = event.target;
		var value = target.value;
		switch(name) 
		{
			case 'typeLookupId':
				let contactTypeRow = this.state.contactTypeList.filter(obj => obj.id == value);
				// console.log("value: ", value, ", contactTypeRow: ", contactTypeRow);
				if(contactTypeRow != null && contactTypeRow[0] != null)
					formData["typeLookupName"] = contactTypeRow[0]['name'];
				
				break;	
				
			default:
				value = target.type === 'checkbox' ? target.checked : target.value;
				switch(name) {
					case 'quantity':
						break;

					default:
						break;
				}
				break;
		}
		
		
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
		
	}
	
	onSave(event) {
		event.preventDefault();
		
		// PERFORM VALIDATION CHECKS HERE	
		let validation = validateForm(this.state.formData, validationFieldsContact);

		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			var vendorContact = this.state.formData;
			this.props.onSave(vendorContact);
		}
	}
	
	
	render() {
		// console.log("props: ", this.props);
		
		var content = <Form className='form-page' horizontal>
			<InputText label={<Translate value="components.labels.Name" />} required 
				id={'name'} name={'name'} action={this.handleInputChanged} 
				value={this.state.formData.name} 
				errMsg={this.state.errors.name}
			/>
			<DataDropdown
				label={<Translate value='components.customers.Position'/>}
				name='typeLookupId'
				data={this.state.contactTypeList}
				colValue={"id"}
				colText={"name"}
				value={this.state.formData.typeLookupId}
				action={this.handleInputChanged} 
				errMsg={this.state.errors.typeLookupId}
			/>
			<InputText label={<Translate value="components.labels.Email" />} required 
				id={'emailAddress'} name={'emailAddress'} action={this.handleInputChanged} 
				value={this.state.formData.emailAddress} 
				errMsg={this.state.errors.emailAddress}
			/>
			<InputText label={<Translate value="components.labels.Phone" />} required 
				id={'phoneNumber'} name={'phoneNumber'} action={this.handleInputChanged} 
				value={this.state.formData.phoneNumber} 
				errMsg={this.state.errors.phoneNumber}
			/>
			<InputText label={<Translate value="components.labels.Mobile" />} required 
				id={'mobileNumber'} name={'mobileNumber'} action={this.handleInputChanged} 
				value={this.state.formData.mobileNumber} 
				errMsg={this.state.errors.mobileNumber}
			/>
		</Form>
		
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{content}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => {
					this.props.onClose();
				}}>
					<Translate value="components.buttons.close" /></Button>
				<Button 
					onClick={this.onSave} bsStyle="primary" 
				>
					<Translate value="components.buttons.saveChanges" />
				</Button>
			</Modal.Footer>
		</Modal>);
	}
}