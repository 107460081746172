export default {
	'settings': {
		'companyInfo': 'Company Info',
		'displayName': 'Display Name',
		'address1': 'Address 1',
		'address2': 'Address 2',
		'city': 'City',
		'zipCode': 'Zip Code',
		'country': 'Country',
		'state': 'State/Province',
		'phone': 'Phone',
		'fax': 'Fax',
		'website': 'Website',
		'formulaCostInfo': 'Formula Cost Info',
		'formulaMiscCharge': 'Formula Misc. Charge',
		'formulaContainerCost': 'Formula Container Cost',
		'formulaShrinkageAmount': 'Formula Shrinkage Amount %',
		'overheadMarkup': 'Overhead Markup %',
		'shippingCost': 'Shipping Cost',
		'defaultMeasurementConditions': 'Default Measurement Conditions',
		'illuminant': 'Illuminant',
		'observerAngle': 'Observer Angle',
		'deltaE': 'ΔE',
		'device': 'Device',
		'baseTolerance': 'Base Tolerance',
		'inkTolerance': 'Ink Tolerance',
		'numQcReads': '# QC Reads',
		'numTargetReads': '# Target Reads',
		'cancel': 'Cancel',
		'save': 'Save',
		'userType': 'User Type',
		'firstName': 'First Name',
		'lastName': 'Last Name',
		'email': 'E-mail',
		'displayName': 'Display Name',
		'userName': 'User Name',
		'inactive': 'Inactive',
		'newPassword': 'New Password',
		'confirmNewPassword': 'Confirm New Password',
		'requireChange': 'Require Change',
		'customers': 'Customers',
		'components': 'Components',
		'formulas': 'Formulas',
		'inventory': 'Inventory',
		'pos': 'POs',
		'salesOrders': 'Sales Orders',
		'vendors': 'Vendors',
		'shippers': 'Shippers',
		'accountingFunctions': 'Accounting Functions',
		'confirmSalesOrder': 'Confirm Sales Order',
		'userMaintanence': 'User Maintanence',
		'userMaintanenceCustomer': 'User Maintanence (Customer)',
		'userMaintanenceAccess': 'User Maintanence (Access)',
		'editCompanyInformation': 'Edit Company Information',
		'approveFormulaPrice': 'Approve Formula Price',
		'userMaintanenceRights': 'User Maintanence (Rights)',
		'userMaintanenceReports': 'User Maintanence (Reports)',
		'userInfo': 'User Info',
		'sessionInfo': 'Session Info',
		'loginCount': 'Login Count',
		'spanish': 'Spanish',
		'programAccess': 'Program Access',
		'canView': 'Can View',
		'canEdit': 'Can Edit',
		'reportAccess': 'Report Access',
		'userRights': 'User Rights',
		'colorConnexionAccess': 'Color ConneXion Access',
		'colorConnexion': 'Color ConneXion',
		'admin': 'Admin',
		'name': 'Name',
		'contact': 'Contact',
		'paymentType': 'Payment Type',
		'sameAsContactInfo': 'Same As Contact Info',
		'genericCommodityVendor': 'Generic Commodity Vendor',
		'paymentTerms': 'Payment Terms',
		'minOrderQuantity': 'Min Order Quantity',
		'comments': 'Comments',
		'vendorInfo': 'Vendor Info',
		'billingInfo': 'Billing Info',
		'other': 'Other',
		'newVendor': 'New Vendor',
		'newContactType': 'New Contact Type',
		'close': 'Close',
		'saveChanges': 'Save Changes',
		'prePaid': 'Pre-Paid',
		'collect': 'Collect',
		'thirdParty': 'Third Party',
		'dueOnReceipt': 'Due on Receipt',
		'shipperInfo': 'Shipper Info',
		'newShipper': 'New Shipper',
		'users': 'Users',
		'administration': 'Administration',
		'company': 'Company',
		'type': 'Type',
		'lastUpdated': 'Last Updated',
		'newUser': 'New User',
		'vendorName': 'Vendor Name',
		'poNumber': 'PO #',
		'status': 'Status',
		'location': 'Location',
		'substrateName': 'Substrate Name',
		'newSubstrate': 'New Substrate',
		'addNewSubstrate': 'Add New Substrate',
		'viscosityCupName': 'Viscosity Cup Name',
		'newViscosityCup': 'New Viscosity Cup',
		'addNewViscosityCup': 'Add New Viscosity Cup',
		'customerAccess': 'Customer Access',
		'canAccessCustomers': 'Can access customers',
		'lastLogin': 'Last Login',
		'category': 'Category',
		'allowCreateColor': 'Allow Create Color',
		'allowCreateEditJobs': 'Allow Create/Edit Jobs?',
		'allowDeactivationOfFormulas': 'Allow Deactivation of Formulas?',
		'allowPerformReads': 'Allow Perform Reads?',
		'allowJobColorDelete': 'Allow Job/Color Delete?',
		'allowJobNoteCreation': 'Allow Job Note Creation',
		'selectAll': 'Select All',
		'jobInterface': 'Job Interface',
		'colorConnexion': 'Color ConneXion',
		'createColor': 'Create Color',
		'accessSetTarget': 'Allow Set Target',
		'performReads': 'Perform Reads',
		'maintainJob': 'Maintain Job',
		'accessDelete': 'Access Delete',
		'deactivateFormula': 'Deactivate Formula',
		'notes': 'Notes',
		'taxRate': 'Tax Rate %',
		'currencyConversionUnit': 'USD to Mex$ Rate',
		'allCustomers': 'All Customers',
		'rfc': 'RFC',
		'emailAccess': 'Email Access',
		'formulaPricing': 'Formula Pricing',
		'formulaPriceBreakdown': 'Formula Price Breakdown',
		"visibleToCustomer": "Formula Visible to Customer",
		"shipmentOutlierAccess": "Shipment Outlier Access",
		"instrumentsDisabledFlag": "Instruments Disabled",
		"inkQualificationQCFlag": "Ink Qualification (QC)",
		"contactUs": "Contact Us",
		"subject": "Subject",
		"message": "Message",
		"send": "Send",
		"viscosityCups": "Viscosity Cups",
		"substrates": "Substrates",
		"contactPositions": "Contact Positions",
		"cannotConvert": "Do Not Convert",
		"din4Value": "Din 4 Value",
		"otherCupValue": "Other Cup Value",
		"viscosityCupConversion": "Viscosity Cup Conversion",
	}
}
