import React, { Component } from 'react';
import Dashboard from './dashboard/Dashboard';
import './App.css';
import Login from './Login';

class App extends Component {

  render() {
    return (

      <div className="App">

      <link href="https://fonts.googleapis.com/css?family=Assistant:400,700" rel="stylesheet" /> 
      <link href="https://stackpath.bootstrapcdn.com/bootswatch/3.3.7/flatly/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="App.css" /> 
      {<Login />}
      <div id="main-content"></div>
      </div>
    );
  }
}

export default App;
