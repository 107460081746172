import React, { Component } from 'react';
import {Button, Form, Modal, Row, Col} from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataGrid from '../common_components/DataGrid.js';

import DataDropdown from '../common_components/DataDropdown.js';
// import NewViscosityCup from './NewViscosityCup';
import {mapArrayToObject, getAPI, postAPI, validateForm, showFormValidationAlert, convertTimestampToDate, getLoggedInUser} from '../common_components/common.js';

import Config from '../Config';
const { Translate } = require('react-i18nify');

export default class ViscosityCups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiUrl: Config.baseURL + 'common/viscositycups',
			columns: [
				<Translate value='components.settings.viscosityCupName' />,
			],
			fields: ['name'],
			formatColumns: {},
			data: [],
			offset: 0,
			limit: 2500,
			showNewViscosityCupForm: false,
			editViscosityCupId: null,
			loading: true,
			autoRefresh: true,
			interval: null,

			viscosityCupRetired: '0',
		};
		this.showEditViscosityCupForm = this.showEditViscosityCupForm.bind(this);
		
	}
	
	refreshData() {
		this.setState({loading: true});
		getAPI("common/viscositycups").then(resViscosityCups => {
			this.setState({
				data: resViscosityCups['data'],
				loading: false,
			});
		});	
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showNewViscosityCupForm) {
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	showEditViscosityCupForm(dataIn) {
		this.setState({
			editViscosityCupId: dataIn['id'],
			showNewViscosityCupForm: true,
		});
	}

	
	render() {
		let data = this.state.data;
		
		if(this.state.viscosityCupRetired != '')
		{
			// console.log("this.state.viscosityCupRetired: ", this.state.viscosityCupRetired, ", data: ", data);
			data = data.filter(obj => obj.retired == this.state.viscosityCupRetired);
		}
		
		if(this.props.search != undefined)
		{
			data = data.filter(obj => 
				obj.name.toLowerCase()
					.indexOf(this.props.search.toLowerCase()) > -1 
			);
		}

		const editViscosityCupForm = this.state.showNewViscosityCupForm ? <NewViscosityCupForm
					visible={this.state.showNewViscosityCupForm}
					onClose={() => this.setState({showNewViscosityCupForm: false})}
					onSave={() => {
						this.setState({showNewViscosityCupForm: false});
						this.refreshData();
					}}
					editViscosityCupId={this.state.editViscosityCupId}
				/> : "";
	
		return(<div>
			<DataGrid
				columns={this.state.columns}
				fields={this.state.fields}
				data={data}
				formatColumns={this.state.formatColumns}
				onDoubleClick={this.showEditViscosityCupForm}
				loading={this.state.loading}
				heightOffset={190}
			/>

			<Row>
				<Col md="2"></Col>
				<Col md="4">
					<Form class='form-page' horizontal>
						<DataDropdown 
							label={<Translate value="components.labels.type" />} 
							action={(event) => {
								// console.log("event: ", event.target.value);
								this.setState({viscosityCupRetired: event.target.value});
							}} 
							data={{'0': 'Active', '1': 'Inactive'}} 
							value={this.state.viscosityCupRetired}
						/>
					</Form>
				</Col>
				<Col md="4">
					<Button bsSize='sm' onClick={() => this.setState({showNewViscosityCupForm: true, editViscosityCupId: null})}>
				<Translate value='components.settings.newViscosityCup' />
			</Button>
				</Col>
			</Row>
			
			{editViscosityCupForm}
		</div>);
	}

}

class DeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deleteViscosityCupConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}
const validationFields = {
	name: "required",
};

class NewViscosityCupForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			formData: {
				id: 0,
				value: "",
				name: "",
				code: "VISCOSITY_CUP",
				category: "",
				retired: false,
			},
			showDeleteConfirmation: false,
			showViscosityCupConversionForm: false,
			viscosityCupConversions: [],
			viscosityCupConversionIndex: null,
			editViscosityCupConversionRow: null,
			deletedViscosityCupConversions: [],
		};
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSaveViscosityCupConversion = this.onSaveViscosityCupConversion.bind(this);
		this.onRemoveViscosityCupConversion = this.onRemoveViscosityCupConversion.bind(this);
		this.validateViscosityCupConversion = this.validateViscosityCupConversion.bind(this);
		
	}
	
	componentDidMount() {
		// console.log("this.props.editViscosityCupId: ", this.props.editViscosityCupId);
		if(this.props.editViscosityCupId) {
			let formData = this.state.formData;
			const viscosityCupId = this.props.editViscosityCupId;
			getAPI("/common/viscositycups/" + viscosityCupId).then(resSubstrate => {
				formData = resSubstrate['data'][0];
				getAPI("/common/viscositycups/" + viscosityCupId + "/conversions").then(resConversions => {
					this.setState({
						formData: formData,
						viscosityCupConversions: resConversions['data'],
					});
				});
			});
		}
	}

	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		formData[name] = value;
		
		switch(name) {
			case 'name':
				formData['value'] = value;
				break;
		}
		
		this.setState({
		  formData: formData,
		});
	}
	
	onSave() {
		let validation = validateForm(this.state.formData, validationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			postAPI("common/viscositycups", this.state.formData).then(resSubstrate => {
			if(resSubstrate['success']) {
				// alert("Viscosity info Saved successfully.");
				const viscosityCupId = resSubstrate['data'][0]['id'];
				
				// Saving Viscosity Cup Conversions
				
				var addOrderComponentPromises = [];
				for(const i in this.state.viscosityCupConversions) {
					var viscosityCupConversion = this.state.viscosityCupConversions[i];
					viscosityCupConversion['viscosityCupId'] = viscosityCupId;
					if(viscosityCupConversion.bHasChanged != undefined && viscosityCupConversion.bHasChanged)
					{
						delete(viscosityCupConversion.bHasChanged);
						postAPI("common/viscositycups/" + viscosityCupId + "/conversion/", viscosityCupConversion).then(resViscosityCupConversion => {
							if(resViscosityCupConversion.error != null) {
								alert("Error " + resViscosityCupConversion.status + ": " + resViscosityCupConversion.message);
							}
						});
					}
				}
				
				
				// Deleting Viscosity cup conversions
				for(const i in this.state.deletedViscosityCupConversions) {
					const viscosityCupConversion = this.state.deletedViscosityCupConversions[i];

					postAPI("common/viscositycups/" + viscosityCupId + "/conversion/" + viscosityCupConversion.id, viscosityCupConversion).then(resViscosityCupConversion => {
						if(resViscosityCupConversion.error != null) {
							alert("Error " + resViscosityCupConversion.error.status + ": " + resViscosityCupConversion.error.error);
						}
					})
				}
				
				
				this.props.onSave();
			}
			else
			{
				var errMsg = resSubstrate['error']['message'];
				if(resSubstrate['error']['status'] == '101')
					errMsg = "The name already exists.  Please enter a different name.";
				alert(errMsg);
			}
		});
		}
	}
	
	onDelete() {
		postAPI("common/viscositycups/" + this.state.formData.id, this.state.formData).then(resSubstrate => {
			// alert(<Translate value="components.labels.poDeleted" />);
			alert("Viscosity cup has been deleted successfully");
			this.props.onSave();
		});
	}

	validateViscosityCupConversion(viscosityCupConversion) {
		var res = true;
		// console.log("validateViscosityCupConversion() called - this.state.viscosityCupConversionIndex: ", this.state.viscosityCupConversionIndex, ", viscosityCupConversion: ", viscosityCupConversion);
		// Checking for duplicate din4Value
		if(this.state.viscosityCupConversionIndex == null) {
			const duplicateRows = this.state.viscosityCupConversions.filter(row => row.din4Value == viscosityCupConversion.din4Value);
			// console.log("duplicateRows: ", duplicateRows);
			if(duplicateRows.length > 0) {
				alert("Din 4 Value " + viscosityCupConversion.din4Value + ": Already in Use for this Cup");
				res = false;
			}
		}
			
		return res;
	}
	
	
	onSaveViscosityCupConversion(viscosityCupConversion) {
		// console.log("onSaveViscosityCupConversion() - viscosityCupConversion: ", viscosityCupConversion, ", this.state.viscosityCupConversionIndex: ", this.state.viscosityCupConversionIndex);
		var viscosityCupConversions = this.state.viscosityCupConversions;
		viscosityCupConversion.bHasChanged = true;
		if(this.state.viscosityCupConversionIndex != null)
		{
			//////// THIS WAS EDIT MODE
			viscosityCupConversions[this.state.viscosityCupConversionIndex] = viscosityCupConversion;	
		}
		else
		{
			const validated = this.validateViscosityCupConversion(viscosityCupConversion);
			if(!validated)
				return false;
			
			///////// THIS WAS INSERT MODE
			viscosityCupConversions.push(viscosityCupConversion);
		}
		

		this.setState({
			viscosityCupConversions: viscosityCupConversions,
			showViscosityCupConversionForm: false,
		});
	}
	
	onRemoveViscosityCupConversion(event) {
		
		const viscosityCupConversionIndex = this.state.viscosityCupConversionIndex;
		// console.log("onRemoveViscosityCupConversion() - viscosityCupConversionIndex: ", viscosityCupConversionIndex );
		if(viscosityCupConversionIndex != null) {
			var viscosityCupConversions = this.state.viscosityCupConversions;
			var deletedViscosityCupConversions = this.state.deletedViscosityCupConversions;
			
			const viscosityCupConversion = viscosityCupConversions[viscosityCupConversionIndex];
			if(viscosityCupConversion.id > 0)
				deletedViscosityCupConversions.push(viscosityCupConversion);
		
			viscosityCupConversions.splice(viscosityCupConversionIndex, 1);
			this.setState({
				viscosityCupConversions: viscosityCupConversions,
				viscosityCupConversionIndex: null,
				deletedViscosityCupConversions: deletedViscosityCupConversions,
			});
		}
	}
	
	render() {
		const visocistyCupConversionForm = this.state.showViscosityCupConversionForm ? <ViscosityCupConversionForm 
			visible={this.state.showViscosityCupConversionForm}
			onClose={() => this.setState({showViscosityCupConversionForm: false})}
			onSave={this.onSaveViscosityCupConversion}
			editViscosityCupConversionRow={this.state.editViscosityCupConversionRow} 
		/>: '';
		const content = 
		<Form className='form-page' horizontal>
			<InputText required label={<Translate value='components.settings.name' />} 
				name={'name'} value={this.state.formData.name} 
				action={this.handleInputChanged} 
				errMsg={this.state.errors.name ? this.state.errors.name : ""}
			/>
			
			<InputText type='CheckBox' label={<Translate value='components.settings.inactive' />} name={'retired'} value={this.state.formData.retired} action={this.handleInputChanged}/>
			<InputText type='CheckBox' label={<Translate value='components.settings.cannotConvert' />} name={'category'} value={!(this.state.formData.category == '' || this.state.formData.category == 'false' )} action={this.handleInputChanged}/>
			{(this.state.formData.category == '' || this.state.formData.category == 'false' ) && <span>
				<DataGrid
					columns={[<Translate value='components.settings.din4Value' />, <Translate value='components.settings.otherCupValue' />]}
					fields={['din4Value', 'viscosityCupValue']}
					data={this.state.viscosityCupConversions}
					onSelect={(index) => {
						// console.log("index: ", index);
						this.setState({viscosityCupConversionIndex: index});
					}}
					onDoubleClick={(row) => {
						// console.log("row: ", row);
						this.setState({editViscosityCupConversionRow: row, showViscosityCupConversionForm: true});
					}}
					heightOffset={190}
				/>
				<Button onClick={this.onRemoveViscosityCupConversion}>Remove</Button>&nbsp;
				<Button onClick={() => {
					this.setState({editViscosityCupConversionRow: null, showViscosityCupConversionForm: true, viscosityCupConversionIndex: null});
				}} >Add</Button>
			</span>}
			{visocistyCupConversionForm}
		</Form>
				
		return(<Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title><Translate value='components.settings.newViscosityCup' /></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
				<DeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
				/>
			</Modal.Body>
			<Modal.Footer>
				{ false && this.props.editViscosityCupId ? <Button bsStyle='danger' onClick={() => this.setState({showDeleteConfirmation: true})}>
					<Translate value="components.buttons.deleteSubstrate" />
				</Button> : ''}
				<Button onClick={this.props.onClose}>{<Translate value='components.settings.close' />}</Button>
				<Button onClick={this.onSave} bsStyle='primary'>{<Translate value='components.settings.saveChanges' />}</Button>
			</Modal.Footer>
		</Modal>);
	}
}

class ViscosityCupConversionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			formData: {
				id: 0,
				viscosityCupId: 0,
				din4Value: 0,
				viscosityCupValue: 0,
				retired: false,
			},
		};
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		if(this.props.editViscosityCupConversionRow) {
			let formData = this.props.editViscosityCupConversionRow;
			this.setState({formData: formData});
		}
	}
	
	onSave() {
		this.props.onSave(this.state.formData);
	}

	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title><Translate value='components.settings.newViscosityCup' /></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='form-page' horizontal>
					<InputText required label={<Translate value='components.settings.din4Value' />} 
						name={'din4Value'} value={this.state.formData.din4Value} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.name ? this.state.errors.name : ""}
					/>
					<InputText required label={<Translate value='components.settings.otherCupValue' />} 
						name={'viscosityCupValue'} value={this.state.formData.viscosityCupValue} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.name ? this.state.errors.name : ""}
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={this.props.onClose}>{<Translate value='components.settings.close' />}</Button>
				<Button onClick={this.onSave} bsStyle='primary'>{<Translate value='components.settings.saveChanges' />}</Button>
			</Modal.Footer>
		</Modal>);
	}
}
