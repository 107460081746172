import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import InputText from './common_components/InputText';
import Dashboard from './dashboard/Dashboard';
import Config from './Config';
import {getLoggedInUser, http_build_query, validateForm, resetObject, getAPI, quickbooksAPI, postAPIUrlEncoded, convertTimestampToDate, constants} from './common_components/common.js';
import './App.css';
import './Login.css';
import './FormPage.css';
import I18n from './translations/myinkiq-i18n.js';
// import ReactGA from "react-ga4";
import {MyInkIQReactGA} from './common_components/common.js'; 

const { Translate } = require('react-i18nify');

const validationFields = {
	resetCode: "",
	password: "length:8",
	confirmPassword: "length:8",
}

class PasswordUpdateScreen extends Component {
	
	constructor(props){
		super(props);
			
		this.state = {
			password: "",
			confirmPassword: "",
			errors: resetObject(validationFields),
		}

		this.onChangeInput = this.onChangeInput.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
	}
	
	onChangeInput(event, name) {
		// console.log("event.target.value: ", event.target.value, ", name: ", name);
		const value = event.target.value;
		this.setState({
			[name]: value,
		});
	}
	
	onChangePassword(event) {
		event.preventDefault();
		
		const password = this.state.password;
		const confirmPassword = this.state.confirmPassword;

		const validation = validateForm(this.state, validationFields);
		
		
		if(password != confirmPassword) {
			validation.errors['confirmPassword'] = "Passwords do not match";
			validation.result = false;
		}
		// console.log("validation in PasswordUpdateScreen: ", validation);
		if(!validation.result) {
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
		
			const loggedInUser = getLoggedInUser();
			const body = {
				password: password,
				old: this.props.oldPassword, // loggedInUser.password,
				id: loggedInUser.id,
				name: loggedInUser.userName,
			};
		
			const queryString = http_build_query(body);
			const postURL = Config.baseURL + "users/password" + queryString;
			console.log("PasswordUpdateScreen::onChangePassword() called - postURL: ", postURL);
			fetch(postURL , {
					method: "post",
					credentials: "include",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"Connection": "keep-alive",
					},
				}).then(res => res.json())
				  .then(
					(result) => {
						console.log("result after changing password: ", result);
						if(result.success) {
						
							this.props.onChangePassword();
						}
					}
				)
		}
	}
	
	render() {
		return <Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title>
					<Translate value='components.labels.changePassword' />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Translate value='components.labels.changePasswordPrompt' />
				<br />
				<br />
				<Form className='form-page' horizontal>
					<InputText
						type='Password'
						name='password'
						action={this.onChangeInput}
						label={<Translate value='components.labels.password' />}
						errMsg={this.state.errors.password}
					/>
					<InputText
						type='Password'
						name='confirmPassword'
						action={this.onChangeInput}
						label={<Translate value='components.labels.confirmPassword' />}
						errMsg={this.state.errors.confirmPassword}
					/>
				</Form>
				{this.props.error ? <div style={{color: 'red'}} >{this.props.error}</div> : ''}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => this.props.onClose()} ><Translate value='components.buttons.close' /></Button>
				<Button bsStyle='primary' onClick={this.onChangePassword} ><Translate value='components.buttons.changePassword' /></Button>
			</Modal.Footer>
		</Modal>
	}
}

class ForgotPasswordScreen extends Component {
	constructor(props){
		super(props);
			
		this.state = {
			username: "",
			errors: {"username": ""},
		}
		
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onSendResetCode = this.onSendResetCode.bind(this);
	}
	
	onChangeInput(event, name) {
		// console.log("ForgotPasswordScreen::onChangeInput() : event.target.value: ", event.target.value, ", name: ", name);
		const value = event.target.value;
		this.setState({
			[name]: value,
		});
	}
	
	onSendResetCode(event) {
		const body = {username: this.state.username, password: "", email: this.state.username};
		let errors = {"username": ""};
		postAPIUrlEncoded("users/recover", body).then(result => {
			if(result['success']) {
				this.props.onSendResetCode(this.state.username);
			} else {
				errors.username = result.error.error;
				this.setState({
					errors: errors
				});
			}
			
		});
	}
	
	render() {
		return <Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title>
					<Translate value='components.labels.resetPassword' />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='form-page' horizontal>
					<InputText 
						name='username' 
						label={<Translate value='components.labels.username' />} 
						required 
						action={this.onChangeInput}
						errMsg={this.state.errors.username}
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => this.props.onClose()} ><Translate value='components.buttons.close' /></Button>
				<Button bsStyle='primary' onClick={this.onSendResetCode} ><Translate value='components.buttons.sendResetCode' /></Button>
			</Modal.Footer>
		</Modal>
	}
}

class ValidatePasswordCodeScreen extends Component {
	constructor(props){
		super(props);
			
		this.state = {
			resetCode: "",
			password: "",
			confirmPassword: "",
			errors: resetObject(validationFields),
		}

		this.onChangeInput = this.onChangeInput.bind(this);
		this.onValidatePassword = this.onValidatePassword.bind(this);
	}
	
	onChangeInput(event, name) {
		// console.log("ValidatePasswordCodeScreen::onChangeInput() - event.target.value: ", event.target.value, ", name: ", name);
		const value = event.target.value;
		this.setState({
			[name]: value,
		});
	}
	
	onValidatePassword(event) {
		event.preventDefault();
		
		const password = this.state.password;
		const confirmPassword = this.state.confirmPassword;

		const validation = validateForm(this.state, validationFields);
		if(!this.state.resetCode) {
			validation.errors['resetCode'] = "Cannot be left empty";
			validation.result = false;
		}
		
		if(password != confirmPassword) {
			validation.errors['confirmPassword'] = "Passwords do not match";
			validation.result = false;
		}
		
		if(password.indexOf("#") >= 0 || password.indexOf("&") >= 0) {
			validation.errors['password'] = "Passwords cannot contain a # or & symbol";
			validation.result = false;
		}
		// console.log("validation in ValidatePasswordCodeScreen::onValidatePassword() - ", validation);
		if(!validation.result) {
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			const loggedInUser = getLoggedInUser();
			const body = {
				code: this.state.resetCode,
				username: this.props.username,
				email: this.props.username,
				password: this.state.password,
			};
		
			postAPIUrlEncoded("users/recover/validate", body).then(result => {
				if(result['success']) {
					this.props.onValidate();
				} else {
					let errors = this.state.errors;
					errors.resetCode = result.error.message;
					this.setState({
						errors: errors
					});
				}
			});
			
		}
	}

	render() {
		return <Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title>
					<Translate value='components.labels.resetPassword' />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='form-page' horizontal>
					<InputText 
						name='resetCode' 
						label={<Translate value='components.labels.resetCode' />} 
						required 
						errMsg={this.state.errors.resetCode}
						action={this.onChangeInput}/>
					<InputText
						type='Password'
						name='password'
						action={this.onChangeInput}
						label={<Translate value='components.labels.password' />}
						errMsg={this.state.errors.password}
						action={this.onChangeInput}
					/>
					<InputText
						type='Password'
						name='confirmPassword'
						action={this.onChangeInput}
						label={<Translate value='components.labels.confirmPassword' />}
						errMsg={this.state.errors.confirmPassword}
						action={this.onChangeInput}
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => this.props.onClose()} >
					<Translate value='components.buttons.back' />
				</Button>
				<Button bsStyle='primary' onClick={this.onValidatePassword} ><Translate value='components.buttons.validate' /></Button>
			</Modal.Footer>
		</Modal>
	}
}

class Login extends Component {
	constructor(props) {
		super(props);

		// console.log("constructor called - initializing ReactGA....");
		// ReactGA.initialize('G-N7BLEDBLPW');
		// ReactGA.initialize('G-ZHEBCNM8Z3');
		this.reactGA = new MyInkIQReactGA();
		
		// if the current user still needs to change their password, log them back out
		const user = getLoggedInUser();
		if (user && user.requireFlag && !user.passwordChanged) sessionStorage.removeItem('user');

		this.state = {
			username: '',
			password: '',
			errorMessage: '',
			loggedIn: sessionStorage.getItem('user'),
			apiUrl: Config.baseURL + '/login',
			showPasswordUpdateScreen: false,
			showForgotPasswordScreen: false,
			showValidatePasswordCodeScreen: false,
			currencyConversionUnit: null,
			autoLogin: false,
		};
		
		this.usernameChanged = this.usernameChanged.bind(this);
		this.passwordChanged = this.passwordChanged.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.userLogin = this.userLogin.bind(this);
		
	}
	
	usernameChanged(event) {
		this.setState({
			username: event.target.value
		});
	}
	
	passwordChanged(event) {
		this.setState({
			password: event.target.value
		});
	}
	
	componentDidMount() {
		
		
		// Define the string
		// var decodedStringBtoA = '{"username": "millcorruser", "pwd": "11400Cin"}';

		// Encode the String
		// var encodedStringBtoA = btoa(decodedStringBtoA);

		// console.log("encodedStringBtoA: ", encodedStringBtoA);

		
		const searchParams = new URLSearchParams(window.location.search);
		// console.log("searchParams: ", searchParams);
		var numParams = 0;
		for (const param of searchParams) {
			if(numParams++ == 0) {
				// console.log("param: ", param);
				try {
					// Decode the String
					var decodedParam = atob(param[0]);
					// console.log("decodedParam: ", decodedParam);
				
					var objParams = JSON.parse(decodedParam);

					// console.log("objParams: ", objParams);
					
					if(objParams != null) {
						var username = objParams['username'];
						var password = objParams['pwd'];
						if(username != null) {
							this.setState({
								username: username,
								password: password,
								autoLogin: true,
							});
							this.userLogin(username, password);
						}
					}
				} catch(e) {
					console.log("exception while trying to parse objParams: ", e);
				}
				
			}
		}
		
		// console.log("sending ReactGA pageview....");
		// ReactGA.send({ hitType: "pageview", page: "/", title: "MyInkIQ Login" });
		// console.log("this.reactGA: ", this.reactGA);
		this.reactGA.pageViewParams("/", "MyInkIQ Login" );
		// this.reactGA.gaEvent("login", "page load", "login view");
		// getAPI("companies/").then(resCompanies => {
		// 	console.log("Login::componentDidMount() called - resCompanies: ", resCompanies);
		// 	this.setState({
		// 		currencyConversionUnit: resCompanies['data'][0]['currencyConversionUnit'] ? 
		// 			resCompanies['data'][0]['currencyConversionUnit'] : 
		// 			Config.currencyConversionUnit,
		// 	});
		// });
		
	}
	
	/*shouldComponentUpdate(nextProps, nextState) {
		console.log("nextState: ", nextState, ", this.state: ", this.state);
		
			this.handleSubmit(null);
			return true;
		return false;
	}*/
	
	userLogin(username, password) {
		console.log("userLogin() - username: ", username, ", password: ", password);
		if(!username || !password)
		{
			this.setState({
				errorMessage: <Translate value="components.labels.blankUsernamePassword" />,
			});
		}
		else
		{
			fetch(this.state.apiUrl + '?username=' + username + '&password=' + password + '&companyId=1', {
				method: "post",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					"Connection": "keep-alive",
				},
				// body: 'username=' + username + '&password=' + password + '&companyId=1',
			}).then(res => res.json())
			  .then(
				(result) => {
					var user = JSON.parse(sessionStorage.getItem('user'));
					// console.log("user from session: ", user);
					// console.log('Login Result: ', result);
					if(result.success)
					{
						const user = result['data'][0];
						user['currencyConversionUnit'] = this.state.currencyConversionUnit; // This must later be dynamically retrieved
						user['userReports'] = {};
						// Getting user reports
						getAPI("users/reports/" + user.id).then(resUserReports => {
							resUserReports['data'].map(report => {
								user['userReports'][report.reportId] = true;
							});
							
							sessionStorage.setItem('user', JSON.stringify(user));
							// console.log("user after logging in: ", user);
							if(user.requireFlag && !user.passwordChanged)
							{
								this.setState({
									showPasswordUpdateScreen: true,
								});
							}
							else
							{
								this.setState({
									errorMessage: "",
									loggedIn: true,
								});
							}
						});
						
					}
					else {
						this.setState({
							errorMessage: <Translate value="components.labels.invalidUsernamePassword" />,
						});
					
					}
				
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log('Error upon login: ', error);
				  this.setState({
						errorMessage: error.toString(),
					});
				}
			  )
		}
	}
	
	handleSubmit(event) {
		if(event != null)
			event.preventDefault();
		this.userLogin(this.state.username, this.state.password);
		/*
		console.log("username: ", this.state.username, ", password: ", this.state.password);
		if(!this.state.username || !this.state.password)
		{
			this.setState({
				errorMessage: <Translate value="components.labels.blankUsernamePassword" />,
			});
		}
		else
		{
			fetch(this.state.apiUrl + '?username=' + this.state.username + '&password=' + this.state.password + '&companyId=1', {
				method: "post",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					"Connection": "keep-alive",
				},
				// body: 'username=' + this.state.username + '&password=' + this.state.password + '&companyId=1',
			}).then(res => res.json())
			  .then(
				(result) => {
					var user = JSON.parse(sessionStorage.getItem('user'));
					// console.log("user from session: ", user);
					// console.log('Login Result: ', result);
					if(result.success)
					{
						const user = result['data'][0];
						user['currencyConversionUnit'] = this.state.currencyConversionUnit; // This must later be dynamically retrieved
						user['userReports'] = {};
						// Getting user reports
						getAPI("users/reports/" + user.id).then(resUserReports => {
							resUserReports['data'].map(report => {
								user['userReports'][report.reportId] = true;
							});
							
							sessionStorage.setItem('user', JSON.stringify(user));
							// console.log("user after logging in: ", user);
							if(user.requireFlag && !user.passwordChanged)
							{
								this.setState({
									showPasswordUpdateScreen: true,
								});
							}
							else
							{
								this.setState({
									errorMessage: "",
									loggedIn: true,
								});
							}
						});
						
					}
					else {
						this.setState({
							errorMessage: <Translate value="components.labels.invalidUsernamePassword" />,
						});
					
					}
				
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log('Error upon login: ', error);
				  this.setState({
						errorMessage: error.toString(),
					});
				}
			  )
		}
		*/
	}

	logout() {
		sessionStorage.removeItem('user');
		this.setState({
			username: '',
			password: '',
			errorMessage: '',
			loggedIn: sessionStorage.getItem('user'),
		});
	}
	
	onChangePassword() {
		const user = getLoggedInUser();
		user.passwordChanged = true;
		sessionStorage.setItem('user', JSON.stringify(user));
		this.setState({
			showPasswordUpdateScreen: false,
			loggedIn: true,
		})
	}
	
	onValidate() {
		this.setState({
			showValidatePasswordCodeScreen: false,
			loggedIn: true,
		})
	}
	

  render() {
	// const myLocale = I18n._locale;
	// console.log("myLocale: ", myLocale);
  	// console.log(" in Login render: this.state.loggedIn: ", this.state.loggedIn);
  	if(this.state.loggedIn)
  	{
  		return <Dashboard onLogout={() => this.logout()} />;
  	}
  	
		
	const passwordUpdateScreen = this.state.showPasswordUpdateScreen ? <PasswordUpdateScreen
			visible={this.state.showPasswordUpdateScreen}
			onClose={() => {this.setState({showPasswordUpdateScreen: false})}}
			oldPassword={this.state.password}
			onChangePassword={this.onChangePassword}
		/> : "";

	const forgotPasswordScreen = this.state.showForgotPasswordScreen ? <ForgotPasswordScreen
		visible={this.state.showForgotPasswordScreen}
		onClose={() => this.setState({showForgotPasswordScreen: false})}
		onSendResetCode={(username) => {
			// console.log("onSendResetCode prop.... username: ", username);
			this.setState({
				username: username,
				showForgotPasswordScreen: false,
				showValidatePasswordCodeScreen: true,
			})}
		}
	/> : '';
	
	const validatePasswordCodeScreen = this.state.showValidatePasswordCodeScreen ? <ValidatePasswordCodeScreen
		visible={this.state.showValidatePasswordCodeScreen}
		onClose={() => this.setState({showValidatePasswordCodeScreen: false})}
		username={this.state.username}
		onValidate={() => {
			alert("Your password has been changed successfully. Please re login.")
			this.setState({showValidatePasswordCodeScreen: false});
		}}
	/> : '';
  	
    return (
      <div>
      	<form className='login-form' method={"post"} onSubmit={this.handleSubmit}>
		  	<img src='appLogoInverted.png' alt='MyInkIQ logo' />
			<br />
			<br />
      		<p>
				<label><Translate value="components.labels.username" />: </label>
				<input id={"username"} value={this.state.username} onChange={this.usernameChanged} />
      		</p>
      		<p>
			  	<label><Translate value="components.labels.password" />: </label>
				<input type={"password"} id={"password"} value={this.state.password} onChange={this.passwordChanged} />
      		</p>
      		<p>
      			<Button type={"submit"}><Translate value="components.buttons.submit" /></Button>
      		</p>
      		<p>
      			<span style={{color:"red", border:"1px solid red", display: this.state.errorMessage ? "" : "none"}}>{this.state.errorMessage}</span>
      		</p>
			<p>
				<a href='#' onClick={() => this.setState({showForgotPasswordScreen: true})}>
					<Translate value='components.labels.forgotPassword' />
				</a>
			</p>
			{ false && <p>
				<a href='javascript:;' onClick={() => {
					// console.log("refreshQBToken clicked");
					getAPI("common/qbrefreshtoken?mode=" + constants.qboMode).then(res => {});
				}}
				>
					<Translate value='components.labels.refreshQBToken' />
				</a> <br /><br />
				<a href='javascript:;' onClick={() => {
					const objData = { 
						"DisplayName": "KAO CHIMIGRAF",
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/vendor?minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(vendor => {
						console.log("created vendor: ", vendor);
						const qboRefId = vendor['Vendor']['Id'];
						// getAPI("/updateQBORefId?entityName=Vendor&entityId=415&qboRefId=" + qboRefId);
					});
				}}
				>
					<Translate value='components.labels.createVendor' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					quickbooksAPI({
						endpoint: "/v3/company/realmId/query?query=",
						query: "select * from Vendor",
					}).then(vendors => {
						console.log("vendors: ", vendors);
					});
				}}
				>
					<Translate value='components.labels.queryVendor' />
				</a>
				<br /><br />
				<a href='javascript:;' onClick={() => {
					const objData = { 
						"Name": "1",
						"FullyQualifiedName": "Test Item (112-44)",
						"Type": "Inventory",
						"ExpenseAccountRef": {
						 "value": "80",
						 "name": "Cost of Goods Sold"
						},
						"AssetAccountRef": {
						 "value": "81",
						 "name": "Inventory Asset"
						},
						"IncomeAccountRef": {
						 "value": "79",
						 "name": "Sales of Product Income"
						},
						"QtyOnHand": 0,
						"InvStartDate": "1970-01-01",
						"TrackQtyOnHand": true
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/item?minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(item => {
						console.log("created item: ", item);
					});
				}}
				>
					<Translate value='components.labels.createItem' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					quickbooksAPI({
						endpoint: "/v3/company/realmId/query?query=",
						query: "select * from Item",
					}).then(items => {
						console.log("items: ", items);
					});
				}}
				>
					<Translate value='components.labels.queryItem' />
				</a>
				 <br /><br />
				<a href='javascript:;' onClick={() => {
					const objData = { 
						"DisplayName": "EXPRESS CONTAINER LINE"
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/customer?minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(customer => {
						console.log("created customer: ", customer);
						if(customer['error'] == null) {
							const qboRefId = customer['Customer']['Id'];
							// getAPI("/common/updateQBORefId?entityName=Customer&entityId=1000&qboRefId=" + qboRefId);
						}
					});
				}}
				>
					<Translate value='components.labels.createCustomer' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					quickbooksAPI({
						endpoint: "/v3/company/realmId/query?query=",
						query: "select * from Customer MAXRESULTS 1000",
					}).then(customers => {
						console.log("customers: ", customers);
					});
				}}
				>
					<Translate value='components.labels.queryCustomer' />
				</a>
				 <br /><br />
				<a href='javascript:;' onClick={() => {
					const objData = { 
						"Line": [ { 
							"Description": "Formula 1", 
							"DetailType": "SalesItemLineDetail", 
							"Amount": 100.0, 
							"SalesItemLineDetail": { 
								"Qty": 1, 
								"UnitPrice": 100.0,
								"ItemRef": { "name": "Services", "value": "1" },
								"DiscountAmt": 50.5,
							} 
						},
						{ 
							"Description": "Formula 2", 
							"DetailType": "SalesItemLineDetail", 
							"Amount": 200.0, 
							"SalesItemLineDetail": { 
								"Qty": 2, 
								"UnitPrice": 100.0,
								"ItemRef": { "name": "Services", "value": "1" },
								"DiscountAmt": 0.0,
							} 
						} 
						], 
						"CustomerRef": { "value": "1" },
						"DocNumber": "112233",
						"TrackingNum": "9900112233",
						"ShipDate": convertTimestampToDate(1647447570777, false, false, true),
						"DueDate": convertTimestampToDate(1647447570777, false, false, true),
						"TotalAmt": 121322
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/invoice?minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(invoice => {
						console.log("created invoice: ", invoice);
					});
				}}
				>
					<Translate value='components.labels.createInvoice' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					quickbooksAPI({
						endpoint: "/v3/company/realmId/query?query=",
						query: "select * from invoice where docNumber=''",
					}).then(invoices => {
						console.log("invoices: ", invoices);
					});
				}}
				>
					<Translate value='components.labels.queryInvoices' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					const objData = {
					  "SyncToken": "0", 
					  "Id": "33" // replace this with the id of the invoice to delete
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/invoice?operation=delete&minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(invoice => {
						console.log("deleted invoice: ", invoice);
					});
				}}
				>
					<Translate value='components.labels.deleteInvoice' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					const objData = {
					  "SyncToken": "0", 
					  "Id": "33" // replace this with the id of the credit memo to delete
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/creditmemo?operation=delete&minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(invoice => {
						console.log("deleted credit memo: ", invoice);
					});
				}}
				>
					<Translate value='components.labels.deleteCreditMemo' />
				</a>
				 <br /><br />
				<a href='javascript:;' onClick={() => {
					const objData = {
					  "DocNumber": 50002,
					  "Line": [
						{
						  "Description": "HYDROPALAT WE 3311 (1433)",
						  "DetailType": "ItemBasedExpenseLineDetail",
						  "Amount": 8676.81,
						  "ItemBasedExpenseLineDetail": {
							"BillableStatus": "NotBillable",
							"Qty": 2061,
							"UnitPrice": 4.21,
							"ItemRef": {
							  "value": 289,
							  "name": "HYDROPALAT WE 3311 (1433)"
							},
							"ClassRef": {
							  "value": "200000000001496495",
							  "name": "USA"
							}
						  }
						}
					  ],
					  "VendorRef": {
						"value": 1011,
						"name": "Azelis Americas CASE LLC"
					  },
					  "APAccountRef": {
						"value": "205",
						"name": "Accounts Payable -Trade USA"
					  },
					  "TaxCodeRef": {
						"value": "NON"
					  },
					  "CustomField": [],
					  "DueDate": "2023-04-05",
					  "TotalAmt": 8676.81
					};

					quickbooksAPI({
						endpoint: "/v3/company/realmId/purchaseorder?minorversion=65&include=allowduplicatedocnum",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(po => {
						console.log("created PO: ", po);
					});
				}}
				>
					<Translate value='components.labels.createPO' />
				</a>
				 <br />
				<a href='javascript:;' onClick={() => {
					quickbooksAPI({
						endpoint: "/v3/company/realmId/query?query=",
						query: "select * from PurchaseOrder Where Metadata.LastUpdatedTime > '2015-03-01'",
					}).then(POs => {
						console.log("POs: ", POs);
					});
				}}
				>
					<Translate value='components.labels.queryPOs' />
				</a>
				<br />
				<a href='javascript:;' onClick={() => {
					const objData = {
					  "SyncToken": "0", 
					  "Id": "33" // replace this with the id of the PO to delete
					};
					quickbooksAPI({
						endpoint: "/v3/company/realmId/purchaseorder?operation=delete&minorversion=65",
						method: "POST",
						contentType: "application/json",
						data: encodeURIComponent(JSON.stringify(objData)),
					}).then(po => {
						console.log("deleted PO: ", po);
					});
				}}
				>
					<Translate value='components.labels.deletePO' />
				</a>
				
			</p>}
      	</form>
		{passwordUpdateScreen}
		{forgotPasswordScreen}
		{validatePasswordCodeScreen}
		{false && <div>
			<table class="table table-striped table-hover data-grid" style={{width: "90%"}}>
				<thead>
					<tr class="data-grid-row">
						<th class="data-grid-header">
							<div id="id">
								<div class="col-md-12">
									<div class="row"><span><span>Name</span></span>&nbsp;</div>
								</div>
							</div>
						</th>
						<th class="data-grid-header">
							<div id="orderDate">
								<div class="col-md-12">
									<div class="row"><span><span>URL</span></span>&nbsp;</div>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="data-grid-row">
						<td style={{"vertical-align":"middle", "font-size": "16px"}}>Beginning Press Side Training Video (EN)</td>
						<td>
							<a target="__blank" href="https://bcminks-assets.s3.us-east-2.amazonaws.com/training/videos/online_presentation.wmv">
								<img width="160px" height="80px" src="https://bcminks-assets.s3.us-east-2.amazonaws.com/training/videos/bcm+training+1.png" />
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>}
	  </div>
    );
  }
}

export default Login;
