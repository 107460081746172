import React, { Component } from 'react';
import TabContainer from '../common_components/TabContainer';
import Video from './Video';
import Quiz from './Quiz';
import {getLoggedInUser} from '../common_components/common.js';
const { Translate } = require('react-i18nify');

class Training extends Component {
	constructor(props) {
		super(props);
		
		this.tabTitles = [
			<Translate value='components.settings.Videos' />,
			<Translate value='components.settings.Quizzes' />,
		];
	}

	render() {
		const loggedInUser = getLoggedInUser();
		const tabs = [
			<Video search={this.props.search} />,
			<Quiz search={this.props.search} />,
		];
		
		const tabsShown = [];
		const tabTitlesShown = [];
		
		// if(loggedInUser.userMaintenanceFlag) {
			tabsShown.push(tabs[0]);
			tabTitlesShown.push(this.tabTitles[0]);
		// }
		
		// if(loggedInUser.accessVendorsCode != 'N') {
			tabsShown.push(tabs[1]);
			tabTitlesShown.push(this.tabTitles[1]);
		// }
		
		return (
			<div>
				<TabContainer tabTitles={tabTitlesShown} tabs={tabsShown}/>
			</div>
		);
	}
}

export default Training;
