import React, { Component } from 'react';
import DataDropdown from '../common_components/DataDropdown.js';
import {Row, Col, Button} from 'react-bootstrap';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import {mapArrayToObject, getAPI, postAPI, barChartOptions, pieChartOptions, lineChartOptions, getLoggedInUser, constants, toCurrency, objMonths} from '../common_components/common.js';
// var config = require('../config.js');
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');


//	PROPS params
//	New approach: Filter the data within the component before passing it on to the grid.
class MonthlyProspectCalls extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			overallData: [],
			salesRepId: null,
			userId: null,
			salesRepData: [],
			monthlyData: [],
			bSalesManagerOrVIP: false,
			salesRepList: [],
			year: (new Date()).getFullYear(),
			month: (new Date()).getMonth() + 1,
			yearsList: [], 
			salesGoalsAndBudgets: [],
		};
		this.salesRepChanged = this.salesRepChanged.bind(this);
		this.yearChanged = this.yearChanged.bind(this);
		this.monthChanged = this.monthChanged.bind(this);
		this.refreshData = this.refreshData.bind(this);
		
	}
	
	
	componentDidMount() {
		this.refreshData(this.state.year);
	}
	
	
	refreshData(pYear) {
		const loggedInUser = getLoggedInUser();
		const userId = loggedInUser['id'];
		const bSalesManagerOrVIP = 
		loggedInUser.userTypeId == constants.sales_manager_type_id || 
		loggedInUser.userTypeId == constants.vip_type_id;
		
		getAPI("/common/salesgoalsandbudgets?year=" + pYear).then(resSalesGoalsAndBudgets => {
			const salesGoalsAndBudgets = resSalesGoalsAndBudgets['data'];
			var salesRepList = salesGoalsAndBudgets.map(row => ({
				id: row.userId,
				name: row.userDisplayName,
			}));
			
			//////////////////// Samee - Get Sales Total and Goals ///////////////////////////
			getAPI("/common/monthlyprospectcalls?year=" + pYear).then(monthlyProspectCalls => {

				// console.log("monthlyProspectCalls: ", monthlyProspectCalls);
				var overallData = [];
				var monthlyData = [];
				// var salesRepList = [];
				
				monthlyProspectCalls['data'].reduce(function(res, value) {
					
					var arrSalesRepName = value.salesRepName.split(" ");
					const salesGoalAndBudgetForSalesRep = salesGoalsAndBudgets.filter(row => row.userId == value.salesRepId)[0];
					const yearlyProspectCallsForSalesRep = salesGoalAndBudgetForSalesRep != undefined ? salesGoalAndBudgetForSalesRep['prospectCallsPerMonth'] : 0;
					
					// OverallData
					if(res[value.salesRepId] == undefined) {
						res[value.salesRepId] = { 
							userId: value.salesRepId, 
							salesRep: value.salesRepName, 
							prospectCalls: yearlyProspectCallsForSalesRep, // value.prospectCalls, 
							numCalls: 0.0,
							yearlyprospectCalls: yearlyProspectCallsForSalesRep, // value.prospectCalls, 
						};
						overallData.push(res[value.salesRepId]);
						/*salesRepList.push({
							id: value.salesRepId, 
							name: value.salesRepName, 
							firstName: arrSalesRepName[0], 
							lastName: arrSalesRepName[1]
						});*/
					}
					// res[value.salesRepId].prospectCalls = value.prospectCalls;
					res[value.salesRepId].numCalls += value.numCalls;
					
					
					// MonthlyData
					if(res[value.salesRepId][value.month] == undefined) {
						res[value.salesRepId][value.month] = { 
							userId: value.salesRepId, 
							numCalls: 0.0,
							month: value.month,
						};
						monthlyData.push(res[value.salesRepId][value.month]);
					}

					res[value.salesRepId][value.month].numCalls += value.numCalls;
					
					
					return res;
				}, {});
				
				// console.log("overall Data: ", overallData);
				// console.log("monthlyData Data: ", monthlyData);
				// let salesRepId = !bSalesManagerOrVIP ? userId : (salesRepList[0] != undefined ? salesRepList[0]['id'] : 0);
				
				var currentYear = (new Date()).getFullYear();
				var yearsList = [];
				for(var startYear = currentYear; startYear >= 2016 ; startYear--) 
					yearsList.push({id: startYear, name: startYear});
				
				this.setState({
					overallData: overallData,
					userId: userId,
					monthlyData: monthlyData,
					// salesRepId: salesRepId,
					bSalesManagerOrVIP: bSalesManagerOrVIP,
					salesRepList: salesRepList,
					yearsList: yearsList,
					year: pYear,
					salesGoalsAndBudgets: salesGoalsAndBudgets,
				});
			});
		});
	}
	
	yearChanged(event) {
		const pYear = event.target.value;
		this.refreshData(pYear);
	}
	
	monthChanged(event) {
		const pMonth = event.target.value;
		this.setState({
			month: pMonth,
		});
	}
	
	salesRepChanged(event) {
		const salesRepId = event.target.value;
		this.setState({
			salesRepId: salesRepId,
		});
	}
	
	render() {
		HC_exporting(Highcharts);
		let salesRepsMapped = mapArrayToObject(this.state.salesRepList, 'id', 'name');
		let yearsMapped = mapArrayToObject(this.state.yearsList, 'id', 'name');
		// console.log("Order::render() called - this.props: ", this.props, ", this.state.data: ", this.state.data);
		const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const objCurrentDate = new Date();
		const currentYear = this.state.year; // objCurrentDate.getFullYear();
		const currentMonth = this.state.month; // objCurrentDate.getMonth() + 1; // Month returned is 0 based.
		let salesRepId = this.state.salesRepId;
		if(!this.state.bSalesManagerOrVIP && salesRepId == null)
			salesRepId = this.state.userId;
		
		// var salesRepData = this.state.categoriesData.filter(obj => obj.userId == salesRepId); // this.state.salesRepData;
		var overallData = this.state.overallData;
		var monthlyData = this.state.monthlyData;
		
		
		let salesRepName = "";
		let salesRepYearlyprospectCalls = 0;
		const salesRepRow = this.state.salesGoalsAndBudgets.filter(obj => obj.userId == salesRepId)[0]; // overallData.filter(obj => obj.userId == salesRepId)[0];
		if(salesRepRow != undefined) {
			// Samee use yearlyprospectCalls instead
			salesRepYearlyprospectCalls = salesRepRow.prospectCallsPerMonth; // yearlyprospectCalls; // salesRepRow.prospectCalls;
			salesRepName = salesRepRow.userDisplayName; // salesRep;
		}
		
		
		let overallYearlyprospectCalls = 0;
		// overallData.forEach(obj => {
			// Samee use yearlyprospectCalls instead
		//	overallYearlyprospectCalls += obj.yearlyprospectCalls; // obj.prospectCalls;
		// });
		
		this.state.salesGoalsAndBudgets.forEach(obj => {
			// Samee use prospectCallsPerMonth instead
			overallYearlyprospectCalls += obj.prospectCallsPerMonth;
		});
		
		// console.log("salesRepId: ", salesRepId, ", salesRepName: ", salesRepName, ", currentYear: ", currentYear, ", currentMonth: ", currentMonth, ", salesRepYearlyprospectCalls: ", salesRepYearlyprospectCalls, ", overallYearlyprospectCalls: ", overallYearlyprospectCalls);
		
		/////////////////////// SALES REP CHARTS /////////////////////
		// var salesRepPieChartOptions = JSON.parse(JSON.stringify(pieChartOptions));
		// var salesRepBarChartOptions = JSON.parse(JSON.stringify(barChartOptions));
		var salesRepLineChartOptions = JSON.parse(JSON.stringify(lineChartOptions));
		
		/*
		/////////////////////////// Pie Chart //////////////////////////////
		salesRepPieChartOptions.title.text = "Total % of " + currentYear + " Expenditures - " + salesRepName;
		salesRepPieChartOptions.series[0].data = salesRepData.map(obj => ({name: obj.customerName, x: obj.prospectCalls, y:obj.numCalls}));
		salesRepPieChartOptions.tooltip.pointFormat = '<b>{point.name}</b>: <b>Total Expenditure:</b>{point.y:,.2f} - <b>{point.percentage:.2f}%</b>';
		
		///////////////////////// Bar Chart //////////////////////////
		let totalSalesAmount = 0;
		let totalMonthlyprospectCalls = 0;
		monthlyData.filter(row => (row.userId == salesRepId && row.month == currentMonth)).map(obj => {
			salesRepBarChartOptions.xAxis.categories.push(obj.customerName);
			const prospectCallsRow = salesRepData.filter(salesRepRow => salesRepRow.customerName == obj.customerName)[0];
			const prospectCalls = prospectCallsRow != undefined ? Math.round((prospectCallsRow.prospectCalls / 12) * 100) / 100 : 0;
			// salesRepBarChartOptions.series[0].data.push(prospectCalls);
			totalMonthlyprospectCalls += prospectCalls;
			salesRepBarChartOptions.series[1].data.push(obj.numCalls);
			totalSalesAmount += obj.numCalls;
		});
		let percentExpenditure = totalMonthlyprospectCalls > 0 ? ((totalSalesAmount * 100) / totalMonthlyprospectCalls) : 0;
		if(percentExpenditure > 100)
			percentExpenditure = 100.0;
		percentExpenditure = percentExpenditure.toFixed(2);
		salesRepBarChartOptions.title.text = salesRepName + " (Monthly Budget: " + toCurrency(totalMonthlyprospectCalls) + ", Total Expenditure: " + toCurrency(totalSalesAmount) + " - " + percentExpenditure + "%)";
		salesRepBarChartOptions.series[0].name = 'Budget';
		salesRepBarChartOptions.series[1].name = 'Expenditure';
		salesRepBarChartOptions.series[0].showInLegend = false;
		*/
		
		////////////////////////// Line Chart ////////////////////////////
		salesRepLineChartOptions.title.text = currentYear + " Calls Goals - " + salesRepName;
		salesRepLineChartOptions.series[0].name = 'Calls Goals';
		salesRepLineChartOptions.series[1].name = 'Actual Calls';
		salesRepLineChartOptions.tooltip.pointFormat = '<b>{point.y:,.0f}</b>';
		
		
		let accumulatedprospectCalls = 0;
		let accumulatednumCalls = 0;
			
		monthsList.map((month, i) => {
			// Samee - Since it's Calls Goals / month, perhaps we DON'T need to divide by 12
			const prospectCalls = salesRepYearlyprospectCalls; //  / 12;
			let numCalls = 0;
			monthlyData.filter(obj => obj.userId == salesRepId 
				&& obj.month == (i + 1)).forEach(row => {
				numCalls += row.numCalls;	
			});
			/*
			salesRepBarChartOptions.xAxis.categories.push(i + 1);
			salesRepBarChartOptions.series[0].data.push(prospectCalls);
			salesRepBarChartOptions.series[1].data.push(numCalls);
			*/
			accumulatedprospectCalls += prospectCalls;
			accumulatednumCalls += numCalls;
			
			salesRepLineChartOptions.xAxis.categories.push(month);
			salesRepLineChartOptions.series[0].data.push(Math.round(accumulatedprospectCalls * 100) / 100);
			salesRepLineChartOptions.series[1].data.push(Math.round(accumulatednumCalls * 100) / 100 );
		});
		

		
		
		/////////////////////// OVERALL CHARTS /////////////////////
		var overallPieChartOptions = JSON.parse(JSON.stringify(pieChartOptions));
		var overallBarChartOptions = JSON.parse(JSON.stringify(barChartOptions));
		var overallLineChartOptions = JSON.parse(JSON.stringify(lineChartOptions));
		
		
		// Pie Chart
		overallPieChartOptions.title.text = "Percent of Overall Calls Goals";
		
		// Samee - User yearlyprospectCalls instead
		// overallPieChartOptions.series[0].data = overallData.map(obj => ({name: obj.salesRep, x: obj.prospectCalls, y:obj.numCalls}));
		overallPieChartOptions.series[0].data = overallData.map(obj => ({name: obj.salesRep, x: obj.yearlyprospectCalls, y:obj.numCalls}));
		overallPieChartOptions.tooltip.pointFormat = '<b>{point.name}</b>: <b>Total Calls:</b>{point.y:,.0f} <b>Calls Goals:</b>{point.x:,.0f} - <b>{point.percentage:.2f}%</b>';
		
		
		// Bar Chart
		overallBarChartOptions.title.text = "Individual Prospect/Actual Calls";
		overallBarChartOptions.xAxis.categories = overallData.map(obj => obj.salesRep);
		// Samee - User yearlyprospectCalls instead
		// overallBarChartOptions.series[0].data = overallData.map(obj => obj.prospectCalls);
		
		// Samee - Since it's Calls Goals / month, maybe we should multiply by 12
		overallBarChartOptions.series[0].data = overallData.map(obj => obj.yearlyprospectCalls * 12);
		overallBarChartOptions.series[1].data = overallData.map(obj => obj.numCalls);
		overallBarChartOptions.series[0].name = 'Calls Goals';
		overallBarChartOptions.series[1].name = 'Actual Calls';
		overallBarChartOptions.tooltip.pointFormat = '<b>{series.name}</b>: {point.y:,.0f}';
		
		// Line Chart
		overallLineChartOptions.title.text = "Overall Prospect/Actual Calls";
		overallLineChartOptions.series[0].name = 'Calls Goals';
		overallLineChartOptions.series[1].name = 'Actual Calls';
		overallLineChartOptions.tooltip.pointFormat = '<b>{point.y:,.0f}</b>';
		
		
		let overallAccumulatedprospectCalls = 0;
		let overallAccumulatednumCalls = 0;
		
		monthsList.map((month, i) => {
			// Samee - Since it's Calls Goals / month, perhaps we DON'T need to divide by 12
			const prospectCalls = overallYearlyprospectCalls; // / 12;
			let numCalls = 0;
			const dataRows = monthlyData.filter(obj => obj.month == (i + 1));
			if(dataRows != undefined) {
				dataRows.forEach(row => {
					numCalls += row.numCalls;
				});
			}
			
			overallAccumulatedprospectCalls += prospectCalls;
			overallAccumulatednumCalls += numCalls;
			
			overallLineChartOptions.xAxis.categories.push(month);
			overallLineChartOptions.series[0].data.push(Math.round(overallAccumulatedprospectCalls * 100) / 100 );
			overallLineChartOptions.series[1].data.push(Math.round(overallAccumulatednumCalls * 100) / 100  );
		});
		
		
		return (
			<div>
				<DataDropdown 
					label={<Translate value="components.labels.year" />} 
					id={'year'} name={'year'} data={yearsMapped} 
					action={this.yearChanged} value={this.state.year} 
				/>
				{this.state.bSalesManagerOrVIP && <DataDropdown 
					label={<Translate value="components.labels.salesRep" />} 
					id={'salesRep'} name={'salesRep'} data={salesRepsMapped} 
					action={this.salesRepChanged} value={this.state.salesRep} 
				/>}
				<br />
				  <HighchartsReact
					highcharts={Highcharts}
					options={salesRepLineChartOptions}
				  />
				  
				<br />
				{this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallPieChartOptions}
				  />}
				 {this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallBarChartOptions}
				  />}
				  {this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallLineChartOptions}
				  />}
			</div>
		);
	}
}

export default MonthlyProspectCalls;