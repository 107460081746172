import React, { Component } from 'react';
import {Form, Row, Col, Modal, Button} from 'react-bootstrap';

import DataDropdown from '../common_components/DataDropdown.js';
import InputText from '../common_components/InputText.js';
import DataGrid from '../common_components/DataGrid.js';
import NewTaskForm from '../tasks/NewTaskForm.js';
import { getAPI, postAPI, postAPIMultipartForm, mapArrayToObject, mapObjectToArray, getLoggedInUser, resetObject, validateForm, showFormValidationAlert, convertTimestampToDate, constants, sampleList, toleranceMethodsList, spanishCallReportLabels, openBirtReport} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import '../FormPage.css';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');


class CallReportFormDeleteConfirmation extends Component {
    onDelete() {
        this.props.onConfirm();
        this.props.onClose();
    }

    render() {
        return(<Modal show={this.props.visible}>
            <Modal.Body><Translate value='components.labels.deleteCallReportConfirmationPrompt' /></Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
                <Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
            </Modal.Footer>
        </Modal>);
    }
}

const callReportTypes = {
	[constants.call_report_type_general] : 'General', // <Translate value='components.labels.general' />,
	[constants.call_report_type_press_run]: 'Press Run', // <Translate value='components.labels.pressRun' />,
	[constants.call_report_type_blending]: 'Blending', // <Translate value='components.labels.blending' />,
};

const objDeptEmailsList = constants.deptEmailsList;
/*
const deptEmailsList = [
	{value: 'sameeullahqazi@gmail.com', label: 'Samee Gmail'},
	{value: 'sameeullahqazi@yahoo.com', label: 'Samee Yahoo'},
	{value: 'samee@lamproslabs.com', label: 'Samee Lampros'},
];
*/

let validationFields = {
	// customerId: "required",
	callDate: "required",
	// objectives: "maxChars:1000",
	// summary: "maxChars:1000",
	// jobDesc: "maxChars:500",
	// notes: "maxChars:500",
	// initialNotes: "maxChars:500",
	// finalNotes: "maxChars:500",
	// nextStep: "maxChars:250",
};


class NewCallReportForm extends Component {
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		this.state = {
			title: "New Call Report",
			errors: resetObject(validationFields),
			formData: {
				callReportCategoryId: constants.call_report_category_customer,
				callReportTypeLookupId: constants.call_report_type_general,
				customerId:null,
				vendorId:null,
				shipperId:null,
				id:0,
				callDate: null,
				objectives: "",
				summary: "",
				jobDesc: null,
				customerPressId: null,
				pressSpeed: null,
				substrateLookupId: null,
				notes: null,
				initialNotes: null,
				initialToleranceMethod: null,
				finalNotes: null,
				finalToleranceMethod: null,
				nextStep: null,
				dueDate: null,
				model:"com.phototype.myinkiq.model.vos.dtos::CallReportsDto",
				retired:false,
				createdDate:null,
				createdBy:null,
				modifiedBy:null,
				modifiedDate:null,
				deptEmails: '',
			},
			showDeleteConfirmation: false,
			activeCustomers: [],
			activeVendors: [],
			activeShippers: [],
			customerContacts: [],
			callReportContacts: [],
			initCallReportContacts: [],
			salesRepList: [],
			callReportSalesReps: [],
			initCallReportSalesReps: [],
			customerPressesList: [],
			customerFormulasList: [],
			sampleCollectedList: [],
			substratesList: [],
			callReportTakeaways: [],
			initCallReportTakeaways: [],
			callReportTasks: [],
			initCallReportTasks: [],
			callReportImages: [],
			initCallReportImages: [],
			callReportStationDetails: [],
			initCallReportStationDetails: [],
			callReportActionItems: [],
			initCallReportActionItems: [],
			createNewCallReport: false,
			editCallReportTakeawayRow: null,
			selectedCallReportTakeawayIndex: null,
			bShowTakeawayForm: false,
			editCallReportImageRow: null,
			selectedCallReportImageIndex: null,
			bShowImageForm: false,
			editCallReportTaskRow: null,
			selectedCallReportTaskIndex: null,
			bShowTaskForm: false,
			editCallReportStationDetailRow: null,
			selectedCallReportStationDetailIndex: null,
			bShowStationDetailForm: false,
			deptEmailsList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.getCustomerData = this.getCustomerData.bind(this);
		this.initData = this.initData.bind(this);
		this.onCreateNewCallReportChanged = this.onCreateNewCallReportChanged.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
	}
	
	async initData() {
		var customerId = '';
		
		
		
		// Getting Customers list to select from
		const loggedInUser = getLoggedInUser();
		let bVIPOrSalesManager = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
		let activeCustomers = [];
		let activeVendors = [];
		let activeShippers = [];
		let deptEmailsList = [];
		
		// Get a list of all active customers
		await getAPI("customers/list").then(resCustomers => {
			// Samee - should include ALL customers
			activeCustomers = resCustomers['data']; // .filter(obj => obj.status == 'Active');
			
		});
		// console.log("customers fetched");
		
		// Get a list of all active vendors
		await getAPI("vendors/list").then(resVendors => {
			activeVendors = resVendors['data']; // .filter(obj => obj.status == 'Active');
			
		});
		// console.log("vendors fetched");
		// Get a list of all active shippers
		await getAPI("shippers/list").then(resShippers => {
			activeShippers = resShippers['data']; // .filter(obj => obj.status == 'Active');
		});
		
		// console.log("customers, vendors and shippers fetched");
		
		
		if(!bVIPOrSalesManager) {
			// In case of a non VIP, Check if there are any user customers specific to the logged in user
			await getAPI("users/" + loggedInUser.id + "/customers").then(resUserCustomers => {
				const userCustomers = resUserCustomers['data'];
				// If so, filter the customer list accordingly 
				if(userCustomers.length) {
					activeCustomers = userCustomers.map(userCustomer => activeCustomers.filter(activeCustomer => activeCustomer.id == userCustomer.customerId)[0]);
				}
			});
		}
		
		if(this.props.editCustomerId != null) {
			activeCustomers = activeCustomers.filter(row => row.id == this.props.editCustomerId);
		}
		
		this.setState({
			activeCustomers: activeCustomers,
			activeVendors: activeVendors,
			activeShippers: activeShippers,
		});
		
		getAPI("common/deptEmails").then(resDeptEmails => {
			this.setState({
				deptEmailsList: resDeptEmails['data']
			});
		});
		var formData = this.state.formData;
		/*
		if (activeCustomers.length == 1){
			var customerId = activeCustomers[0].id;
			formData['customerId'] = customerId;
			this.setState({
				formData: formData,
			});
			// this.getCustomerData(customerId, formData['callReportTypeLookupId'], this.state.formData.callReportCategoryId);
		}
		else if (activeVendors.length == 1){
			var vendorId = activeVendors[0].id;
			formData['vendorId'] = vendorId;
			this.setState({
				formData: formData,
			});
			// this.getCustomerData(vendorId, formData['callReportTypeLookupId'], this.state.formData.callReportCategoryId);
		}
		else if (activeShippers.length == 1){
			var shipperId = activeShippers[0].id;
			formData['shipperId'] = shipperId;
			this.setState({
				formData: formData,
			});
			// this.getCustomerData(shipperId, formData['callReportTypeLookupId'], this.state.formData.callReportCategoryId);
		}
		*/
	}
	
	onLinkClick(event) {
		const callReportImageId = event.target.id;
		// console.log("event.target: ", event.target, ", callReportImageId: ", callReportImageId);
		// let pdfURL = "myinkiq-0.1.0/callreports/" + callReportImageId + "/downloadImage";
		const imgLocation = event.target.text;
		const arrImgLocation = imgLocation.split("/image.");
		const imgExt = arrImgLocation[1];
		// console.log("arrImgLocation: ", arrImgLocation, ", imgExt: ", imgExt);
		let imgURL = "callReportFile/" + callReportImageId + "/image." + imgExt;
		// window.location.href = pdfURL;
		window.open(imgURL);
	}
	
	// ToDo - customerId should be generic, it could contain vendorId/shipperId
	async getCustomerData(customerVendorShipperId, callReportTypeLookupId, callReportCategoryId) {
		const loggedInUser = getLoggedInUser();
		let bVIPOrSalesManager = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
		
		let promises = [];
		let contactsEndpoint = "customers/" + customerVendorShipperId + "/contacts/list";
		if(callReportCategoryId == constants.call_report_category_vendor)
			contactsEndpoint = "/common/vendorShipperContacts?vendorId=" + customerVendorShipperId + "&shipperId=0";
		else if(callReportCategoryId == constants.call_report_category_shipper)
			contactsEndpoint = "/common/vendorShipperContacts?vendorId=0&shipperId=" + customerVendorShipperId;
		promises.push(getAPI(contactsEndpoint));
		
		
		if(callReportTypeLookupId == undefined) callReportTypeLookupId = this.state.formData.callReportTypeLookupId;
		// console.log("getCustomerData() - this.state.formData.callReportTypeLookupId: ", this.state.formData.callReportTypeLookupId, ", callReportTypeLookupId: ", callReportTypeLookupId);
		
		// Samee - We can simply get ALL employees instead of customer specific employees if its simpler
		// Just pass in bShowAll=1
		// if(!bVIPOrSalesManager && callReportCategoryId == constants.call_report_category_customer) {
			// Get Customer Sales Reps
			// promises.push(getAPI("customers/" + customerVendorShipperId + "/salesreps"));
		// } else {
			// Get All Sales Reps
			// promises.push(getAPI("users/salesreps?bLabTechsRnDs=1"));
		// }
		promises.push(getAPI("users/salesreps?bShowAll=1"));
		
		// if(bVIPOrSalesManager) {
			// Get All Sales Reps
		//	promises.push(getAPI("users/salesreps?bLabTechsRnDs=1"));
		//} else {
			// Get Customer Sales Reps
		//	promises.push(getAPI("customers/" + customerVendorShipperId + "/salesreps"));
		// }

		// var labTechs = [];
		// await getAPI("users/labtechsnrnds?bVIPs=1").then(resLabTechs => {
			// labTechs = resLabTechs['data'].map(obj => ({actorId: obj.id, displayName: obj.name}));
		// });
		// console.log("labTechs: ", labTechs);
		
		if(callReportTypeLookupId != constants.call_report_type_press_run) {
			Promise.all(promises).then(
			([resCustomerContactList, resSalesRep]) => {
				// console.log("resCustomerContactList: ", resCustomerContactList);
				let salesRepList = resSalesRep['data'];
				// if(bVIPOrSalesManager) {
					salesRepList = salesRepList.map(obj => ({actorId: obj.id, displayName: obj.name}));
				// } else {
				// 	salesRepList = salesRepList.concat(labTechs);
				// }
				this.setState({
					customerContacts: resCustomerContactList['data'],
					salesRepList: salesRepList,
				})
			});
		}
		else if(callReportTypeLookupId == constants.call_report_type_press_run) {
			
			// Get Customer Presses
			promises.push(getAPI("customers/" + customerVendorShipperId + "/presses/list"));
			
			// Get Customer Formulas
			promises.push(getAPI("customers/" + customerVendorShipperId + "/formulas"));
			
			// Get Substrates List
			promises.push(getAPI("/common/substrates"));

			Promise.all(promises).then(
			([resCustomerContactList, resSalesRep, resCustomerPresses, resCustomerFormulas, resSubstrates]) => {
				// console.log("resCustomerContactList: ", resCustomerContactList);
				let salesRepList = resSalesRep['data'];
				// if(bVIPOrSalesManager) {
					salesRepList = salesRepList.map(obj => ({actorId: obj.id, displayName: obj.name}));
				// } else {
					// salesRepList = salesRepList.concat(labTechs);
				// }
				
				this.setState({
					customerContacts: resCustomerContactList['data'],
					salesRepList: salesRepList,
					customerPressesList: resCustomerPresses['data'],
					customerFormulasList: resCustomerFormulas['data'],
					substratesList: resSubstrates['data'],
				})
			});
		}
	}
	
	onCreateNewCallReportChanged(event) {
		 const val = event.target.checked;
		 // console.log("onCreateNewCallReportChanged() - val: ", val);
		 this.setState({
			 createNewCallReport: val
		 });
	}

	componentDidMount() {
		this.initData();
		
		// console.log("this.props.editCallReportId: ", this.props.editCallReportId);
		if(this.props.editCallReportId != null)
		{
			// console.log("In edit mode...");
			var editCallReportId = this.props.editCallReportId;
			
			getAPI("/callreports/" + editCallReportId).then(resCallReport => {
				
				const formData = resCallReport['data'][0];
				// console.log("formData: ", formData);
				const title = 'CallReport #: ' + editCallReportId;
				
				// ToDo - customerId, vendorId or shipperId could be passed based upon the selected category (Customer/Vendor/Shipper)
				var customerVendorShipperId = formData['customerId'];
				if(formData['callReportCategoryId'] == constants.call_report_category_vendor)
					customerVendorShipperId = formData['vendorId'];
				else if(formData['callReportCategoryId'] == constants.call_report_category_shipper)
					customerVendorShipperId = formData['shipperId'];
				this.getCustomerData(customerVendorShipperId, formData['callReportTypeLookupId'], formData['callReportCategoryId']);
				
				let callReportDataPromises = [];
				
				// Get Call Reports Contacts 
				callReportDataPromises.push(getAPI("/callreports/" + editCallReportId + "/contacts"));
				
				// Get Call Reports Tasks 
				callReportDataPromises.push(getAPI("/callreports/" + editCallReportId + "/tasks"));
				
				// Get Call Report Sales Reps
				callReportDataPromises.push(getAPI("/callreports/" + editCallReportId + "/salesreps"));
				
				// Get Call Report Images
				callReportDataPromises.push(getAPI("/callreports/" + editCallReportId + "/images"));
				
				if(formData.callReportTypeLookupId != constants.call_report_type_press_run) {
					// Get Call Report Takeaways
					callReportDataPromises.push(getAPI("/callreports/" + editCallReportId + "/takeaways"));
					
					Promise.all(callReportDataPromises).then(
					([resContacts, resTasks, resCallReportSalesReps, reCallReportImages, resTakeaways]) => {
						console.log("this.state.customerContacts: ", this.state.customerContacts, ", resContacts['data']: ", resContacts['data']);
						let callReportContacts = resContacts['data'];
						this.setState({
							callReportContacts: callReportContacts,
							initCallReportContacts: JSON.parse(JSON.stringify(callReportContacts)),
							callReportTasks: resTasks['data'],
							callReportSalesReps: resCallReportSalesReps['data'],
							initCallReportTasks: JSON.parse(JSON.stringify(resTasks['data'])),
							callReportTakeaways: resTakeaways['data'],
							initCallReportImages: JSON.parse(JSON.stringify(reCallReportImages['data'])),
							callReportImages: reCallReportImages['data'],
							initCallReportSalesReps: JSON.parse(JSON.stringify(resCallReportSalesReps['data'])),
							initCallReportTakeaways: JSON.parse(JSON.stringify(resTakeaways['data'])),
						});
					});
					
				} else if(formData.callReportTypeLookupId == constants.call_report_type_press_run) {
					
					
					
					// Get Call Report Station Details
					callReportDataPromises.push(getAPI("/callreports/" + editCallReportId + "/stationdetails"));
					
					Promise.all(callReportDataPromises).then(
					([resContacts, resTasks, resCallReportSalesReps, reCallReportImages, resCallReportStationDetails]) => {
						// console.log(", resCallReportSalesReps['data']: ", resCallReportSalesReps['data'], ", resCallReportStationDetails: ", resCallReportStationDetails['data']);
						let callReportContacts = resContacts['data'];
						this.setState({
							callReportContacts: callReportContacts,
							initCallReportContacts: JSON.parse(JSON.stringify(callReportContacts)),
							callReportTasks: resTasks['data'],
							initCallReportTasks: JSON.parse(JSON.stringify(resTasks['data'])),
							callReportSalesReps: resCallReportSalesReps['data'],
							initCallReportSalesReps: JSON.parse(JSON.stringify(resCallReportSalesReps['data'])),
							initCallReportImages: JSON.parse(JSON.stringify(reCallReportImages['data'])),
							callReportImages: reCallReportImages['data'],
							callReportStationDetails: resCallReportStationDetails['data'],
							initCallReportStationDetails: JSON.parse(JSON.stringify(resCallReportStationDetails['data'])),
						});
						
						if(resCallReportStationDetails['data'].length == 0) {
							getAPI("customers/" + formData.customerId + "/presses/" + formData.customerPressId + "/stations").then(resPressStations => {	
								// Create Station Details data
								let callReportStationDetails = resPressStations['data'].map(obj => ({
									customerPressStationId: obj.id, 
									customerPressStationNumber: obj.stationNumber, 
									formulaId: null, 
									formulaNumber: '-', 
									formulaBatchNumberId: null, 
									formulaBatchNumber: '-', 
									customerPressStationAniloxId: null, 
									customerPressStationAnilox: '-', 
									dryerPct: 0.0, 
									uv: false,
									callReportId: formData.id,
								}));
								
								this.setState({
									callReportContacts: callReportContacts,
									initCallReportContacts: JSON.parse(JSON.stringify(callReportContacts)),
									callReportTasks: resTasks['data'],
									initCallReportTasks: JSON.parse(JSON.stringify(resTasks['data'])),
									initCallReportImages: JSON.parse(JSON.stringify(reCallReportImages['data'])),
									callReportImages: reCallReportImages['data'],
									callReportSalesReps: resCallReportSalesReps['data'],
									initCallReportSalesReps: JSON.parse(JSON.stringify(resCallReportSalesReps['data'])),
									// callReportStationDetails: resCallReportStationDetails['data'],
									initCallReportStationDetails: JSON.parse(JSON.stringify(resCallReportStationDetails['data'])),
									callReportStationDetails: callReportStationDetails,
								});
							});
						}
					});
				}
				
			
				this.setState({
					title: title,
					formData: formData,
				});
			});
		}
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var formData = this.state.formData;
		var value = '';
		
		if(name == 'customerId' || name == 'vendorId' || name == 'shipperId') {
			value = event.value;
			formData[name] = value;
			this.setState({
			  formData: formData,
			});
			// ToDo - repeat this code for vendor/shipper
			this.getCustomerData(value, formData['callReportTypeLookupId'], this.state.formData.callReportCategoryId);
		} else if(name == 'callReportTypeLookupId') {
			value = event.target.value;
			// console.log("callReportTypeLookupId: ", value);
			formData['callReportTypeLookupId'] = value;
			this.setState({
			  formData: formData,
			});
			// ToDo - make 1st argument generic for vendor/shipper, not fixed to customerId
			let customerVendorShipperId = formData['customerId'];
			if(this.state.formData.callReportCategoryId == constants.call_report_category_vendor)
				customerVendorShipperId = formData['vendorId'];
			else if(this.state.formData.callReportCategoryId == constants.call_report_category_shipper)
				customerVendorShipperId = formData['shipperId'];
			this.getCustomerData(customerVendorShipperId, value, this.state.formData.callReportCategoryId);
		} 
		else if(name == 'callReportContacts') {
			value = event;
			var callReportContacts = [];
			if(this.state.formData.callReportCategoryId == constants.call_report_category_customer)
				callReportContacts = value.map(obj => ({customerContactId: obj.value, customerContactName: obj.label, vendorShipperContactId: 0}));
			else
				callReportContacts = value.map(obj => ({customerContactId: 0, vendorShipperContactName: obj.label, vendorShipperContactId: obj.value}));
			
			// console.log("callReportContacts - value: ", value, ", callReportContacts: ", callReportContacts);
			this.setState({
				callReportContacts: callReportContacts,
			});
		} else if(name == 'callReportSalesReps') {
			value = event;
			// console.log("callReportSalesReps - value: ", value);
			var callReportSalesReps = value.map(obj => ({salesRepId: obj.value, salesRepName: obj.label}));
			this.setState({
				callReportSalesReps: callReportSalesReps,
			});
		}  else if(name == 'deptEmails') {
			value = event;
			// console.log("deptEmails - value: ", value);
			
			let strDeptEmails = value != null && value.length > 0 ? value.map(obj => obj.value).join(";") : "";
			// console.log("strDeptEmails: ", strDeptEmails);
			formData['deptEmails'] = strDeptEmails;
			this.setState({
			  formData: formData,
			});
		} else if(name == 'customerPressId') {
			value = target.value;
			// console.log("customerPressId - value: ", value);
			
			let callReportStationDetails = JSON.parse(JSON.stringify(this.state.initCallReportStationDetails));
			// console.log("this.state.initCallReportStationDetails: ", this.state.initCallReportStationDetails);
			// Get Customer Press Stations;
			getAPI("customers/" + formData.customerId + "/presses/" + value + "/stations").then(resPressStations => {	
				// Create Station Details data
				let newCallReportStationDetails = resPressStations['data'].map(obj => ({
					customerPressStationId: obj.id, 
					customerPressStationNumber: obj.stationNumber, 
					formulaId: null, 
					formulaNumber: '-', 
					formulaBatchNumberId: null, 
					formulaBatchNumber: '-', 
					customerPressStationAniloxId: null, 
					customerPressStationAnilox: '-', 
					dryerPct: 0.0, 
					uv: false,
					callReportId: formData.id,
				}));
				formData[name] = value;
				
				// If any new stations are found that do not already exist, add them
				newCallReportStationDetails.forEach((newRow, i) => {
					var existingRow = callReportStationDetails.filter(row => row.customerPressStationNumber == newRow.customerPressStationNumber);
					if(existingRow[0] == null) {
						callReportStationDetails.push(newRow);
					}
				});
				
				// Sorting by Station number 
				callReportStationDetails.sort((a,b) => (a.customerPressStationNumber > b.customerPressStationNumber) ? 1 : ((b.customerPressStationNumber > a.customerPressStationNumber) ? -1 : 0));
				
				this.setState({
				  formData: formData,
				  callReportStationDetails: callReportStationDetails,
				});
			});
			
		}
		else {
			switch(name) 
			{		
				case 'callDate':
				case 'dueDate':
					value = event._d ? event._d.getTime() : event;
					break;
				
				default:
					value = target.type === 'checkbox' ? target.checked : target.value;
			}
			// console.log("setting form state - name: ", name, ", value: ", value);
			formData[name] = value;
			this.setState({
			  formData: formData
			});
		}
		// console.log("value: ", value);
	}

	onSave() {

		const validation = validateForm(this.state.formData, validationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			// console.log("this.state.formData: ", this.state.formData);
			postAPI("callreports", this.state.formData).then(resCallReport => {
				var callReportId = resCallReport['data'][0]['id'];
				this.reactGA.gaEvent("Call Report", "Save", "Call Report saved successfully.");
				
				// console.log("this.state.initCallReportContacts: ", this.state.initCallReportContacts, ", this.state.callReportContacts: ", this.state.callReportContacts);
				// Add Call Report Contacts
				this.state.callReportContacts.forEach(row => {
					const existingRow = this.state.formData.callReportCategoryId == constants.call_report_category_customer ? this.state.initCallReportContacts.filter(initRow => initRow.customerContactId == row.customerContactId) :
					this.state.initCallReportContacts.filter(initRow => initRow.vendorShipperContactId == row.vendorShipperContactId);
					if(existingRow.length == 0) {
						// Row does not exist so add it.
						row['callReportId'] = callReportId;
						delete row['customerContactName'];
						delete row['vendorShipperContactName'];
						postAPI("callreports/" + callReportId + "/contacts", row); 
					}
				});

				// Delete Call Report Contacts
				this.state.initCallReportContacts.forEach(initRow => {
					const existingRow = this.state.formData.callReportCategoryId == constants.call_report_category_customer ?  this.state.callReportContacts.filter(row => initRow.customerContactId == row.customerContactId) :
					this.state.callReportContacts.filter(row => initRow.vendorShipperContactId == row.vendorShipperContactId);
					if(existingRow.length == 0) {
						// Row does not exist so delete it.
						postAPI("callreports/" + callReportId + "/contacts/" + initRow.id, {}); 
					}
				});
				
				
					
				// Save Call Report Sales Reps
				this.state.callReportSalesReps.forEach(row => {
					const existingRow = this.state.initCallReportSalesReps.filter(initRow => initRow.salesRepId == row.salesRepId);
					if(existingRow.length == 0) {
						// Row does not exist so add it.
						row['callReportId'] = callReportId;
						postAPI("callreports/" + callReportId + "/salesreps", row); 
					}
				});

				// Delete Call Report Sales Reps
				this.state.initCallReportSalesReps.forEach(initRow => {
					const existingRow = this.state.callReportSalesReps.filter(row => initRow.salesRepId == row.salesRepId);
					if(existingRow.length == 0) {
						// Row does not exist so delete it.
						postAPI("callreports/" + callReportId + "/salesreps/" + initRow.id, {}); 
					}
				});
				
				
				// Save Call Report Images
				this.state.callReportImages.forEach(row => {
					const existingRow = this.state.initCallReportImages.filter(initRow => initRow.id == row.id);
					/*
					if(existingRow.length == 0) {
						// Row does not exist so add it.
						row['callReportId'] = callReportId;
						postAPI("callreports/" + callReportId + "/images", row).then(imgResp => {
							console.log("imgResp when adding: ", imgResp);
						}); 
					} else {
						// Row exists so save it if any modifications haven been made
						if(row['bModified']) {
							postAPI("callreports/" + callReportId + "/images", row)then(imgResp => {
								console.log("imgResp on updating: ", imgResp);
							}); 
						}
					}
					*/
					// console.log("row: ", row);
					if(existingRow.length == 0 || row['bModified']) {
						row['callReportId'] = callReportId;
						const imgFile = row['imgFile'];
						delete row['imgFile'];
						
						postAPI("callreports/" + callReportId + "/images", row).then(imgResp => {
							// console.log("imgResp when saving: ", imgResp);
							if(imgFile != null) {
								const callReportImageId = imgResp['data'][0]["id"]; // dataIn["id"];
								postAPIMultipartForm("callreports/" + callReportId + "/uploadImage/" + callReportImageId, imgFile).then((res) => {
									// this.refreshContractsData();
									if(res.error != null) {
										alert("Error uploading Media file for callReportImage " + callReportImageId + ": " + res.error.error);
									}
								});
							}
						}); 
					}
				});

				// Delete Call Report Images
				this.state.initCallReportImages.forEach(initRow => {
					const existingRow = this.state.callReportImages.filter(row => initRow.id == row.id);
					if(existingRow.length == 0) {
						// Row does not exist so delete it.
						postAPI("callreports/" + callReportId + "/images/" + initRow.id, {}); 
					}
				});
				
				if(this.state.formData.callReportTypeLookupId != constants.call_report_type_press_run) {
					// Add/Edit Call Report Takeaways
					this.state.callReportTakeaways.forEach(row => {
						const existingRow = this.state.initCallReportTakeaways.filter(initRow => initRow.id == row.id);
						if(existingRow.length == 0) {
							// Row does not exist so add it.
							row['callReportId'] = callReportId;
							postAPI("callreports/" + callReportId + "/takeaways", row); 
						} else {
							// Row exists so save it if any modifications haven been made
							if(row['bModified']) {
								postAPI("callreports/" + callReportId + "/takeaways", row);
							}
						}
					});
					
					// Delete Call Report Takeaways
					this.state.initCallReportTakeaways.forEach(initRow => {
						const existingRow = this.state.callReportTakeaways.filter(row => initRow.id == row.id);
						if(existingRow.length == 0) {
							// Row does not exist so delete it.
							postAPI("callreports/" + callReportId + "/takeaways/" + initRow.id, {}); 
						}
					});
				} else if(this.state.formData.callReportTypeLookupId == constants.call_report_type_press_run) {
					
					// console.log("initCallReportSalesReps: ", this.state.initCallReportSalesReps, ", callReportSalesReps: ", this.state.callReportSalesReps);
					
					
					// console.log("initCallReportStationDetails: ", this.state.initCallReportStationDetails, ", callReportStationDetails: ", this.state.callReportStationDetails);

					// Save Call Report Station Details
					this.state.callReportStationDetails.forEach(row => {
						const existingRow = this.state.initCallReportStationDetails.filter(initRow => initRow.customerPressStationId == row.customerPressStationId);
						if(existingRow.length == 0) {
							// Row does not exist so add it.
							row['callReportId'] = callReportId;
							postAPI("callreports/" + callReportId + "/stationdetails", row); 
						} else {
							// Row exists so save it if any modifications haven been made
							if(row['bModified']) {
								postAPI("callreports/" + callReportId + "/stationdetails", row);
							}
						}
					});

					// Delete Call Report Station Details
					this.state.initCallReportStationDetails.forEach(initRow => {
						const existingRow = this.state.callReportStationDetails.filter(row => initRow.customerPressStationId == row.customerPressStationId);
						if(existingRow.length == 0) {
							// Row does not exist so delete it.
							postAPI("callreports/" + callReportId + "/stationdetails/" + initRow.id, {}); 
						}
					});
				}
				
				
				let bTasksModified = false;
				// Add/Edit Call Report Tasks
				this.state.callReportTasks.forEach(row => {
					const existingRow = this.state.initCallReportTasks.filter(initRow => initRow.id == row.id);
					if(existingRow.length == 0) {
						// Row does not exist so add it.
						row['callReportId'] = callReportId;
						postAPI("tasks", row); 
					} else {
						// Row exists so edit if needed
						if(row['bModified']) {
							postAPI("tasks", row); 
						}
						
					}
					bTasksModified = true;
				});
				
				// Delete Call Report Tasks
				this.state.initCallReportTasks.forEach(initRow => {
					const existingRow = this.state.callReportTasks.filter(row => initRow.id == row.id);
					if(existingRow.length == 0) {
						// Row does not exist so delete it.
						postAPI("/tasks/" + initRow.id, {}); 
						bTasksModified = true;
					}
				});
				
				alert("Call Report has been saved successfully");
				this.props.onSave();
				if(bTasksModified)
					this.props.refreshTasksData();
			});
		}
	}
	
	onDelete() {
		postAPI("callreports/" + this.state.formData.id, this.state.formData).then(resCallReport => {

			// alert(<Translate value="components.labels.componentDeleted" />);
			alert("CallReport has been deleted successfully");
			this.reactGA.gaEvent("Call Report", "Delete", "Call Report saved successfully.");
			this.props.onSave();
		});
	}
	
	shouldComponentUpdate() {
		// var bUpdate = this.state.vendorsList.length > 0;
		return true;
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		
		var activeCustomersMapped = this.state.activeCustomers.filter(row => row != undefined).map((obj, key) => ({ value: obj.id, label: obj.name }));
		const customerContactsMapped = this.state.customerContacts.map(obj => ({ value: obj.id, label: obj.name }));
		const salesRepListMapped = this.state.salesRepList.map(obj => ({ value: obj.actorId, label: obj.displayName }));
		const customerName = this.state.formData.customerId ?
			activeCustomersMapped.filter(obj => obj.value == this.state.formData.customerId)[0] : null;

		// ToDos - Samee - fill in this info
		var activeVendorsMapped = this.state.activeVendors.filter(row => row != undefined).map((obj, key) => ({ value: obj.id, label: obj.name }));
		const vendorContactsMapped = {};
		var vendorName = this.state.formData.vendorId ?
			activeVendorsMapped.filter(obj => obj.value == this.state.formData.vendorId)[0] : null;
		
		var activeShippersMapped = this.state.activeShippers.filter(row => row != undefined).map((obj, key) => ({ value: obj.id, label: obj.name }));;
		const shipperContactsMapped = {};
		var shipperName = this.state.formData.shipperId ?
			activeShippersMapped.filter(obj => obj.value == this.state.formData.shipperId)[0] : null;

		
		const deptEmailsList = this.state.deptEmailsList.map((obj) => ({value: obj.name, label: obj.code}));
		// console.log("deptEmailsList: ", deptEmailsList);
		const deptEmailsMapped = this.state.formData.deptEmails != null && this.state.formData.deptEmails != "" && deptEmailsList.length > 0 ? 
			this.state.formData.deptEmails.split(";").map((email) => ({value: email, label: deptEmailsList.filter(row => row.value == email)[0].label})) : [];
		// console.log("deptEmailsList: ", deptEmailsList);

		const callDateFormatted = isNaN(this.state.formData.callDate) || this.state.formData.callDate == 0 || this.state.formData.callDate == null ? this.state.formData.callDate : convertTimestampToDate(this.state.formData.callDate);

		const dueDateFormatted = isNaN(this.state.formData.dueDate) || this.state.formData.dueDate == 0 || this.state.formData.dueDate == null ? this.state.formData.dueDate : convertTimestampToDate(this.state.formData.dueDate);

		const bDeleteEnabled = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
		
		let callReportContactsMapped = this.state.formData.callReportCategoryId == constants.call_report_category_customer ?  this.state.callReportContacts.map(obj => (
			{value: obj.customerContactId, label: obj.customerContactName})
		) : 
		this.state.callReportContacts.map(obj => (
			{value: obj.vendorShipperContactId, label: obj.vendorShipperContactName})
		);
		// console.log("callReportContactsMapped: ", callReportContactsMapped, ", customerContactsMapped: ", customerContactsMapped);
		
		let callReportSalesRepsMapped = this.state.callReportSalesReps.map(obj => (
			{value: obj.salesRepId, label: obj.salesRepName})
		);
		
		let customerPressesMapped = mapArrayToObject(this.state.customerPressesList, 'id', 'pressName');
		// console.log("salesRepListMapped: ", salesRepListMapped, ", callReportSalesRepsMapped: ", callReportSalesRepsMapped);
		
		const substratesMapped = mapArrayToObject(this.state.substratesList, 'id', 'name');
		const callReportTaskForm = this.state.bShowTaskForm ? 
		<NewTaskForm 
			onClose={() => {this.setState({bShowTaskForm: false});}}
			onSave={(dataIn, bCreateNewTask) => {
				let callReportTasks = this.state.callReportTasks;
				// console.log("this.state.selectedCallReportTaskIndex: ", this.state.selectedCallReportTaskIndex, ", bCreateNewTask: ", bCreateNewTask, ", dataIn: ", dataIn);
				if(this.state.selectedCallReportTaskIndex == null || bCreateNewTask) {
					callReportTasks.push(dataIn);
				} else {
					dataIn['bModified'] = true;
					callReportTasks[this.state.selectedCallReportTaskIndex] = dataIn;
				}
				this.setState({
					callReportTasks: callReportTasks,
					bShowTaskForm: bCreateNewTask,
				});
			}}
			visible={this.state.bShowTaskForm}
			editCallReportTaskRow={this.state.editCallReportTaskRow}
			callReportId={this.state.formData.id}
			callReportCustomerId={this.state.formData.customerId}
			// callReportVendorId={this.state.formData.vendorId}
			callReportShipperId={this.state.formData.shipperId}
			activeCustomers={this.state.activeCustomers}
			activeVendors={this.state.activeVendors}
			activeShippers={this.state.activeShippers}
			taskCategoryId={this.state.formData.callReportCategoryId}
		/>
		: '';
		
		// Callreport Image Form
		const callReportImageForm = this.state.bShowImageForm ? 
			<NewCallReportImageForm 
				onClose={() => {this.setState({bShowImageForm: false});}}
				onSave={(dataIn) => {
					let callReportImages = this.state.callReportImages;
					if(this.state.selectedCallReportImageIndex == null) {
						callReportImages.push(dataIn);
					} else {
						dataIn['bModified'] = true;
						callReportImages[this.state.selectedCallReportImageIndex] = dataIn;
					}
					this.setState({
						callReportImages: callReportImages,
						bShowImageForm: false,
					});
				}}
				visible={this.state.bShowImageForm}
				editCallReportImageRow={this.state.editCallReportImageRow}
			/>
			: '';
		
		let typeSpecificContent = '';
		let lblContacts = "";
		let lblObjectives = "";
		let lblSummary = "";
		if(this.state.formData.callReportTypeLookupId != constants.call_report_type_press_run) {
			lblContacts = "metWith";
			lblObjectives = "meetingObjectives";
			lblSummary = "meetingSummary";
			// Call Report Takeaway Form
			const callReportTakeawayForm = this.state.bShowTakeawayForm ? 
			<NewCallReportTakeawayForm 
				onClose={() => {this.setState({bShowTakeawayForm: false});}}
				onSave={(dataIn) => {
					let callReportTakeaways = this.state.callReportTakeaways;
					if(this.state.selectedCallReportTakeawayIndex == null) {
						callReportTakeaways.push(dataIn);
					} else {
						dataIn['bModified'] = true;
						callReportTakeaways[this.state.selectedCallReportTakeawayIndex] = dataIn;
					}
					this.setState({
						callReportTakeaways: callReportTakeaways,
						bShowTakeawayForm: false,
					});
				}}
				visible={this.state.bShowTakeawayForm}
				editCallReportTakeawayRow={this.state.editCallReportTakeawayRow}
			/>
			: '';
			
			typeSpecificContent = 
			<Row>
				<Col md={9}>
					<DataGrid 
						columns={['Takeaway']} 
						fields={['takeawayName']} 
						data={this.state.callReportTakeaways} 
						formatColumns={{}} 
						onDoubleClick={(event) => {
							this.setState({editCallReportTakeawayRow: event, bShowTakeawayForm: true});
						}}
						onSelect={(event => {this.setState({selectedCallReportTakeawayIndex: event});})}
					/>
					<Button onClick={() => {
						this.setState({
							bShowTakeawayForm: true, 
							selectedCallReportTakeawayIndex: null, 
							editCallReportTakeawayRow: null
						});
					}} >
						<Translate value="components.buttons.add" />
					</Button>
					&nbsp;
					<Button onClick={() => {
						let selectedCallReportTakeawayIndex = this.state.selectedCallReportTakeawayIndex;
						if(selectedCallReportTakeawayIndex != null) {
							let callReportTakeaways = this.state.callReportTakeaways;
							callReportTakeaways.splice(selectedCallReportTakeawayIndex, 1);
							const numTakeaways = callReportTakeaways.length;
							if(numTakeaways == 0)
								selectedCallReportTakeawayIndex = null;
							else if(selectedCallReportTakeawayIndex == numTakeaways)
								selectedCallReportTakeawayIndex--;
							
							this.setState({
								selectedCallReportTakeawayIndex: selectedCallReportTakeawayIndex,
								callReportTakeaways: callReportTakeaways,
							});
						}
					}}>
						<Translate value="components.buttons.remove" />
					</Button>
					{callReportTakeawayForm}
					<DataGrid 
						columns={['Task', 'Due Date', 'Completed']} 
						fields={['toDo', 'dueDateTime', 'id']} 
						data={this.state.callReportTasks} 
						formatColumns={
							{1: {'date': true}, 
							2: {checkbox: {}, 
								colCheckBound: 'completed',
								callbackCheckbox: (event) => {
									const checked = event.target.checked;
									const value = event.target.value;
									// console.log("checked: ", checked, ", value: ", value);
									
									let callReportTasks = this.state.callReportTasks;
									const rowIndex = callReportTasks.findIndex((row) => {
										return row.id == value;
									});
									// console.log("rowIndex: ", rowIndex);
									if(rowIndex > -1) {
										callReportTasks[rowIndex]['completed'] = checked;
										callReportTasks[rowIndex]['bModified'] = true;
										this.setState({callReportTasks: callReportTasks});
									}
								}
						}}}
						onDoubleClick={(event) => {
							this.setState({editCallReportTaskRow: event, bShowTaskForm: true});
						}}
						onSelect={(event => {this.setState({selectedCallReportTaskIndex: event});})}

					/>
					<Button onClick={() => {
						this.setState({
							bShowTaskForm: true, 
							selectedCallReportTaskIndex: null, 
							editCallReportTaskRow: null
						});
					}} >
						<Translate value="components.buttons.add" />
					</Button>
					&nbsp;
					<Button onClick={() => {
						let selectedCallReportTaskIndex = this.state.selectedCallReportTaskIndex;
						if(selectedCallReportTaskIndex != null) {
							let callReportTasks = this.state.callReportTasks;
							callReportTasks.splice(selectedCallReportTaskIndex, 1);
							const numTakeaways = callReportTasks.length;
							if(numTakeaways == 0)
								selectedCallReportTaskIndex = null;
							else if(selectedCallReportTaskIndex == numTakeaways)
								selectedCallReportTaskIndex--;
							
							this.setState({
								selectedCallReportTaskIndex: selectedCallReportTaskIndex,
								callReportTasks: callReportTasks,
							});
						}
					}}>
						<Translate value="components.buttons.remove" />
					</Button>
					{callReportTaskForm}
				</Col>
			</Row>;
			} else if(this.state.formData.callReportTypeLookupId == constants.call_report_type_press_run) {
				//	Samee - This should just say Met With 
				lblContacts = "metWith"; // "supervisedRun";
				lblObjectives = "pressRunObjectives";
				lblSummary = "pressRunSummary";
				const callReportStationDetailForm = this.state.bShowStationDetailForm ? 
				<NewCallReportStationDetailForm 
					onClose={() => {this.setState({bShowStationDetailForm: false});}}
					onSave={(dataIn) => {
						let callReportStationDetails = this.state.callReportStationDetails;
						if(this.state.selectedCallReportStationDetailIndex == null) {
							callReportStationDetails.push(dataIn);
						} else {
							dataIn['bModified'] = true;
							callReportStationDetails[this.state.selectedCallReportStationDetailIndex] = dataIn;
						}
						this.setState({
							callReportStationDetails: callReportStationDetails,
							bShowStationDetailForm: false,
						});
					}}
					visible={this.state.bShowStationDetailForm}
					editCallReportStationDetailRow={this.state.editCallReportStationDetailRow}
					customerId={this.state.formData.customerId}
					customerPressId={this.state.formData.customerPressId}
					customerFormulasList={this.state.customerFormulasList}
					sampleCollectedList={this.state.sampleCollectedList}
				/>
				: '';
				
				typeSpecificContent = 
				<Row>
					<Col md={9}>
						<InputText 
							name={"jobDesc"} 
							label={this.state.formData.spanish ? spanishCallReportLabels['jobDesc'][1] : <Translate value="components.labels.jobDesc" />}  
							action={this.handleInputChanged}
							value={this.state.formData.jobDesc}  
						/>
						<DataDropdown label={this.state.formData.spanish ? spanishCallReportLabels['press'][1] : <Translate value="components.labels.press" />} 
							id={"customerPressId"} name={"customerPressId"} 
							action={this.handleInputChanged} required
							errMsg={this.state.errors.customerPressId} data={customerPressesMapped} 
							value={this.state.formData.customerPressId} 
						/>
						<InputText 
							name={"pressSpeed"} 
							label={this.state.formData.spanish ? spanishCallReportLabels['pressSpeed'][1] : <Translate value="components.labels.pressSpeed" />}  
							action={this.handleInputChanged}
							value={this.state.formData.pressSpeed}  
						/>
						<DataDropdown label={this.state.formData.spanish ? spanishCallReportLabels['substrate'][1] : <Translate value="components.labels.substrate" />} 
							id={"substrateLookupId"} name={"substrateLookupId"} 
							action={this.handleInputChanged} required
							errMsg={this.state.errors.substrateLookupId} data={substratesMapped} 
							value={this.state.formData.substrateLookupId} 
						/>
						<InputText 
							label={this.state.formData.spanish ? spanishCallReportLabels['initialNotes'][1] : <Translate value="components.labels.initialNotes" />} id={"notes"} 
							name={"notes"} action={this.handleInputChanged} type={"TextArea"} 
							value={this.state.formData.notes}  
						/>
						<DataDropdown label={this.state.formData.spanish ? spanishCallReportLabels['toleranceMethod'][1] : <Translate value="components.labels.toleranceMethod" />} 
							id={"initialToleranceMethod"} name={"initialToleranceMethod"} 
							action={this.handleInputChanged}
							errMsg={this.state.errors.initialToleranceMethod} data={toleranceMethodsList} 
							value={this.state.formData.initialToleranceMethod} 
						/>
						
						<DataGrid 
							columns={[
								this.state.formData.spanish ? spanishCallReportLabels['station'][1] : <Translate value="components.labels.station" />, 
								this.state.formData.spanish ? spanishCallReportLabels['formulaCol'][1] : <Translate value="components.labels.formulaCol" />, 
								this.state.formData.spanish ? spanishCallReportLabels['batch'][1] : <Translate value="components.labels.batch" />, 
								this.state.formData.spanish ? spanishCallReportLabels['anilox'][1] : <Translate value="components.labels.anilox" />, 
								this.state.formData.spanish ? spanishCallReportLabels['dryerPct'][1] : <Translate value="components.labels.dryerPct" />, 
								this.state.formData.spanish ? spanishCallReportLabels['uv'][1] : <Translate value="components.labels.uv" />, 
								this.state.formData.spanish ? spanishCallReportLabels['pHInitial'][1] : <Translate value="components.labels.pHInitial" />, 
								this.state.formData.spanish ? spanishCallReportLabels['viscosityInitial'][1] : <Translate value="components.labels.viscosityInitial" />, 
								this.state.formData.spanish ? spanishCallReportLabels['dEInitial'][1] : <Translate value="components.labels.dEInitial" />, 
								this.state.formData.spanish ? spanishCallReportLabels['densityInitial'][1] : <Translate value="components.labels.densityInitial" />, 
								this.state.formData.spanish ? spanishCallReportLabels['pHFinal'][1] : <Translate value="components.labels.pHFinal" />, 
								this.state.formData.spanish ? spanishCallReportLabels['viscosityFinal'][1] : <Translate value="components.labels.viscosityFinal" />, 
								this.state.formData.spanish ? spanishCallReportLabels['dEFinal'][1] : <Translate value="components.labels.dEFinal" />, 
								this.state.formData.spanish ? spanishCallReportLabels['densityFinal'][1] : <Translate value="components.labels.densityFinal" />,
								this.state.formData.spanish ? spanishCallReportLabels['sampleCollected'][1] : <Translate value="components.labels.sampleCollected" />
							]}
							fields={[
								'customerPressStationNumber', 
								'formulaNumber', 
								'formulaBatchNumber', 
								'customerPressStationAnilox', 
								'dryerPct', 
								'id', 
								'pHInitial', 
								'viscosityInitial', 
								'toleranceInitial',
								'densityInitial', 
								'pHFinal', 
								'viscosityFinal', 
								'toleranceFinal',
								'densityFinal', 
								'samplesCollected'
							]} 
							data={this.state.callReportStationDetails} 
							formatColumns={{
								5: {checkbox: {}, 
									colCheckBound: 'uv',
									callbackCheckbox: (event) => {
										const checked = event.target.checked;
										const value = event.target.value;
										// console.log("checked: ", checked, ", value: ", value);
										
										let callReportStationDetails = this.state.callReportStationDetails;
										const rowIndex = callReportStationDetails.findIndex((row) => {
											return row.id == value;
										});
										// console.log("rowIndex: ", rowIndex);
										if(rowIndex > -1) {
											callReportStationDetails[rowIndex]['uv'] = checked;
											callReportStationDetails[rowIndex]['bModified'] = true;
											this.setState({callReportStationDetails: callReportStationDetails});
										}
									}
							}}} 
							onDoubleClick={(event) => {
								this.setState({editCallReportStationDetailRow: event, bShowStationDetailForm: true});
							}}
							onSelect={(event => {this.setState({selectedCallReportStationDetailIndex: event});})}
						/>
						<InputText 
							label={this.state.formData.spanish ? spanishCallReportLabels['notesAdjustments'][1] : <Translate value="components.labels.notesAdjustments" />} id={"initialNotes"} 
							name={"initialNotes"} action={this.handleInputChanged} type={"TextArea"} 
							value={this.state.formData.initialNotes}  
						/>
						
						<InputText 
							label={this.state.formData.spanish ? spanishCallReportLabels['finalNotes'][1] : <Translate value="components.labels.finalNotes" />} id={"finalNotes"} 
							name={"finalNotes"} action={this.handleInputChanged} type={"TextArea"} 
							value={this.state.formData.finalNotes}  
						/>
						<DataGrid 
							columns={['Task', 'Due Date', 'Completed']} 
							fields={['toDo', 'dueDateTime', 'id']} 
							data={this.state.callReportTasks} 
							formatColumns={
								{1: {'date': true}, 
								2: {checkbox: {}, 
									colCheckBound: 'completed',
									callbackCheckbox: (event) => {
										const checked = event.target.checked;
										const value = event.target.value;
										// console.log("checked: ", checked, ", value: ", value);
										
										let callReportTasks = this.state.callReportTasks;
										const rowIndex = callReportTasks.findIndex((row) => {
											return row.id == value;
										});
										// console.log("rowIndex: ", rowIndex);
										if(rowIndex > -1) {
											callReportTasks[rowIndex]['completed'] = checked;
											callReportTasks[rowIndex]['bModified'] = true;
											this.setState({callReportTasks: callReportTasks});
										}
									}
							}}}
							onDoubleClick={(event) => {
								this.setState({editCallReportTaskRow: event, bShowTaskForm: true});
							}}
							onSelect={(event => {this.setState({selectedCallReportTaskIndex: event});})}

						/>
						<Button onClick={() => {
							this.setState({
								bShowTaskForm: true, 
								selectedCallReportTaskIndex: null, 
								editCallReportTaskRow: null
							});
						}} >
							<Translate value="components.buttons.add" />
						</Button>
						&nbsp;
						<Button onClick={() => {
							let selectedCallReportTaskIndex = this.state.selectedCallReportTaskIndex;
							if(selectedCallReportTaskIndex != null) {
								let callReportTasks = this.state.callReportTasks;
								callReportTasks.splice(selectedCallReportTaskIndex, 1);
								const numTakeaways = callReportTasks.length;
								if(numTakeaways == 0)
									selectedCallReportTaskIndex = null;
								else if(selectedCallReportTaskIndex == numTakeaways)
									selectedCallReportTaskIndex--;
								
								this.setState({
									selectedCallReportTaskIndex: selectedCallReportTaskIndex,
									callReportTasks: callReportTasks,
								});
							}
						}}>
							<Translate value="components.buttons.remove" />
						</Button>
						{callReportTaskForm}
						<InputText 
							label={this.state.formData.spanish ? spanishCallReportLabels['nextStep'][1] : <Translate value="components.labels.nextStep" />} id={"nextStep"} 
							name={"nextStep"} action={this.handleInputChanged} type={"TextArea"} 
							value={this.state.formData.nextStep}  
						/>
						<InputText 
							label={this.state.formData.spanish ? spanishCallReportLabels['dueDate'][1] : <Translate value="components.labels.dueDate" />} 
							type={"DateTime"} id={"dueDate"} 
							name={"dueDate"} action={this.handleInputChanged} 
							value={dueDateFormatted} required
							errMsg={this.state.errors.dueDate}
						/>
						{callReportStationDetailForm}
					</Col>
				</Row>;
			}
		
		
		return(
			<Modal show={this.props.visible} bsSize="large">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={9}>
								<DataDropdown label={<Translate value="components.labels.Category" />} 
									id={"callReportCategoryId"} name={"callReportCategoryId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.callReportCategoryId} 
									data={{
										[constants.call_report_category_customer]: "Customer",
										[constants.call_report_category_vendor]: "Vendor", 
										[constants.call_report_category_shipper]: "Shipper"
									}} 
									value={this.state.formData.callReportCategoryId} 
								/>
								{this.state.formData.callReportCategoryId == constants.call_report_category_customer && <InputText 
									label={this.state.formData.spanish ? spanishCallReportLabels['account'][1] : <Translate value="components.labels.account" />} 
									id={"customerId"} name={"customerId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.customerId} type={"SingleSelect"} 
									value={customerName} data={activeCustomersMapped} 
								/>}
									
								{this.state.formData.callReportCategoryId == constants.call_report_category_vendor && <InputText 
									label={this.state.formData.spanish ? spanishCallReportLabels['account'][1] : <Translate value="components.labels.Vendor" />} 
									id={"vendorId"} name={"vendorId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.vendorId} type={"SingleSelect"} 
									value={vendorName} data={activeVendorsMapped} 	
								/>}
									
								{this.state.formData.callReportCategoryId == constants.call_report_category_shipper && <InputText 
									label={this.state.formData.spanish ? spanishCallReportLabels['account'][1] : <Translate value="components.labels.Shipper" />} 
									id={"shipperId"} name={"shipperId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.shipperId} type={"SingleSelect"} 
									value={shipperName} data={activeShippersMapped} 
								/>}
								
								
								<DataDropdown label={<Translate value="components.labels.type" />} 
									id={"callReportTypeLookupId"} name={"callReportTypeLookupId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.callReportTypeLookupId} data={callReportTypes} 
									value={this.state.formData.callReportTypeLookupId} 
									disabled={this.state.formData.customerId == null}
								/>
								
								<InputText 
									label={this.state.formData.spanish ? spanishCallReportLabels['callDate'][1] : <Translate value="components.labels.callDate" />} 
									type={"DateTime"} id={"callDate"} 
									name={"callDate"} action={this.handleInputChanged} 
									value={callDateFormatted} required
									errMsg={this.state.errors.callDate}
								/>
								<InputText
								label={this.state.formData.spanish ? spanishCallReportLabels['bcmReps'][1] : <Translate value="components.labels.bcmReps" />} 
								type={"MultiSelect"}
								id={"callReportSalesReps"}
								name={"callReportSalesReps"} 
								value={callReportSalesRepsMapped}
								action={this.handleInputChanged} 
								data={salesRepListMapped}
								errMsg={this.state.errors.callReportSalesReps}
								maxHeight='90px'
							/>
								<InputText
									label={this.state.formData.spanish ? spanishCallReportLabels[lblContacts][1] : <Translate value={"components.labels." + lblContacts} />} 
									type={"MultiSelect"}
									id={"callReportContacts"}
									name={"callReportContacts"} 
									value={callReportContactsMapped}
									action={this.handleInputChanged} 
									data={customerContactsMapped}
									errMsg={this.state.errors.callReportContacts}
									maxHeight='90px'
								/>
								
								<InputText 
									label={this.state.formData.spanish ? spanishCallReportLabels[lblObjectives][1] : <Translate value={"components.labels." + lblObjectives} />} id={"objectives"} 
									name={"objectives"} action={this.handleInputChanged} type={"TextArea"} 
									value={this.state.formData.objectives}  
								/>
								
								<InputText 
									label={this.state.formData.spanish ? spanishCallReportLabels[lblSummary][1] : <Translate value={"components.labels." + lblSummary} />} id={"summary"} 
									name={"summary"} action={this.handleInputChanged} type={"TextArea"} 
									value={this.state.formData.summary}  
								/>

								
							</Col>
						</Row>
						{typeSpecificContent}
						
						<DataGrid 
							columns={['Image', 'Location']} 
							fields={['title', 'imgLocation']} 
							data={this.state.callReportImages} 
							formatColumns={{
								1: {'link': {'column': 'id', 'func': this.onLinkClick}}
							}} 
							onDoubleClick={(event) => {
								this.setState({editCallReportImageRow: event, bShowImageForm: true});
							}}
							onSelect={(event => {this.setState({selectedCallReportImageIndex: event});})}
						/>
						<Button onClick={() => {
							this.setState({
								bShowImageForm: true, 
								selectedCallReportImageIndex: null, 
								editCallReportImageRow: null
							});
						}} >
							<Translate value="components.buttons.add" />
						</Button>
						&nbsp;
						<Button onClick={() => {
							let selectedCallReportImageIndex = this.state.selectedCallReportImageIndex;
							if(selectedCallReportImageIndex != null) {
								let callReportImages = this.state.callReportImages;
								callReportImages.splice(selectedCallReportImageIndex, 1);
								const numImages = callReportImages.length;
								if(numImages == 0)
									selectedCallReportImageIndex = null;
								else if(selectedCallReportImageIndex == numImages)
									selectedCallReportImageIndex--;
								
								this.setState({
									selectedCallReportImageIndex: selectedCallReportImageIndex,
									callReportImages: callReportImages,
								});
							}
						}}>
							<Translate value="components.buttons.remove" />
						</Button>
						{callReportImageForm}
						<Row>
							<Col md={9}>
								<InputText
									label={<Translate value={"components.labels.deptEmails"} />} 
									type={"MultiSelect"}
									id={"deptEmails"}
									name={"deptEmails"} 
									value={deptEmailsMapped}
									action={this.handleInputChanged} 
									data={deptEmailsList}
									errMsg={this.state.errors.deptEmails}
									maxHeight='90px'
								/>
							</Col>
						</Row>
					</Form>
					<CallReportFormDeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
					/>
				</Modal.Body>
				<Modal.Footer>
					{this.props.editCallReportId ? 
					<Button 
						onClick={() => openBirtReport('BCM_Call_Report', {
								callReportId: this.state.formData.id
							}
						)}
						bsStyle={Config.reportBtnStyle}
					>
						<Translate value="components.buttons.exportPDFEnglish" />
					</Button> : ''}
					&nbsp;
					{this.props.editCallReportId ? 
					<Button 
						onClick={() => openBirtReport('BCM_Call_Report', {
								callReportId: this.state.formData.id,
								spanish: '1',
							}
						)}
						bsStyle={Config.reportBtnStyle}
					>
						<Translate value="components.buttons.exportPDFSpanish" />
					</Button> : ''}
					&nbsp;
					{this.props.editCallReportId && !this.props.cannotSave && 
					<Button 
						bsStyle='danger' 
						onClick={() => this.setState({showDeleteConfirmation: true})}
						disabled={!bDeleteEnabled}
					>
						<Translate value="components.buttons.deleteCallReport" />
					</Button>
					}
					&nbsp;
					<Button onClick={this.props.onClose}>
						<Translate value="components.buttons.close" />
					</Button>
					&nbsp;
					{!this.props.cannotSave && 
					<Button bsStyle="primary" onClick={this.onSave} >
						<Translate value="components.buttons.saveChanges" />
					</Button>
					}
				</Modal.Footer>
			</Modal>
		);
	}
}

class NewCallReportTakeawayForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "New Call Report Takeaway",
			errors: resetObject(validationFields),
			formData: {
				takeawayName: '',
				id: 0,
				callReportId: 0,
				model:"com.phototype.myinkiq.model.vos.dtos::CallReportsTakeawaysDto",
			}
		}
	}
	
	componentDidMount() {
		if(this.props.editCallReportTakeawayRow != null) {
			this.setState({formData: this.props.editCallReportTakeawayRow});
		}
	}
	
	render() {
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					<InputText 
						label={<Translate value="components.labels.takeaway" />}  
						action={(event) => {
							let formData = this.state.formData; 
							formData['takeawayName'] = event.target.value;
							this.setState({formData: formData});
						}}
						value={this.state.formData.takeawayName}  
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={() => {this.props.onSave(this.state.formData);}} bsStyle="primary">
						<Translate value="components.buttons.saveChanges" />
					</Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
}


class NewCallReportImageForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "New Call Report Image",
			errors: resetObject(validationFields),
			formData: {
				title: '',
				id: 0,
				callReportId: 0,
				imgLocation: '',
				model:"com.phototype.myinkiq.model.vos.dtos::CallReportsImagesDto",
				imgFile: null
			},
		}
	}
	
	componentDidMount() {
		if(this.props.editCallReportImageRow != null) {
			this.setState({formData: this.props.editCallReportImageRow});
		}
	}
	
	render() {
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					<InputText 
						label={<Translate value="components.labels.title" />}  
						action={(event) => {
							let formData = this.state.formData; 
							formData['title'] = event.target.value;
							this.setState({formData: formData});
						}}
						value={this.state.formData.title}  
					/>
					<InputText 
					label={<Translate value='components.customers.Img'/>} 
					type="File" 
					name="imgLocation"
					action={(data, fileInfo) => {
						// console.log("data.target: ", data.target, ", fileInfo: ", fileInfo);
						if(data.target != null) {
							// this.props.handleInputChangedTempContract(data, "imgLocation");
							let formData = this.state.formData; 
							formData['imgLocation'] = data.target.value;
							formData['imgFile'] = data.target.files != null ? data.target.files[0] : null;
							this.setState({formData: formData});
						}
					}}
				/>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={() => {
							// check for file size and type
							const imgFile = this.state.formData.imgFile;
							// console.log("imgFile.size: ", imgFile.size, ", call_report_file_size_limit: ", constants.call_report_file_size_limit);
							if(imgFile == null) {
								alert("Media File not specified");
								return false;
							} else if (imgFile.size > (constants.call_report_file_size_limit * 1024 * 1024)) {
								alert("Media File size cannot exceed " + constants.call_report_file_size_limit + "MB");
								return false;
							}
							this.props.onSave(this.state.formData);
						}} bsStyle="primary">
						<Translate value="components.buttons.saveChanges" />
					</Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
}


const stationDetailValidationFields = {
	// formulaId: "required,numeric",
	// formulaBatchNumberId: "required,numeric",
	customerPressStationAniloxId: "required,numeric",
	dryerPct: "numeric",
	pHInitial: "numeric",
	pHFinal: "numeric",
	toleranceInitial: "numeric",
	toleranceFinal: "numeric",
	densityInitial: "numeric",
	densityFinal: "numeric",
};


class NewCallReportStationDetailForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "New Call Report Station Detail",
			errors: resetObject(stationDetailValidationFields),
			formData: {
				customerPressStationId: 0, 
				customerPressStationNumber: 0, 
				formulaId: null, 
				formulaNumber: '-', 
				formulaBatchNumberId: null, 
				formulaBatchNumber: '-', 
				customerPressStationAniloxId: null, 
				customerPressStationAnilox: '-', 
				dryerPct: 0.0, 
				uv: false,
				pHInitial: 0.0,
				viscosityInitial: null,
				toleranceInitial: 0.0,
				densityInitial: 0.0,
				pHFinal: 0.0,
				viscosityFinal: null,
				toleranceFinal: 0.0,
				densityFinal:0.0,
				samplesCollected: null,
				sampleCollected: '',
				id: 0,
				callReportId: 0,
				model:"com.phototype.myinkiq.model.vos.dtos::CallReportsStationDetailsDto",
			},
			customerPressStationAniloxes: [],
			batchNumbersList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var formData = this.state.formData;
		var value = '';
		if(name == 'formulaId') {
			value = event.value;
			// console.log("label: ", event.label, ", value: ", value);
			formData['formulaNumber'] = event.label;
			formData[name] = event.value;

			
			getAPI("/customers/" + this.props.customerId + "/formulas/" + value + "/batches").then(
			resBatches => {
				// console.log("resPressStationAniloxes: ", resPressStationAniloxes);
				this.setState({
					formData: formData,
					batchNumbersList: resBatches['data'],
				});
			});
			
		} else {
			switch(name) 
			{					
				case 'formulaBatchNumberId':
					value = event.value;
					// console.log("label: ", event.label, ", value: ", value);
					formData['formulaBatchNumber'] = event.label;

					break;
				
				case 'customerPressStationAniloxId':
					value = target.value;
					formData['customerPressStationAnilox'] = event.target.options[event.target.selectedIndex].text;
					break;
				
				case 'samplesCollected':
					value = event;
					value = value.map(obj => (obj.label)).join(',');
					// console.log("samplesCollected - event: ", event, ", value: ", value);
					break;
					
				default:
					value = target.type === 'checkbox' ? target.checked : target.value;
			}
			
			formData[name] = value;
			this.setState({
			  formData: formData
			});
		}
	}
	
	componentDidMount() {
		     const aniloxURL = "customers/" + this.props.customerId + "/presses/" + this.props.customerPressId + "/stations/" + this.props.editCallReportStationDetailRow.customerPressStationId +"/aniloxes";
		// console.log("this.props.editCallReportStationDetailRow: ", this.props.editCallReportStationDetailRow, "aniloxURL: ", aniloxURL);
		     if(this.props.editCallReportStationDetailRow != null) {
			     getAPI(aniloxURL).then(resPressStationAniloxes => {
				// console.log("resPressStationAniloxes: ", resPressStationAniloxes);
				let formulaId = this.props.editCallReportStationDetailRow.formulaId;
				if(formulaId == null) formulaId = 0;
				     getAPI("/customers/" + this.props.customerId + "/formulas/" + formulaId + "/batches").then(
				     resBatches => {
					// console.log("resPressStationAniloxes: ", resPressStationAniloxes);
					     this.setState({
						     formData: this.props.editCallReportStationDetailRow,
						     customerPressStationAniloxes: resPressStationAniloxes['data'],
						     batchNumbersList: resBatches['data'],
					     });
				     });
			     });
		     }	 
	}

	render() {
		
		var batch = [];
		var batchNumbersList = [];

		var customerFormulasList = this.props.customerFormulasList.filter(row => row != undefined).map((obj, key) => ({ value: obj.formulaId, label: obj.formula }));
		
		const formula = this.state.formData.formulaId ?
		customerFormulasList.filter(obj => obj.value == this.state.formData.formulaId)[0] : {};
	
		batchNumbersList = this.state.batchNumbersList != undefined ? this.state.batchNumbersList.filter(row => row != undefined).map((obj, key) => ({ value: obj.orderFormulaId, label: obj.batchNumber })):[];
		batch = this.state.batchNumbersList != undefined ? batchNumbersList.filter(obj => obj.value == this.state.formData.formulaBatchNumberId)[0]:[];

		let formulaNumber = this.state.formData.formulaNumber; // {value: this.state.formData.formulaId, label: this.state.formData.formulaNumber};
		let batchNumber = this.state.formData.formulaBatchNumber; // {value: this.state.formData.formulaBatchNumberId, label: this.state.formData.formulaBatchNumber};
		
		
		const customerPressStationAniloxes = mapArrayToObject(this.state.customerPressStationAniloxes, 'id', 'anilox');
		
		const sampleCollectedList = sampleList;// mapArrayToObject(this.props.sampleCollectedList, 'id', 'value');
		const samplesListMapped =  Object.values(sampleList).map(obj => ({value: obj, label: obj}));
		const samplesCollectedMapped = this.state.formData.samplesCollected != null ? this.state.formData.samplesCollected.split(",").map(obj => ({value: obj, label: obj})) : [];
		
		return(<Modal show={this.props.visible}>
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={6}>
								
								<InputText label={<Translate value="components.labels.formula" />}
								id={"formulaId"} name={"formulaId"}
								action={this.handleInputChanged} 
								inputChanged={(event, name, type, value) => {
									// console.log("formula inputChanged() - event.target.value: ", event.target.value, ", name: ", name, ", type: ", type, ", value: ", value);
									let formData = this.state.formData;
									formData['formulaNumber'] = event.target.value;
									formData['formulaId'] = null;
									this.setState({formData: formData});
								}}
								required
								errMsg={this.state.errors.formulaId}
								value={formulaNumber}
								data={customerFormulasList} type="AutoComplete"
								/>
								
								<InputText label={<Translate value="components.labels.batch" />}
								id={"formulaBatchNumberId"} name={"formulaBatchNumberId"}
								action={this.handleInputChanged} 
								inputChanged={(event, name, type, value) => {
									// console.log("batch inputChanged() - event.target.value: ", event.target.value, ", name: ", name, ", type: ", type, ", value: ", value);
									let formData = this.state.formData;
									formData['formulaBatchNumber'] = event.target.value;
									formData['formulaBatchNumberId'] = null;
									this.setState({formData: formData});
								}}
								required
								errMsg={this.state.errors.formulaBatchNumberId}
								value={batchNumber}
								data={batchNumbersList} type="AutoComplete"
								/>

								<DataDropdown label={<Translate value="components.labels.anilox" />} 
									id={"customerPressStationAniloxId"} name={"customerPressStationAniloxId"} 
									action={this.handleInputChanged} required
									errMsg={this.state.errors.customerPressStationAniloxId} data={customerPressStationAniloxes} 
									value={this.state.formData.customerPressStationAniloxId} 
								/>
								<InputText 
									label={<Translate value="components.labels.dryerPct" />}
									name={"dryerPct"} 
									action={this.handleInputChanged}
									value={this.state.formData.dryerPct}  
								/>
								<InputText label={<Translate value="components.labels.uv" />} 
									id={"uv"} name={"uv"} 
									action={this.handleInputChanged} type={"CheckBox"} 
									value={this.state.formData.uv}
								/>
								<InputText 
									name={"pHInitial"} 
									label={<Translate value="components.labels.pHInitial" />}  
									action={this.handleInputChanged}
									value={this.state.formData.pHInitial}  
								/>
								<InputText 
									name={"viscosityInitial"} 
									label={<Translate value="components.labels.viscosityInitial" />}  
									action={this.handleInputChanged}
									value={this.state.formData.viscosityInitial}  
								/>
								<InputText 
									name={"toleranceInitial"} 
									label={<Translate value="components.labels.dEInitial" />}  
									action={this.handleInputChanged}
									value={this.state.formData.toleranceInitial}  
							    />
							    <InputText
								    name={"densityInitial"}
								    label={<Translate value="components.labels.densityInitial" />}
								    action={this.handleInputChanged}
								    value={this.state.formData.densityInitial}
							    />
								<InputText 
									name={"pHFinal"} 
									label={<Translate value="components.labels.pHFinal" />}  
									action={this.handleInputChanged}
									value={this.state.formData.pHFinal}  
								/>
								<InputText 
									name={"viscosityFinal"} 
									label={<Translate value="components.labels.viscosityFinal" />}  
									action={this.handleInputChanged}
									value={this.state.formData.viscosityFinal}  
								/>
								<InputText 
									name={"toleranceFinal"} 
									label={<Translate value="components.labels.dEFinal" />}  
									action={this.handleInputChanged}
									value={this.state.formData.toleranceFinal}  
								/>
							    <InputText
								    name={"densityFinal"}
								    label={<Translate value="components.labels.densityFinal" />}
								    action={this.handleInputChanged}
								    value={this.state.formData.densityFinal}
							    />
								<InputText
									label={this.state.formData.spanish ? spanishCallReportLabels['sampleCollected'][1] : <Translate value="components.labels.sampleCollected" />} 
									type={"MultiSelect"}
									id={"samplesCollected"}
									name={"samplesCollected"} 
									value={samplesCollectedMapped}
									action={this.handleInputChanged} 
									data={samplesListMapped}
									errMsg={this.state.errors.samplesCollected}
									maxHeight='90px'
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}>
						<Translate value="components.buttons.close" />
					</Button>
					<Button onClick={() => {
						const validation = validateForm(this.state.formData, stationDetailValidationFields);

						// console.log("validation: ", validation, ", formData: ", this.state.formData);
						// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
						if(!validation.result) {
							showFormValidationAlert(validation.errors);
							this.setState({
								errors: validation.errors
							});
						}
						else {
							this.props.onSave(this.state.formData);
						}
					}} bsStyle="primary">
						<Translate value="components.buttons.saveChanges" />
					</Button>
				</Modal.Footer>
		</Modal>);
	}
}


export default NewCallReportForm;