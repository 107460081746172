import React, { Component } from 'react';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown.js';
import OrderForm from '../orders/OrderForm.js';
import {Row, Col, Button} from 'react-bootstrap';
import {getAPI, postAPI, convertTimestampToDate, getLoggedInUser} from '../common_components/common.js';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');

// var config = require('../config.js');


//	PROPS params
//	New approach: Filter the data within the component before passing it on to the grid.
class TabItem extends Component {


	constructor(props){
		super(props);
		
		this.state = {
			data: [],
			loading: true,
			autoRefresh: true,
			interval: null,
			chkNotesToBeApproved: {},
			chkEmailsToBeSent: {},
		};
		
		this.onNotesApprovedChanged = this.onNotesApprovedChanged.bind(this);
		this.onEmailsToBeSentChanged = this.onEmailsToBeSentChanged.bind(this);
		this.approveNotes = this.approveNotes.bind(this);
		this.sendEmail = this.sendEmail.bind(this);
		this.refreshData = this.refreshData.bind(this);
	}
	
	refreshData() {
		console.log("TabItem::refreshData() called - this.props: ", this.props);
		this.setState({
			loading: true,
		})
		let endpoint = "/salesorders/list";
		switch(this.props.dashboard) {
			case 'ApproveProcessNotes':
				endpoint = "/customers/notes/unapproved";
				break;
			
			case 'EmailsToBeSent':
				endpoint = "/formulas/emaillist?companyId=1&search=";
				break;
		}
		getAPI(endpoint).then(res => {
			if(this.props.dashboard) {
				this.props.onLoad(res['data'].length);
			}
			this.setState({
				data: res['data'],
				loading: false,
				chkNotesToBeApproved: {},
				chkEmailsToBeSent: {},
			});
		});
	}
	
	componentDidMount() {
		this.refreshData();

		const interval = setInterval(async () => {
			if(this.state.autoRefresh) {
				// console.log("TabItem::componentDidMount() - refreshData being called....");
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});

	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	onNotesApprovedChanged(event) {
		let chkNotesToBeApproved = this.state.chkNotesToBeApproved;
		const checked = event.target.checked;
		const value = event.target.value;
		const name = event.target.name;
		// console.log("TabItem::onNotesApprovedChanged() - name: ", name, ", value: ", value, ", checked: ", checked, ", chkNotesToBeApproved: ", chkNotesToBeApproved);
		
		if(checked)
			chkNotesToBeApproved[value] = true;
		else
			delete chkNotesToBeApproved[value];
		
		this.setState({
			chkNotesToBeApproved: chkNotesToBeApproved,
		});
	}
	
	
	onEmailsToBeSentChanged(event) {
		let chkEmailsToBeSent = this.state.chkEmailsToBeSent;
		const checked = event.target.checked;
		const value = event.target.value;
		const name = event.target.name;
		const stateVarName = "chkEmailsToBeSent"; // event.target.id;
		const id = event.target.id;
		
		// console.log("TabItem::onEmailsToBeSentChanged() - id: ", id, ", name: ", name, ", value: ", value, ", checked: ", checked, ", stateVarName: ", stateVarName, ", chkEmailsToBeSent: ", chkEmailsToBeSent);
		if(name == 'id')
		{
			for(const i in this.state.data)
			{
				const id = this.state.data[i]['id'];
				if(checked)
					chkEmailsToBeSent[id] = true;
				else
					delete chkEmailsToBeSent[id];
			}
		}
		else
		{
			if(checked)
				chkEmailsToBeSent[value] = true;
			else
				delete chkEmailsToBeSent[value];
		}
		
		this.setState({
			chkEmailsToBeSent: chkEmailsToBeSent,
		});
	}
	
	approveNotes(event) {
		event.preventDefault();
		const loggedInUser = getLoggedInUser();
		const idsNotes = Object.keys(this.state.chkNotesToBeApproved);
		// console.log("idsNotes: ", idsNotes);
		if(idsNotes.length) {
			for(const i in idsNotes) {
				const noteId = idsNotes[i];
				const objNote = this.state.data.filter(obj => obj.id == noteId)[0];
				console.log("noteId: ", noteId, ", objNote: ", objNote);
				objNote.approvedBy = loggedInUser.userName;
				objNote.approvedDate = (new Date()).getTime();
				objNote.model = "com.phototype.myinkiq.model.vos.dtos::CustomerNotesDto";
				objNote.name = null;
				
				postAPI("/customers/" + noteId + "/notes/unapproved", objNote).then(resNoteApproved => {				
					if(i == idsNotes.length - 1) {
						console.log("All notes approved, refreshing data....");
						this.refreshData();
					}
				});
			}
		}
	}
	
	sendEmail(sendYN) {
		// event.preventDefault();
		
		// console.log("TabItem::sendEmail() - this.state.chkEmailsToBeSent: ", this.state.chkEmailsToBeSent, ", sendYN: ", sendYN);
		const pids = Object.keys(this.state.chkEmailsToBeSent);
		if(pids.length) {
			const cspids = pids.join(";");
			const endpoint = "/formulas/recipients?sendEmail=" + sendYN+ "&cfpids=" + cspids
			getAPI(endpoint).then(res => {
				this.refreshData();
			});
			
		}
		
	}
	
	
	
	render() {
		// console.log("Order::render() called - this.props: ", this.props, ", this.state.data: ", this.state.data);
		let data = this.state.data;
		
		let columns = [];
		let fields = [];
		let formatColumns= {};
		let buttons;
		
		switch(this.props.dashboard) {
			case 'ApproveProcessNotes':
				// data = data.filter(obj => obj.salesOrderType == "Sales Order" && (obj.statusName == "Entered" || obj.statusName == "Confirmed" || obj.statusName == "Shipped"));
				columns = [
					<Translate value="components.labels.customer" />,
					<Translate value="components.labels.note" />,
					<Translate value="components.labels.approved" />,
				];
				fields = ['customerName', 'note', 'id'];
				formatColumns= {2:{'checkbox': this.state.chkNotesToBeApproved, 'callbackCheckbox': this.onNotesApprovedChanged}};
				
				
				buttons = <div>
					<Button onClick={this.approveNotes} ><Translate value="components.buttons.approveNotes" /></Button>&nbsp;
				</div>
				break;
			
			case 'EmailsToBeSent':
				// data = data.filter(obj => obj.salesOrderType == "Lab Request" && (obj.statusName == "Entered" || obj.statusName == "Confirmed" || obj.statusName == "Shipped"));
				columns = [
					'', 
					<Translate value="components.labels.formulaNumber" />,
					<Translate value="components.labels.formulaName" />,
					<Translate value="components.labels.customer" />,
					<Translate value="components.labels.price" />,
				];
				fields = ['id', 'formulaNumber', 'name', 'customerName', 'amount'];
				formatColumns= {
					0:{
						'checkbox': this.state.chkEmailsToBeSent, 
						'callbackCheckbox': this.onEmailsToBeSentChanged, 
						'headerCheckbox': true
					},
					4:{
						'currency': true
					},
				};
				
				buttons = <div>
					<Button onClick={() => this.sendEmail('N')} ><Translate value="components.buttons.noEmail" /></Button>&nbsp;
					<Button onClick={() => this.sendEmail('Y')} ><Translate value="components.buttons.sendEmail" /></Button>
				</div>;
				break;
			
			default:
				break;
	
		}
		
		
		
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		var orderFormContent = this.state.showOrderForm ? <OrderForm
				visible={this.state.showOrderForm}
				onClose={this.handleCloseOrderForm}
				onSave={this.handleSaveOrder}
				activeCustomers={this.state.activeCustomers}
				editOrderId={this.state.editOrderId} /> : "";
		
		// console.log("data to be passed to the grid: ", data, ", this.props: ", this.props);
		
		return (
			<div>
				<br />
				<Row>
					<Col md="4"></Col>
					<Col md="4">
					</Col>
				</Row>
				<DataGrid 
					loading={this.state.loading}
					columns={columns} 
					fields={fields} 
					data={data} 
					formatColumns={formatColumns} 
					txtSearch={this.props.txtSearch} 
					type={"order"} 
					clickable={true} 
					onDoubleClick={this.editOrderForm} 
					/>
				{buttons}
				{orderFormContent}
			</div>
		);
	}
}

export default TabItem;
