import React, { Component } from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid';
import DataDropdown from '../common_components/DataDropdown.js';
import { getAPI, postAPI, mapArrayToObject, convertTimestampToDate, getLoggedInUser, quickbooksAPI} from '../common_components/common.js';
import NewVendorForm from '../settings/NewVendorForm.js';
import NewInkComponentForm from './NewInkComponentForm';
import Config from '../Config';
const { Translate } = require('react-i18nify');

class InkComponent extends Component {
	constructor(props) {
		super(props);

		var filter = this.props.txtSearch != undefined && this.props.txtSearch != '' ? {'*': {'op': 'contains', 'val': this.props.txtSearch}}: {};

		this.state = {
			columns: [
				<Translate value="components.labels.componentId" />,
				<Translate value="components.labels.name" />,
				<Translate value="components.labels.status" />,
				<Translate value="components.labels.vendor" />,
				<Translate value="components.labels.numOfFormulas" />,
				<Translate value="components.labels.dateEntered" />,
				<Translate value="components.labels.lastUsed" />,
				<Translate value="components.labels.notes" />,
			],
			fields: ['component', 'name', 'status', 'vendor', 'formulas', 'enteredDate', 'lastUsedDate', 'componentNotes'],
			data: [],
			filter: filter,
			newInkComponentFormVisible: false,
			editInkComponentFormVisible: false,
			editComponentId: null,
			inkType: 'Active',
			loading: true,
			autoRefresh: true,
			interval: null,
			editVendorId: null,
		};

		this.inkTypeChanged = this.inkTypeChanged.bind(this);
		this.showEditInkComponentForm = this.showEditInkComponentForm.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
	}
	
	showEditInkComponentForm(dataIn) {
		this.setState({
			editComponentId: dataIn['id'],
			editInkComponentFormVisible: true,
		});
	}
	
	onLinkClick(event) {
		const editId = event.target.id;
		const varName = event.target.name;
		console.log("InkComponent::onLinkClick() - editId: ", editId, ", varName: ", varName);
		this.setState({
			[varName]: editId,
		});
	}
	
	refreshData() {
		this.setState({loading: true});
		getAPI("components/list")
		.then(
			(result) => {
				this.setState({loading: false, data: result['data']});
			},
			(error) => {this.setState({loading: false, error});}
		);
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.editInkComponentFormVisible) {
				console.log("InkComponent::componentDidMount() - refreshData being called....");
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	inkTypeChanged(event) {
		var val = event.target.value;
		this.setState({
			inkType: val,
		});
	}
	
	
	/*
	shouldComponentUpdate(nextProps, nextState) {
		var bUpdate = true;
		console.log("shouldComponentUpdate() - nextProps: ", nextProps, ", nextState: ", nextState);
		if(nextState.editVendorId != null || nextState.editInkComponentFormVisible)
			bUpdate = false;
		return bUpdate;
	}
	*/

	render() {
		const loggedInUser = getLoggedInUser();
		var inkTypes = {
			'': 'All', // <Translate value='components.labels.filterAll' />,
			'Active': 'Active', // <Translate value='components.labels.filterActive' />,
			'Inactive': 'Inactive', // <Translate value='components.labels.filterInactive' />,
			'Experimental': 'Experimental', // <Translate value='components.labels.filterExperimental' />,
		}
		
		const formatColumns = {
			5: {'date': true},
			6: {'date': true}, 
			3: {'link': 
				{'column': 'vendorId', 'var': 'editVendorId', 'func': this.onLinkClick}
			}
		};
					
		var data = this.state.data;
		
		if(this.state.inkType != '')
		{
			data = data.filter(obj => obj.status == this.state.inkType);
		}
		
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}

		const newInkComponentForm = this.state.newInkComponentFormVisible ? <NewInkComponentForm
				onClose={() => this.setState({newInkComponentFormVisible: false})}
				onSave={() => {
					this.setState({newInkComponentFormVisible: false});
					this.refreshData();
				}}
				visible={this.state.newInkComponentFormVisible}
				></NewInkComponentForm> : "";
		
		const editInkComponentForm = this.state.editInkComponentFormVisible ? <NewInkComponentForm
				onClose={() => this.setState({editInkComponentFormVisible: false})}
				onSave={() => {
					this.setState({editInkComponentFormVisible: false});
					this.refreshData();
				}}
				visible={this.state.editInkComponentFormVisible}
				editComponentId={this.state.editComponentId}></NewInkComponentForm> : "";
			
		const editVendorForm = this.state.editVendorId != null ? <NewVendorForm
					visible={this.state.editVendorId != null}
					onClose={() => this.setState({editVendorId: null})}
					onSave={() => {
						this.setState({editVendorId: null});
						this.refreshData();
					}}
					editVendorId={this.state.editVendorId}
				/> : "";
				
		return (
			<div>
				<DataGrid 
					loading={this.state.loading}
					columns={this.state.columns}
					fields={this.state.fields}
					importantColumns={[0, 2]}
					data={data}
					// filter={this.state.filter}
					formatColumns={formatColumns}
					txtSearch={this.props.txtSearch}
					onDoubleClick={this.showEditInkComponentForm}
					onSort={(data) => {this.setState({data:data})}}
					limit={1000}
				/>
				<Row>
					<Col md="2"></Col>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.type" />} 
								action={this.inkTypeChanged} data={inkTypes} 
								value={this.state.inkType}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Button bsSize='sm' onClick={() => this.setState({newInkComponentFormVisible: true})} disabled={loggedInUser.accessComponentsCode == 'V'} ><Translate value="components.buttons.newComponent" /></Button>
						&nbsp;
						<Button bsSize='sm' onClick={() => {
									
							let qboItems = {};

							//	Get All QBO items
							quickbooksAPI({
								endpoint: "/v3/company/realmId/query?query=",
								query: "select * from Item where Type = 'NonInventory' maxresults 1000",
							}).then(items => {
								// console.log("items: ", items);
								// qboItems[]
								if(items['QueryResponse']['Item'] != null) {
									items['QueryResponse']['Item'].forEach(row => {
										const indexItemName = row['Name'].replace(/[^a-zA-Z0-9]/g, "");
										qboItems[indexItemName] = [row['Id'], row['Description']];
									});
									
									console.log("qboItems: ", qboItems);
							
									// Check each MyInkIQ Item if it's present in QBO (by name)
									this.state.data.forEach(row => {
										// If it's not present, add it
										const component = row['component'].replaceAll(':', '');
										const indexItemName = component.replace(/[^a-zA-Z0-9]/g, "");
										const componentId = row['id'];
										const componentName = row['name'];
										const qboComponentRefId = row['qboComponentRefId'];
										// console.log("indexItemName: ", indexItemName, ", Object.hasOwn(qboItems, indexItemName): ", Object.hasOwn(qboItems, indexItemName));

										// if(qboItems[indexItemName] === undefined) {
										if(!Object.hasOwn(qboItems, indexItemName)) {
											console.log("Not found - MyInkIQ component#: ", indexItemName, ", componentName: ", componentName);
										// if(!Object.hasOwnProperty(qboItems, indexItemName)) {
											const objData = { 
												"Name": component,
												"FullyQualifiedName": component,
												"Description": componentName,
												"Type": "NonInventory",
												"IncomeAccountRef": {
												 "value": "217",
												 "name": "Sales -Other"
												}
											};
											
											quickbooksAPI({
												endpoint: "/v3/company/realmId/item?minorversion=65",
												method: "POST",
												contentType: "application/json",
												data: encodeURIComponent(JSON.stringify(objData)),
											}).then(Item => {
												// console.log("created Item: ", Item);
												if(Item['Item']) {
													const qboRefId = Item['Item']['Id'];
													getAPI("/common/updateQBORefId?entityName=Component&entityId=" + componentId + "&qboRefId=" + qboRefId);
												}
											});
										} else {
											// if(componentName != qboItems[indexItemName][1])
												console.log("Found with difference - MyInkIQ component#: ", indexItemName, ", componentName: ", componentName, ", QBO Component: ", qboItems[indexItemName][1]);
											// If it's present get it's ID and store it 
											const qboRefId = qboItems[indexItemName][0];
											// console.log("qboComponentRefId: ", qboComponentRefId, ", qboRefId: ", qboRefId);
											if(qboComponentRefId == null || qboComponentRefId != parseInt(qboRefId))
												getAPI("/common/updateQBORefId?entityName=Component&entityId=" + componentId + "&qboRefId=" + qboRefId);
											
										}
									});
								}
							});
							
						}} ><Translate value="components.buttons.qboSync" /></Button>
					</Col>
				</Row>
				{newInkComponentForm}
				{editInkComponentForm}
				{editVendorForm}
			</div>
		);
	}
}

export default InkComponent;
