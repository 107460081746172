import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid.js';
import {getAPI, convertTimestampToDate, getLoggedInUser} from '../common_components/common.js';
import Config from '../Config';
const { Translate } = require('react-i18nify');

export default class Quiz extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiUrl: Config.baseURL + 'quizzes/list',
			columns: [
				<Translate value='components.settings.Name' />,
				<Translate value='components.settings.URL' />,
			],
			fields: ['quizName', 'quizURL'],
			formatColumns: {5: {'link': {'column': 'quizURL'}}},
			data: [],
			offset: 0,
			limit: 2500,
			showNewShipperForm: false,
			editShipperId: null,
			loading: true,
			autoRefresh: true,
			interval: null,
		};
	}

	refreshData() {
		this.setState({loading: true});
		/*
		getAPI("shippers/list").then(resShippers => {
			this.setState({
				data: resShippers['data'],
				loading: false,
			});
		});
		*/
		this.setState({
			data: [{
				'quizName': 'Beginning Press Side Training Quiz', 
				'quizURL': 'https://bcminks-assets.s3.us-east-2.amazonaws.com/training/quizzes/Beginning_Press_Side_Training_Quiz.pdf'
			}],
			loading: false,
		});
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showNewShipperForm) {
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.state.data;
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return this.state.formatColumns[i] && this.state.formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		return (
			<table class="table table-striped table-hover data-grid" style={{width: "90%"}}>
				<thead>
					<tr class="data-grid-row">
						<th class="data-grid-header">
							<div id="id">
								<div class="col-md-12">
									<div class="row"><span><span>Name</span></span>&nbsp;</div>
								</div>
							</div>
						</th>
						<th class="data-grid-header">
							<div id="orderDate">
								<div class="col-md-12">
									<div class="row"><span><span>URL</span></span>&nbsp;</div>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="data-grid-row">
						<td style={{"vertical-align":"middle", "font-size": "16px"}}>Beginning Press Side Training Quiz (EN)</td>
						<td style={{"font-size": "16px"}}>
							<a target="__blank" href="https://bcminks-assets.s3.us-east-2.amazonaws.com/training/quizzes/Beginning_Press_Side_Training_Quiz.pdf">Download
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}
}
