export default {
	"buttons": {
		"newSalesOrder": "New Sales Order",
		"newFormula": "New Formula",
		"newLabRequest": "New Lab Request",
		"newComponent": "New Component",
		"newPurchaseOrder": "New Purchase Order",
		"add": "Add",
		"remove": "Remove",
		"delete": "Delete",
		"deleteOrder": "Delete Order",
		"close": "Close",
		"cancel": "Cancel",
		"saveChanges": "Save Changes",
		"addNewFormula": "Add New Formula",
		"deleteFormula": "Delete Formula",
		"deleteComponent": "Delete Component",
		"deleteUser": "Delete User",
		"deleteVendor": "Delete Vendor",
		"deleteShipper": "Delete Shipper",
		"deleteSubstrate": "Delete Substrate",
		"specOutput": "Spec Output",
		"pdf": "PDF",
		"xls": "XLS",
		"submit": "Submit",
		"approveNotes": "Approve Notes",
		"sendEmail": "Send Email",
		"noEmail": "No Email",
		"changePassword": "Change Password",
		"sendResetCode": "Send Reset Code",
		"back": "Back",
		"validate": "Validate",
		"combineBatches": "Combine Batches",
		"uncombineBatches": "Uncombine Batches",
		"showAll": "Show all",
		"hideAll": "Hide all",
		"billOfLadingInstructions": "Bill of Lading Instructions",
		"populate": "Populate",
		"update": "Update",
		"refresh": "Refresh",
		"updateActual": "Update Actual",
		"export": "Export",
		"yes": "Yes",
		"no": "No",
		"newTask": "New Task",
		"uploadCRMReport": "Upload CRM Report",
		"deleteTask": "Delete Task",
		"newCallReport": "New Call Report",
		"deleteCallReport": "Delete Call Report",
		"uploadCallReport": "Upload Press Run Call Report",
		"uploadBlendingCallReport": "Upload Blending System Call Report",
		"upload": "Upload",
		"exportPDF": "Export to PDF",
		"exportPDFEnglish": "Export to PDF in English",
		"exportPDFSpanish": "Export to PDF in Spanish",
		"inactivate": "Inactivate",
		"qboSync": "Sync with QBO",
	},
}
