import React, { Component } from 'react';
import { Row, Col, Form,Button } from 'react-bootstrap';
import CustomerPopin from './CustomerPopin.js';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown.js';
import { getAPI, postAPI, mapArrayToObject, convertTimestampToDate, getLoggedInUser, constants, quickbooksAPI } from '../common_components/common.js';

const { Translate, Localize } = require('react-i18nify');

class Customer extends Component {
	constructor(props){
		super(props);
		this.state = {
			columns: [
				<Translate value='components.customers.id' />,
				<Translate value='components.customers.name' />,
				<Translate value='components.customers.phone' />,
				<Translate value='components.customers.city' />,
				<Translate value='components.customers.stateProvince' />,
				<Translate value='components.customers.notes' />,
				<Translate value='components.customers.status' />,
				<Translate value='components.customers.lastSalesOrderDate' />,
				<Translate value='components.customers.tieredVolumeDiscount' />,
				<Translate value='components.customers.contract' />,
			],
			fields: ['id', 'name', 'phoneNumber', 'city', 'stateCode', 'notes', 'status', 'lastSalesOrderDate','tieredVolumeDiscount', 'hasContracts'],
			editCustomerId: null,
			editCustomerFormVisible: false,
			newCustomerFormVisible: false,
			status: 'Active',
			data: [],
			offset: 0,
			limit: 2500,
			filter: {},
			loading: true,
		};
		
		this.showEditCustomerForm = this.showEditCustomerForm.bind(this);
		this.customerTypeChanged = this.customerTypeChanged.bind(this);
		this.closeCustomerForm = this.closeCustomerForm.bind(this);
		this.getCustomerList = this.getCustomerList.bind(this);
	}

	closeCustomerForm() {
		this.setState({
			editCustomerFormVisible: false,
			newCustomerFormVisible: false,
			loading: true,
		})
		this.getCustomerList();
	}

	getCustomerList() {
		const loggedInUser = getLoggedInUser();
		let customersList = [];
		getAPI('customers/list')
		.then(
			(result) => {
				// console.log('customer data fetched via API call');
				// console.log("loggedInUser: ", loggedInUser);
				let customers = result['data'];
				// customers = customers.filter(row => row.status == 'Active');
				if(loggedInUser.userTypeId == constants.customer_type_id)
					customers = customers.filter(row => row.id == loggedInUser.customerId);
			  
				this.setState({
					loading: false,
					data: customers,
				});
				if (loggedInUser.userTypeId == constants.sales_type_id && !loggedInUser.allCustomerAccessFlag) {
					// console.log("get sales customers")
					getAPI('users/' + loggedInUser.id + '/salesrepcustomers').then(salesCustomers => {
						salesCustomers.data.forEach(cust => {
							const custRow = customers.filter(row => row.id == cust.customerId)[0];
							if(custRow)
								customersList.push(custRow);
						});
						 console.log("customersList in case sales person is logged in : ", customersList);
						 //customers = customersList;
						 this.setState({
							loading: false,
							data: customersList,
						  });
					});
				}
				
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  this.setState({
				loading: false,
				error
			  });
			}
		)  
	}

	showEditCustomerForm(dataIn) {
		// Grabs the UID for the customer. In this case it is at index 0.
		this.setState({
			editCustomerId: dataIn['id'], 
			editCustomerFormVisible: true
		});
	}
	componentDidMount() {
	
		this.getCustomerList();
		
	}
	
	customerTypeChanged(event) {
		let val = event.target.value;
		var filter = this.state.filter;
		if(val != ''){
			filter['status'] = val;
			this.setState({filter: filter, status: val});
		}else{
			delete filter['status'];
			this.setState({filter: filter, status: val});
		}
	}
	
	render() {

		var data = this.state.data;

		var fields = this.state.fields;

		var formatColumns = {7:{'date': true}};
		
		let customerTypes = {
			'': 'All', // <Translate value='components.labels.filterAll' />, 
			'Active': 'Active', // <Translate value='components.labels.filterActive' />, 
			'Inactive': 'Inactive', // <Translate value='components.labels.filterInactive' />,
			'Prospect': 'Prospect', // <Translate value='components.labels.filterProspect' />,
		};

		const editCustomerForm = this.state.editCustomerFormVisible ? 
			<CustomerPopin 
				onClose={this.closeCustomerForm} 
				editCustomerId={this.state.editCustomerId} 
				visible={this.state.editCustomerFormVisible}/> : ""
		
		const newCustomerForm = this.state.newCustomerFormVisible ? 
			<CustomerPopin 
				onClose={this.closeCustomerForm} 
				visible={this.state.newCustomerFormVisible}/> : ""
			
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		if(this.state.status) {
			data = data.filter(obj => obj.status == this.state.status);
		}
		
		return (
			<div>
				<DataGrid 
					loading={this.state.loading}
					columns={this.state.columns}
					importantColumns={[1, 6]}
					fields={fields} 
					data={data} 
					formatColumns={formatColumns} 
					limit={1000}
					onDoubleClick={this.showEditCustomerForm}
				/>
				<Row>
					<Col md={2}></Col>
					<Col md={4}>
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value='components.labels.status' />} 
								action={this.customerTypeChanged} 
								data={customerTypes} value={this.state.status}
							/>
						</Form>
					</Col>
					<Col md={4}>
						<Button bsSize='sm' onClick={() => this.setState({newCustomerFormVisible: true})} ><Translate value="components.customers.newCustomer" /></Button>
						&nbsp;
						<Button bsSize='sm' onClick={() => {
							
							let qboCustomers = {};

							//	Get All QBO Customers
							quickbooksAPI({
								endpoint: "/v3/company/realmId/query?query=",
								query: "select * from Customer MAXRESULTS 1000",
							}).then(customers => {
								// console.log("customers: ", customers);
								// qboCustomers[]
								if(customers['QueryResponse']['Customer'] != null) {
									customers['QueryResponse']['Customer'].forEach(row => {
										const indexCustomerName = row['DisplayName'].toLowerCase().replaceAll(':', '').replace(/[^a-zA-Z0-9]/g, "");
										qboCustomers[indexCustomerName] = row['Id'];
									});
									
									console.log("qboCustomers: ", qboCustomers);
							
									// Check each MyInkIQ customer if it's present in QBO (by name)
									this.state.data.forEach(row => {
										// If it's not present, add it
										const customerName = row['name'].replaceAll(':', '');
										const indexCustomerName = customerName.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
										const customerId = row['id'];
										const qboCustomerRefId = row['qboCustomerRefId'];
										// console.log("indexCustomerName: ", indexCustomerName, ", Object.hasOwn(qboCustomers, indexCustomerName): ", Object.hasOwn(qboCustomers, indexCustomerName));

										// if(qboCustomers[indexCustomerName] === undefined) {
										if(!Object.hasOwn(qboCustomers, indexCustomerName)) {
											console.log("indexCustomerName: ", indexCustomerName, ", Object.hasOwn(qboCustomers, indexCustomerName): ", Object.hasOwn(qboCustomers, indexCustomerName));
										// if(!Object.hasOwnProperty(qboCustomers, indexCustomerName)) {
											const objData = { 
												"DisplayName": customerName
											};
											
											quickbooksAPI({
												endpoint: "/v3/company/realmId/customer?minorversion=65",
												method: "POST",
												contentType: "application/json",
												data: encodeURIComponent(JSON.stringify(objData)),
											}).then(customer => {
												// console.log("created customer: ", customer);
												if(customer['Customer']) {
													const qboRefId = customer['Customer']['Id'];
													getAPI("/common/updateQBORefId?entityName=Customer&entityId=" + customerId + "&qboRefId=" + qboRefId);
												}
											});
										} else {
											// If it's present get it's ID and store it 
											const qboRefId = qboCustomers[indexCustomerName];
											// console.log("qboCustomerRefId: ", qboCustomerRefId, ", qboRefId: ", qboRefId);
											if(qboCustomerRefId == null || qboCustomerRefId != parseInt(qboRefId))
												getAPI("/common/updateQBORefId?entityName=Customer&entityId=" + customerId + "&qboRefId=" + qboRefId);
											
										}
									});
								}
							});
							
						}} ><Translate value="components.buttons.qboSync" /></Button>
					</Col>
				</Row>
				{ editCustomerForm }
				{ newCustomerForm }
			</div>
		);
	}
}
export default Customer;
