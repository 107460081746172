import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid.js';
import NewVendorForm from './NewVendorForm';
import {getAPI, convertTimestampToDate, getLoggedInUser, quickbooksAPI} from '../common_components/common.js';
import Config from '../Config';
const { Translate } = require('react-i18nify');

export default class Vendors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiUrl: Config.baseURL + 'vendors/list',
			columns: [
				<Translate value='components.settings.vendorName' />,
				<Translate value='components.settings.contact' />,
				<Translate value='components.settings.phone' />,
				<Translate value='components.settings.email' />,
				<Translate value='components.settings.poNumber' />,
				<Translate value='components.settings.status' />,
				<Translate value='components.settings.lastUpdated' />,
			],
			fields: ['name', 'contactName', 'phoneNumber', 'emailAddress', 'poCount', 'retired', 'lastUpdatedDate'],
			formatColumns: {6: {'date': true}},
			data: [],
			offset: 0,
			limit: 2500,
			showNewVendorForm: false,
			editVendorId: null,
			loading: true,
			autoRefresh: true,
			interval: null,
		};
		this.showEditVendorForm = this.showEditVendorForm.bind(this);
	}
	
	showEditVendorForm(dataIn) {
		// console.log("Vendor::showEditVendorForm() = dataIn: ", dataIn);
		this.setState({
			editVendorId: dataIn['id'],
			showNewVendorForm: true,
		});
	}

	refreshData() {
		this.setState({loading: true});
		getAPI("vendors/list").then(resVendors => {
			this.setState({
				data: resVendors['data'],
				loading: false,
			});
		});	
	}

	componentDidMount() {
		this.refreshData();	
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showNewVendorForm) {
				// console.log("Vendors::componentDidMount() - refreshData being called....");
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.state.data;
		
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return this.state.formatColumns[i] && this.state.formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		const editVendorForm = this.state.showNewVendorForm ? <NewVendorForm
					visible={this.state.showNewVendorForm}
					onClose={() => this.setState({showNewVendorForm: false})}
					onSave={() => {
						this.setState({showNewVendorForm: false});
						this.refreshData();
					}}
					editVendorId={this.state.editVendorId}
				/> : "";
	
		return (
			<div>
				<DataGrid
					columns={this.state.columns}
					fields={this.state.fields}
					data={data}
					formatColumns={this.state.formatColumns}
					onDoubleClick={this.showEditVendorForm}
					loading={this.state.loading}
					heightOffset={190}
				/>
				<Button bsSize='sm' onClick={() => this.setState({showNewVendorForm: true, editVendorId: null})} disabled={loggedInUser.accessVendorsCode == 'V'}>
					<Translate value='components.settings.newVendor' />
				</Button>
				&nbsp;
				<Button bsSize='sm' onClick={() => {
							
					let qboVendors = {};

					//	Get All QBO Vendors
					quickbooksAPI({
						endpoint: "/v3/company/realmId/query?query=",
						query: "select * from Vendor MAXRESULTS 1000",
					}).then(vendors => {
						// console.log("vendors: ", vendors);
						// qboVendors[]
						if(vendors['QueryResponse']['Vendor'] != null) {
							vendors['QueryResponse']['Vendor'].forEach(row => {
								const indexVendorName = row['DisplayName'].toLowerCase().replaceAll(':', '').replace(/[^a-zA-Z0-9]/g, "");
								qboVendors[indexVendorName] = row['Id'];
							});
							
							console.log("qboVendors: ", qboVendors);
					
							// Check each MyInkIQ Vendor if it's present in QBO (by name)
							this.state.data.forEach(row => {
								// If it's not present, add it
								const vendorName = row['name'].replaceAll(':', '');
								const indexVendorName = vendorName.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
								const vendorId = row['id'];
								const qboVendorRefId = row['qboVendorRefId'];
								// console.log("indexVendorName: ", indexVendorName, ", Object.hasOwn(qboVendors, indexVendorName): ", Object.hasOwn(qboVendors, indexVendorName));

								// if(qboVendors[indexVendorName] === undefined) {
								if(!Object.hasOwn(qboVendors, indexVendorName)) {
									console.log("Not found - vendorName: ", vendorName, ", indexVendorName: ", indexVendorName);
								// if(!Object.hasOwnProperty(qboVendors, indexVendorName)) {
									const objData = { 
										"DisplayName": vendorName
									};
									
									quickbooksAPI({
										endpoint: "/v3/company/realmId/vendor?minorversion=65",
										method: "POST",
										contentType: "application/json",
										data: encodeURIComponent(JSON.stringify(objData)),
									}).then(vendor => {
										// console.log("created vendor: ", vendor);
										if(vendor['Vendor']) {
											const qboRefId = vendor['Vendor']['Id'];
											getAPI("/common/updateQBORefId?entityName=Vendor&entityId=" + vendorId + "&qboRefId=" + qboRefId);
										}
									});
								} else {
									// If it's present get it's ID and store it 
									const qboRefId = qboVendors[indexVendorName];
									// console.log("qboVendorRefId: ", qboVendorRefId, ", qboRefId: ", qboRefId);
									if(qboVendorRefId == null || qboVendorRefId != parseInt(qboRefId))
										getAPI("/common/updateQBORefId?entityName=Vendor&entityId=" + vendorId + "&qboRefId=" + qboRefId);
									
								}
							});
						}
					});
					
				}} ><Translate value="components.buttons.qboSync" /></Button>
				{editVendorForm}
			</div>
		);
	}
}
