import React, { Component } from 'react';
import {Col, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import './DataDropdown.css'
// import {Grid, Row, Col } from 'react-bootstrap';

// Params:
//	data: associative array containing Grid Data to be displayed
// 	value: unique key representing the 'selected' option
class DataDropdown extends Component {


	constructor(props) {
		super(props);
		this.state = {
			value: '',
		};
		this.change = this.change.bind(this);
	}
	
	
	
	change(event) {
		this.props.action(event, this.props.name); // , event.target.value);
	}
	
	render() {
		var rows = [];
		var header = [];
		var value = this.props.value != undefined ? this.props.value : '';
		var label = this.props.label != undefined ? this.props.label : '';
		var asterisk = this.props.required ? '*' : '';
		const errMsg = this.props.errMsg ? <span style={{color:'red'}}>{this.props.errMsg}</span> : "";

		let strOptionAll = this.props.optionAll ? "All": '---';
		var options = this.props.bHideEmpty ? []: [<option value={''}>{strOptionAll}</option>];
		if(Array.isArray(this.props.data)) {
			
			this.props.data.forEach((row, i) => {
				const key = this.props.colValue ? row[this.props.colValue] : row[0];
				const text = this.props.colText ? row[this.props.colText] : row[1];
				const selected = key == value ? " selected" : "";
				options.push(<option value={key} selected={selected}>{text}</option>);
			});
		}
		else 
		{
			for(var key in this.props.data)
			{
				var text = this.props.data[key];
				var selected = key == value ? " selected" : "";
				options.push(<option value={key} selected={selected}>{text}</option>);
				/*if(key == value) {
					options.push(<option value={key} selected={"selected"}>{text}</option>);
				} else {
					options.push(<option value={key}>{text}</option>);
				}*/
			}
		}
		return(<FormGroup bsSize='sm'>
			<Col sm={4}>
				<ControlLabel>{label}: <span className='required'>{asterisk}</span></ControlLabel>
			</Col>
			<Col sm={8}>
				<FormControl disabled={this.props.disabled} componentClass='select' onChange={this.change}>{options}</FormControl>{errMsg}
			</Col>
		</FormGroup>);
		// return options;
	}
}

export default DataDropdown;
