import React, { Component } from 'react';
import {Form, Row, Col, Modal, Button} from 'react-bootstrap';

import DataDropdown from '../common_components/DataDropdown.js';
import InputText from '../common_components/InputText.js';
import DataGrid from '../common_components/DataGrid.js';
import { getAPI, postAPI, mapArrayToObject, getLoggedInUser, resetObject, validateForm, getCurrencySymbol, getWeightSymbol, showAddress, showFormValidationAlert} from '../common_components/common.js';
import '../FormPage.css';
import {MyInkIQReactGA} from '../common_components/common.js';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');


var vendorsList = {};
var componentStatuses = {};
var containerTypes = {};

class InkComponentFormDeleteConfirmation extends Component {
    onDelete() {
        this.props.onConfirm();
        this.props.onClose();
    }

    render() {
        return(<Modal show={this.props.visible}>
            <Modal.Body><Translate value='components.labels.deleteInkComponentConfirmationPrompt' /></Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
                <Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
            </Modal.Footer>
        </Modal>);
    }
}

const validationFields = {
	componentCostPerUm: "required,numeric",
	name: "required",
	componentVendorId: "required",
	componentCostPerUm: "numeric",
	componentPigment: "numeric",
	componentResin: "numeric",
	componentInventoryCount: "numeric",
	componentMinInventory: "numeric",
	vocWeight: "required,numeric",
	density: "required,numeric",
	ammoniaWeight: "required,numeric",
	
};

const validationFieldsContainer = {
	vendorId: "required",
	containerId: "required",
	price: "required,numeric,positive",
	weight: "numeric",
};


class NewInkComponentForm extends Component {
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		this.state = {
			title: "New Component",
			errors: resetObject(validationFields),
			formData: {
				_enteredDate:null,
				backupVendorComponentName:"",
				backupVendorLeadTime:"",
				componentBackupVendorId:null,
				componentCostPerUm:0,
				componentInventoryCount:0,
				componentLastUsedDate:null,
				componentMinInventory:0,
				componentNotes:"",
				componentPigment:0.0,
				componentPrefix:"",
				componentResin:0.0,
				componentStatusLookupId:46, // Active
				componentSuffix:"",
				componentVendorId:null,
				containerId:null,
				containerName:null,
				custComponentNumber:"",
				enteredBy:null,
				enteredDate:null,
				id:0,
				inventoryComponentFlag:false,
				model:"com.phototype.myinkiq.model.vos.dtos::ComponentsDto",
				modifiedBy:null,
				modifiedDate:null,
				name:"",
				price:null,
				physicalQuantity:0,
				retired:false,
				vendorLeadTime:"",
				vendorName:null,
				vocWeight:null,
				density:null,
				ammoniaWeight:null,
			},
			saveDisabled: false,
			vendorLocale: null,
			vendorsList: [],
			componentStatuses: [],
			containerTypes: [],

			componentVendorAddress: "",
			componentBackupVendorAddress: "",
			componentContainers: [],
			
			deletedComponentContainerIds: [],
			editContainerRow: null,
			selectedContainerRowIndex: null,
			bShowContainerForm: false,
			showDeleteConfirmation: false,
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onAddContainer = this.onAddContainer.bind(this);
		this.onRemoveContainer = this.onRemoveContainer.bind(this);
		this.editInkComponentContainer = this.editInkComponentContainer.bind(this);
		this.containerRowSelected = this.containerRowSelected.bind(this);
		this.saveComponentContainer = this.saveComponentContainer.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		
		this.getVendorInfo = this.getVendorInfo.bind(this);
	}

	getVendorInfo(editComponentId, vendorId, addressFieldName) {
		getAPI("/components/" + editComponentId + "/vendors/" + vendorId).then(resVendor => {
			this.setState({
				[addressFieldName]: resVendor['data'][0]['address1'],
			});
		});
	}
	
	componentDidMount() {

		// Fetching Colors List
		// getAPI("/colors/list").then(resColorsList => {
			getAPI("/components/statuses").then(resStatuses => {
				getAPI("/components/vendors/containertypes").then(resContainerTypes => {
					getAPI("/vendors/list").then(resVendorsList => {
						this.setState({
							containerTypes: resContainerTypes['data'],
							componentStatuses: resStatuses['data'],
							vendorsList: resVendorsList['data'],
						});
						
						if(this.props.editComponentId != null)
						{
							var componentVendorId = null;
							var componentBackupVendorId = null;
							var editComponentId = this.props.editComponentId;
							getAPI("/components/" + editComponentId).then(resComponent => {
								getAPI("/components/" + editComponentId + "/vendors/containers").then(resComponentContainers => {
									var componentContainers = [];
									var backupBackupVendorContainer = resComponentContainers['data'].slice(-1)[0]
									var componentBackupBackupVendorId = null;
									if (backupBackupVendorContainer != null) {
										componentBackupBackupVendorId = backupBackupVendorContainer['vendorId'];
									}
									const formData = resComponent['data'][0];
									
									for(var i in resComponentContainers['data']) {
										var componentContainer = resComponentContainers['data'][i];
										const vendorId = componentContainer['vendorId'];
										const containerId = componentContainer['containerId'];
										const vendorRow = resVendorsList['data'].filter(obj => obj.id == vendorId)[0];
										componentContainer['vendorName'] = vendorRow['name'];
										componentContainer['vendorLocale'] = vendorRow['locale'];
										
										componentContainer['containerName'] = resContainerTypes['data'].filter(obj => obj.id == containerId)[0]['name'];
										componentContainer['model'] = "com.phototype.myinkiq.model.vos.dtos::ComponentVendorsContainersDto";
										componentContainer['retired'] = false;
										componentContainer['name'] = null;
										componentContainers.push(componentContainer);
									};
									componentVendorId = resComponent['data'][0]['componentVendorId'];
									componentBackupVendorId = resComponent['data'][0]['componentBackupVendorId'];
									const vendorLocale = resVendorsList['data'].filter(obj => 
									obj.id == formData.componentVendorId)[0]['locale'];
									
									
									this.setState({
										formData: formData,
										componentContainers: componentContainers,
										// componentVendorAddress: componentVendorAddress,
										// componentBackupVendorAddress: resVendor['data'][0]['address1'],
										title: "Component ID: " + editComponentId,
										vendorLocale: vendorLocale,	
									});
									if(componentVendorId)
										this.getVendorInfo(formData.id, componentVendorId, 'componentVendorAddress');
									
									if(componentBackupVendorId)
										this.getVendorInfo(formData.id, componentBackupVendorId, 'componentBackupVendorAddress');
									
									/*
									getAPI("/components/" + editComponentId + "/vendors/" + componentVendorId).then(resVendor => {
										const componentVendorAddress = showAddress(resVendor['data'][0]);

										if(componentBackupVendorId)
										{
											getAPI("/components/" + editComponentId + "/vendors/" + componentBackupVendorId).then(resVendor => {
												this.setState({
													formData: formData,
													componentContainers: componentContainers,
													componentVendorAddress: componentVendorAddress,
													componentBackupVendorAddress: resVendor['data'][0]['address1'],
													title: "Component ID: " + editComponentId,
													vendorLocale: vendorLocale,	
												});
											});
										} else if(componentBackupBackupVendorId) {
											formData.componentBackupVendorId = componentBackupBackupVendorId;
											getAPI("/components/" + editComponentId + "/vendors/" + componentBackupBackupVendorId).then(resVendor => {
												this.setState({
													formData: formData,
													componentContainers: componentContainers,
													componentVendorAddress: componentVendorAddress,
													componentBackupVendorAddress: resVendor['data'][0]['address1'],
													title: "Component ID: " + editComponentId,
													vendorLocale: vendorLocale,	
												});
											});
										} else {
											this.setState({
												formData: formData,
												componentContainers: componentContainers,
												componentVendorAddress: componentVendorAddress,
												title: "Component ID: " + editComponentId,
												vendorLocale: vendorLocale,
											});
										};
									});
									*/
								});
							});
						}	
					});
				});
			});
		// });
		
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		let vendorLocale = this.state.vendorLocale;
		
		switch(name) 
		{
		
			case 'componentPrefix':
				formData['custComponentNumber'] = value + '-' + this.state.formData.id + '-' + this.state.formData.componentSuffix;
				break;
			
			case 'componentSuffix':
				formData['custComponentNumber'] = this.state.formData.componentPrefix + '-' + this.state.formData.id + '-' + value;
				break;
			
			case 'componentVendorId':
				vendorLocale = this.state.vendorsList.filter(obj => 
					obj.id == value)[0]['locale'];
				// console.log("vendorLocale: ", vendorLocale);
				this.getVendorInfo(formData.id, value, 'componentVendorAddress');
				break;
			
			case 'componentBackupVendorId':
				this.getVendorInfo(formData.id, value, 'componentBackupVendorAddress');
				break;
		}
		
		
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		  vendorLocale: vendorLocale,
		});
	}
	
	onAddContainer(event) {
		event.preventDefault();
		
		this.setState({
			editContainerRow: null,
			selectedContainerRowIndex: null,
			bShowContainerForm: true,
		});
	}
	
	containerRowSelected(rowIndex) {
		this.setState({
			selectedContainerRowIndex: rowIndex,
		});
	}
	
	onRemoveContainer(event) {
		event.preventDefault();
		
		const selectedContainerRowIndex = this.state.selectedContainerRowIndex;
		if(selectedContainerRowIndex != null) {
			var componentContainers = this.state.componentContainers;
			var deletedComponentContainerIds = this.state.deletedComponentContainerIds;
		
			const componentContainer = this.state.componentContainers[selectedContainerRowIndex];
			if(componentContainer.id > 0)
				deletedComponentContainerIds.push(componentContainer);
			
			// componentContainers.pop();
			componentContainers.splice(selectedContainerRowIndex, 1);
			this.setState({
				componentContainers: componentContainers,
				deletedComponentContainerIds: deletedComponentContainerIds,
			});
		}
	}
	
	editInkComponentContainer(editRow) {
		this.setState({
			editContainerRow: editRow,
			bShowContainerForm: true,
		});
	}
	
	saveComponentContainer(componentContainer) {

		var componentContainers = this.state.componentContainers;
		componentContainer.bHasChanged = true;
		if(this.state.selectedContainerRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			componentContainers[this.state.selectedContainerRowIndex] = componentContainer;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			componentContainers.push(componentContainer);
		}
		this.setState({
			componentContainers: componentContainers,
			bShowContainerForm: false,
		});
	}
	
	onSave() {
	
		const validation = validateForm(this.state.formData, validationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			this.setState({
				saveDisabled: true,
			});
			postAPI("components", this.state.formData).then(resComponent => {
				
				var componentId = resComponent['data'][0]['id'];
				var addComponentContainerPromises = [];
				for(const i in this.state.componentContainers) {
					var componentContainer = this.state.componentContainers[i];
					componentContainer['componentId'] = componentId;
					if(componentContainer.bHasChanged != undefined && componentContainer.bHasChanged)
					{
						delete(componentContainer.bHasChanged);
						addComponentContainerPromises.push(postAPI("components/" + componentId + "/vendors/containers", componentContainer).then(resComponentContainer => {
						}));
					}
				}
				var removeComponentContainerPromises = [];
				for(const i in this.state.deletedComponentContainerIds) {
					const componentContainer = this.state.deletedComponentContainerIds[i];
					removeComponentContainerPromises.push(postAPI("/components/" + componentId + "/vendors/containers/" + componentContainer.id, componentContainer).then(resComponentContainer => {
					}));
				}
				Promise.all(addComponentContainerPromises)
				.then(() => {
					Promise.all(removeComponentContainerPromises)
					.then(() => {
						getAPI("common/componentCostChangeProcess?formulaId=0").then(compCostChange => {
							// alert(<Translate value="components.labels.componentSaved" />);
							alert("Component has been saved successfully");
							this.setState({
								saveDisabled: false,
							});
							this.reactGA.gaEvent("Component", "Save", "Component saved successfully.");
							this.props.onSave();
						});
					});
				});
			});
		}
	}
	
	onDelete() {
		postAPI("components/" + this.state.formData.id, this.state.formData).then(resComponent => {

			// alert(<Translate value="components.labels.componentDeleted" />);
			alert("Component has been deleted successfully");
			this.reactGA.gaEvent("Component", "Delete", "Component deleted successfully.");
			this.props.onSave();
		});
	}
	
	shouldComponentUpdate() {
		// var bUpdate = this.state.vendorsList.length > 0;
		return true;
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		var vendorsColumns = [
			'Vendor', 'Container', 'Price', 'Weight',
		];
		var vendorsFields = [
			'vendorName', 'containerName', 'price', 'weight',
		];
		
		const formatColumns = {
			2: {currency: true, colLocale: 'vendorLocale'}
		};
		
		// var componentStatusesMapped = mapArrayToObject(this.state.componentStatuses, 'id', 'value', true);
		const componentStatusesMapped = this.state.componentStatuses.map(obj => [obj.id, obj.value] );
		// console.log("componentStatusesMapped: ", componentStatusesMapped);
		var containerTypesMapped = mapArrayToObject(this.state.containerTypes, 'id', 'name');
		var vendorsListMapped = mapArrayToObject(this.state.vendorsList, 'id', 'name');

		
		const editContainerRow = this.state.editContainerRow;
		const inkComponentContainerForm = this.state.bShowContainerForm ? (<InkComponentContainerForm
			visible={this.state.bShowContainerForm}
			onClose={() => this.setState({bShowContainerForm: false})}
			onSave={this.saveComponentContainer}
			editContainerRow={editContainerRow} 
			vendorsList={this.state.vendorsList} 
			containerTypesMapped={containerTypesMapped} />)
		: '';
		
		

		return(
			<Modal show={this.props.visible} bsSize="large">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={4}>
								{false && <InputText label={<Translate value="components.labels.prefix" />} id={"componentPrefix"} name={"componentPrefix"} value={this.state.formData.componentPrefix} action={this.handleInputChanged} ></InputText>}
								<InputText label={<Translate value="components.labels.componentNumber" />} id={"custComponentNumber"} name={"custComponentNumber"} value={this.state.formData.custComponentNumber} action={this.handleInputChanged} ></InputText>
								{false && <InputText label={<Translate value="components.labels.suffix" />} id={"componentSuffix"} name={"componentSuffix"} value={this.state.formData.componentSuffix} action={this.handleInputChanged}></InputText>}
								<InputText 
									label={<span><Translate value={"components.labels.costPer" + Config.weightSymbol} /> ({getCurrencySymbol(Config.locale)})</span>} 
									required id={"componentCostPerUm"} name={"componentCostPerUm"} 
									value={this.state.formData.componentCostPerUm} 
									action={this.handleInputChanged} 
									errMsg={this.state.errors.componentCostPerUm}
									>
								</InputText>
								<InputText label={<Translate value="components.labels.pigment" />} 
									id={"componentPigment"} name={"componentPigment"} 
									value={this.state.formData.componentPigment} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.componentPigment}
								/>
								<InputText label={<Translate value="components.labels.resin" />} 
									id={"componentResin"} name={"componentResin"} 
									value={this.state.formData.componentResin} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.componentResin}
								/>
								<DataDropdown label={<Translate value="components.labels.status" />} 
									required id={"componentStatusLookupId"} 
									name={"componentStatusLookupId"} data={componentStatusesMapped} 
									value={this.state.formData.componentStatusLookupId} 
									action={this.handleInputChanged} >
								</DataDropdown>
							</Col>
							<Col md={4}>
								<InputText 
									label={<Translate value="components.labels.name" />} required 
									id={"name"} name={"name"} value={this.state.formData.name} 
									action={this.handleInputChanged} 
									errMsg={this.state.errors.name}
									>
								</InputText>
								<DataDropdown label={<Translate value="components.labels.vendor" />} 
									required id={"componentVendorId"} name={"componentVendorId"} 
									data={this.state.vendorsList} colValue='id' colText='name' 
									value={this.state.formData.componentVendorId} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.componentVendorId}
								>
								</DataDropdown>
								<InputText label={<Translate value="components.labels.address" />} 
									id={"componentVendorAddress"} name={"componentVendorAddress"} 
									value={this.state.componentVendorAddress} type={"Label"} >
								</InputText>
								<InputText label={<Translate value="components.labels.leadTime" />} 
									id={"vendorLeadTime"} name={"vendorLeadTime"} 
									value={this.state.formData.vendorLeadTime} 
									action={this.handleInputChanged} >
								</InputText>
								<DataDropdown 
									label={<Translate value="components.labels.backupVendor" />} 
									id={"componentBackupVendorId"} name={"componentBackupVendorId"} 
									data={vendorsListMapped} 
									value={this.state.formData.componentBackupVendorId} 
									action={this.handleInputChanged} >
								</DataDropdown>
								<InputText 
									label={<Translate value="components.labels.name" />} 
									id={"backupVendorComponentName"} 
									name={"backupVendorComponentName"} 
									value={this.state.formData.backupVendorComponentName} 
									action={this.handleInputChanged} 
									>
								</InputText>
								<InputText label={<Translate value="components.labels.address" />} 
									id={"componentBackupVendorAddress"} 
									name={"componentBackupVendorAddress"} 
									value={this.state.componentBackupVendorAddress} 
									type={"Label"} >
								</InputText>
								<InputText label={<Translate value="components.labels.leadTime" />} 
									id={"backupVendorLeadTime"} name={"backupVendorLeadTime"} 
									value={this.state.formData.backupVendorLeadTime} 
									action={this.handleInputChanged} >
								</InputText>
							</Col>
							<Col md={4}>
								<InputText 
									label={<Translate value="components.labels.inventoryCount" />} 
									id={"componentInventoryCount"} name={"componentInventoryCount"} 
									value={this.state.formData.componentInventoryCount} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.componentInventoryCount}
								/>
								<InputText 
									label={<Translate value="components.labels.minInventory" />} 
									id={"componentMinInventory"} name={"componentMinInventory"} 
									value={this.state.formData.componentMinInventory} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.componentMinInventory}
								/>
								<InputText type='CheckBox' label={<Translate value="components.labels.inventory" />} id={"inventoryComponentFlag"} name={"inventoryComponentFlag"} value={this.state.formData.inventoryComponentFlag} action={this.handleInputChanged} ></InputText>
								
								<InputText 
									label={<Translate value="components.labels.vocWeight" />} 
									id={"vocWeight"} name={"vocWeight"} 
									value={this.state.formData.vocWeight} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.vocWeight}
								/>
								<InputText 
									label={<Translate value="components.labels.density" />} 
									id={"density"} name={"density"} 
									value={this.state.formData.density} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.density}
								/>
								<InputText 
									label={<Translate value="components.labels.ammoniaWeight" />} 
									id={"ammoniaWeight"} name={"ammoniaWeight"} 
									value={this.state.formData.ammoniaWeight} 
									action={this.handleInputChanged}
									errMsg={this.state.errors.ammoniaWeight}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<DataGrid columns={vendorsColumns} fields={vendorsFields}
									formatColumns={formatColumns} 
									onDoubleClick={this.editInkComponentContainer} 
									onSelect={this.containerRowSelected} 
									data={this.state.componentContainers} 
								/>
								<Button id={"btnAddContainer"} onClick={this.onAddContainer} 
									disabled={loggedInUser.accessComponentsCode == 'V'}>
									<Translate value="components.buttons.add" />
								</Button>&nbsp;
								<Button id={"btnRemoveContainer"} onClick={this.onRemoveContainer} 
									disabled={loggedInUser.accessComponentsCode == 'V'} >
									<Translate value="components.buttons.remove" />
								</Button>
								{inkComponentContainerForm}
							</Col>
							<Col md={6}>
								<InputText type='TextArea' label={<Translate value="components.labels.notes" />} id={"componentNotes"} name={"componentNotes"} value={this.state.formData.componentNotes} action={this.handleInputChanged} ></InputText>
							</Col>
						</Row>
					</Form>
					<InkComponentFormDeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
					/>
				</Modal.Body>
				<Modal.Footer>
					{this.props.editComponentId ? 
					<Button 
						bsStyle='danger' onClick={() => this.setState({showDeleteConfirmation: true})} 
						disabled={loggedInUser.accessComponentsCode == 'V'}>
							<Translate value="components.buttons.deleteComponent" />
					</Button> : ''}
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} 
						bsStyle="primary" 
						disabled={loggedInUser.accessComponentsCode == 'V' || this.state.saveDisabled}>
						<Translate value="components.buttons.saveChanges" />
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default NewInkComponentForm;

class InkComponentContainerForm extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Add New Container",
			errors: resetObject(validationFieldsContainer),
			formData: {
				componentId:0,
				containerId:null,
				containerName: "",
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::ComponentVendorsContainersDto",
				name:null,
				price:0,
				weight: 0,
				retired:false,
				vendorId:null,
				vendorName: "",
				vendorLocale: null,
			},
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editContainerRow) { // If this is not null
			const vendorRow = this.props.vendorsList.filter(obj => obj.id 
				== this.props.editContainerRow.vendorId)[0];
			const vendorLocale = vendorRow.locale;
			this.setState({
				formData: this.props.editContainerRow,
			});
		}
		
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		
		switch(name) {
			case 'vendorId':
				const vendorRow = this.props.vendorsList.filter(obj => obj.id 
					== value)[0];
				// console.log("value: ", value, ", vendorRow: ", vendorRow);
				formData.vendorLocale = vendorRow.locale;
				break;
		}
		

		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	
	onSave(event) {
		event.preventDefault();
		const validation = validateForm(this.state.formData, validationFieldsContainer);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			var componentContainer = this.state.formData;
			const vendorRow = this.props.vendorsList.filter(obj => obj.id 
			== this.state.formData.vendorId)[0];
			
			// componentContainer['vendorName'] = this.props.vendorsListMapped[this.state.formData.vendorId];
			componentContainer['vendorName'] = vendorRow['name'];
			
			componentContainer['containerName'] = this.props.containerTypesMapped[this.state.formData.containerId];
			// console.log("componentContainer: ", componentContainer);
			this.props.onSave(componentContainer);
		}
	}
	
	render() {
		const vendorsListMapped = mapArrayToObject(this.props.vendorsList, 'id', 'name');
		const loggedInUser = getLoggedInUser();
		var content = (<Form className='form-page' horizontal>
			<Row>
				<Col md={2}></Col>
				<Col md={8}>
					<DataDropdown label={<Translate value="components.labels.vendor" />} 
						id={"vendorId"} name={"vendorId"} action={this.handleInputChanged} 
						data={vendorsListMapped} value={this.state.formData.vendorId} 
						errMsg={this.state.errors.vendorId}
					/>
					<DataDropdown label={<Translate value="components.labels.container" />} 
						id={"containerId"} name={"containerId"} action={this.handleInputChanged} 
						data={this.props.containerTypesMapped} 
						value={this.state.formData.containerId} 
						errMsg={this.state.errors.containerId}
					/>
					<InputText label={<span><Translate value="components.labels.price" /> ({getCurrencySymbol(this.state.formData.vendorLocale)})</span>} 
						id={"price"} name={"price"} action={this.handleInputChanged} 
						value={this.state.formData.price} 
						errMsg={this.state.errors.price}
					/>
					<InputText label={<Translate value="components.labels.weight" />} 
						id={"weight"} name={"weight"} action={this.handleInputChanged} 
						value={this.state.formData.weight} 
						errMsg={this.state.errors.weight}
					/>
				</Col>
				<Col md={2}></Col>
			</Row>
		</Form>);
				
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessComponentsCode == 'V'}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
	
}
