import React, { Component } from 'react';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown.js';

import OrderForm from '../orders/OrderForm.js';
import {Row, Col, Button, Form} from 'react-bootstrap';
import { getAPI, postAPI, convertTimestampToDate, lab_request_lookup_id, credit_memo_lookup_id, sales_order_lookup_id, orderTypes, getLoggedInUser, postAPIUrlEncoded, generateUUID, openBirtReport, constants} from '../common_components/common.js';
import CustomerPopin from '../customers/CustomerPopin.js';
import NewFormulaForm from '../formulas/NewFormulaForm.js';

import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');



const bcm_ink_orders = '1';
const non_bcm_ink_orders = '2';

const bcm_inks_usa = 'BCM INKS USA, INC.';


//	PROPS params
//	New approach: Filter the data within the component before passing it on to the grid.
class Order extends Component {
	constructor(props){
		super(props);
		
		this.state = {
			showOrderForm: false,
			showLabRequestForm: false,
			showFormulaForm: false,
			data: [],
			orderType: !this.props.dashboard ? sales_order_lookup_id : '',
			showBCMInksOrders: non_bcm_ink_orders,
			editOrderId: null,
			idField: 'id',
			loading: true,
			editCustomerId: null, // This is for the Customer link column
			editFormulaId: null,	// This is for the Formula link column
			autoRefresh: true,
			interval: null,
			chkBatchOrderIds: {},
			salesPeopleCustomers: {},
		};
		
		this.orderTypeChanged = this.orderTypeChanged.bind(this);
		this.newSalesOrderClicked = this.newSalesOrderClicked.bind(this);
		this.showBCMInksOrdersChanged = this.showBCMInksOrdersChanged.bind(this);
		this.showEditOrderForm = this.showEditOrderForm.bind(this);
		this.handleCloseOrderForm = this.handleCloseOrderForm.bind(this);
		this.handleSaveOrder = this.handleSaveOrder.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.onBatchOrderIdChanged = this.onBatchOrderIdChanged.bind(this);
		this.onCombineBatchesClicked = this.onCombineBatchesClicked.bind(this);
		this.onUncombineBatchesClicked = this.onUncombineBatchesClicked.bind(this);
	}
	
	componentWillReceiveProps(props) {
		// console.log("Order::componentWillReceiveProps() called - props: ", props, ", this.props: ", this.props);
	}
	
	componentWillMount() {
		// console.log("Order::componentWillMount() called - this.props: ", this.props);
	}
	
	refreshData() {
		// console.log("Order::refreshData() called - this.props: ", this.props);
		this.setState({
			loading: true,
		})
		let endpoint = "salesorders/list";
		switch(this.props.dashboard) {
			case 'Batch':
				endpoint = "formulas/batches";
				this.setState({
					idField: 'orderId'
				})
				break;
				
			case 'ColorMatch':
				endpoint = "salesorders/colormatches";
				this.setState({
					idField: 'orderId'
				})
				break;
		}
		
		
		getAPI(endpoint).then(resOrders => {
			let data = resOrders['data'];
			const bgColors = {0: '', 1: '#ffaaaa', 2: '#FFDEAD'};
			data = data.map(obj => {
				obj.bgColor = bgColors[obj.openOrderType] != undefined ? bgColors[obj.openOrderType] : '';
				return obj;
			});
			if(this.props.dashboard) {
				switch(this.props.dashboard) {
					case 'Order':
						
						data = data.filter(obj => obj.salesOrderType == "Sales Order" && 
							(obj.statusName == "Entered" 
							|| obj.statusName == "Confirmed" 
							|| obj.statusName == "Shipped"
							|| obj.statusName == "Customer Entered"));
						
						break;
					
					case 'LabRequest':
						data = data.filter(obj => obj.salesOrderType == "Lab Request" && 
							(obj.statusName == "Entered" 
							|| obj.statusName == "Confirmed" 
							|| obj.statusName == "Shipped"
							|| obj.statusName == "Customer Entered"));
						break;
					
					case 'Batch':
						data = data.map(obj => {
							obj.bgColor = obj.combinedBatchNumber != null 
								? 'yellow' : null;
							return obj;
						});
						break;
							
				}
				this.props.onLoad(data.length);
			}
			this.setState({
				data: resOrders['data'],
				loading: false,
				chkBatchOrderIds : {},
			});
		});		
	}
	
	componentDidMount() {
		const loggedInUser = getLoggedInUser();
		if(loggedInUser.userTypeId == 8) {// In case of Sales People, store in their customers for later
			getAPI("users/" + loggedInUser.id + "/customers").then(resSalesPeopleCustomers => {
				let objSalesPeopleCustomerIDs = {};
				resSalesPeopleCustomers['data'].map(row => {
					const customerId = row.customerId;
					objSalesPeopleCustomerIDs[customerId] = 1;
				});
				this.setState({
					salesPeopleCustomers: objSalesPeopleCustomerIDs,
				});
			});
		}
		this.refreshData();

		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showOrderForm) {
				// console.log("Order::componentDidMount() - refreshData being called....");
				this.refreshData();	  
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	orderTypeChanged(event) {
		var val = event.target.value;
		this.setState({
			orderType: val,
		});
	}
	
	showBCMInksOrdersChanged(event) {
		var val = event.target.value;
		this.setState({
			showBCMInksOrders: val,
		});
	}
	
	newSalesOrderClicked(event) {
		event.preventDefault();
		this.setState({
			showOrderForm: true,
			editOrderId: null
		});
	}
	
	showEditOrderForm(dataIn) {		
		// Sales People cannot double click to open orders other than their own customers
		const loggedInUser = getLoggedInUser();
		if(loggedInUser.userTypeId == 8) {
			const salesPeopleCustomers = this.state.salesPeopleCustomers;
			// If the Sales person does not have access to all customers
			// and the order does not belong to their own customer
			if(!loggedInUser.allCustomerAccessFlag && salesPeopleCustomers[dataIn.customerId] == undefined)
				return false;
		}
		
		// console.log(dataIn, dataIn[this.state.idField])
		this.setState({
			editOrderId: dataIn[this.state.idField],
			showOrderForm: true,
		});
	}

	handleCloseOrderForm() {
		this.setState({
			showOrderForm: false,
		});
	}

	handleSaveOrder() {
		this.handleCloseOrderForm();
		this.refreshData();
	}
	
	onLinkClick(event) {
		const editId = event.target.id;
		const varName = event.target.name;
		
		const loggedInUser = getLoggedInUser();
		if(loggedInUser.userTypeId == 8) {
			const salesPeopleCustomers = this.state.salesPeopleCustomers;
			if(salesPeopleCustomers[editId] == undefined)
				return false;
		}
		
		this.setState({
			[varName]: editId,
		});
	}
	
	onBatchOrderIdChanged(event) {
		let chkBatchOrderIds = this.state.chkBatchOrderIds;
		const checked = event.target.checked;
		const value = event.target.value;
		const name = event.target.name;
		const stateVarName = "chkBatchOrderIds"; // event.target.id;
		const id = event.target.id;
		// console.log("Order::onBatchOrderIdChanged(): name: ", name, ", value: ", value, ", checked: ", checked);
		
		// Select All checkbox
		if(name == 'orderFormulaId')
		{
			for(const i in this.state.data)
			{
				const orderFormulaId = this.state.data[i]['orderFormulaId'];
				if(checked)
					chkBatchOrderIds[orderFormulaId] = true;
				else
					delete chkBatchOrderIds[orderFormulaId];
			}
		}
		else
		{
			const batchRow = this.state.data.filter(obj => obj.orderFormulaId == value);
			// console.log("batchRow: ", batchRow, ", value: ", value);
			const cbn = batchRow && batchRow[0].combinedBatchNumber ? batchRow[0].combinedBatchNumber : true;
			if(checked)
				chkBatchOrderIds[value] = cbn;
			else
				delete chkBatchOrderIds[value];
		}
		// console.log("chkBatchOrderIds in Order::onBatchOrderIdChanged() : ", chkBatchOrderIds);
		this.setState({
			chkBatchOrderIds: chkBatchOrderIds,
		});
	}
	
	onCombineBatchesClicked(event) {
		event.preventDefault();
		const orderFormulaIds = Object.keys(this.state.chkBatchOrderIds).join(",");
		const cbn = generateUUID(); // This has be calculated somehow
		const body = {ids: orderFormulaIds, cbn: cbn};
		// console.log("body: ", body);
		postAPIUrlEncoded("salesorders/combinebatches", body).then(result => {
			if(result.success) {
				// Open Birt Report
				openBirtReport('BCM_Batch_Ticket_Combined', {
						batchNumber: cbn,
					}
				);
				this.refreshData();
			}
		});
	}
	
	onUncombineBatchesClicked(event) {
		event.preventDefault();
		const orderFormulaIds = Object.keys(this.state.chkBatchOrderIds).join(",");
		const body = {ids: orderFormulaIds};
		// console.log("body: ", body);
		postAPIUrlEncoded("salesorders/uncombinebatches", body).then(result => {
			if(result.success) {
				this.refreshData();
			}
		});
	}
	
	/*
	shouldComponentUpdate(nextProps, nextState) {
		console.log("Order::shouldComponentUpdate - nextProps: ", nextProps, ", nextState: ", nextState);
		let bUpdate = nextState.data.length > 0;
		return bUpdate;
	}
	*/
	
	render() {
		const loggedInUser = getLoggedInUser();
		let data = this.state.data;
		const amountField = Config.locale.toLowerCase() == 'es_mx' ? 'grandTotal' : 'amount';
		
		let columns = [
			<Translate value="components.labels.orderId" />,
			<Translate value="components.labels.orderDate" />,
			<Translate value="components.labels.orderType" />,
			<Translate value="components.labels.customer" />,
			// <Translate value="components.labels.note" />,
			"PO #",
			<Translate value="components.labels.shipper" />,
			<Translate value="components.labels.shipDate" />,
			<Translate value="components.labels.status" />,
			<Translate value="components.labels.amount" />,
		];
		let fields = ['id', 'orderDate', 'salesOrderType', 'customerName'
		// , 'note'
		, 'poNumber', 'shipper', 'shipDate', 'statusName', amountField];
		let formatColumns= {1:{'date': true}, 3: {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}}, 6:{'date': true}, 8:{'currency': true, 'bgColor': 'bgColor'}};
		
		if(loggedInUser.userTypeId == constants.customer_type_id) {
			delete formatColumns[3];
		}
		let totalField = amountField;
		
		let formatTotal = {'currency': true};
		
		switch(this.props.dashboard) {
			case 'Order':
				data = data.filter(obj => obj.salesOrderType == "Sales Order" && (obj.statusName == "Entered" || obj.statusName == "Confirmed" || obj.statusName == "Shipped" || obj.statusName == "Customer Entered"));

				columns = [
					<Translate value="components.labels.orderId" />,
					<Translate value="components.labels.orderDate" />,
					<Translate value="components.labels.orderType" />,
					<Translate value="components.labels.customer" />,
					// <Translate value="components.labels.note" />,
					"PO #",
					<Translate value="components.labels.status" />,
					<Translate value="components.labels.progress" />,
					'#', 
					<Translate value="components.labels.shipper" />,
					<Translate value="components.labels.shipDate" />,
					<Translate value="components.labels.amount" />,
				];
				
				fields = ['id', 'orderDate', 'salesOrderType', 'customerName'
				// , 'note'
				, 'poNumber', 'statusName', 'batchesCompletedPercentage', 'batchesCount', 'shipper', 'shipDate', amountField];
				
				if(loggedInUser.userTypeId == constants.customer_type_id) {
					formatColumns= {1:{'date': true}, 6: {'percentage': true}, 9:{'date': true}, 10:{'currency': true, 'bgColor': 'bgColor'}};
				}
				else {
					formatColumns= {1:{'date': true}, 3: {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}}, 6: {'percentage': true}, 9:{'date': true}, 10:{'currency': true, 'bgColor': 'bgColor'}};

				}
				
				break;
			
			case 'Batch':
				columns = [
					<Translate value="components.labels.orderId" />,
					<Translate value="components.labels.orderDate" />,
					<Translate value="components.labels.formulaNumber" />,
					<Translate value="components.labels.name" />,
					<Translate value="components.labels.status" />,
					<Translate value="components.labels.customer" />,
					<Translate value="components.labels.container" />,
					<Translate value="components.labels.quantity" />,
					<Translate value={"components.labels." + Config.weightSymbol + "sOrdered"} />,
					<Translate value="components.labels.combine" />,
				];
				fields = ['orderId', 'orderDate', 'formulaNumber', 'name', 'orderStatusName', 'customerName', 'container', 'quantity', 'lbsOrdered', 'orderFormulaId'];
				formatColumns= {
					0: {'link': {'column': 'orderId', 'var': 'editOrderId', 'func': this.onLinkClick}}, 
					1:{'date': true}, 
					3: {'link': {'column': 'formulaId', 'var': 'editFormulaId', 'func': this.onLinkClick}}, 
					9:{
						checkbox: this.state.chkBatchOrderIds, 
						callbackCheckbox: this.onBatchOrderIdChanged, 
						// colVisibleBound: 'combinedBatchNumber',
						bgColor: 'bgColor',
					}
				};
				break;
			
			case 'LabRequest':
				data = data.filter(obj => obj.salesOrderType == "Lab Request" && (obj.statusName == "Entered" || obj.statusName == "Confirmed" || obj.statusName == "Shipped" || obj.statusName == "Customer Entered"));
				columns = [
					<Translate value="components.labels.labRequestId" />,
					<Translate value="components.labels.dueDate" />,
					<Translate value="components.labels.customer" />,
					<Translate value="components.labels.note" />,
				];
				fields = ['id', 'dueDate', 'customerName', 'note'];
				formatColumns= {1:{'date': true}, 2: {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}}};
				break;
			
			case 'ColorMatch':
				// data = data.filter(obj => obj.salesOrderType == "Lab Request" && (obj.statusName == "Entered" || obj.statusName == "Confirmed" || obj.statusName == "Shipped"));
				columns = [
					<Translate value="components.labels.labRequestId" />,
					<Translate value="components.labels.dueDate" />,
					<Translate value="components.labels.customer" />,
					<Translate value="components.labels.colorName" />,
					<Translate value="components.labels.matchedBy" />,
					<Translate value="components.labels.anilox" />,
				];
				fields = ['orderId', 'dueDate', 'customerName', 'name', 'matchedBy', 'anilox'];
				formatColumns= {1:{'date': true}, 2: {'link': {'column': 'customerId', 'var': 'editCustomerId', 'func': this.onLinkClick}}};
				break;
			
			default:
				break;
	
		}
		
		
		
		var orderTypesList = {
			'': 'All', // <Translate value='components.labels.filterAll' />,
			[credit_memo_lookup_id]: 'Credit Memo', // <Translate value='components.labels.filterCreditMemo' />,
			[lab_request_lookup_id]: 'Lab Request', // <Translate value='components.labels.filterLabRequest' />,
			[sales_order_lookup_id]: 'Sales Order', // <Translate value='components.labels.filterSalesOrder' />,
		};
		
		const orderTypesEnglish = {
			'': "All",
			[credit_memo_lookup_id]: "Credit Memo",
			[lab_request_lookup_id]: "Lab Request",
			[sales_order_lookup_id]: "Sales Order",
		};
		
		var BCMInkFilter = {
			0: 'All', // <Translate value='components.labels.filterAll' />,
			[bcm_ink_orders]: 'BCM Ink Orders', // <Translate value='components.labels.filterBcmInkOrders' />,
			[non_bcm_ink_orders]: 'Non BCM Ink Orders', // <Translate value='components.labels.filterNonBcmInkOrders' />,
		};
		
		
		
		if(this.state.orderType != '')
		{
			data = data.filter(obj => obj.salesOrderType == orderTypesEnglish[this.state.orderType]);
		}
		
		
		
		if(this.state.showBCMInksOrders != '0')
		{
			console.log("Config.bcmInksId: ", Config.bcmInksId);
			switch(this.state.showBCMInksOrders)
			{
				case bcm_ink_orders:
					data = data.filter(obj => obj.customerId == Config.bcmInksId);
					break;
				
				case non_bcm_ink_orders:
					data = data.filter(obj => obj.customerId != Config.bcmInksId);
					break;
			}
		}
		
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		let totalValue = 0;
		data.forEach(row => {
			totalValue += row[amountField];
		});
		
		var orderFormContent = this.state.editOrderId || this.state.showOrderForm ? <OrderForm
			visible={this.state.editOrderId || this.state.showOrderForm}
			onClose={() => this.setState({editOrderId: null, showOrderForm: false})}
			onSave={this.handleSaveOrder}
			editOrderId={this.state.editOrderId}
		/> : "";
		
		var customerFormContent = this.state.editCustomerId ? <CustomerPopin
			visible={this.state.editCustomerId != null}
			onClose={() => this.setState({editCustomerId: null})}
			editCustomerId={this.state.editCustomerId}
		/> : "";
		
		var formulaFormContent = this.state.editFormulaId || this.state.showFormulaForm ? <NewFormulaForm
			visible={this.state.editFormulaId != null || this.state.showFormulaForm}
			onClose={() => this.setState({editFormulaId: null, showFormulaForm: false})}
			editFormulaId={this.state.editFormulaId}
		/> : "";

		var labRequestFormContent = this.state.showLabRequestForm ? <OrderForm
			visible={this.state.showLabRequestForm}
			onClose={() => this.setState({showLabRequestForm: false})}
			orderTypeLookupId={lab_request_lookup_id}
			
		/> : '';
		
		
		let newButton;
		switch(this.props.dashboard) {
			case 'LabRequest':
				newButton = <Button bsSize='sm' onClick={() => this.setState({showLabRequestForm: true})} disabled={loggedInUser.accessOrdersCode == 'V'}>
					<Translate value="components.buttons.newLabRequest" />
				</Button>
				break;
			case 'Batch':
				newButton = <span>
					<Button
						bsSize='sm'
						onClick={this.onCombineBatchesClicked}
						disabled={loggedInUser.accessOrdersCode == 'V'}>
						<Translate value="components.buttons.combineBatches" />
					</Button>
					&nbsp;
					<Button
						bsSize='sm'
						onClick={this.onUncombineBatchesClicked}
						disabled={loggedInUser.accessOrdersCode == 'V'}>
						<Translate value="components.buttons.uncombineBatches" />
					</Button>
					&nbsp;
					<Button
						bsSize='sm'
						onClick={() => this.setState({showFormulaForm: true, editFormulaId: null})}
						disabled={loggedInUser.accessFormulasCode == 'V'}>
						<Translate value="components.buttons.newFormula" />
					</Button>
					</span>
				break;
			default:
				newButton = <Button bsSize='sm' onClick={this.newSalesOrderClicked} disabled={loggedInUser.accessOrdersCode == 'V'}><Translate value="components.buttons.newSalesOrder" /></Button>
				break;
		}

		return (
			<div>
				<DataGrid 
					loading={this.state.loading}
					columns={columns}
					fields={fields} 
					data={data} 
					formatColumns={formatColumns} 
					txtSearch={this.props.txtSearch}
					onDoubleClick={this.showEditOrderForm}
					heightOffset={this.props.dashboard ? 200 : 150}
					totalField={totalField}
					totalValue={totalValue}
					formatTotal={formatTotal}
				/>
				<Form class='form-page' horizontal>
					<Row>
						{this.props.dashboard ? <Col md={2}></Col> : ''}
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.show" />} 
								action={this.showBCMInksOrdersChanged} data={BCMInkFilter} 
								value={this.state.showBCMInksOrders} 
							/>
						</Col>
						{!this.props.dashboard ? <Col md='4'>
							<DataDropdown 
								label={<Translate value="components.labels.type" />} 
								action={this.orderTypeChanged} data={orderTypesList} 
								value={this.state.orderType} 
							/>
						</Col> : ''}
						<Col md={4}>{newButton}</Col>
					</Row>
				</Form>
				{orderFormContent}
				{labRequestFormContent}
				{customerFormContent}
				{formulaFormContent}
			</div>
		);
	}
}

export default Order;
