import React, { Component } from 'react';
import {Grid, Row, Col, Button } from 'react-bootstrap';
import TabContainer from '../common_components/TabContainer.js';

import Customer from '../customers/Customer.js';
import Inventory from '../inventory/Inventory.js';
import TabItem from './TabItem.js';
import Order from '../orders/Order.js';
import Formula from '../formulas/Formula.js';
import ActionableItems from './ActionableItems.js';
import SalesGoals from './SalesGoals.js';
import {getLoggedInUser} from '../common_components/common.js';
import I18n from '../translations/myinkiq-i18n.js';
import Octicon, { Sync } from '@githubprimer/octicons-react';
const { Translate, Localize } = require('react-i18nify');

class Home extends Component {


constructor(props){
    super(props);
    
    this.state = {
    	orderCount: 0,
    	batchesCount: 0,
    	labRequestCount: 0,
    	matchesCount: 0,
    	actionableItemsCount: 0,
    };

	

  }
  
  componentWillReceiveProps(props) {
		// console.log("Home::componentWillReceiveProps() called - props: ", props, ", this.props: ", this.props);
	}
  
	render(){
		// console.log("Home::render() called - this.props: ", this.props)
		
		const tabTitles = [
			 <span><Translate value="components.tabs.orders" />
			 	{" (" + this.state.orderCount + ")"}</span>,
			 <span><Translate value="components.tabs.batches" />
			 	{" (" + this.state.batchesCount + ")"}</span>,
			 <span><Translate value="components.tabs.labRequests" />
			 	{" (" + this.state.labRequestCount + ")"}</span>,
			 <span><Translate value="components.tabs.matches" />
			 	{" (" + this.state.matchesCount + ")"}</span>,
			 <span><Translate value="components.tabs.actionableItems" />
			 {" (" + this.state.actionableItemsCount + ")"}</span>,
			 <Translate value="components.tabs.goals" />
		];
		
		const tabs = [
			<Order dashboard={'Order'} search={this.props.search} 
				onLoad={(orderCount) => {
					this.setState({orderCount: orderCount});
				}}
			/>,
			<Order dashboard={'Batch'} search={this.props.search} 
				onLoad={(batchesCount) => {
					// console.log("batchesCount: ", batchesCount);
					this.setState({batchesCount: batchesCount});
				}}
			/>,
			<Order dashboard={'LabRequest'} search={this.props.search} 
				onLoad={(labRequestCount) => {
					this.setState({labRequestCount: labRequestCount});
				}}
			/>,
			<Order dashboard={'ColorMatch'} search={this.props.search} 
				onLoad={(matchesCount) => {
					// console.log("matchesCount: ", matchesCount);
					this.setState({matchesCount: matchesCount});
				}}
			/>,
			<ActionableItems search={this.props.search} 
				onLoad={(itemCount) => {
					// console.log("matchesCount: ", matchesCount);
					let actionableItemsCount = this.state.actionableItemsCount;
					actionableItemsCount = itemCount;
					this.setState({actionableItemsCount: actionableItemsCount});
				}}
			/>,
			<SalesGoals search={this.props.search} />,
		];
		
		const tabsShown = [];
		const tabTitlesShown = [];
		const loggedInUser = getLoggedInUser();
		
		//	Adding the Orders
		if(loggedInUser.accessOrdersCode != 'N') {
			tabsShown.push(tabs[0]);
			tabTitlesShown.push(tabTitles[0]);
		}
		
		
		// Proceed only if the  User type is other than a customer
		const userTypeId = loggedInUser.userTypeId;
		if(userTypeId != 7) {
			//	Adding the Batches tab
			if(loggedInUser.accessFormulasCode != 'N') {
				tabsShown.push(tabs[1]);
				tabTitlesShown.push(tabTitles[1]);
			}
			
			const dashboardTabsByUserType = {
				10: [2, 3],
				11: [2, 3],
				12: [2, 3],
				13: [2, 3],
				171: [2, 3],
				8: [2, 3, 5],
				170: [2, 3, 5],
				9: [2, 3, 4, 5],
			};
			
			for(const i in dashboardTabsByUserType[userTypeId]) {
				const tabIndex = dashboardTabsByUserType[userTypeId][i];
				tabsShown.push(tabs[tabIndex]);
				tabTitlesShown.push(tabTitles[tabIndex]);
			}
		}
		
		
		
		return (
			<div>
			<TabContainer tabTitles={tabTitlesShown} tabs={tabsShown} />
			</div>
		);
	}
}

export default Home;
