import React, { Component } from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid';
import DataDropdown from '../common_components/DataDropdown.js';
import { getAPI, postAPI, convertTimestampToDate, getLoggedInUser} from '../common_components/common.js';
import NewVendorForm from '../settings/NewVendorForm.js';
import NewPurchaseOrderForm from './NewPurchaseOrderForm';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');

class PurchaseOrder extends Component {
	constructor(props) {
		super(props);

		var filter = this.props.txtSearch != undefined && this.props.txtSearch != '' ? {'*': {'op': 'contains', 'val': this.props.txtSearch}}: {};

		this.state = {
			columns: [
				<Translate value="components.labels.poId" />,
				<Translate value="components.labels.vendor" />,
				<Translate value="components.labels.status" />,
				<Translate value="components.labels.notes" />,
				<Translate value="components.labels.orderDate" />,
				<Translate value="components.labels.dueDate" />,
				<Translate value="components.labels.amount" />,
			],
			fields: ['id', 'vendorName', 'statusName', 'notes', 'orderDate', 'dueDate', 'amount'],
			totalField: 'amount',
			data: [],
			filter: filter,
			newPurchaseOrderFormVisible: false,
			showEditPurchaseOrderFormVisible: false,
			editPurchaseOrderId: null,
			showEditPurchaseOrder: false,
			orderType: '',
			loading: true,
			editVendorId: null,
			autoRefresh: true,
			interval: null,
		};

		this.orderTypeChanged = this.orderTypeChanged.bind(this);
		this.showEditPurchaseOrderForm = this.showEditPurchaseOrderForm.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
	}
	refreshData() {
		this.setState({loading: true});
		getAPI("purchaseorders/list")
		.then(
			(result) => {
				var data = result['data'];
				data = data.map(obj => {
					obj.bgColor = obj.openOrderType == 1 ? '#ffaaaa' : '';
					return obj;
				});
				this.setState({loading: false, data: data});
			},
			(error) => {this.setState({loading: false, error});}
		);
	}
	
	componentDidMount() {
		this.refreshData();
		
		
		const interval = setInterval(async () => {
			if(this.state.autoRefresh && !this.state.showEditPurchaseOrderFormVisible) {
				// console.log("PurchaseOrder::componentDidMount() - refreshData being called....");
				this.refreshData();
			}
		}, Config.keepConnInterval);
		this.setState({interval: interval});
	}
	
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	orderTypeChanged(event) {
		var val = event.target.value;
		var filter = this.state.filter;
		filter['statusName'] = val;
		this.setState({
			filter: filter,
			orderType: val,
		});
	}
	
	showEditPurchaseOrderForm(dataIn) {
		this.setState({
			editPurchaseOrderId: dataIn['id'],
			editPurchaseOrderFormVisible: true,
		});
	}
	
	onLinkClick(event) {
		const editId = event.target.id;
		const varName = event.target.name;
		console.log("PurchaseOrder::onLinkClick() - editId: ", editId, ", varName: ", varName);
		this.setState({
			[varName]: editId,
		});
	}

	render() {
		const loggedInUser = getLoggedInUser();
		var orderTypes = {
			'': 'All', // <Translate value='components.labels.filterAll' />,
			'Completed': 'Completed', // <Translate value='components.labels.filterCompleted' />,
			'New': 'New', // <Translate value='components.labels.filterNew' />,
			'Saved': 'Saved', // <Translate value='components.labels.filterSaved' />,
			'Submitted': 'Submitted', // <Translate value='components.labels.filterSubmitted' />,
		}
		const formatColumns = {
			1: {'link': 
				{'column': 'vendorId', 'var': 
				'editVendorId', 'func': this.onLinkClick}
			},
			4: {'date': true},
			5: {'date': true}, 
			6: {'currency': true, colLocale: 'vendorLocale', 'bgColor': 'bgColor'}, 
		};

		var data = this.state.data;
		
		// if(this.state.orderType != '')
		// {
		// 	data = this.state.data.filter(obj => obj.status == this.state.orderType);
		// }
		
		
		if(this.state.orderType != '')
		{
			data = data.filter(obj => obj.statusName == this.state.orderType);
		}
		
		// Global Search
		if(this.props.search) {
			data = data.filter(obj => {
				const strSearchString = this.state.fields.map((field, i) => { 
					return formatColumns[i] && formatColumns[i]['date'] ? 
						convertTimestampToDate(obj[field]) : obj[field];
				}).join("~").toLowerCase();
				return strSearchString.indexOf(this.props.search.toLowerCase()) !== -1;
			});
		}
		
		const newPurchaseOrderForm = this.state.newPurchaseOrderFormVisible ? <NewPurchaseOrderForm
				visible={this.state.newPurchaseOrderFormVisible}
				onClose={() => this.setState({newPurchaseOrderFormVisible: false})}
				onSave={() => {
					this.setState({newPurchaseOrderFormVisible: false});
					this.refreshData();	
				}} /> : "";

		const editPurchaseOrderForm = this.state.editPurchaseOrderFormVisible ? <NewPurchaseOrderForm
				visible={this.state.editPurchaseOrderFormVisible}
				onClose={() => this.setState({editPurchaseOrderFormVisible: false})}
				onSave={() => {
					this.setState({editPurchaseOrderFormVisible: false});
					this.refreshData();
				}}
				editPurchaseOrderId={this.state.editPurchaseOrderId} /> : "";
		
		const editVendorForm = this.state.editVendorId != null ? <NewVendorForm
					visible={this.state.editVendorId != null}
					onClose={() => this.setState({editVendorId: null})}
					onSave={() => {
						this.setState({editVendorId: null});
						this.refreshData();
					}}
					editVendorId={this.state.editVendorId}
				/> : "";
					
		return (
			<div>
				<DataGrid 
					loading={this.state.loading}
					columns={this.state.columns}
					fields={this.state.fields}
					totalField={this.state.totalField}
					importantColumns={[1, 2]}
					data={data}
					filter={this.state.filter}
					formatColumns={formatColumns}
					txtSearch={this.props.txtSearch}
					onDoubleClick={this.showEditPurchaseOrderForm}
					limit={1000}
				/>

				<Row>
					<Col md="2"></Col>
					<Col md="4">
						<Form class='form-page' horizontal>
							<DataDropdown 
								label={<Translate value="components.labels.type" />} action={this.orderTypeChanged} 
								data={orderTypes} 
								value={this.state.orderType}
							/>
						</Form>
					</Col>
					<Col md="4">
						<Button bsSize='sm' onClick={() => this.setState({newPurchaseOrderFormVisible: true})}  disabled={loggedInUser.accessPOCode == 'V'}><Translate value="components.buttons.newPurchaseOrder" /></Button>
					</Col>
				</Row>
				{newPurchaseOrderForm}
				{editPurchaseOrderForm}
				{editVendorForm}
			</div>
		);
	}
}

export default PurchaseOrder;

