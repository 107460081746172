import React, { Component } from 'react';
import TabContainer from '../common_components/TabContainer';
import Users from './Users';
import Vendors from './Vendors';
import Shippers from './Shippers';
import Administration from './Administration';
import Company from './Company';
import ViscosityCups from './ViscosityCups';
import QBOConfig from './QBOConfig';
import {getLoggedInUser} from '../common_components/common.js';
import Config from '../Config';
const { Translate } = require('react-i18nify');


class Setting extends Component {
	constructor(props) {
		super(props);
		
		this.tabTitles = [
			<Translate value='components.settings.users' />,
			<Translate value='components.settings.vendors' />,
			<Translate value='components.settings.shippers' />,
			<Translate value='components.settings.administration' />,
			<Translate value='components.settings.company' />,
			// <Translate value='components.settings.viscocityCups' />,
		];
	}

	render() {
		const loggedInUser = getLoggedInUser();
		const bQBOConfig = Config.env != "mex" && (loggedInUser.userName == 'mmurphy' || loggedInUser.userName == 'rburton');
		const tabs = [
			<Users search={this.props.search} />,
			<Vendors search={this.props.search} />,
			<Shippers search={this.props.search} />,
			<Administration search={this.props.search} />,
			<Company />,
			// <ViscosityCups />,
		];
		
		const tabsShown = [];
		const tabTitlesShown = [];
		
		if(loggedInUser.userMaintenanceFlag) {
			tabsShown.push(tabs[0]);
			tabTitlesShown.push(this.tabTitles[0]);
		}
		
		if(loggedInUser.accessVendorsCode != 'N') {
			tabsShown.push(tabs[1]);
			tabTitlesShown.push(this.tabTitles[1]);
		}
		
		if(loggedInUser.accessShippersCode != 'N') {
			tabsShown.push(tabs[2]);
			tabTitlesShown.push(this.tabTitles[2]);
		}
		// If User is not a Customer
		if(loggedInUser.userTypeId != 7) {
			tabsShown.push(tabs[3]);
			tabTitlesShown.push(this.tabTitles[3]);
		}
		
		if(loggedInUser.editCompanyFlag) {
			tabsShown.push(tabs[4]);
			tabTitlesShown.push(this.tabTitles[4]);
		}
		
		if(bQBOConfig) {
			tabsShown.push(<QBOConfig />);
			tabTitlesShown.push(<Translate value='components.settings.QBO Config' />);
		}
		
		return (
			<div>
				<TabContainer tabTitles={tabTitlesShown} tabs={tabsShown}/>
			</div>
		);
	}
}

export default Setting;
