export default {
	"tabs":{
		"orders": "Orders",
		"batches": "Batches",
		"labRequests": "Lab Requests",
		"matches": "Matches",
		"actionableItems": "Actionable Items",
		"goals": "Goals",
		"approvedProcessNotes": "Approved Process Notes",
		"toBePriced": "To be Priced",
		"emailsToBeSent": "Emails to be Sent",
		"customers" : "Customers",
		"formulas": "Formulas",
		"quoted": "Quoted",
		"tasks": "Tasks",
		"callReports": "Call Reports",
		"salesSection": "Sales Section",
		"expenditures": "Expenditures",
		"prospectCalls": "Prospect Calls",
		"substrates": "Substrates",
		"viscosityCups": "Viscosity Cups",
	}
}
