import React, { Component } from 'react';
import DataDropdown from '../common_components/DataDropdown.js';
import {Row, Col, Button} from 'react-bootstrap';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import ReactHighcharts from 'react-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import {mapArrayToObject, getAPI, postAPI, barChartOptions, pieChartOptions, lineChartOptions, getLoggedInUser, constants, toCurrency, objMonths} from '../common_components/common.js';
// var config = require('../config.js');
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');


//	PROPS params
//	New approach: Filter the data within the component before passing it on to the grid.
class SalesGoals extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			overallData: [],
			salesRepId: null,
			userId: null,
			salesRepData: [],
			monthlyData: [],
			bSalesManagerOrVIP: false,
			salesRepList: [],
			customersData: [],
			year: (new Date()).getFullYear(),
			month: (new Date()).getMonth() + 1,
			yearsList: [], 
			salesGoalsAndBudgets: [],
		};
		this.salesRepChanged = this.salesRepChanged.bind(this);
		this.yearChanged = this.yearChanged.bind(this);
		this.monthChanged = this.monthChanged.bind(this);
		this.getSalesRepData = this.getSalesRepData.bind(this);
		this.refreshData = this.refreshData.bind(this);
		
	}
	
	
	componentDidMount() {
		this.refreshData(this.state.year);
	}
	
	refreshData(pYear) {
		const loggedInUser = getLoggedInUser();
		const userId = loggedInUser['id'];
		const bSalesManagerOrVIP = 
		loggedInUser.userTypeId == constants.sales_manager_type_id || 
		loggedInUser.userTypeId == constants.vip_type_id;
		
		/*
		getAPI("/common/salesgoals?userId=0&companyId=1").then(overallData => {
			getAPI("/common/salesgoals/customers").then(customersData => {
		
				getAPI("/common/salestotals/month").then(monthlyData => {
					getAPI("/users/salesreps").then(salesRepList => {
						// console.log("overallData: ", overallData, ", monthlyData: ", monthlyData);
						
				
						let salesRepId = !bSalesManagerOrVIP ? userId : 
						(salesRepList['data'][0] != undefined ? salesRepList['data'][0]['id'] : 0);
				
						this.setState({
							overallData: overallData['data'],
							userId: userId,
							monthlyData: monthlyData['data'],
							salesRepId: salesRepId,
							bSalesManagerOrVIP: bSalesManagerOrVIP,
							salesRepList: salesRepList['data'],
							customersData: customersData['data'],
						});
					});
				});
			});
		});
		*/
		
		//////////////////// Samee - Get Sales Total and Goals ///////////////////////////
		
		// getAPI("/users/salesreps").then(salesRepList => {
			// console.log("overallData: ", overallData, ", monthlyData: ", monthlyData);
			
	
		getAPI("/common/salesgoalsandbudgets?year=" + pYear).then(resSalesGoalsAndBudgets => {
			const salesGoalsAndBudgets = resSalesGoalsAndBudgets['data'];
			
			var salesRepList = salesGoalsAndBudgets.map(row => ({
				id: row.userId,
				name: row.userDisplayName,
			}));
			getAPI("/common/salestotalsandgoals?year=" + pYear).then(salesTotalAndGoals => {
				// console.log("salesTotalAndGoals: ", salesTotalAndGoals);
				var overallData = [];
				var customersData = [];
				var monthlyData = [];
				// var salesRepList = [];
				
				salesTotalAndGoals['data'].reduce(function(res, value) {
					
					var arrSalesRepName = value.salesRepName.split(" ");
					const salesGoalAndBudgetForSalesRep = salesGoalsAndBudgets.filter(row => row.userId == value.salesRepId)[0];
					const yearlySalesGoalForSalesRep = salesGoalAndBudgetForSalesRep != undefined ? salesGoalAndBudgetForSalesRep['totalSalesGoal'] : 0;
					// OverallData
					if(res[value.salesRepId] == undefined) {
						res[value.salesRepId] = { 
							userId: value.salesRepId, 
							salesRep: value.salesRepName, 
							salesGoal: value.customerSalesGoal, 
							salesTotal: 0.0,
							yearlySalesGoal: yearlySalesGoalForSalesRep, // value.totalSalesGoal,
						};
						overallData.push(res[value.salesRepId]);
						/*salesRepList.push({
							id: value.salesRepId, 
							name: value.salesRepName, 
							firstName: arrSalesRepName[0], 
							lastName: arrSalesRepName[1]
						});*/
					}
					// res[value.salesRepId].salesGoal = value.customerSalesGoal;
					res[value.salesRepId].salesTotal += value.salesTotal;
					
					
					// CustomersData
					if(res[value.salesRepId][value.customerId] == undefined) {
						res[value.salesRepId][value.customerId] = { 
							userId: value.salesRepId, 
							salesRep: value.salesRepName, 
							salesGoal: value.customerSalesGoal, 
							salesTotal: 0.0,
							customerId: value.customerId,
							customerName: value.customerName,
							yearlySalesGoal: yearlySalesGoalForSalesRep, // value.totalSalesGoal,
						};
						customersData.push(res[value.salesRepId][value.customerId]);
					}
					// res[value.salesRepId][value.customerId].salesGoal = value.customerSalesGoal;
					res[value.salesRepId][value.customerId].salesTotal += value.salesTotal;
					
					// MonthlyData
					if(res[value.salesRepId][value.customerId][value.month] == undefined) {
						res[value.salesRepId][value.customerId][value.month] = { 
							userId: value.salesRepId, 
							customerName: value.customerName,
							salesTotal: 0.0,
							month: value.month,
						};
						monthlyData.push(res[value.salesRepId][value.customerId][value.month]);
					}

					res[value.salesRepId][value.customerId][value.month].salesTotal += value.salesTotal;
					
					
					return res;
				}, {});

				// console.log("overallData: ", overallData);
				// console.log(", customersData: ", customersData);
				// console.log(", monthlyData: ", monthlyData);
				
				// let salesRepId = !bSalesManagerOrVIP ? userId : (salesRepList[0] != undefined ? salesRepList[0]['id'] : 0);
				
				var currentYear = (new Date()).getFullYear();
				var yearsList = [];
				for(var startYear = currentYear; startYear >= 2016 ; startYear--)  
					yearsList.push({id: startYear, name: startYear});
				
				this.setState({
					overallData: overallData,
					userId: userId,
					monthlyData: monthlyData,
					// salesRepId: salesRepId,
					bSalesManagerOrVIP: bSalesManagerOrVIP,
					salesRepList: salesRepList,
					customersData: customersData,
					yearsList: yearsList,
					year: pYear,
					salesGoalsAndBudgets: salesGoalsAndBudgets,
				});
			});
		});
		// });
	}
	
	yearChanged(event) {
		const pYear = event.target.value;
		this.refreshData(pYear);
	}
	
	monthChanged(event) {
		const pMonth = event.target.value;
		this.setState({
			month: pMonth,
		});
	}
	
	salesRepChanged(event) {
		const salesRepId = event.target.value;
		this.setState({
			salesRepId: salesRepId,
		});
	}
	
	getSalesRepData(salesRepId) {
		getAPI("/common/salesgoals/customers?userId=" + salesRepId).then(salesRepData => {
			// console.log("salesRepData: ", salesRepData);
			this.setState({
				salesRepData: salesRepData['data'],
				salesRepId: salesRepId,
			});
		});
	}
	
	
	
	
	
	render() {
		HC_exporting(Highcharts);
		let salesRepsMapped = mapArrayToObject(this.state.salesRepList, 'id', 'name');
		let yearsMapped = mapArrayToObject(this.state.yearsList, 'id', 'name');
		// console.log("Order::render() called - this.props: ", this.props, ", this.state.data: ", this.state.data);
		const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const objCurrentDate = new Date();
		const currentYear = this.state.year; // objCurrentDate.getFullYear();
		const currentMonth = this.state.month; // objCurrentDate.getMonth() + 1; // Month returned is 0 based.
		let salesRepId = this.state.salesRepId;
		if(!this.state.bSalesManagerOrVIP && salesRepId == null)
			salesRepId = this.state.userId;
		
		var salesRepData = this.state.customersData.filter(obj => obj.userId == salesRepId); // this.state.salesRepData;
		var overallData = this.state.overallData;
		var monthlyData = this.state.monthlyData;
		
		
		let salesRepName = "";
		let salesRepYearlySalesGoal = 0;
		let customerYearlySalesGoal = 0;
		const salesRepRow = this.state.salesGoalsAndBudgets.filter(obj => obj.userId == salesRepId)[0]; // overallData.filter(obj => obj.userId == salesRepId)[0];
		if(salesRepRow != undefined) {
			// Samee use yearlySalesGoal instead
			salesRepYearlySalesGoal = salesRepRow.totalSalesGoal; // yearlySalesGoal; // salesRepRow.salesGoal;
			salesRepName = salesRepRow.userDisplayName; // salesRep;
		}
		
		
		let overallYearlySalesGoal = 0;
		// overallData.forEach(obj => {
			// Samee use yearlySalesGoal instead
		// 	overallYearlySalesGoal += obj.yearlySalesGoal; // obj.salesGoal;
		// });
		
		this.state.salesGoalsAndBudgets.forEach(obj => {
			// Samee use yearlySalesGoal instead
			overallYearlySalesGoal += obj.totalSalesGoal;
		});
		
		
		
		salesRepData.forEach(obj => {
			customerYearlySalesGoal += obj.salesGoal;
		});
		
		// console.log("salesRepId: ", salesRepId, ", salesRepName: ", salesRepName, ", currentYear: ", currentYear, ", currentMonth: ", currentMonth, ", salesRepYearlySalesGoal: ", salesRepYearlySalesGoal, ", overallYearlySalesGoal: ", overallYearlySalesGoal);
		
		/////////////////////// SALES REP CHARTS /////////////////////
		var salesRepPieChartOptions = JSON.parse(JSON.stringify(pieChartOptions));
		var salesRepBarChartOptions = JSON.parse(JSON.stringify(barChartOptions));
		var salesRepLineChartOptions = JSON.parse(JSON.stringify(lineChartOptions));
		
		/////////////////////////// Pie Chart //////////////////////////////
		salesRepPieChartOptions.title.text = "Accounts Total % of " + currentYear + " Sales - " + salesRepName;
		salesRepPieChartOptions.series[0].data = salesRepData.map(obj => ({name: obj.customerName, x: obj.salesGoal, y:obj.salesTotal}));
		
		
		///////////////////////// Bar Chart //////////////////////////
		let totalSalesAmount = 0;
		let totalMonthlySalesGoal = Math.round((salesRepYearlySalesGoal / 12) * 100) / 100; // 0;
		monthlyData.filter(row => (row.userId == salesRepId && row.month == currentMonth)).map(obj => {
			salesRepBarChartOptions.xAxis.categories.push(obj.customerName);
			const salesGoalRow = salesRepData.filter(salesRepRow => salesRepRow.customerName == obj.customerName)[0];
			const salesGoal = salesGoalRow != undefined ? Math.round((salesGoalRow.salesGoal / 12) * 100) / 100 : 0;
			// totalMonthlySalesGoal += salesGoal;
			salesRepBarChartOptions.series[0].data.push(salesGoal);
			salesRepBarChartOptions.series[1].data.push(obj.salesTotal);
			totalSalesAmount += obj.salesTotal;
		});
		let percentSalesGoal = totalMonthlySalesGoal > 0 ? ((totalSalesAmount * 100) / totalMonthlySalesGoal) : 0;
		// if(percentSalesGoal > 100)
		//	percentSalesGoal = 100.0;
		percentSalesGoal = percentSalesGoal.toFixed(2);
		salesRepBarChartOptions.title.text = salesRepName + " (Monthly Sales Goal: " + toCurrency(totalMonthlySalesGoal) + ", Total Sales: " + toCurrency(totalSalesAmount) + " - " + percentSalesGoal + "%)";

		////////////////////////// Line Chart ////////////////////////////
		salesRepLineChartOptions.title.text = currentYear + " Sales - " + salesRepName;
		
		let accumulatedSalesGoal = 0;
		let accumulatedSalesTotal = 0;
			
		monthsList.map((month, i) => {
			const salesGoal = salesRepYearlySalesGoal / 12;
			let salesTotal = 0;
			monthlyData.filter(obj => obj.userId == salesRepId 
				&& obj.month == (i + 1)).forEach(row => {
				salesTotal += row.salesTotal;	
			});
			/*
			salesRepBarChartOptions.xAxis.categories.push(i + 1);
			salesRepBarChartOptions.series[0].data.push(salesGoal);
			salesRepBarChartOptions.series[1].data.push(salesTotal);
			*/
			accumulatedSalesGoal += salesGoal;
			accumulatedSalesTotal += salesTotal;
			
			salesRepLineChartOptions.xAxis.categories.push(month);
			salesRepLineChartOptions.series[0].data.push(Math.round(accumulatedSalesGoal * 100) / 100);
			salesRepLineChartOptions.series[1].data.push(Math.round(accumulatedSalesTotal * 100) / 100 );
		});
		

		
		
		/////////////////////// OVERALL CHARTS /////////////////////
		var overallPieChartOptions = JSON.parse(JSON.stringify(pieChartOptions));
		var overallBarChartOptions = JSON.parse(JSON.stringify(barChartOptions));
		var overallLineChartOptions = JSON.parse(JSON.stringify(lineChartOptions));
		
		// Pie Chart
		overallPieChartOptions.title.text = "Percent of Overall Sales";
		// Samee - User yearlySalesGoal instead
		// overallPieChartOptions.series[0].data = overallData.map(obj => ({name: obj.salesRep, x: obj.salesGoal, y:obj.salesTotal}));
		overallPieChartOptions.series[0].data = overallData.map(obj => ({name: obj.salesRep, x: obj.yearlySalesGoal, y:obj.salesTotal}));
		console.log("overallPieChartOptions: ", overallPieChartOptions);
		// Bar Chart
		overallBarChartOptions.title.text = "Individual Goals/Sales";
		overallBarChartOptions.xAxis.categories = overallData.map(obj => obj.salesRep);
		// Samee - User yearlySalesGoal instead
		// overallBarChartOptions.series[0].data = overallData.map(obj => obj.salesGoal);
		overallBarChartOptions.series[0].data = overallData.map(obj => obj.yearlySalesGoal);
		overallBarChartOptions.series[1].data = overallData.map(obj => obj.salesTotal);
		
		// Line Chart
		overallLineChartOptions.title.text = "Overall Sales/Goal";
		let overallAccumulatedSalesGoal = 0;
		let overallAccumulatedSalesTotal = 0;
		
		monthsList.map((month, i) => {
			const salesGoal = overallYearlySalesGoal / 12;
			let salesTotal = 0;
			const dataRows = monthlyData.filter(obj => obj.month == (i + 1));
			if(dataRows != undefined) {
				dataRows.forEach(row => {
					salesTotal += row.salesTotal;
				});
			}
			
			overallAccumulatedSalesGoal += salesGoal;
			overallAccumulatedSalesTotal += salesTotal;
			
			overallLineChartOptions.xAxis.categories.push(month);
			overallLineChartOptions.series[0].data.push(Math.round(overallAccumulatedSalesGoal * 100) / 100 );
			overallLineChartOptions.series[1].data.push(Math.round(overallAccumulatedSalesTotal * 100) / 100  );
		});
		
		
		return (
			<div>
				<DataDropdown 
					label={<Translate value="components.labels.year" />} 
					id={'year'} name={'year'} data={yearsMapped} 
					action={this.yearChanged} value={this.state.year} 
				/>
				{this.state.bSalesManagerOrVIP && <DataDropdown 
					label={<Translate value="components.labels.salesRep" />} 
					id={'salesRep'} name={'salesRep'} data={salesRepsMapped} 
					action={this.salesRepChanged} value={this.state.salesRep} 
				/>}
				<br />
				<HighchartsReact
					highcharts={Highcharts}
					options={salesRepPieChartOptions}
				  />
{/*<ReactHighcharts config={salesRepPieChartOptions} />*/}
				  <br />
				  <DataDropdown 
					label={<Translate value="components.labels.month" />} 
					id={'month'} name={'month'} data={objMonths} 
					action={this.monthChanged} value={this.state.month} 
				/>
				<HighchartsReact
					highcharts={Highcharts}
					options={salesRepBarChartOptions}
				  />
				  <HighchartsReact
					highcharts={Highcharts}
					options={salesRepLineChartOptions}
				  />
				  
				<br />
				{this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallPieChartOptions}
				  />}
				 {this.state.bSalesManagerOrVIP && <HighchartsReact
					highcharts={Highcharts}
					options={overallBarChartOptions}
				  />}
				  <HighchartsReact
					highcharts={Highcharts}
					options={overallLineChartOptions}
				  />
			</div>
		);
	}
}

export default SalesGoals;